import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserLoginResponse } from '../api/ResponseSchemas';
import { LoginScreen } from './Login';
import { RegisterScreen } from './Register';
import { RegisterScreenStep2 } from './Register/RegisterScreenStep2';
import { Routes } from '../routes';
import { useStonlyWidget } from 'conversifi-shared-react/es6/hooks/useStonlyWidget';
import { UserSource } from 'conversifi-types/src/User/UserSource';

interface Props {
  onLoggedIn: (onLoggedIn: UserLoginResponse) => void;
  onDetectUserSource: (userSource: UserSource) => void;
}

const AnonymousApp: React.FC<Props> = (props) => {
  const { onLoggedIn, onDetectUserSource } = props;
  useStonlyWidget();

  return (
    <React.Fragment>
      <Switch>
        <Route
          path={Routes.login.uri}
          render={() => <LoginScreen onUserLoggedIn={onLoggedIn} />}
        />
        <Route
          path={Routes.register.uri}
          exact
          render={() => (
            <RegisterScreen onDetectUserSource={onDetectUserSource} />
          )}
        />
        <Route
          path={`${Routes.register.uri}/:code`}
          render={(routeProps) => (
            <RegisterScreenStep2
              {...routeProps}
              onDetectUserSource={onDetectUserSource}
            />
          )}
        />
        <Route path="*">
          {/* This is a  "catch all", like a Not Found but instead of showing
          a not found screen, we redirect the user back to the login page.
          The user can hit this endpoint if for example he is not logged in
          but enters a url like "/admin" or "/instructor" */}
          <Redirect to={Routes.login.uri} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default AnonymousApp;
