import { UtilsSdk } from '../../sdk';
export const createLogger = (title) => {
    const logger = {};
    title = title.trim();
    if (!title) {
        throw new Error('[title] is required and can not be empty');
    }
    Object.keys(console).forEach((k) => {
        const cmd = k;
        logger[cmd] = (...args) => console[cmd](title, '>', ...args);
    });
    logger.grouped = (groupTitle, fn) => {
        console.group(`${title} > ${groupTitle}`);
        const result = UtilsSdk.resolveValue(fn(logger));
        console.groupEnd();
        return result;
    };
    return logger;
};
