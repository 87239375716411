import ActiveSearchesContext from '../index';
import { ConversifiSocketBackendEventsData } from 'conversifi-types/src/Socket/types/ConversifiEvents';

export default function (this: ActiveSearchesContext) {
  this.props.socketCtx.emit('JOIN_ROOM', { room: 'LIVE_ALERTS' });
  this.props.socketCtx.once(
    'JOINED_ROOM',
    (data: ConversifiSocketBackendEventsData<'JOINED_ROOM'>) => {
      if (data.room === 'LIVE_ALERTS') {
        this.props.liveAlertSnackbar.setEphemeralMessage({
          message: this.props.trans({
            id: 'admin.live-alerts.websocket.joined-to-room',
            message: 'Joined to "Live Alerts" room',
          }),
          autoHideDuration: 3000,
          severity: 'info',
        });

        this.props.socketCtx.on(
          'LIVE_ALERTS_UPDATED',
          this.onLiveAlertsUpdated
        );
      }
    }
  );

  this.props.socketCtx.socket?.io.on('reconnect', this.onReconnect);
  this.props.socketCtx.onStateEvent('disconnect', () => {
    this.props.liveAlertSnackbar.setEphemeralMessage({
      message: this.props.trans({
        id: 'websocket.socket-disconnected',
        message: 'Socket disconnected!',
      }),
      severity: 'error',
      autoHideDuration: 2000,
    });
    this.leaveRoom();
  });
}
