import { requestUserLogout } from '../requests';
import { clear as zendeskClear } from 'conversifi-shared-react/es6/util/zendesk/clear';
import { apiErrorHandler } from '../apiErrorHandler';

export async function userLogout() {
  zendeskClear();

  try {
    await requestUserLogout();
  } catch (error) {
    throw apiErrorHandler(error);
  }
}
