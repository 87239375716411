import React, { FC } from 'react';
import Navbar from './app/components/Navbar';
import cssModule from './style.module.css';
import AppLoading from '../App.loading';
import type { User } from 'conversifi-types/src/User/User';
import './instructor-styles.css';
import SidebarContext from 'conversifi-shared-react/es6/components/SidebarContext';
import { getSidebarItems } from './utils/getSidebarItems';
import { useHistory } from 'react-router';
import UserSession from 'conversifi-shared-react/es6/components/UserSession';

const Setup = React.lazy(() => import('./app/Setup'));

interface Props {
  instructor: User;
}

const InstructorsPortal: FC<Props> = ({ instructor }) => {
  const history = useHistory();
  const sidebarItems = getSidebarItems({ history });

  return (
    <div className={[cssModule.root, 'instructor-portal'].join(' ')}>
      <UserSession>
        <SidebarContext items={sidebarItems} user={instructor} app="instructor">
          <Navbar userName={instructor.email} />
          <React.Suspense fallback={<AppLoading />}>
            <Setup session={instructor} />
          </React.Suspense>
        </SidebarContext>
      </UserSession>
    </div>
  );
};

export default InstructorsPortal;
