import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../routes';
import { Typography } from '@mui/material';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import {
  clearLocalSession,
  getLocalSession,
  saveLocal,
} from 'conversifi-shared-react/es6/util/session';
import { UserLoginResponse } from '../api/ResponseSchemas';
import { LtiSdk } from '../sdk/lti.sdk';
import refreshSessionExpirationDate from 'conversifi-shared-react/es6/util/session/refreshSessionExpirationDate';

interface LtiScreenProps {
  onLoggedIn: (onLoggedIn: UserLoginResponse) => void;
}

export const LtiScreen: React.FC<LtiScreenProps> = ({ onLoggedIn }) => {
  const history = useHistory();
  const snackbarCtx = SnackbarContext.useCtx();

  const handleLtiLogin = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(history.location.search);
      const token = searchParams.get('token');

      if (token) {
        const { token: localToken } = getLocalSession();
        if (localToken) {
          clearLocalSession();
        }

        saveLocal(token);
        const userResponse = await LtiSdk.requestUser(token);
        refreshSessionExpirationDate();

        onLoggedIn({
          token,
          userApi: userResponse,
          APP_VERSION: userResponse.APP_VERSION,
          subscriptionFeeStatus: userResponse.subscriptionFeeStatus,
          user: (userResponse as unknown) as UserLoginResponse['user'],
        });

        history.push(Routes.root.uri);
        return window.location.reload();
      }
    } catch (err) {
      const defaultTranslationKey = 'lti.error-authenticating-user';

      snackbarCtx.showSnackbarTranslatedErrorMessage(
        err,
        defaultTranslationKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLtiLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  return (
    <Typography textAlign="center" variant="h6" color="primary">
      Conversifi LTI
    </Typography>
  );
};
