import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
const PublicContext = React.createContext({});
export class LinguiContext extends BaseProvider {
    className = 'LinguiContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    componentDidUpdate() {
        if (this.props.languagesCtx.state.languagesLoaded) {
            this.loadCatalog();
        }
    }
    getInitialState() {
        return {
            translationsDownloaded: false,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            trans: this.trans,
            reloadTranslations: this.reloadTranslations,
        };
    }
    async readLocalTranslations() {
        const languages = this.props.languagesCtx
            .getEnabledLanguages({
            excludeOthers: true,
        })
            .map((lang) => lang.langCode);
        let userLang = this.props.languagesCtx.getDefaultLang().langCode;
        for (const lang of languages) {
            try {
                const translations = localStorage.getItem(`${lang}-translations`);
                if (translations) {
                    i18n.load(lang, JSON.parse(translations));
                    if (this.props.userLang === lang) {
                        userLang = lang;
                    }
                }
            }
            catch (error) {
                this.logger.error('Error reading local translations:', error);
            }
        }
        i18n.activate(userLang);
    }
    async downloadTranslations() {
        const languages = this.props.languagesCtx
            .getEnabledLanguages({
            excludeOthers: true,
        })
            .map((lang) => lang.langCode);
        for (const lang of languages) {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_AWS_BUCKET_TRANSLATIONS}.s3.amazonaws.com/${lang}/messages.mjs`, {
                    cache: 'reload',
                });
                const moduleText = await response.text();
                const sanitizedModuleText = moduleText
                    .replace(/export\s+const\s+messages\s*=\s*/, '')
                    .replace(/\/\*.*?\*\//g, '')
                    .trim();
                const translationsModule = new Function(`return ${sanitizedModuleText}`)();
                i18n.load(lang, translationsModule);
                localStorage.setItem(`${lang}-translations`, JSON.stringify(translationsModule));
            }
            catch (error) {
                console.error('Error loading translations:', error);
            }
        }
    }
    async loadCatalog() {
        await this.readLocalTranslations();
        if (!this.state.translationsDownloaded) {
            await this.downloadTranslations();
            this.produce((draft) => {
                draft.translationsDownloaded = true;
            });
        }
    }
    reloadTranslations = async () => {
        await this.downloadTranslations();
        this.forceUpdate();
    };
    trans = i18n._.bind(i18n);
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() },
            React.createElement(I18nProvider, { i18n: i18n }, this.props.children)));
    }
}
