import { requestCouponList } from '../requests';
import type {
  Coupon,
  FilterCouponType,
  SelectFilter,
} from 'conversifi-types/src/Coupon';
import { PaginatedSize } from 'conversifi-types/src/shared-frontend-backend/PaginatedResult';

interface Params {
  offset: number;
  limit: PaginatedSize;
  sortField: string;
  sortOrder: number;
  searchKey?: string;
  type?: FilterCouponType;
  selectedFilters?: SelectFilter[];
}

export const getCouponList = async (
  params: Params
): Promise<{
  docs: Coupon[];
  total: number;
}> => {
  return requestCouponList(params);
};
