export const JWT_TOKEN_NAME = 'ngStorage-userStorage';
export const SESSION_EXPIRATION_DATE = 'sessionExpirationDate';
export const SESSION_KEY_SUBSCRIPTION_FEE_STATUS = 'ngStorage-subscriptionFeeStatus';
export const SESSION_KEY_IN_CALL = 'ngStorage-inCall';
export const LAST_SEARCH_CONFIGURATION = 'searchConfiguration';
export const RECYCLE_USER = 'recycleUser';
export const LAST_CHECKED_CALL_ID = 'lastCheckedCallId';
export const LAST_LEDGER_DATA = 'lastLedgerData';
export const LAST_PAYMENT_TIMESTAMP = 'lastPaymentTimestamp';
export const PRIVACY_POLICY_URL = 'https://www.conversifi.com/privacy-policy';
export const TERMS_OF_USER_URL = 'https://www.conversifi.com/terms-of-use';
export const HELP_URL = 'https://conversifi.zendesk.com/hc/en-us';
export const BABBEL_HELP_URL = 'https://conversifi.zendesk.com/hc/en-us/categories/9933985142427-Babbel-Users-Connect-FAQs';
export const COMMON_DATA_FORMAT = 'MM/dd/yy';
export const S3_URL_REGEX = /\bs3\.amazonaws\.com\b/;
export const REDIS_EXPIRE_SESSION = Number(process.env.REACT_APP_REDIS_EXPIRE_SESSION || 432000);
export const CONVERSIFI_EVENT_USER_SESSION_EXTENDED = 'userSessionExtended';
export const ADMIN_URL = '/admin';
export const INSTRUCTOR_URL = '/instructor';
export const LOGOUT_URL = '/logout';
export const USER_VIEW_URL = '/search';
