import { Trans } from '@lingui/react';
import { useCloudinaryWidget } from 'conversifi-shared-react/es6/hooks/useCloudinaryWidget';
import { UserPicture } from 'conversifi-shared-react/es6/components/UserPicture/UserPicture';
import React from 'react';
import { Stack, Typography } from '@mui/material';

interface RegisterPictureProps {
  photoId?: string;
  onUploadSuccess: (photoId: string) => void;
}

export const RegisterPicture: React.FC<RegisterPictureProps> = ({
  photoId,
  onUploadSuccess,
}) => {
  const cloudinary = useCloudinaryWidget({
    context: 'registration',
    onUploadSuccess: (publicId: string) => {
      console.info('publicId', publicId);
      onUploadSuccess(publicId);
    },
  });

  const openWidget = () => {
    // @ts-ignore
    cloudinary.result.open();
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      my={2}
      onClick={openWidget}
      sx={{ cursor: 'pointer' }}
    >
      <UserPicture photoId={photoId} width={120} height={120} />
      <Typography sx={{ textDecoration: 'underline' }}>
        <Trans id="anonymous.register.inputs.upload-photo">Upload Photo</Trans>
      </Typography>
    </Stack>
  );
};
