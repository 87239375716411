import React, { createContext } from 'react';
import { produce } from 'immer';
export function createImmerApp() {
    const StateContext = createContext({});
    const useAppState = () => {
        return React.useContext(StateContext);
    };
    return {
        useAppState,
        context: StateContext,
        App: class ImmerApp extends React.Component {
            produce;
            constructor(props) {
                super(props);
                this.produce = (mutateFn) => {
                    this.setState((prev) => produce(prev, (draft) => mutateFn(draft)));
                };
                this.state = {
                    ...props.state,
                    produce: this.produce,
                };
            }
            render() {
                return (React.createElement(StateContext.Provider, { value: this.state }, this.props.children));
            }
        },
    };
}
