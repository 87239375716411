import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CustomTooltip } from '../components/CustomTooltip';
export const IsConnectedApp = () => {
    const [open, setOpen] = useState(false);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
        React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                    boxShadow: '2px 3px 28px 0px rgba(0,255,39,0.75)',
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: 'greenyellow',
                } }), titleKey: "websocket.connected.app", titleDefault: "All good - your network connection is stable", open: open, onClick: () => setOpen((prev) => !prev) })));
};
export const IsConnectingApp = ({ socketCtx }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (socketCtx.state.hasConnectedOnce) {
            setOpen(true);
        }
    }, [socketCtx.state.hasConnectedOnce, socketCtx.state.status]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
        React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                    boxShadow: '2px 3px 28px 0px rgb(255,107,0)',
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: 'orange',
                } }), titleKey: "websocket.reconnecting-websocket.app", titleDefault: "Hold tight \u2013 we detected a network interruption and are reconnecting you", open: open, onClick: () => setOpen((prev) => !prev) })));
};
export const IsClosedApp = ({ displayMessage, }) => {
    const [open, setOpen] = useState(true);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
        React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                    boxShadow: '2px 3px 28px 0px rgb(235,0,0)',
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                } }), titleKey: "websocket.socket-broken.app", titleDefault: "We\u2019re detecting a problem with your connection \u2013 please check your internet or find a stronger signal then refresh your browser to reconnect", open: open && displayMessage, onClick: () => setOpen((prev) => !prev) })));
};
