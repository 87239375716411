import { utcToZonedTime } from 'date-fns-tz';
import formatDate from 'date-fns-tz/format';
import dateFnsFormat from 'date-fns/format';
import { getLocaleByLang, getLocaleByUser } from '../locale/miscellaneous';
import ensureDate from 'conversifi-commons/utils/ensureDate';
export function formatDateInLocaleByLangCode(date, format, langCode, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const parsedDate = timezone ? utcToZonedTime(date, timezone) : date;
    return formatDate(parsedDate, format, {
        locale: getLocaleByLang(langCode),
        timeZone: timezone,
    });
}
export function formatDateInLocale(date, inputData, format) {
    function isUser(obj) {
        return (obj.hasOwnProperty('_id') &&
            typeof obj._id === 'string' &&
            obj.hasOwnProperty('email') &&
            typeof obj.email === 'string');
    }
    let locale = getLocaleByLang('en');
    if (typeof inputData === 'string') {
        locale = getLocaleByLang(inputData);
    }
    else if (typeof inputData === 'object') {
        if (isUser(inputData)) {
            locale = getLocaleByUser(inputData);
        }
        else {
            locale = inputData;
        }
    }
    return dateFnsFormat(ensureDate(date), format ?? 'P', { locale });
}
