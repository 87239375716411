import { getJwt, saveLocal, clearLocalSession } from '../util';
import { unexpectedResponse, unauthorizedResponse } from '../errors';
import HttpStatus from 'http-status-codes';
import AxiosInstance from './AxiosInstance';
const APPLICATION_JSON = 'application/json';
const response204error = (response) => {
    if (response.status !== 204) {
        throw new Error(response.statusText);
    }
};
export class UserSdk {
    static async getUniversity() {
        try {
            const { data } = await AxiosInstance.instance.get('/user/university');
            return data;
        }
        catch (err) {
            console.info(`[USDK81] couldn't get user university`, err);
            return null;
        }
    }
    static async sendTemporaryPassword(email) {
        const body = { email };
        const response = await fetch('/api/auth/forgot-password', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': APPLICATION_JSON,
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error('Failed to send temporary password');
        }
    }
    static async get() {
        const jwt = getJwt();
        if (!jwt) {
            return null;
        }
        const response = await fetch('/api/user', {
            headers: {
                Authorization: jwt,
            },
        });
        if (response.status === 200) {
            const userUniversity = await this.getUniversity();
            const user = await response.json();
            const userApi = user;
            return { user, userApi, userUniversity };
        }
        else if (response.status === 401) {
            clearLocalSession();
            return null;
        }
        throw unexpectedResponse(response);
    }
    static async login(creds) {
        let credsString;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        try {
            credsString = JSON.stringify({ ...creds, timeZone });
        }
        catch (err) {
            console.warn('[USDK116] json parsing error');
            throw err;
        }
        const loginResponse = await fetch('/api/auth/login', {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': APPLICATION_JSON,
            },
            body: credsString,
        });
        if (loginResponse.status === 200) {
            const loginData = await loginResponse.json();
            saveLocal(loginData.token, loginData.subscriptionFeeStatus);
            AxiosInstance.refreshAxiosInstance();
            const userUniversity = await this.getUniversity();
            return { user: loginData.user, userUniversity };
        }
        else if (loginResponse.status === 404 || loginResponse.status === 401) {
            console.info('[USDK158] invalid credentials');
            throw unauthorizedResponse(loginResponse);
        }
        throw unexpectedResponse(loginResponse);
    }
    static put(data) {
        const jwt = getJwt();
        return !jwt
            ? Promise.reject('User not logged in')
            : fetch('/api/user', {
                method: 'PUT',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
                body: JSON.stringify(data),
            }).then((response) => {
                if (response.status === HttpStatus.OK) {
                    return response.json().then((json) => {
                        return json;
                    });
                }
                else {
                    Promise.reject(response.statusText);
                }
                throw unexpectedResponse(response);
            });
    }
    static updatePicture(photoId) {
        const jwt = getJwt();
        return !jwt
            ? Promise.resolve(null)
            : fetch('/api/user/picture', {
                method: 'PUT',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
                body: JSON.stringify({
                    photoId,
                }),
            }).then((response) => {
                return response.status === HttpStatus.NO_CONTENT
                    ? Promise.resolve(null)
                    : Promise.reject(null);
            });
    }
    static usersInImpasse() {
        const jwt = getJwt();
        return !jwt
            ? Promise.resolve(null)
            : fetch('/api/user/usersInImpasse', {
                method: 'GET',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
            }).then((response) => {
                return response.status === HttpStatus.OK
                    ? response.json().then((data) => data)
                    : Promise.reject(response.statusText);
            });
    }
    static updateNativeLanguage(code) {
        const jwt = getJwt();
        return !jwt
            ? Promise.reject()
            : fetch('/api/user/nativelanguages/edit', {
                method: 'PUT',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
                body: JSON.stringify({
                    code,
                }),
            }).then((response) => {
                return response.status === HttpStatus.OK
                    ? Promise.resolve()
                    : Promise.reject();
            });
    }
    static changePassword(oldPassword, newPassword) {
        const jwt = getJwt();
        return !jwt
            ? Promise.resolve(null)
            : fetch('/api/auth/change-password', {
                method: 'POST',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
                body: JSON.stringify({
                    oldPassword,
                    newPassword,
                }),
            }).then((response) => {
                return response.status === HttpStatus.NO_CONTENT
                    ? Promise.resolve(null)
                    : Promise.reject(null);
            });
    }
    static alreadyExists(type, identity) {
        const jwt = getJwt();
        return !jwt
            ? Promise.resolve(false)
            : fetch(`/api/auth/exists/${type}/${identity}`, {
                method: 'GET',
                headers: {
                    Authorization: jwt,
                },
            }).then((response) => {
                return Promise.resolve(response.status === HttpStatus.NO_CONTENT);
            });
    }
    static async checkLoggedIn() {
        const user = await this.get();
        return Boolean(user);
    }
    static Coach = {
        canApplyAsPaidCoach: () => {
            const jwt = getJwt();
            return !jwt
                ? Promise.reject(null)
                : fetch('/api/user/canApplyAsPaidCoach', {
                    method: 'GET',
                    headers: {
                        Authorization: jwt,
                    },
                })
                    .then((response) => {
                    return response.json().then((json) => json);
                })
                    .catch((err) => {
                    return Promise.reject(err);
                });
        },
        applyAsPaidCoach: () => {
            const jwt = getJwt();
            return !jwt
                ? Promise.reject(null)
                : fetch('/api/user/paidCoachApply', {
                    method: 'POST',
                    headers: {
                        Authorization: jwt,
                    },
                })
                    .then((response) => {
                    return response.json().then((json) => json);
                })
                    .catch((err) => {
                    return Promise.reject(err);
                });
        },
        getTokens: () => {
            const jwt = getJwt();
            return !jwt
                ? Promise.reject(null)
                : fetch('/api/user/tokens', {
                    method: 'GET',
                    headers: {
                        Authorization: jwt,
                    },
                })
                    .then((response) => {
                    return response.json().then((json) => json);
                })
                    .catch((err) => {
                    return Promise.reject(err);
                });
        },
    };
    static TargetLanguages = {
        add: (code, proficiency) => {
            const jwt = getJwt();
            return !jwt
                ? Promise.resolve(null)
                : fetch(`/api/user/languages/add`, {
                    method: 'POST',
                    headers: {
                        Authorization: jwt,
                        'Content-Type': APPLICATION_JSON,
                    },
                    body: JSON.stringify({
                        code,
                        proficiency,
                    }),
                }).then((response) => {
                    return response.status === HttpStatus.OK
                        ? response.json().then((json) => json)
                        : Promise.reject();
                });
        },
        edit: (id, code, proficiency) => {
            const jwt = getJwt();
            return !jwt
                ? Promise.resolve(null)
                : fetch(`/api/user/languages/edit/${id}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: jwt,
                        'Content-Type': APPLICATION_JSON,
                    },
                    body: JSON.stringify({
                        code,
                        proficiency,
                    }),
                }).then((response) => {
                    return response.status === HttpStatus.OK
                        ? response.json().then((json) => json)
                        : Promise.reject();
                });
        },
        delete: (languageId) => {
            const jwt = getJwt();
            return !jwt
                ? Promise.resolve(null)
                : fetch(`/api/user/languages/remove/${languageId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: jwt,
                    },
                }).then((response) => {
                    return Promise.resolve(response.status === HttpStatus.OK);
                });
        },
    };
    static Sections = {
        add: async (code) => {
            const { data } = await AxiosInstance.getAxiosInstance().post('/user/section', {
                code,
            });
            return data;
        },
        delete: async (code) => {
            const { data } = await AxiosInstance.getAxiosInstance().delete('/user/section', {
                params: {
                    code,
                },
            });
            return data;
        },
        edit: async (oldCode, newCode) => {
            const { data } = await AxiosInstance.getAxiosInstance().put('/user/section', {
                oldCode,
                newCode,
            });
            return data;
        },
        validateCode: async (code) => {
            const { data } = await AxiosInstance.getAxiosInstance().get(`/sections/valid/${code}`);
            return data;
        },
    };
    static Stats = {
        summary: () => {
            const jwt = getJwt();
            return !jwt
                ? Promise.resolve(null)
                : fetch('/api/user/stats/summary', {
                    method: 'GET',
                    headers: {
                        Authorization: jwt,
                    },
                }).then((response) => {
                    return response.json().then((json) => json);
                });
        },
        getStats: (params) => {
            const { type, page = 0, limit = 20, sort, searchKey = '' } = params;
            const jwt = getJwt();
            const sortOrder = JSON.stringify(sort ?? ['createdAt', -1]);
            return !jwt
                ? Promise.resolve(null)
                : fetch(`/api/user/stats/${type}?page=${page}&size=${limit}&sort[]=${sortOrder}&searchKey=${searchKey}`, {
                    method: 'GET',
                    headers: {
                        Authorization: jwt,
                    },
                }).then((response) => {
                    return response.json().then((json) => json);
                });
        },
    };
    static Modules = {
        toggleIgnoreModule: (moduleId) => {
            const jwt = getJwt();
            return !jwt
                ? Promise.resolve(null)
                : fetch(`/api/user/modules/toggleIgnoreModule/${moduleId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: jwt,
                    },
                }).then((response) => {
                    return response.json().then((json) => json);
                });
        },
    };
    static Connections = {
        Coaches: {
            block: (id, reason) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/blocked/add/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify({ reason }),
                        headers: {
                            Authorization: jwt,
                            'Content-Type': APPLICATION_JSON,
                        },
                    }).then(response204error);
            },
            unblock: (id) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/blocked/remove/${id}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: jwt,
                        },
                    }).then(response204error);
            },
        },
        Favorites: {
            add: (id) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/favorited/add/${id}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: jwt,
                        },
                    }).then(response204error);
            },
            remove: (id) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/favorited/remove/${id}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: jwt,
                        },
                    }).then(response204error);
            },
        },
        Following: {
            follow: (id) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/following/add/${id}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: jwt,
                        },
                    }).then(response204error);
            },
            stopFollowing: (id) => {
                const jwt = getJwt();
                return !jwt
                    ? Promise.resolve(null)
                    : fetch(`/api/user/following/remove/${id}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: jwt,
                        },
                    }).then(response204error);
            },
        },
    };
    static ScheduleSession = {
        get: async (type, userId) => {
            const { data } = await AxiosInstance.getAxiosInstance().get('/user/scheduled-session/next', {
                params: {
                    type,
                    userId,
                },
            });
            return data;
        },
    };
}
