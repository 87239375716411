import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export const createChatTable = (callSource, learner, coach, chat, date) => {
    const doc = new jsPDF();
    doc.text(`Chat transcript from call between @${coach.handle} and @${learner.handle} on \n\r${format(date, 'MMM-dd-yyyy')} at ${format(date, 'HH:mm')}`, 10, 15);
    const parsedData = callSource === 'twilio'
        ? chat.map(({ message, user }) => {
            const handle = String(user) === String(learner._id)
                ? learner.handle
                : coach.handle;
            return {
                message,
                handle,
            };
        })
        : chat.map(({ message, username }) => {
            return {
                message,
                handle: username,
            };
        });
    parsedData.forEach(({ message, handle }, index) => {
        doc.autoTable({
            startY: 35 + index * 10,
            body: [[`@${handle}: ${message}`]],
        });
    });
    doc.save(`conversifi-chat-transcript-${format(date, 'MMM-dd-yyyy')}.pdf`);
};
