import { useCallback, useMemo, useState } from 'react';
export function useAsyncController() {
    const [status, setStatus] = useState('idle');
    const [result, setResult] = useState();
    const [error, setError] = useState();
    const isIdle = useMemo(() => {
        return status === 'idle';
    }, [status]);
    const isPending = useMemo(() => {
        return status === 'pending';
    }, [status]);
    const isResolved = useMemo(() => {
        return status === 'resolved';
    }, [status]);
    const isRejected = useMemo(() => {
        return status === 'rejected';
    }, [status]);
    const isFinal = useMemo(() => {
        return Boolean(isResolved || isRejected);
    }, [isRejected, isResolved]);
    const setIdle = useCallback(() => {
        setStatus('idle');
    }, []);
    const setPending = useCallback(() => {
        setStatus('pending');
    }, []);
    const setResolved = useCallback((result, debug = false) => {
        if (debug) {
            console.debug('useAsyncController setResolved result', result);
        }
        setStatus('resolved');
        setError(null);
        setResult(result);
    }, []);
    const setRejected = useCallback((err, debug = false) => {
        if (debug) {
            console.warn('[UAC64] useAsyncController setRejected err', err.message);
        }
        setStatus('rejected');
        setError(err);
    }, []);
    const reset = useCallback(() => {
        setIdle();
        setError(null);
        setResult(undefined);
    }, [setIdle]);
    return useMemo(() => {
        return {
            status,
            result,
            error,
            isIdle,
            isPending,
            isResolved,
            isRejected,
            isFinal,
            setIdle,
            setPending,
            setResolved,
            setRejected,
            reset,
        };
    }, [
        status,
        result,
        error,
        isIdle,
        isPending,
        isResolved,
        isRejected,
        isFinal,
        setIdle,
        setPending,
        setResolved,
        setRejected,
        reset,
    ]);
}
