import type { User } from 'conversifi-types/src/User/User';
import { convFetch } from '../../util/util.fetch';

/**
 * user fetch the users data by key search.
 * Returns [] if no result, throws error if request fails.
 * Returns response data otherwise.
 *
 * @see checkUserOk
 */
export const userGetById = async (userId: string): Promise<User | Response> =>
  convFetch(`/api/admin/users/${userId}`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    return response;
  });

/**
 * Check if the {@link userGetById} result is ok.
 *
 * @param userResult {@link userGetById} result.
 * @returns
 * @see userGetById
 */
export function checkUserOk(userResult: User | Response): userResult is User {
  const user = userResult as User;
  return Boolean(user._id && user.email && user.handle);
}
