import { apiErrorHandler } from '../apiErrorHandler'
import { requestUniversityDelete } from '../requests'

export async function universityDelete(universityId: string): Promise<any> {
  try {
    const message = await requestUniversityDelete(universityId)

    return message
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
