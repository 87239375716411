import BaseError from './BaseError';
class HttpResponseError extends BaseError {
    status;
    statusText;
    ok;
    url;
    constructor(response) {
        super(`${response.status} ${response.statusText}`);
        this.ok = response.ok;
        this.status = response.status;
        this.statusText = response.statusText;
        this.url = response.url;
    }
    toJSON() {
        return {
            name: this.name,
            message: this.message,
            status: this.status,
            statusText: this.statusText,
            ok: this.ok,
            url: this.url,
        };
    }
}
export default HttpResponseError;
