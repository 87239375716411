import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import Navbar from './navbar';
import styles from './assets/css/Admin.module.scss';
import type { User } from 'conversifi-types/src/User/User';
import { Routes } from '../routes';
import { AdminRoutes } from './adminRoutes';
import LogoutComponent from '../common-components/LogoutComponent';
import { AppState, State, useAppState } from './state';
import { LiveAlertCtx } from './context/LiveAlertContext';
import { Ringer as RingerCtx } from 'conversifi-shared-react/es6/components/Ringer';
import { LazyLoadSpinner } from 'conversifi-shared-react/es6/components/LazyLoadSpinner';
import { Box } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SocketContext } from './context/SocketContext';
import { SocketStatus } from 'conversifi-types/src/Socket/types';
import { UniversitiesContext } from 'conversifi-shared-react/es6/components/UniversitiesContext';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { Trans } from '@lingui/react';
import SidebarContext from 'conversifi-shared-react/es6/components/SidebarContext';
import { getSidebarItems } from './utils/getSidebarItems';
import UserSession from 'conversifi-shared-react/es6/components/UserSession';
import Languages from './Admin/languages';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import Avatar from './Admin/avatar';
import CoachesBehaviors from './Reports/coachBehaviors';

const CallConfigMain = lazy(() => import('./Admin/call-config'));
const CashoutMain = lazy(() => import('./cashout'));
const PaidCoachApplicationsMain = lazy(
  () => import('./Users/PaidCoachApplication')
);
const CouponsAdminDashboard = lazy(() => import('./Admin/coupons'));
const Faculties = lazy(() => import('./faculties/index'));
const Incentives = lazy(() => import('./Equilibrium/incentives'));
const InsightsDashboard = lazy(() => import('./insights-dashboard'));
const MAv2 = lazy(() => import('./mav2'));
const ModulesMain = lazy(() => import('./Admin/modules'));
const PaginatedTerms = lazy(() => import('./Schools/terms/Screen'));
const PCDashboard = lazy(() => import('./pc-dashboard'));
const PriceBundlesMain = lazy(() => import('./price-bundles'));
const PricesMain = lazy(() => import('./Admin/prices'));
const SettingsMain = lazy(() => import('./Admin/settings'));
const Universities = lazy(() => import('./Schools/universities'));
const UploadsRouter = lazy(() => import('./uploads'));
const SearchUsersMain = lazy(() => import('./Users/SearchUsers'));
const AlertsMain = lazy(() => import('./Admin/alerts'));
const ActiveCalls = lazy(() => import('./Reports/activeCalls'));
const ActiveSearches = lazy(() => import('./Reports/activeSearches'));
const LiveHoursMain = lazy(() => import('./LiveHours'));
const ScheduleReportMain = lazy(() => import('./Reports/scheduleReport'));
const SocketsReport = lazy(() => import('./Reports/socketsReport'));
const coachQuizReport = lazy(() => import('./Reports/coachQuizReport'));
const VerificationEmailMain = lazy(() => import('./Users/VerificationEmail'));
const BannerScreen = lazy(() => import('./Banner'));
const CoachReport = lazy(() => import('./Reports/coachesReport'));

interface Props {
  user: User;
}

const AdminApp: React.FC = () => {
  const socketCtx = SocketContext.useCtx();
  const linguiCtx = LinguiContext.useCtx();

  useEffect(() => {
    if (!socketCtx.isConnected()) {
      socketCtx.connect().then(() => {
        socketCtx.on('TRANSLATIONS_UPDATED', () =>
          linguiCtx.reloadTranslations()
        );
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box className={styles.root}>
        <Navbar />
        <Suspense fallback={<LazyLoadSpinner />}>
          <Switch>
            <Route
              path={`${Routes.admin.uri}/call-config`}
              exact
              component={CallConfigMain}
            />
            <Route
              path={`${Routes.admin.uri}/incentives`}
              exact
              component={Incentives}
            />
            <Route
              path={AdminRoutes.Users.PaidCoachApplications.path}
              exact
              component={PaidCoachApplicationsMain}
            />
            <Route
              path={AdminRoutes.Users.VerificationEmail.path}
              exact
              component={VerificationEmailMain}
            />
            <Route
              path={`${Routes.admin.uri}/coupons`}
              component={CouponsAdminDashboard}
            />
            <Route
              path={AdminRoutes.Users.SearchUsers.path}
              component={SearchUsersMain}
            />
            <Route
              path={`${Routes.admin.uri}/universities`}
              component={Universities}
            />
            <Route
              path={`${Routes.admin.uri}/faculties`}
              component={Faculties}
            />
            <Route
              path={`${Routes.admin.uri}/terms`}
              component={PaginatedTerms}
            />
            <Route
              path={`${Routes.admin.uri}/uploads`}
              exact
              component={UploadsRouter}
            />
            <Route
              path={`${Routes.admin.uri}/insights`}
              render={(props: RouteComponentProps) => (
                <LiveAlertCtx>
                  <InsightsDashboard {...props} />
                </LiveAlertCtx>
              )}
            />
            <Route
              path={`${Routes.admin.uri}/settings`}
              exact
              component={SettingsMain}
            />
            <Route
              path={`${Routes.admin.uri}/price-bundles`}
              exact
              component={PriceBundlesMain}
            />
            <Route
              path={`${Routes.admin.uri}/prices`}
              exact
              component={PricesMain}
            />
            <Route
              path={`${Routes.admin.uri}/logout`}
              component={LogoutComponent}
            />
            <Route
              path={`${Routes.admin.uri}/modules`}
              exact
              component={ModulesMain}
            />
            <Route
              path={`${Routes.admin.uri}/pc-dashboard`}
              exact
              component={PCDashboard}
            />
            <Route
              path={`${Routes.admin.uri}/languages`}
              exact
              component={Languages}
            />
            <Route path={AdminRoutes.MAv2.path} component={MAv2} />
            <Route
              path={`${Routes.admin.uri}/cashout-pending`}
              component={CashoutMain}
            />
            <Route path={AdminRoutes.Alerts.path} component={AlertsMain} />
            <Route
              path={AdminRoutes.Reports.ActiveCalls.path}
              component={ActiveCalls}
            />
            <Route
              path={AdminRoutes.Reports.ActiveSearches.path}
              component={ActiveSearches}
            />
            <Route
              path={AdminRoutes.Reports.Schedule.path}
              component={ScheduleReportMain}
            />
            <Route
              path={AdminRoutes.Reports.Sockets.path}
              component={SocketsReport}
            />
            <Route
              path={AdminRoutes.Reports.CoachQuizes.path}
              component={coachQuizReport}
            />
            <Route path={AdminRoutes.Avatar.path} component={Avatar} />
            <Route
              path={AdminRoutes.Reports.CoachReport.path}
              component={CoachReport}
            />
            <Route
              path={AdminRoutes.Reports.CoachBehaviors.path}
              component={CoachesBehaviors}
            />
            <Route
              path={`${Routes.admin.uri}/live-hours`}
              component={LiveHoursMain}
            />
            <Route
              path={`${Routes.admin.uri}/banner`}
              component={BannerScreen}
            />
            <Route path="*">
              <Redirect to={`${Routes.admin.uri}/insights`} />
            </Route>
          </Switch>
        </Suspense>
      </Box>
    </LocalizationProvider>
  );
};

const AdminAppWithSocket: React.FC<Props> = ({ user }) => {
  const appState = useAppState();
  const initialState: State = {
    user,
    clientUuid: appState.clientUuid,
  };
  const snackbarCtx = SnackbarContext.useCtx();

  // Simulated forceUpdate()... re render the dependencies
  const [, setSocketStatus] = useState<SocketStatus>();
  const history = useHistory();
  const sidebarItems = getSidebarItems({ history });

  return (
    <UniversitiesContext>
      <SocketContext
        onStatusUpdated={(status) => setSocketStatus(status)}
        clientUuid={appState.clientUuid}
        onDisconnect={() => {
          snackbarCtx.setMessage({
            message: (
              <Trans id="websocket.socket-disconnected">
                Socket disconnected!
              </Trans>
            ),
            severity: 'error',
            autoHideDuration: 2000,
          });
        }}
      >
        <UserSession>
          <AppState state={initialState}>
            <RingerCtx>
              <SidebarContext items={sidebarItems} user={user} app="admin">
                <AdminApp />
              </SidebarContext>
            </RingerCtx>
          </AppState>
        </UserSession>
      </SocketContext>
    </UniversitiesContext>
  );
};

export default AdminAppWithSocket;
