import { AdminSdk } from '../../../../sdk/admin.sdk';
import { AdminLanguagesContext } from '../index';

export default async function (this: AdminLanguagesContext): Promise<void> {
  this.props.linearProgressCtx.showLinearProgressWithBackdrop();
  try {
    if (this.state.language._id && this.state.language.langCode) {
      await AdminSdk.Languages.updateLanguage(
        this.state.language.langCode,
        this.state.language
      );
    } else {
      await AdminSdk.Languages.createLanguage(this.state.language);
    }

    await this.props.languagesCtx.reloadLanguages();

    this.cleanLanguage();
  } catch (err) {
  } finally {
    this.props.linearProgressCtx.hideLinearProgressAndBackdrop();
  }
}
