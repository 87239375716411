import * as locales from 'date-fns/locale';
export function getLocaleByLang(langCode) {
    if (langCode === 'en' || !Object.keys(locales).includes(langCode)) {
        return locales.enUS;
    }
    return locales[langCode];
}
export function getLocaleByUserLang(langCode) {
    return getLocaleByLang(langCode);
}
export function getLocaleByUser(user) {
    return getLocaleByLang(user.language.code);
}
