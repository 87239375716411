import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { DEFAULT_LANG, OTHER_LANG, SUPPORTED_LANGUAGE_CODES, } from 'conversifi-commons/constants';
import { LanguagesSdk } from '../../sdk/LanguagesSdk';
import isoLanguages from '@cospired/i18n-iso-languages';
import { getLanguageName } from '../../util/miscellaneous/languages';
export const LanguagesPublicContext = React.createContext({});
export class LanguagesContext extends BaseProvider {
    className = 'LanguagesContext';
    PublicContext = LanguagesPublicContext;
    static useCtx = () => React.useContext(LanguagesPublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
        SUPPORTED_LANGUAGE_CODES.forEach((code) => {
            const langCode = code === 'asl' ? 'en' : code.toLowerCase();
            isoLanguages.registerLocale(require(`@cospired/i18n-iso-languages/langs/${langCode}.json`));
        });
    }
    loadLanguages = async () => {
        const enabledLanguages = await LanguagesSdk.getEnabledLanguages();
        this.produce((draft) => {
            draft.enabledLanguages = enabledLanguages;
            draft.languagesLoaded = true;
        });
    };
    componentDidMount() {
        this.loadLanguages();
    }
    getInitialState() {
        return {
            enabledLanguages: [DEFAULT_LANG, OTHER_LANG],
            defaultLang: DEFAULT_LANG,
            isoLanguages,
            languagesLoaded: false,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            getEnabledLanguages: this.getEnabledLanguages,
            getDefaultLang: this.getDefaultLang,
            reloadLanguages: this.reloadLanguages,
            getLanguageName: this.getLanguageName,
        };
    }
    getEnabledLanguages = (params) => {
        const { excludeOthers = false } = params || {};
        return excludeOthers
            ?
                this.state.enabledLanguages.filter((lang) => lang.langCode !== 'xx')
            : this.state.enabledLanguages;
    };
    getDefaultLang = () => {
        return this.state.defaultLang;
    };
    reloadLanguages = async () => {
        this.loadLanguages();
    };
    getLanguageName(langCode, toLangCode = DEFAULT_LANG.langCode) {
        return getLanguageName(langCode, toLangCode);
    }
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() }, this.props.children));
    }
}
