import React, { FC, useCallback, useState } from 'react';
import { LoginRegisterTemplate } from '../templates/login-register';
import styles from './style.module.scss';
import { Trans } from '@lingui/react';
import { REACT_APP_CONVERSIFI_NOREPLY_EMAIL } from '../../util/util.parsedEnvs';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { ValidationsHelper } from 'conversifi-shared-react/es6/util/validations/ValidationsHelper';
import { UserSdk } from 'conversifi-shared-react/es6/sdk/UserSdk';
import { Box, Typography, Stack, InputAdornment } from '@mui/material';
import InputTextField from '../components/InputTextField';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import InputSubmit from '../components/InputSubmit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

type EmailSentStatus = 'OK' | 'ERROR' | 'NOT_SENT';

export const ForgotPassword: FC = () => {
  const trans = LinguiContext.useCtx().trans;
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSentStatus, setEmailSentStatus] =
    useState<EmailSentStatus>('NOT_SENT');
  const snackbarCtx = SnackbarContext.useCtx();

  const buttonEnabled = () =>
    email && ValidationsHelper.emailRegex.test(email) && !loading;

  const sendEmail = useCallback(async () => {
    setLoading(true);
    snackbarCtx.closeSnackbar();
    try {
      await UserSdk.sendTemporaryPassword(email);
      setEmailSentStatus('OK');
    } catch (err) {
      snackbarCtx.setMessage({
        severity: 'error',
        title: trans({
          id: 'anonymous.forgot-password.error-title',
          message: 'Error',
        }),
        message: trans({
          id: 'anonymous.forgot-password.error-detail',
          message: 'The email is not registered',
        }),
      });
    }
    setLoading(false);
  }, [email, snackbarCtx, trans]);

  return (
    <LoginRegisterTemplate
      headerExtraClassName={styles.header}
      containerExtraClassName={styles.mainContainer}
    >
      <Typography
        variant="h5"
        fontWeight={600}
        textAlign="center"
        color="white"
        gutterBottom
        my={2}
      >
        <Trans id="anonymous.forgot-password.title">Reset your password</Trans>
      </Typography>
      {emailSentStatus !== 'OK' && (
        <>
          <Typography my={2}>
            <Trans id="anonymous.forgot-password.we-will-send-instructions">
              Fear not. We'll email you instructions to reset your password.
            </Trans>
          </Typography>
          <InputTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" color="white">
                  <EmailOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
                </InputAdornment>
              ),
            }}
            id="email"
            type="email"
            required
            label={trans({
              id: 'anonymous.forgot-password.email',
              message: 'Email',
            })}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
          <Box display="flex" justifyContent="flex-end" my={1}>
            <InputSubmit
              type="submit"
              variant="contained"
              disabled={!buttonEnabled()}
              onClick={sendEmail}
            >
              <Trans id="anonymous.forgot-password.send">Send</Trans>
            </InputSubmit>
          </Box>
        </>
      )}
      {emailSentStatus === 'OK' && (
        <Box mb={2}>
          <Typography fontSize={(theme) => theme.spacing(2.3)} gutterBottom>
            <Trans id="anonymous.forgot-password.email-sent" values={{ email }}>
              We've sent an email to {email}. Log back in with the temporary
              password and then go to Profile and click on Change Password.
            </Trans>
          </Typography>
          <Typography variant="caption" gutterBottom>
            <Trans
              id="anonymous.forgot-password.check-spam-folder"
              values={{ REACT_APP_CONVERSIFI_NOREPLY_EMAIL }}
              components={[<strong />]}
            >
              If the email doesn't show up soon, check your spam folder. We sent
              it from <strong>{REACT_APP_CONVERSIFI_NOREPLY_EMAIL}</strong>.
            </Trans>
          </Typography>
        </Box>
      )}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography color="white">
          <Trans id="anonymous.register.form.already-have-an-account">
            Already have an account?
          </Trans>
        </Typography>
        <Link to={Routes.login.uri}>
          <Typography color="white">
            <Trans id="anonymous.register.form.login">Login</Trans>
          </Typography>
        </Link>
      </Stack>
    </LoginRegisterTemplate>
  );
};
