import { getLocaleByLang } from 'conversifi-shared-react/es6/util/locale';
import { sectionCodeIsValid } from '../../api';
import { ExtraMessageContent } from '../../hooks';
import { I18nTrans } from 'conversifi-shared-react/es6/types/Lingui';
import { formatDateInLocale } from 'conversifi-shared-react/es6/util/miscellaneous/date';

export const sectionServerValidation = async (
  section: string,
  trans: I18nTrans
): Promise<string | undefined | ExtraMessageContent> => {
  console.debug('Section seems valid. Validate on the server...');
  const msg = trans({
    id: 'multiple-sections.error.expired-or-invalid',
    message: 'Section code is expired or invalid',
  });

  try {
    const isValid = await sectionCodeIsValid(section);

    if (isValid.sectionCodeIsValid && isValid.extraMessage) {
      return {
        extraMessage: isValid.extraMessage,
      };
    }

    if (!isValid.sectionCodeIsValid && isValid.termStartDate) {
      const startDate = formatDateInLocale(
        isValid.termStartDate,
        getLocaleByLang('en')
      );

      return trans({
        id: 'multiple-sections.register.error.section-not-valid-yet',
        values: { startDate },
        message: `Ups, your section isn’t valid yet. It will be valid from ${startDate}. Please come back on that day to complete your registration.`,
      });
    }
  } catch (err) {
    const error = err as any;

    if (!error.code || error.code >= 500) {
      console.error('[SSV12]', error);
    } else {
      console.info('[SSV13]', error);
    }

    return msg;
  }

  return msg;
};

const getServerValidation = (trans: I18nTrans) => (section: string) =>
  sectionServerValidation(section, trans);

export default getServerValidation;
