import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../routes';
import { Typography } from '@mui/material';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import {
  getLocalSession,
  clearLocalSession,
} from 'conversifi-shared-react/es6/util/session';

interface LtiLogoutScreenProps {}

export const LtiLogoutScreen: React.FC<LtiLogoutScreenProps> = () => {
  const history = useHistory();
  const snackbarCtx = SnackbarContext.useCtx();

  const handleLogout = useCallback(async () => {
    try {
      const { token } = getLocalSession();

      if (token) {
        clearLocalSession();
      }

      return history.push(Routes.register.uri);
    } catch (err) {
      const defaultTranslationKey = 'lti-logout.error-logout-user';

      snackbarCtx.showSnackbarTranslatedErrorMessage(
        err,
        defaultTranslationKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Typography textAlign="center" variant="h6" color="primary">
      Conversifi LTI Logout
    </Typography>
  );
};
