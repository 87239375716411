import ActiveSearchesContext from '../index';
import { AdminSdk } from '../../../../sdk/admin.sdk';

export default async function (this: ActiveSearchesContext) {
  try {
    const alerts = await AdminSdk.LiveAlerts.getAlertsData();

    this.produce((draft) => {
      draft.alerts = alerts;
    });
  } catch (err) {
    let message = this.props.trans({
      id: 'admin.live-alerts.get-live-alerts-error-message',
      message: 'Error getting live alerts',
    });

    if (err.data && err.data.message) {
      message = err.data.message;
    }

    this.props.liveAlertSnackbar.setEphemeralMessage({
      message,
      severity: 'info',
    });
  }
}
