import { getZopim } from './zopim';
function normalizeTags(tags) {
    const zTags = [];
    for (const t of tags) {
        if (t) {
            if (Array.isArray(t)) {
                const r = t.filter((x) => x);
                if (r.length) {
                    zTags.push(...r);
                }
            }
            else {
                zTags.push(t);
            }
        }
    }
    return zTags;
}
function withZopimFactory(functionTitle, callback) {
    return async (...tags) => {
        if (tags?.length) {
            const zTags = normalizeTags(tags);
            if (zTags.length) {
                console.debug(`Zendesk ${functionTitle}`, zTags);
                const zopim = await getZopim();
                callback(zopim.livechat, zTags);
            }
        }
    };
}
export const addTags = withZopimFactory('addTags', (livechat, tags) => livechat.addTags(...tags));
export const removeTags = withZopimFactory('removeTags', (livechat, tags) => livechat.removeTags(...tags));
