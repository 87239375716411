import React, { FC } from 'react';
import { InputTextProps } from '../../../components/InputText';
import { InputValidationStatus } from '../../../hooks/useInputTextValidation';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import InputTextField from '../../components/InputTextField';
import { InputAdornment } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

interface Props extends InputTextProps {
  value: string;
  validationStatus: InputValidationStatus;
  onValueChange: (value: string) => void;
  inputId?: string;
}

export const RegisterEmailSimple: FC<Props> = (props) => {
  const trans = LinguiContext.useCtx().trans;

  return (
    <InputTextField
      id={props.inputId ?? 'register-email'}
      value={props.value}
      onChange={(event) =>
        props.onValueChange(event.target.value.toLowerCase())
      }
      label={trans({ id: 'anonymous.register.inputs.email', message: 'Email' })}
      placeholder={trans({
        id: 'anonymous.register.inputs.email',
        message: 'Email',
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" color="white">
            <EmailOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
          </InputAdornment>
        ),
      }}
      error={Boolean(props.validationStatus.errorMessage)}
      helperText={props.validationStatus.errorMessage}
    />
  );
};
