import React from 'react';
import { AdminLanguagesContext } from '../index';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getLanguageNameBrowserLang } from 'conversifi-shared-react/es6/util/miscellaneous/languages';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function (this: AdminLanguagesContext) {
  return (
    <Box>
      <List>
        {this.props.languagesCtx.getEnabledLanguages().map((language) => {
          return (
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography>
                    {language.flagIconCountryCode
                      ? this.props.countriesCtx.createFlagSpanElement(
                          language.flagIconCountryCode
                        )
                      : language.langCode}{' '}
                    - {getLanguageNameBrowserLang(language.langCode)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {language.youtubeLink && (
                    <Link
                      href={`https://www.youtube.com/watch?v=${language.youtubeLink}`}
                      target="_blank"
                      sx={{ flex: 1 }}
                    >{`https://www.youtube.com/watch?v=${language.youtubeLink}`}</Link>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      this.produce((draft) => {
                        draft.language = language;
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => this.deleteLanguage(language.langCode)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
