import React, { FC, useEffect } from 'react';
import { useImmutableState } from '../../../../hooks';
import { REACT_APP_UNCONFIRMED_SMS_CODE_LEN } from '../../../../util/util.parsedEnvs';
import { userRedeemSmsCode } from '../../../../api';
import { Spec } from 'immutability-helper';
import { Trans } from '@lingui/react';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { User } from 'conversifi-types/src/User';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import InputTextField from '../../../../App.anonymous/components/InputTextField';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { isServerErrorInfo } from '../../../../util';

interface Props {
  phone: string;
  rating?: 'valid' | 'possible';
  className?: string;
  onTryAgain: () => void;
  onFinish: () => void;
  userSource: User['source'];
}

interface State {
  validationCode: string;
  allowReceiveSms: boolean;
  operationSucceeded: boolean;
  redeemCodeRequest: {
    isRunning: boolean;
    succeeded: boolean;
    failed: boolean;
    error: string | JSX.Element;
    errorType?: 'UNEXPECTED' | 'INVALID_CODE';
  };
}

export const SmsSent: FC<Props> = ({
  phone,
  onTryAgain,
  rating,
  onFinish,
  userSource,
}) => {
  const [state, setState] = useImmutableState<State>({
    validationCode: '',
    allowReceiveSms: true,
    operationSucceeded: false,
    redeemCodeRequest: {
      isRunning: false,
      succeeded: false,
      failed: false,
      error: '',
    },
  });
  const trans = LinguiContext.useCtx().trans;
  const dialogCtx = DialogContext.useCtx();

  const translationBySource =
    userSource === 'babbel' ? 'Connect' : 'Conversifi';

  const onValidationCodeChange = (code: string) => {
    setState({
      validationCode: { $set: code },
    });
  };

  useEffect(() => {
    if (state.redeemCodeRequest.failed && state.redeemCodeRequest.error) {
      dialogCtx.setMessage({
        title: (
          <Typography>
            {state.redeemCodeRequest.errorType === 'UNEXPECTED' ? (
              <Trans id="sms-sent.unexpected-error-title">
                Unexpected error
              </Trans>
            ) : (
              <Trans id="sms-sent.invalid-or-expired-code">
                Invalid or Expired code
              </Trans>
            )}
          </Typography>
        ),
        message: (
          <Typography sx={{ width: 400 }}>
            {state.redeemCodeRequest.error}
          </Typography>
        ),
        actions: (
          <Button
            variant="contained"
            id="smsSent.errorDialogBtn"
            onClick={() => {
              dialogCtx.closeDialog();
              backToStepOne();
            }}
          >
            {state.redeemCodeRequest.errorType === 'UNEXPECTED' ? (
              <Trans id="sms-sent.ok">Ok</Trans>
            ) : (
              <Trans id="sms-sent.request-a-new-code">Request a new code</Trans>
            )}
          </Button>
        ),
        defaultAction() {
          dialogCtx.closeDialog();
          backToStepOne();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.redeemCodeRequest.failed, state.redeemCodeRequest.error]);

  useEffect(() => {
    if (state.redeemCodeRequest.succeeded) {
      dialogCtx.setMessage({
        title: (
          <Typography>
            <Trans id="sms-sent.finished">Finished</Trans>
          </Typography>
        ),
        message: (
          <Typography sx={{ width: 300 }}>
            <Trans id="sms-sent.you-have-validated-your-phone-number">
              Congratulations, you have successfully validated your phone
              number!
            </Trans>
          </Typography>
        ),
        actions: (
          <Button
            id="smsSent.succeedDialogBtn"
            variant="contained"
            onClick={() => {
              dialogCtx.closeDialog();
              onFinish();
            }}
          >
            <Trans
              id="sms-sent.go-to-conversifi"
              values={{ source: translationBySource }}
            >
              Go to {translationBySource}!
            </Trans>
          </Button>
        ),
        defaultAction() {
          dialogCtx.closeDialog();
          onFinish();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.redeemCodeRequest.succeeded]);

  const onCodeSubmit = async () => {
    if (!state.redeemCodeRequest.isRunning) {
      setState({
        redeemCodeRequest: {
          isRunning: { $set: true },
        },
      });

      const setError = (errType: State['redeemCodeRequest']['errorType']) => {
        setState({
          redeemCodeRequest: {
            isRunning: { $set: false },
            succeeded: { $set: false },
            failed: { $set: true },
            errorType: { $set: errType },
            error: {
              $set:
                errType === 'INVALID_CODE' ? (
                  <Trans id="sms-sent.invalid-code">
                    This code is invalid or expired. Try requesting a new code.
                  </Trans>
                ) : (
                  <Trans id="sms-sent.unexpected-error">
                    We're having trouble submitting that code. Please try again
                  </Trans>
                ),
            },
          },
        });
      };

      try {
        await userRedeemSmsCode(
          phone,
          state.validationCode,
          state.allowReceiveSms
        );
        setState({
          redeemCodeRequest: {
            isRunning: { $set: false },
            succeeded: { $set: true },
            errorType: { $set: undefined },
            error: { $set: '' },
            failed: { $set: false },
          },
        });
      } catch (err) {
        if (isServerErrorInfo(err)) {
          if (err.responseStatus >= 500) {
            console.error('[SS115]', err);
            setError('UNEXPECTED');
          } else {
            setError('INVALID_CODE');
          }
        } else {
          setError('UNEXPECTED');
        }
      }
    }
  };

  const backToStepOne = () => {
    const updates: Spec<State> = {
      redeemCodeRequest: {
        error: { $set: '' },
        failed: { $set: false },
        succeeded: { $set: false },
      },
    };

    if (state.redeemCodeRequest.errorType === 'INVALID_CODE') {
      updates.validationCode = { $set: '' };
    }
    setState(updates);
    onTryAgain();
  };

  const toggleAlowSms = (checked: boolean) => {
    setState({ allowReceiveSms: { $set: checked } });
  };

  const onTryAgainLink = () => {
    backToStepOne();
  };

  const getIntlPhoneColor = () => {
    switch (rating) {
      case 'valid':
        return '#3bf03b';
      case 'possible':
        return 'primary.light';
      default:
        return 'error';
    }
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography variant="h6">
          <Trans id="sms-sent.we-sent-a-confirmation-code">
            Great! We sent a confirmation code to
          </Trans>
          <Typography
            fontWeight={600}
            color={getIntlPhoneColor()}
            component="span"
            variant="h6"
          >
            {phone}
          </Typography>
          .{' '}
          <Trans id="sms-sent.if-you-do-not-see-it">If you don't see it</Trans>,{' '}
          <Typography
            component="span"
            variant="h6"
            color="primary.light"
            id="smsSent.tryAgainLink"
            onClick={onTryAgainLink}
            fontWeight={600}
            sx={{ cursor: 'pointer' }}
          >
            <Trans id="sms-sent.click-here">click here</Trans>
          </Typography>{' '}
          <Trans id="sms-sent.to-change-your-phone-number-or-try-again">
            to change your phone number or try again
          </Trans>
          .
        </Typography>
        <InputTextField
          id="smsSent.codeInput"
          value={state.validationCode}
          placeholder={trans('sms-sent.enter-the-code-here', {
            defaults: 'Enter the code here',
          })}
          onChange={(e) => onValidationCodeChange(e.target.value)}
        />
        {userSource === 'conversifi' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.allowReceiveSms}
                onChange={(e) => toggleAlowSms(e.target.checked)}
              />
            }
            label={
              <Trans
                id="sms-sent.send-me-periodic-text-messages"
                values={{
                  source: translationBySource,
                }}
              >
                Send me periodic text messages to maximize the Conversifi
                experience (regular rates apply).
              </Trans>
            }
          />
        )}
        <Button
          id="smsSent.submit"
          variant="contained"
          disabled={
            !state.validationCode ||
            state.validationCode.length < REACT_APP_UNCONFIRMED_SMS_CODE_LEN
          }
          sx={{
            height: 50,
          }}
          onClick={onCodeSubmit}
        >
          <Trans id="sms-sent.validate">Validate!</Trans>
        </Button>
      </Stack>
    </>
  );
};
