import { getZopim } from './zopim';
import { getPageTitlePrefix } from './pageTitlePrefix';
import { addTags, removeTags } from './tags';
import { setMode } from './setMode';
export const notifyVisitorPath = async (pageTitle, mode, tagsToAdd, tagsToRemove) => {
    const zopim = await getZopim();
    const title = `${getPageTitlePrefix()} | ${pageTitle}`;
    document.title = title;
    await setMode(mode);
    if (tagsToAdd) {
        await addTags(tagsToAdd);
    }
    if (tagsToRemove) {
        await removeTags(tagsToRemove);
    }
    zopim.livechat.sendVisitorPath({
        url: `${document.URL}?${encodeURIComponent(pageTitle.toLowerCase())}`,
        title,
    });
};
