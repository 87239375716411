import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Alert, AlertTitle, Typography } from '@mui/material';
const getOptions = (options, closeSnackbar) => ({
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    autoHideDuration: 8000,
    preventDuplicate: true,
    content: (key) => {
        return (React.createElement(Alert, { severity: options.severity, style: { maxWidth: '400px' }, onClose: () => closeSnackbar(key), action: options.noAction ? React.createElement(React.Fragment, null) : options.action },
            options.title && React.createElement(AlertTitle, null, options.title),
            React.createElement(Typography, null, options.message)));
    },
    ...options,
});
export const LiveAlertSnackbarProvider = ({ children }) => {
    return (React.createElement(SnackbarProvider, { maxSnack: 4, classes: { anchorOriginTopCenter: '70px !important' } }, children));
};
export const useLiveAlertSnackbar = (ringerCtx) => {
    const [messages, setMessages] = React.useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ringOnce, setRingOnce] = React.useState(false);
    const queueMessage = (options) => {
        replaceMessageByKey(options.key, options);
    };
    const setEphemeralMessage = (options) => {
        enqueueSnackbar(options.message, {
            ...getOptions(options, closeSnackbar),
        });
    };
    const replaceMessageByKey = async (key, options) => {
        const index = messages.findIndex((message) => message.key === key);
        const isEqual = messages[index]?.message === options.message;
        if (!isEqual)
            closeSnackbar(options.key);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (!ringOnce && ringerCtx.state.isSoundAllowed) {
            ringerCtx.play();
            setRingOnce(true);
        }
        setMessages((prev) => isEqual ? prev : [...prev.filter((option) => option.key !== key), options]);
    };
    const removeMessageByKey = (key) => {
        closeSnackbar(key);
        setMessages((prev) => prev.filter((option) => option.key !== key));
    };
    React.useEffect(() => {
        if (messages.length) {
            messages.forEach((options) => {
                enqueueSnackbar(options.message, {
                    ...getOptions(options, closeSnackbar),
                    autoHideDuration: null,
                    preventDuplicate: true,
                    persist: true,
                });
            });
        }
    }, [messages]);
    return {
        queueMessage,
        setEphemeralMessage,
        removeMessageByKey,
    };
};
