import React from 'react';
import { CoachesBehaviorsContext } from '..';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PopoverDescription } from 'conversifi-shared-react/es6/components/PopoverDescription';
import { getNativeLanguageFlag } from '../../../../util/util.format';
import CollapsableTable from '../components/CollapsableTable';
import BodyTableCellWithLessPadding from '../components/BodyTableCellWithLessPadding';
import CommentIcon from '@mui/icons-material/Comment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingSpinner } from '../../../../App.instructors/app/screens/Components';
import { Trans } from '@lingui/react';
import { PaidCoachApplication } from 'conversifi-types/src/PaidCoachApplication';

export default function (this: CoachesBehaviorsContext) {
  const getRole = (paidCoachApplication: PaidCoachApplication) => {
    if (paidCoachApplication?.isScheduledPaidCoach) return 'SC';

    if (paidCoachApplication?.certifiedBeginner) return 'CBC';

    if (paidCoachApplication?.applicationStatus === 'APPROVED') return 'PC';

    return 'RC';
  };

  const editNote = (coachReport: any) => {
    this.produce((draft) => {
      draft.currentAdminNote =
        coachReport.coach.paidCoachApplication.adminNotes;
    });

    this.props.dialogCtx.setMessage({
      title: 'Add note',
      message: (
        <TextField
          defaultValue={coachReport.coach.paidCoachApplication.adminNotes}
          onChange={(e) => {
            this.produce((draft) => {
              draft.currentAdminNote = e.target.value;
            });
          }}
          sx={{ minWidth: 500 }}
          multiline
          rows={4}
          // banning special characters
          onInput={(e) => {
            (e.target as HTMLInputElement).value = (
              e.target as HTMLInputElement
            ).value.replace(/[^a-zA-Z0-9 .,]/g, '');
          }}
        />
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              this.produce((draft) => {
                draft.currentAdminNote = '';
              });
              this.props.dialogCtx.closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.postCoachNotes(
                coachReport.coach._id,
                this.state.currentAdminNote
              );
              this.props.dialogCtx.closeDialog();
            }}
          >
            Save
          </Button>
        </>
      ),
    });
  };
  if (this.state.loading) {
    return (
      <TableRow>
        <TableCell colSpan={8}>
          <LoadingSpinner />
        </TableCell>
      </TableRow>
    );
  } else if (this.state.coachesAiReport.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={7} align="center">
          <Trans id="commons.no-data" message="No data available" />
        </TableCell>
      </TableRow>
    );
  }

  return this.state.coachesAiReport.map((coachReport) => {
    let isCollapsed = this.state.displayRows.includes(coachReport._id);

    return (
      <>
        <TableRow
          id={coachReport._id.toString()}
          key={coachReport._id.toString()}
          sx={{ '& > *': { borderBottom: 'unset' } }}
        >
          <BodyTableCellWithLessPadding>
            <IconButton
              onClick={() => this.handleRowClick(coachReport._id)}
              sx={{
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.3s',
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {coachReport.coach.name}
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            <PopoverDescription
              content={coachReport.coach.language.code.toUpperCase()}
            >
              {this.props.countriesCtx.createFlagSpanElement(
                getNativeLanguageFlag(coachReport.coach.language.code)
              )}
            </PopoverDescription>
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {coachReport.coach.language.country.toUpperCase()}
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {getRole(coachReport.coach.paidCoachApplication)}
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {coachReport.calls}
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {coachReport.totalCallsWithProblemsPercentage.toFixed(2)} %
          </BodyTableCellWithLessPadding>
          <BodyTableCellWithLessPadding>
            {(() => {
              if (getRole(coachReport.coach.paidCoachApplication) === 'RC') {
                return (
                  <Tooltip
                    title="Only paid coaches can have notes"
                    disableInteractive
                  >
                    <IconButton
                      sx={{
                        padding: '6px',
                        color: 'rgba(0, 0, 0, 0.26)',
                      }}
                    >
                      <AddCircleIcon sx={{ fontSize: '24px' }} />
                    </IconButton>
                  </Tooltip>
                );
              } else if (coachReport.coach.paidCoachApplication?.adminNotes) {
                return (
                  <Tooltip
                    title={coachReport.coach.paidCoachApplication?.adminNotes}
                    disableInteractive
                  >
                    <IconButton
                      onClick={() => editNote(coachReport)}
                      sx={{
                        padding: '6px',
                      }}
                    >
                      <CommentIcon sx={{ fontSize: '24px' }} />
                    </IconButton>
                  </Tooltip>
                );
              } else {
                return (
                  <IconButton
                    onClick={() => editNote(coachReport)}
                    sx={{
                      padding: '6px',
                    }}
                  >
                    <AddCircleIcon sx={{ fontSize: '24px' }} />
                  </IconButton>
                );
              }
            })()}
          </BodyTableCellWithLessPadding>
        </TableRow>
        <TableRow>
          <TableCell sx={{ pb: 0, pt: 0 }} colSpan={8}>
            <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
              <CollapsableTable
                calls={coachReport.totalCallsWithProblemsDetails}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  });
}
