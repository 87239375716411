import React, { useState } from 'react';
import {
  InputStatus,
  TextInputStateProps,
} from '../../../components/InputTextState';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { getDateInUsDateFormat } from '../../../util/util.date';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputAdornment } from '@mui/material';
import { userBirthdateValidationSchema } from '../../../validations/yupValidations';
import InputTextField from '../../components/InputTextField';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { formatDateInLocale } from 'conversifi-shared-react/es6/util/miscellaneous/date';
import { getLocaleByLang } from 'conversifi-shared-react/es6/util/locale';

interface RegisterBirthdayProps {
  onStatusChange: Required<TextInputStateProps>['onStatusChange'];
  birthDate: InputStatus;
}

export const RegisterBirthday: React.FC<RegisterBirthdayProps> = ({
  onStatusChange,
  birthDate,
}) => {
  const trans = LinguiContext.useCtx().trans;
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [toggleDatepicker, setToggleDatepicker] = useState<boolean>(false);

  const onChangeBirthDate = (newDate: Date) => {
    try {
      const dateFormated = formatDateInLocale(newDate, getLocaleByLang('en'));
      userBirthdateValidationSchema().validateSync(dateFormated);

      onStatusChange({
        ...birthDate,
        error: '',
        isValid: true,
        touched: true,
        value: dateFormated,
      });
    } catch (err) {
      onStatusChange({
        ...birthDate,
        error: err.message,
        isValid: false,
        touched: true,
      });
    }
  };

  return (
    <DatePicker
      label={trans({
        id: 'users.editprofile.userform.datebirth',
        message: 'Date of Birth',
      })}
      value={getDateInUsDateFormat(birthDate.value)}
      onChange={() => {}}
      onAccept={(date) => date && onChangeBirthDate(new Date(date))}
      open={toggleDatepicker}
      slotProps={{
        popper: {
          placement: 'bottom-end',
          anchorEl: anchorEl,
        },
      }}
      maxDate={new Date().toISOString()}
      onClose={() => setToggleDatepicker(false)}
      desktopModeMediaQuery="@media (min-width: 500px)"
      slots={{
        textField: () => (
          <InputTextField
            id="registerForm.step2.dob"
            fullWidth
            label={trans({
              id: 'users.editprofile.userform.datebirth',
              message: 'Date of Birth',
            })}
            placeholder={trans({
              id: 'users.editprofile.userform.datebirth',
              message: 'Date of Birth',
            })}
            onFocus={() => {
              onStatusChange({
                ...birthDate,
                touched: true,
              });
            }}
            value={birthDate.value}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  color="white"
                  onClick={(e) => {
                    setAnchorEl(e.target);
                    setToggleDatepicker(true);
                  }}
                >
                  <CalendarTodayOutlinedIcon
                    sx={{ color: 'white', cursor: 'pointer' }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            error={birthDate.touched && !birthDate.isValid}
            helperText={birthDate.error}
          />
        ),
      }}
    />
  );
};
