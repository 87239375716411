import React, { FC } from 'react';
import {
  Typography,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import { Trans } from '@lingui/react';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { BaseProvider } from 'conversifi-shared-react/es6/BaseProvider';
import { State } from './types/State';
import { Props } from './types/Props';
import { CoachesBehaviorsPublicContext } from './types/PublicContext';
import { PublicApi } from './types/PublicApi';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import TableHeaderPlus from '../../../components/TableHeaderPlus';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { getHeadCells } from './methods/getHeadcells';
import setDates from './methods/setDates';
import fetchCoachesAiReport from './methods/fetchCoachesAiReport';
import postCoachNotes from './methods/postCoachNotes';
import handleRowClick from './methods/handleRowClick';
import handleSelectedRoles from './methods/handleSelectedRoles';
import renderRows from './methods/renderRows';
import { SORT_ORDER } from 'conversifi-shared-react/es6/types/TableMaterialUI';

export class CoachesBehaviorsContext
  extends BaseProvider<Props, PublicApi>
  implements PublicApi
{
  protected readonly PublicContext = CoachesBehaviorsPublicContext;
  readonly className = 'CoachBehaviorsContext';
  static useCtx = () => React.useContext(CoachesBehaviorsPublicContext);

  constructor(props: Props) {
    super(props);
    this.bindAll();
  }

  getInitialState(): State {
    return {
      filters: {
        language: '',
        country: '',
        selectedRole: '',
      },
      endDate: new Date('2024-12-31'),
      startDate: new Date('2024-06-01'),
      loading: false,
      coachesAiReport: [],
      currentAdminNote: '',
      displayRows: [],
    };
  }

  protected getPublicApi(): PublicApi {
    return {
      state: this.state,
    };
  }

  componentDidMount(): void {
    this.setDates();
  }
  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate ||
      this.state.filters !== prevState.filters
    ) {
      this.fetchCoachesAiReport();
    }
  }

  protected getHeadCells = getHeadCells;
  protected setDates = setDates;
  protected fetchCoachesAiReport = fetchCoachesAiReport;
  protected postCoachNotes = postCoachNotes;
  protected handleRowClick = handleRowClick;
  protected renderRows = renderRows;
  protected handleSelectedRoles = handleSelectedRoles;

  render() {
    return (
      <this.PublicContext.Provider value={this.getPublicApi()}>
        <Box display="flex" justifyContent="center">
          <Paper sx={{ maxWidth: '90vw', minWidth: '70vw' }}>
            <Box p={2}>
              <Typography
                color="primary"
                variant="h4"
                textAlign="center"
                gutterBottom
              >
                <Trans
                  id="admin.coach-behaviors.title"
                  message="Coach Behaviors"
                />
              </Typography>
              <TableContainer sx={{ height: '60vh' }}>
                <Table stickyHeader>
                  <TableHeaderPlus
                    headCells={this.getHeadCells()}
                    order={SORT_ORDER.DESC}
                    orderBy="callsWithProblems"
                  />
                  <TableBody>{this.renderRows()}</TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Box>
      </this.PublicContext.Provider>
    );
  }
}

const CoachesBehaviors: FC = () => {
  const snackbarCtx = SnackbarContext.useCtx();
  const linearProgressCtx = LinearProgressContext.useCtx();
  const countriesCtx = CountriesContext.useCtx();
  const trans = LinguiContext.useCtx().trans;
  const languagesCtx = LanguagesContext.useCtx();
  const dialogCtx = DialogContext.useCtx();

  return (
    <CoachesBehaviorsContext
      snackbarCtx={snackbarCtx}
      linearProgressCtx={linearProgressCtx}
      trans={trans}
      countriesCtx={countriesCtx}
      languagesCtx={languagesCtx}
      dialogCtx={dialogCtx}
    />
  );
};

export default CoachesBehaviors;
