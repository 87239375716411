import { getBrowserLang } from 'conversifi-shared-react/es6/util';
import { format, isValid, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { DEFAULT_DATE_FORMAT } from 'conversifi-commons/constants';
import { getLocaleByLang } from 'conversifi-shared-react/es6/util/locale';

export const getDateInUsDateFormat = (date: string | Date) => {
  const locale = getLocaleByLang(getBrowserLang());

  if (typeof date === 'string') {
    const parsedDate = parse(date, DEFAULT_DATE_FORMAT, new Date(), {
      locale: enUS,
    });
    const isValidDate = isValid(parsedDate);
    if (isValidDate) return format(parsedDate, 'P', { locale });

    return undefined;
  } else {
    return format(date, 'P', { locale: enUS });
  }
};
