import { requestUniversityDataPost } from '../requests';
import { apiErrorHandler } from '../apiErrorHandler';
import { UniversityCreate } from 'conversifi-types/src/University';

// Call endpoint API for create a new promotion
export async function universityDataPost(
  universityData: UniversityCreate
): Promise<void> {
  try {
    await requestUniversityDataPost(universityData);
  } catch (err) {
    throw apiErrorHandler(err);
  }
}
