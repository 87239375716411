import Session from './Session';
import React, { useContext } from 'react';

export class SDK {
  public session: Session;
  constructor() {
    this.session = new Session();
  }
}

export const sdk = new SDK();
export const SDKContext = React.createContext<SDK>({} as SDK);
export const useSdk = (): SDK => useContext(SDKContext);
export * from './getErrorData';
