import React from 'react';
import { PublicApi } from './PublicApi';

/**
 * Create and export the react context that will allow you
 * to access your public api.
 */
export const CoachesBehaviorsPublicContext = React.createContext(
  {} as PublicApi
);
