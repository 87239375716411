const validationsHelper = {};

Object.defineProperty(validationsHelper, 'regexObjectId', {
  get() {
    return /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/;
  },
});

/**
 * @ref #EMAIL-VALIDATION
 */
Object.defineProperty(validationsHelper, 'regexEmail', {
  get() {
    return /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
  },
});

Object.defineProperty(validationsHelper, 'regexGoogleDocLink', {
  get() {
    return /^https:\/\/docs\.google\.com\/document\/|https:\/\/drive\.google\.com\//;
  },
});

Object.defineProperty(validationsHelper, 'regexS3Link', {
  get() {
    return /^https?:\/\/[a-z-0-9]+\.s3\.amazonaws\.com\/.+/;
  },
});

// CONV-1896 Handles can't contain accented letters.
/**
 * Validate user handle regular expression [#REGEX_USER_HANDLE].
 *   - Check if handle has only letters, numbers, (_) and (-)
 *   - Check if handle doesn't start with (_) and (-)
 *   - Check if handle doesn't finish with (_) and (-)
 *   - Check if handle doesn't contain two (_) and (-) together
 *   - Handle max length <= 100
 *
 * @ref #USER_HANDLE_REGEX
 */
Object.defineProperty(validationsHelper, 'regexUserHandle', {
  get() {
    return /^[a-z]([+><=#^!_$%&*-]?[a-z0-9]){2,99}$/i;
  },
});

Object.defineProperty(validationsHelper, 'regexHashtagModule', {
  get() {
    return /^[a-záéíñóúüÁÉÍÑÓÚÜ]([_$%&*-]?[a-z0-9áéíñóúüÁÉÍÑÓÚÜ]){2,49}$/i;
  },
});

Object.defineProperty(validationsHelper, 'regexModIdModule', {
  get() {
    return /^[a-z]{2,3}-\d{5,6}$/i;
  },
});

/**
 * It is a valid e-Mail?
 *
 * @param {string | undefined | null} x Input
 * @return {boolean} True if it is a valid e-Mail
 */
validationsHelper.isEmail = (x) =>
  Boolean(x && typeof x === 'string' && validationsHelper.regexEmail.test(x));

/**
 * Validate user handle.
 *
 * @param {string | undefined | null} handle User handle
 * @return {boolean} True if it is a valid user handle
 * @see {evaluateUserHandleRegex}
 * @ref #USER_HANDLE_VALIDATOR
 */
validationsHelper.validateHandle = (handle) =>
  Boolean(
    handle &&
      typeof handle === 'string' &&
      handle.length >= 3 &&
      handle.length <= 100 &&
      validationsHelper.regexUserHandle.test(handle)
  );

/**
 * Validate hastag module.
 *
 * @param {string | undefined | null} hashtag User hashtag
 * @return {boolean} True if it is a valid user handle
 * @see {validationsHelper.regexHashtagModule}
 * @ref #USER_HASHTAG_VALIDATOR
 */
validationsHelper.validateHashtagModule = (hashtag) =>
  Boolean(
    hashtag &&
      typeof hashtag === 'string' &&
      validationsHelper.regexHashtagModule.test(hashtag)
  );

/**
 * Validate identifier module.
 *
 * @param {string} modId New Module ID
 * @return {boolean} True if it is a valid user handle
 * @see {validationsHelper.regexIdentifierModule}
 * @ref #USER_IDENTIFIER_VALIDATOR
 */
validationsHelper.validateModIdModule = (modId) =>
  Boolean(
    modId &&
      typeof modId === 'string' &&
      validationsHelper.regexModIdModule.test(modId)
  );

/**
 * @param {string} link
 * @returns {boolean}
 */
validationsHelper.validateGoogleDocLink = (link) =>
  validationsHelper.regexGoogleDocLink.test(link);

validationsHelper.validateS3Link = (link) =>
  validationsHelper.regexS3Link.test(link);

module.exports = validationsHelper;
