/**
 * Validate phone number.
 * @param {String} number Phone number.
 * @param {Boolean} requirePlus Require plus sign.
 * @return {Boolean}
 */
const phoneNumberValidate = (number, requirePlus = true) => {
  if (!number) return false;
  const regex = requirePlus ? /^[+][0-9]{4,20}$/ : /^[0-9]{4,20}$/;
  return regex.test(number);
};

/**
 * Cleanup phone number.
 * @param {String} number Phone number.
 * @return {String}
 */
const phoneNumberCleanup = (number) =>
  typeof number === 'string' ? number.replace(/[^0-9+]/g, '') : null;

/**
 * Validate a flexible phone number.
 * @param {String} number Phone number.
 * @return {Boolean}
 */
const phoneNumberValidateFlexible = (number) =>
  number && /^[+]?[0-9\s]*$/.test(number.replace(/\s+/g, ''));

/**
 * Format phone number.
 * @param {String} number Phone number.
 * @return {String}
 */
const formatPhoneNumber = (number) => {
  if (number.trim()) {
    const cleanedValue = phoneNumberCleanup(number);
    return cleanedValue.startsWith('+') ? cleanedValue : `+${cleanedValue}`;
  }
  return number;
};

module.exports = {
  phoneNumberValidate,
  phoneNumberCleanup,
  formatPhoneNumber,
  phoneNumberValidateFlexible,
};
