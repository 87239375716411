import {
  createError,
  ServerErrorInfo,
  NoResponseErrorInfo,
  AxiosSettingErrorInfo,
  ErrorInfo,
} from '../util/util.axios';
import { AxiosError } from 'axios';
// @ts-ignore
import { clearLocalSession } from 'conversifi-shared-react';

/**
 * here we could perform actions that should be transparent
 * for the api user like cleaning local storage or log error
 * to the server
 */
function handleServerError(error: ServerErrorInfo) {
  switch (error.responseStatus) {
    case 401:
      clearLocalSession();
      return error;
    default:
      return error;
  }
}

function handleNoResponseError(error: NoResponseErrorInfo) {
  return error;
}

function handleAxiosSettingError(error: AxiosSettingErrorInfo) {
  return error;
}

export function apiErrorHandler(
  error: AxiosError | Error | unknown
): ErrorInfo {
  try {
    const errorWithInfo = createError(error);

    switch (errorWithInfo.errorType) {
      case 'ServerError':
        return handleServerError(errorWithInfo);
      case 'NoResponseError':
        return handleNoResponseError(errorWithInfo);
      case 'AxiosSettingError':
        return handleAxiosSettingError(errorWithInfo);
      case 'RegularError':
        return errorWithInfo;
      default:
        return errorWithInfo;
    }
  } catch (err) {
    console.warn('[AEH52] ERROR creating error with info!', err, error);

    // this is an unexpected condition that means that is
    // something wrong with the `createError` implementation
    // so we intentionally throw an error
    throw error;
  }
}
