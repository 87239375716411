import axios from 'axios'
import { apiErrorHandler } from '../../api/apiErrorHandler'

export default class Session {
  constructor() {
    if (!axios.defaults.headers.common.Authorization) {
      console.info('[S7] user may not be logged')
    }
  }

  /**
   * Publish any valid section draft on the server as "real sections"
   */
  public async skipOrAcceptSubscriptionFeeWizard(
    action: 'SKIPPED' | 'ACCEPTED'
  ): Promise<any> {
    try {
      const { data } = await axios.put<any>(`/api/user`, {
        subscriptionFeeDialog: action,
      })
      return data
    } catch (err) {
      throw apiErrorHandler(err)
    }
  }
}
