import React from 'react';
import { Ringer } from 'conversifi-shared-react/es6/components/Ringer';
import { BaseProvider } from 'conversifi-shared-react/es6/BaseProvider';
import { LiveAlertState } from './state';
import { SocketContext } from '../SocketContext';
import getAlertsData from './methods/getAlertsData';
import interpretateAlerts from './methods/interpretateAlerts';
import joinRoom from './methods/joinRoom';
import leaveRoom from './methods/leaveRoom';
import onLiveAlertsUpdated from './methods/onLiveAlertsUpdated';
import {
  LiveAlertSnackbarProvider,
  useLiveAlertSnackbar,
} from 'conversifi-shared-react/es6/components/LiveAlertSnackbar';
import onReconnect from './methods/onReconnect';
import removeAllLiveAlertListeners from './methods/removeAllLiveAlertListeners';
import { I18nTrans } from 'conversifi-shared-react/es6/types/Lingui';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';

export interface LiveAlertPublicAPI {
  state: LiveAlertState;
}

interface Props {
  ringerCtx: ReturnType<typeof Ringer.useCtx>;
  socketCtx: ReturnType<typeof SocketContext.useCtx>;
  trans: I18nTrans;
  liveAlertSnackbar: ReturnType<typeof useLiveAlertSnackbar>;
}

export const PublicContext = React.createContext({} as LiveAlertPublicAPI);

const ONE_MINUTE = 1000 * 60;

const ding = `${process.env.PUBLIC_URL}/assets/ding.mp3`;

export default class LiveAlertContext
  extends BaseProvider<Props, LiveAlertPublicAPI>
  implements LiveAlertPublicAPI
{
  readonly className = 'LiveAlertContext';

  protected readonly PublicContext = PublicContext;
  static useCtx = () => React.useContext(PublicContext);
  _listenerAttached: boolean;
  _intervalRef: NodeJS.Timeout | null;

  constructor(props: any) {
    super(props);
    this._intervalRef = null;
    this._listenerAttached = false;
    this.bindAll();
    if (this.props.ringerCtx) {
      this.props.ringerCtx.setSound({
        src: ding,
      });
    }
  }

  getInitialState(): LiveAlertState {
    return {
      alerts: [],
    };
  }

  getPublicApi() {
    return {
      state: this.state,
    };
  }

  componentDidMount() {
    this.getAlertsData();
    this.interpretateAlerts();
  }

  componentDidUpdate(_prevProps: Readonly<Props>): void {
    if (!this._listenerAttached && this.props.socketCtx.isConnected()) {
      this._listenerAttached = true;
      this.joinRoom();

      if (this._intervalRef) {
        clearInterval(this._intervalRef);
      }
    }

    if (this.props.socketCtx.isClosed() && !this._intervalRef) {
      this.props.liveAlertSnackbar.setEphemeralMessage({
        title: this.props.trans({
          id: 'admin.active-searches.websocket.socket-broken',
          message: 'WebSocket connection broken.',
        }),
        message: this.props.trans({
          id: 'admin.active-searches.websocket.setting-interval-check',
          message: 'Setting interval to check active searches',
        }),
        severity: 'error',
      });

      this._intervalRef = this.addInterval(() => {
        this.getAlertsData();
        this.interpretateAlerts();
      }, ONE_MINUTE);
    }
  }

  componentWillUnmount(): void {
    this.leaveRoom();

    if (this._intervalRef) {
      clearInterval(this._intervalRef);
    }
  }

  getAlertsData = getAlertsData;
  interpretateAlerts = interpretateAlerts;
  joinRoom = joinRoom;
  leaveRoom = leaveRoom;
  onLiveAlertsUpdated = onLiveAlertsUpdated;
  protected onReconnect = onReconnect;
  protected removeAllLiveAlertListener = removeAllLiveAlertListeners;
}

export const LiveAlertCtx: React.FC = (props) => {
  const ringerCtx = Ringer.useCtx();
  const socketCtx = SocketContext.useCtx();
  const trans = LinguiContext.useCtx().trans;
  const liveAlertSnackbar = useLiveAlertSnackbar(ringerCtx);

  return (
    <LiveAlertSnackbarProvider>
      <LiveAlertContext
        ringerCtx={ringerCtx}
        socketCtx={socketCtx}
        liveAlertSnackbar={liveAlertSnackbar}
        trans={trans}
      >
        {props.children}
      </LiveAlertContext>
    </LiveAlertSnackbarProvider>
  );
};
