import { useEffect } from 'react';
import { useAsyncController } from './useAsyncController';
const { REACT_APP_STONLY_WID, REACT_APP_STONLY_WIDGET_ENABLED } = process.env;
const getStonlyWidgetScript = (s, t, o, n, l, y, w, g) => {
    return new Promise((resolve, reject) => {
        s.StonlyWidget ||
            (((w = s.StonlyWidget = () => {
                w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
            }).queue = []),
                ((y = t.createElement(o)).async = !0),
                (g = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
                (g.onreadystatechange = () => {
                    if (200 === g.status) {
                        resolve();
                    }
                    else {
                        reject(g.responseText);
                    }
                    4 === g.readyState &&
                        ((y.src =
                            n +
                                'stonly-widget.js?v=' +
                                (200 === g.status ? g.responseText : Date.now())),
                            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
                }),
                g.send());
    });
};
export const useStonlyWidget = () => {
    const ctrl = useAsyncController();
    useEffect(() => {
        if (REACT_APP_STONLY_WIDGET_ENABLED === 'true' &&
            !window.STONLY_WID &&
            ctrl.isIdle) {
            window.STONLY_WID = REACT_APP_STONLY_WID;
            getStonlyWidgetScript(window, document, 'script', 'https://stonly.com/js/widget/v2/')
                .then(() => {
                ctrl.setResolved(window.StonlyWidget._api);
            })
                .catch((err) => {
                ctrl.setRejected(err);
            });
        }
    }, [ctrl]);
    return ctrl;
};
