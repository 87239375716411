import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getJwt } from 'conversifi-shared-react/es6/util/jwt';
import { REACT_APP_CONVERSIFI_API_PATH } from '../util/util.parsedEnvs';

type TServerError = ServerError | NoResponseError | AxiosSettingError;
type TServerErrorInfo =
  | ServerErrorInfo
  | NoResponseErrorInfo
  | AxiosSettingErrorInfo;

/**
 * define the properties that the axios error will have
 * if it's a server error.
 */
export interface ServerError<T = any> extends Error {
  config: AxiosRequestConfig;
  response: AxiosResponse<T>;
  request?: AxiosError['request'];
}

/**
 * define the properties that the axios error will have
 * if the request is made, but no response is received.
 */
interface NoResponseError extends Error {
  config: AxiosRequestConfig;
  request: any;
}

/**
 * define the properties that the axios error will have
 * if the error occurred when setting up the request.
 */
interface AxiosSettingError extends Error {
  config: AxiosRequestConfig;
}

export interface ServerErrorInfo<ResponseData = any> extends Error {
  errorType: 'ServerError';
  name: Error['name'];
  data: ResponseData;
  message: Error['message'];
  responseStatus: AxiosResponse['status'];
  responseStatusText: AxiosResponse['statusText'];
  request?: AxiosError['request'];
  stack?: Error['stack'];
  extra?: {
    responseData?: AxiosResponse<ResponseData>['data'];
    requestParams?: AxiosRequestConfig['params'];
    requestData?: AxiosRequestConfig['data'];
    requestUrl?: AxiosRequestConfig['url'];
    requestMethod?: AxiosRequestConfig['method'];
  };
}

export interface NoResponseErrorInfo extends Error {
  errorType: 'NoResponseError';
  name: Error['name'];
  message: Error['message'];
  stack?: Error['stack'];
  request?: AxiosError['request'];
  extra?: {
    requestParams?: AxiosRequestConfig['params'];
    requestData?: AxiosRequestConfig['data'];
    requestUrl?: AxiosRequestConfig['url'];
    requestMethod?: AxiosRequestConfig['method'];
  };
}

export interface AxiosSettingErrorInfo extends Error {
  errorType: 'AxiosSettingError';
  name: Error['name'];
  message: Error['message'];
  stack?: Error['stack'];
  extra?: {
    requestParams?: AxiosRequestConfig['params'];
    requestData?: AxiosRequestConfig['data'];
    requestUrl?: AxiosRequestConfig['url'];
    requestMethod?: AxiosRequestConfig['method'];
  };
}

export interface RegularErrorInfo extends Error {
  errorType: 'RegularError';
}

/**
 * Determines if the error is a native AxiosError
 */
function isAxiosError(param: any): param is AxiosError {
  return param.response || param.request || param.config;
}

/**
 * Determines if is a ServerError (response has data, status & headers).
 */
function isServerError(param: any): param is ServerError {
  const { response } = param;
  return (
    isAxiosError(param) &&
    response &&
    response.data &&
    response.status &&
    response.headers
  );
}

function isNoResponseError(param: any): param is NoResponseError {
  return !isServerError(param) && param.request && param.config;
}

function isAxiosSettingError(param: any): param is AxiosSettingError {
  return !isNoResponseError(param) && param.config;
}

// function isRegularError(param: any): param is Error {
//   return !isAxiosSettingError(param) && param.message
// }

function addResponseData(error: ServerError, errWithInfo: ServerErrorInfo) {
  if (error.response.data) {
    if (!errWithInfo.extra) {
      errWithInfo.extra = {};
    }
    errWithInfo.data = error.response.data;
    errWithInfo.extra.responseData = error.response.data;
  }
}

function addRequestParams(error: TServerError, errWithInfo: TServerErrorInfo) {
  if (error.config.params) {
    if (!errWithInfo.extra) {
      errWithInfo.extra = {};
    }
    errWithInfo.extra.requestParams = error.config.params;
  }
}

function addRequestData(error: TServerError, errWithInfo: TServerErrorInfo) {
  if (error.config.data) {
    if (!errWithInfo.extra) {
      errWithInfo.extra = {};
    }
    errWithInfo.extra.requestData = error.config.data;
  }
}

function addRequestUrl(error: TServerError, errWithInfo: TServerErrorInfo) {
  if (error.config.url) {
    if (!errWithInfo.extra) {
      errWithInfo.extra = {};
    }
    errWithInfo.extra.requestUrl = error.config.url;
  }
}

function addRequestMethod(error: TServerError, errWithInfo: TServerErrorInfo) {
  if (error.config.method) {
    if (!errWithInfo.extra) {
      errWithInfo.extra = {};
    }
    errWithInfo.extra.requestMethod = error.config.method;
  }
}

function createServerErrorInfo(error: ServerError): ServerErrorInfo {
  const errWithInfo: ServerErrorInfo = {
    errorType: 'ServerError',
    name: error.name,
    data: error.response.data,
    message: error.message,
    responseStatus: error.response.status,
    responseStatusText: error.response.statusText,
  };

  if (error.stack) {
    errWithInfo.stack = error.stack;
  }

  if (error.request) {
    // request can be `any` in AxiosError declaration
    errWithInfo.request = error.request;
  }

  addResponseData(error, errWithInfo);
  addRequestParams(error, errWithInfo);
  addRequestData(error, errWithInfo);
  addRequestUrl(error, errWithInfo);
  addRequestMethod(error, errWithInfo);

  return errWithInfo;
}

function createNoResponseErrorInfo(
  error: NoResponseError
): NoResponseErrorInfo {
  const errWithInfo: NoResponseErrorInfo = {
    errorType: 'NoResponseError',
    name: error.name,
    message: error.message,
  };

  if (error.stack) {
    errWithInfo.stack = error.stack;
  }

  if (error.request) {
    // request can be `any` in AxiosError declaration
    errWithInfo.request = error.request;
  }

  addRequestParams(error, errWithInfo);
  addRequestData(error, errWithInfo);
  addRequestUrl(error, errWithInfo);
  addRequestMethod(error, errWithInfo);

  return errWithInfo;
}

function createSettingError(error: AxiosSettingError): AxiosSettingErrorInfo {
  const errWithInfo: AxiosSettingErrorInfo = {
    errorType: 'AxiosSettingError',
    name: error.name,
    message: error.message,
  };

  if (error.stack) {
    errWithInfo.stack = error.stack;
  }

  addRequestParams(error, errWithInfo);
  addRequestData(error, errWithInfo);
  addRequestUrl(error, errWithInfo);
  addRequestMethod(error, errWithInfo);

  return errWithInfo;
}

function createRegularError(err: AxiosError | Error | unknown) {
  const error = err as Error;

  const errWithInfo: RegularErrorInfo = {
    errorType: 'RegularError',
    name: error.name,
    message: error.message,
  };

  return errWithInfo;
}

export type ErrorInfo =
  | ServerErrorInfo
  | NoResponseErrorInfo
  | AxiosSettingErrorInfo
  | RegularErrorInfo;

/**
 * @param error AxiosError | Error
 */
export const createError = (err: AxiosError | Error | unknown): ErrorInfo => {
  // See: https://github.com/axios/axios#handling-errors

  let error:
    | ServerErrorInfo
    | NoResponseErrorInfo
    | AxiosSettingErrorInfo
    | RegularErrorInfo;

  if (isAxiosError(err)) {
    if (isServerError(err)) {
      error = createServerErrorInfo(err);
    } else if (isNoResponseError(err)) {
      error = createNoResponseErrorInfo(err);
    } else if (isAxiosSettingError(err)) {
      error = createSettingError(err);
    } else {
      error = createRegularError(err);
    }
  } else {
    error = createRegularError(err);
  }

  return error;
};

export function errorsAreEqual(prevError: ErrorInfo, nextError: ErrorInfo) {
  if (
    prevError.errorType === 'ServerError' &&
    nextError.errorType === 'ServerError'
  ) {
    return (
      prevError.responseStatus === nextError.responseStatus &&
      prevError.responseStatusText === nextError.responseStatusText
    );
  }

  return (
    prevError.message === nextError.message &&
    prevError.stack === nextError.stack
  );
}

export function isErrorInfo(error?: any): error is ErrorInfo {
  return Boolean(error?.errorType && error?.message);
}

export function isErrorUnauthorized(
  error: ErrorInfo
): error is ServerErrorInfo {
  return error.errorType === 'ServerError' && error.responseStatus === 401;
}

export const isServerErrorInfo = (err: any): err is ServerErrorInfo => {
  return Boolean(err && err.errorType === 'ServerError');
};

/**
 * try to extract the response `data.message` from the error
 * if this is a ServerError
 */
export const getServerErrorInfoData = (err: any) => {
  return isServerErrorInfo(err) && err.extra && err.extra.responseData
    ? err.extra.responseData.message
    : 'Server Error';
};

/**
 * try to extract the response data from a server error.
 * Will return undefined if no data exists in the response.
 */
export const getResponseDataFromError = <ErrorResponse = any>(
  err: ErrorInfo
) => {
  if (isServerErrorInfo(err) && err.extra) {
    return err.extra.responseData as ErrorResponse;
  }
};

const headers: any = {
  'Content-Type': 'application/json',
};

if (getJwt() !== '') {
  headers['Authorization'] = getJwt();
}

const axiosInstance = axios.create({
  baseURL: REACT_APP_CONVERSIFI_API_PATH,
  headers,
});

axiosInstance.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const token = getJwt();
    config.headers.Authorization = token;
  }
  return config;
});

export default axiosInstance;
