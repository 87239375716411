import { apiErrorHandler } from '../apiErrorHandler';
import { UniversityGetResponse } from '../ResponseSchemas';
import { UniversityPaginateData } from '../RequestSchemas';
import axios from 'axios';

export const getUniversities = async (
  paginate: UniversityPaginateData
): Promise<UniversityGetResponse> => {
  try {
    const { data } = await axios.request<UniversityGetResponse>({
      url: `/api/admin/universities/`,
      params: { paginate },
    });

    return data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
