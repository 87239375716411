import { useRef, useState } from 'react';
import { injectScript } from 'conversifi-shared-react/es6/util/dom';

/**
 * hook to lazily load google apis library when is needed.
 * google api key must exists in the `.env` file under the name
 * `REACT_APP_GOOGLE_API_KEY`
 */
export function useGoogle(): [boolean, typeof google | null, () => void] {
  const googleApis = useRef<typeof google | null>(null);
  const googleApisLoading = useRef<boolean>(false);
  const k = process.env.REACT_APP_GOOGLE_API_KEY;
  const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${k}&libraries=places`;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ensureGoogleLibrary = () => {
    if (!googleApis.current && !googleApisLoading.current && !isLoading) {
      setIsLoading(true);
      googleApisLoading.current = true;
      injectScript(scriptUrl).then(() => {
        googleApisLoading.current = false;
        googleApis.current = google;
        setIsLoading(false);
        console.debug('google library loaded okay');
      });
    }
  };

  return [isLoading, googleApis.current, ensureGoogleLibrary];
}
