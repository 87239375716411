import { JWT_TOKEN_NAME, SESSION_KEY_SUBSCRIPTION_FEE_STATUS, SESSION_KEY_IN_CALL, } from '../../constants';
import getLocalSessionItem from './getLocalSessionItem';
export default () => {
    const token = getLocalSessionItem(JWT_TOKEN_NAME);
    const subscriptionFeeStatus = getLocalSessionItem(SESSION_KEY_SUBSCRIPTION_FEE_STATUS);
    const inCall = getLocalSessionItem(SESSION_KEY_IN_CALL);
    return {
        token,
        subscriptionFeeStatus,
        inCall,
    };
};
