import React, { useState } from 'react';
import { usePopoverDescriptionStyles } from './PopoverDescriptionStyles';
import { Box, Fade, Paper, Popper } from '@mui/material';
export const PopoverDescription = ({ content, containerClassName, containerStyle, popperStyle, clickable = false, children, }) => {
    const classes = usePopoverDescriptionStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handlePopoverClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    return (React.createElement(Box, { ...(!clickable && {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
        }), ...(clickable && { onClick: handleClick }), className: containerClassName, style: containerStyle },
        React.createElement(Box, { className: classes.childrenContainer }, children),
        content && (React.createElement(Popper, { open: open, anchorEl: anchorEl, transition: true, className: "popperContainer", style: popperStyle }, ({ TransitionProps }) => (React.createElement(Fade, { ...TransitionProps, timeout: 350 },
            React.createElement(Paper, { className: ['paperContainer', classes.paper].join(' '), elevation: 2 }, content)))))));
};
