export default function (event, open) {
    if (event.type === 'keydown' &&
        (event.key === 'Tab' ||
            event.key === 'Shift')) {
        return;
    }
    this.setOpen(open);
    if (!open) {
        this.setItemSelected(undefined);
    }
}
