import React from 'react';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
const SecondaryItem = ({ onClick, text }) => {
    return (React.createElement(ListItem, { onClick: onClick, sx: { m: 0, p: 0 } },
        React.createElement(ListItemButton, null,
            React.createElement(ListItemText, { primary: text, primaryTypographyProps: {
                    color: (theme) => theme.palette.grey[500],
                    variant: 'caption',
                } }))));
};
export default SecondaryItem;
