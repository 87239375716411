import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    filterByTextStyles: {
      borderWidth: '3px',
      '& input': {
        fontWeight: 550,
        fontSize: '14px',
      },
      '& :hover:not(.Mui-disabled):before': {
        borderBottom: '3px solid rgba(0, 0, 0, 0.6) !important',
      },
      '& .MuiInput-root:before ': {
        borderWidth: '3px',
      },
      '& .MuiInput-root:after ': {
        borderWidth: '3px',
      },
    },
  })
);
