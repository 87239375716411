import React, { FC, useState, useEffect } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { InputAdornment } from '@mui/material';

import { capitalizeFirstLetter } from 'conversifi-shared-react/es6/util/miscellaneous/capitalizeFirstLetter';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { EmailConstraint } from '../LoginForm';
import InputTextField from '../../components/InputTextField';

interface Props {
  onUpdate: (email: string) => void;
}

export const Email: FC<Props> = ({ onUpdate }) => {
  const trans = LinguiContext.useCtx().trans;
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    onUpdate(email);

    EmailConstraint.validate(email)
      .then(() => {
        setError('');
      })
      .catch((err) => {
        setError(capitalizeFirstLetter(err.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <InputTextField
      value={email}
      onChange={(e) => {
        setChanged(true);
        setEmail(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" color="white">
            <EmailOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
          </InputAdornment>
        ),
      }}
      id="loginForm.email"
      aria-describedby="loginForm.email-help"
      autoComplete="conv-login-email"
      name="conv-login-email"
      label={trans('common.email-case-sensitive')}
      placeholder={trans('common.email-case-sensitive')}
      fullWidth
      required
      error={changed && !focused && Boolean(error)}
      helperText={changed && !focused && Boolean(error) && error}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};
