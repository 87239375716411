import axios from 'axios';
import type { Section } from 'conversifi-types/src/Section';
import { apiErrorHandler } from '../../apiErrorHandler';

interface Params {
  universityId: string;
  page: number;
}

export const getLatestByPage = async (params: Params) => {
  const { universityId, page } = params;
  try {
    const { data } = await axios.get<Section[]>(
      `/api/admin/university/${universityId}/section-draft/page/${page}`
    );

    return data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
