import React, { useState, useEffect, useCallback } from 'react';
import { S3_URL_REGEX } from '../..';
import { getJwt } from '../../util';
const spinner = `${process.env.PUBLIC_URL}/assets/spinner.svg`;
const defaultWrapperStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
export const VideoPlayer = ({ url, obfuscateBackground = false, closeOnClickOutside = false, wrapperStyles = defaultWrapperStyles, onClose, }) => {
    const [show, setShow] = useState(true);
    const [videoSrc, setVideoSrc] = useState();
    const getVideoFromTwilio = useCallback(async () => {
        const result = await fetch(url, {
            headers: {
                Authorization: getJwt(),
            },
        });
        const blob = await result.blob();
        if (blob) {
            setVideoSrc(URL.createObjectURL(blob));
        }
    }, [url]);
    useEffect(() => {
        if (!show) {
            setShow(true);
        }
        if (url) {
            if (S3_URL_REGEX.test(url)) {
                setVideoSrc(url);
            }
            else {
                getVideoFromTwilio();
            }
        }
    }, [url]);
    if (!show) {
        onClose?.();
        return null;
    }
    const close = () => {
        onClose?.();
        setShow(false);
    };
    const CloseButton = (React.createElement("span", { onClick: close, style: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'white',
            fontSize: '1.3em',
            cursor: 'pointer',
        } }, "X"));
    const backgroundLayer = (React.createElement("div", { style: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 99,
        }, onClick: closeOnClickOutside ? close : undefined }));
    wrapperStyles = {
        ...wrapperStyles,
        zIndex: 100,
        backgroundColor: 'black',
        minWidth: '500px',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: wrapperStyles },
            React.createElement("video", { src: videoSrc, controls: true, width: "100%" }),
            !videoSrc && (React.createElement("img", { alt: "", src: spinner, style: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    height: '100px',
                    transform: 'translate(-50%, -50%)',
                } })),
            CloseButton),
        obfuscateBackground && backgroundLayer));
};
