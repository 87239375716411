/** @type {import('./resolveObjectPath')} */
const resolveObjectPath = (object, objPath, defaultValue) => {
  try {
    if (!objPath) {
      return object;
    }

    objPath = objPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    objPath = objPath.replace(/^\./, ''); // strip a leading dot
    const a = objPath.split('.');

    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];

      if (k in object) {
        object = object[k];
      } else {
        return defaultValue;
      }
    }
    return object;
  } catch (e) {
    return defaultValue;
  }
};

module.exports = resolveObjectPath;
