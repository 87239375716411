import { requestResetPasswordPost } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'

export const userResetPassword = async (userId: string, password: string) => {
  try {
    return await requestResetPasswordPost(userId, password);
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
