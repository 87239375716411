import { getJwt } from 'conversifi-shared-react';

export const isResponse = (res: any): res is Response => {
  return Boolean(res && typeof res.json === 'function');
};

export const isNotFound = (res: any): boolean => {
  return isResponse(res) && res.status === 404;
};

export const convFetch = (url: string, options?: RequestInit) => {
  const AuthenticatedHeaders = new Headers((options && options.headers) || {});
  AuthenticatedHeaders.set('Content-Type', 'application/json');
  AuthenticatedHeaders.set('Authorization', getJwt());

  let requestOptions: RequestInit;

  if (!options) {
    requestOptions = {
      headers: AuthenticatedHeaders,
    };
  } else {
    requestOptions = options;
    requestOptions.headers = AuthenticatedHeaders;
  }

  return fetch(url, requestOptions);
};
