import axios from 'axios';
import { getJwt } from '../util/jwt';
import getSessionExpirationDate from '../util/session/getSessionExpirationDate';
import refreshSessionExpirationDate from '../util/session/refreshSessionExpirationDate';
const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export function createAxiosInstance({ onSessionExpired, }) {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (getJwt() !== '') {
        headers['Authorization'] = getJwt();
    }
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_CONVERSIFI_API_PATH,
        headers,
    });
    axiosInstance.interceptors.request.use((config) => {
        const date = getSessionExpirationDate();
        if (!date || date.getTime() < Date.now()) {
            onSessionExpired?.();
        }
        else {
            refreshSessionExpirationDate();
            if (!config.headers.Authorization) {
                const token = getJwt();
                config.headers.Authorization = token;
            }
        }
        config.params = {
            ...config.params,
            timeZone: getTimeZone(),
        };
        return config;
    });
    return axiosInstance;
}
export default createAxiosInstance;
