import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Trans } from '@lingui/react';

import { notifyVisitorPath } from 'conversifi-shared-react/es6/util/zendesk/notifyVisitorPath';
import { Routes } from '../../routes';
import { UserLoginResponse } from '../../api/ResponseSchemas';
import { LoginRegisterTemplate } from '../templates/login-register';
import { LoginForm } from './LoginForm';

interface Props {
  onUserLoggedIn: (userLoggedInResponse: UserLoginResponse) => void;
}

export const LoginScreen: React.FC<Props> = ({ onUserLoggedIn }) => {
  notifyVisitorPath('Login');

  return (
    <LoginRegisterTemplate>
      <Typography variant="h6" textAlign="center">
        <Trans id="anonymous.login-screen.welcome">
          Welcome! Please log into your account
        </Trans>
      </Typography>
      <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
        <Trans id="anonymous.login-screen.do-not-have-an-account-yet">
          Don't have an account yet?
        </Trans>
        <Link
          id="loginScreen.register"
          to={Routes.register.uri}
          style={{ marginLeft: '8px' }}
        >
          <Trans id="anonymous.login-screen.register">Register</Trans>
        </Link>
      </Typography>
      <LoginForm onLoggedIn={onUserLoggedIn} />
      <Link id="loginScreen.forgotPassword" to={Routes.forgotPassword.uri}>
        <Typography color="white" textAlign="center">
          <Trans id="anonymous.login-screen.forgot-your-password">
            Forgot your password?
          </Trans>
        </Typography>
      </Link>
    </LoginRegisterTemplate>
  );
};
