import { AdminSdk } from '../../../../sdk/admin.sdk';
import { CoachesBehaviorsContext } from '../index';

export default async function fetchCoachesAiReport(
  this: CoachesBehaviorsContext
) {
  try {
    this.produce((draft) => {
      draft.loading = true;
    });

    const data = await AdminSdk.Coaches.getCoachesAiReport({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      filters: this.state.filters,
    });

    this.produce((draft) => {
      draft.coachesAiReport = data;
      draft.loading = false;
    });
  } catch (err) {
    console.error(err);
  }
}
