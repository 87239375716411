import React from 'react';

interface GooglePlacesContextType {
  isLoading: boolean;
  google: typeof google | null;
  /**
   * Ensure that google library is loaded.
   * This work in pair with the `useGoogle`
   * custom hook at `src/util/util.hooks.ts`
   */
  ensureGoogleLibrary: () => typeof google.maps | void;
}

const googlePlacesContext: GooglePlacesContextType = {
  isLoading: false,
  google: null,
  ensureGoogleLibrary: () => {},
};

export const GooglePlacesContext = React.createContext(googlePlacesContext);
