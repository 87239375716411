import React from 'react';
import type { PublicApi } from 'conversifi-shared-react/es6/components/Sockets/types/PublicApi';
import { SocketContext as Socket } from 'conversifi-shared-react/es6/components/Sockets';

/**
 * Public context for MAv2.
 *
 * > Only type casting.
 */
const AdminPortalSocketContext: React.Context<PublicApi> = React.createContext(
  {} as any
);

export class SocketContext extends Socket {
  readonly className = 'AdminPortalSocketContext';
  protected readonly PublicContext = AdminPortalSocketContext;

  static useCtx = () => React.useContext(AdminPortalSocketContext);

  render() {
    return (
      <this.PublicContext.Provider value={this.getPublicApi()}>
        {this.props.children}
      </this.PublicContext.Provider>
    );
  }
}
