import { useMediaQuery, useTheme } from '@mui/material';
export const useAllMediaBreakpoint = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));
    return [isXs, isSm, isMd, isLg, isXl];
};
export const useCurrentMediaBreakpoint = () => {
    const theme = useTheme();
    const status = useAllMediaBreakpoint();
    return theme.breakpoints.keys[status.indexOf(true)];
};
export function useIsMobile() {
    return useCurrentMediaBreakpoint() === 'xs';
}
export function useIsTablet() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between('sm', 'md'));
}
export function useIsPortrait() {
    return useMediaQuery('(orientation: portrait)');
}
export function useIsLandscape() {
    return useMediaQuery('(orientation: lanscape)');
}
export function useIsMobileOrTablet() {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    return isMobile || isTablet;
}
