import React from 'react';
import { CircularProgress } from '@mui/material';
export class LazyLoadSpinner extends React.Component {
    render() {
        return (React.createElement(CircularProgress, { color: "secondary", size: 60, style: {
                position: 'fixed',
                top: '40%',
                left: 'calc(50% - 30px)',
                zIndex: 100,
            } }));
    }
}
