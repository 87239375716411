const zopimRequests = [];
let zopimLoadFailed = false;
function resolveZopim(z) {
    while (zopimRequests.length) {
        const r = zopimRequests.shift();
        r?.(z);
    }
}
function zopimLoadFail(resolve) {
    if (!zopimLoadFailed) {
        zopimLoadFailed = true;
        const i = zopimRequests.indexOf(resolve);
        if (i >= 0) {
            zopimRequests.slice(i, 1);
        }
        console.warn('[Z107] Zendesk not loaded! (timeout)');
    }
}
function waitForZopim(resolve) {
    const timestamp = $zopim?.livechat ? 0 : new Date().getTime();
    let lastCheck = 0;
    const tryGetZopim = () => {
        if ($zopim?.livechat) {
            resolveZopim($zopim);
        }
        else {
            const now = new Date().getTime();
            if (lastCheck + 3000 < now) {
                lastCheck = now;
                console.debug('[Z99] Zendesk not ready!', { $zopim });
            }
            if (timestamp + 30000 > now) {
                setTimeout(tryGetZopim, 100);
            }
            else {
                zopimLoadFail(resolve);
            }
        }
    };
    tryGetZopim();
}
export function getZopim() {
    if (zopimLoadFailed) {
        return new Promise(() => {
            console.debug('[Z97] Zendesk not loaded! (already failed)');
        });
    }
    return new Promise((resolve) => {
        zopimRequests.push(resolve);
        if ($zopim?.livechat) {
            resolveZopim($zopim);
        }
        else {
            waitForZopim(resolve);
        }
    });
}
