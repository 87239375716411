/**
 * Expose functions for making HTTP requests to the server API.
 *
 * IMPORTANT:
 *
 * - Keep this functions simple.
 * - functions MUST NOT RECEIVE MORE THAN ONE PARAM.
 * - Do not try to handle errors here (error handling will be responsibility of the caller).
 * - Keep function declarations ordered alphabetically by `url`.
 */

import {
  CreateCoupon,
  UniversityPostRequest,
  RowDataRequest,
  PromotionsListRequest,
} from './RequestSchemas';

import {
  UserResponse,
  UserLoginResponse,
  LogoutResponse,
  GenerateCouponCodeResponse,
  CreateCouponResponse,
  JsonUploadResponse,
  SendSmsValidationCode,
  PhoneValidationSuccess,
  DepartmentsByUniversityResponse,
  CoursesByDepartmentResponse,
  UniversityByUserGetResponse,
  FacultiesDataResponse,
  PromotionsListResponse,
  ModulesListResponse,
  PendingPaypalTransactionsResponse,
} from './ResponseSchemas';
import axios from 'axios';
import type { Coupon } from 'conversifi-types/src/Coupon';
import type { Course, AdminCourse } from 'conversifi-types/src/Course';
import type {
  University,
  UniversityCreate,
} from 'conversifi-types/src/University';
import type { CouponsBatchInputParameters } from 'conversifi-types/src/CouponsBatchConfig';
import type { PaginatedData } from 'conversifi-types/src/commons/paginatedData';
import type { User } from 'conversifi-types/src/User/User';
import { CsvFileType } from '../util';
import { IncentivesUserFilters } from 'conversifi-types/src/shared-frontend-backend/Incentives';
import { Balance } from 'conversifi-types/src/Balance';
import { PAYPAL_TRANSACTION_ADMIN_OPERATIONS } from 'conversifi-types/src/PaypalTransaction';
import { PaginatedSize } from 'conversifi-types/src/shared-frontend-backend/PaginatedResult';
import refreshSessionExpirationDate from 'conversifi-shared-react/es6/util/session/refreshSessionExpirationDate';
import clearLocalSession from 'conversifi-shared-react/es6/util/session/clearLocalSession';
import { UserLoginData } from 'conversifi-types/src/shared-frontend-backend/UserLoginData';
import { CallPopulated } from '../App.admin/Users/SearchUsers/components/CallsList';
import { DepartmentCreate } from 'conversifi-types/src/Department';

/**
 * user email exists
 */
export const requestUserEmailExists = async (email: string) =>
  axios.request({
    url: `/api/auth/exists/email/${email}`,
  });

/**
 * user handle exists
 */
export const requestUserHandleExists = async (handle: string) =>
  axios.request({
    url: `/api/auth/exists/handle/${handle}`,
  });

/**
 * user login
 * login and set the auth token in the axios default headers
 */
export const requestUserLogin = async (userLoginData: UserLoginData) => {
  const { data } = await axios.request<UserLoginResponse>({
    method: 'POST',
    url: requestUserLogin.url,
    data: userLoginData,
  });

  // set the token as default in axios so we don't have to passing around every
  // time we want to make an authorized request.
  axios.defaults.headers.common.Authorization = data.token;

  refreshSessionExpirationDate();

  return data;
};

// attach the login url
requestUserLogin.url = `/api/auth/login`;

/**
 * @TODO: Logout disabled
 * User logout. Remove Auth headers from axios and request logout to the server
 */
export const requestUserLogout = async () => {
  // remove Authorization header before making the request.
  // if the request fail, the user is at least logged out
  // in the axios session
  let returnValue;
  try {
    const { data } = await axios.request<LogoutResponse>({
      method: 'POST',
      url: `/api/auth/logout`,
    });
    returnValue = data;
  } catch (err) {
    console.error('[RUL118] Error logging out', err);
  } finally {
    delete axios.defaults.headers.common.Authorization;
    clearLocalSession();
  }

  return returnValue;
};

/**
 * user fetch the current user data.
 * Returns null if no token exists, throws error if request fails.
 * Returns response data otherwise.
 */
export const requestUserGet = async () => {
  const { data } = await axios.request<UserResponse>({
    url: `/api/user`,
  });

  return data;
};

/**
 * Edit user data.
 * Returns null if no token exists, throws error if request fails.
 * Returns response data otherwise.
 */
export const requestUserDataPut = async (userData: User) => {
  const { data } = await axios.request<string>({
    method: 'PUT',
    url: `/api/admin/users/` + userData._id,
    data: userData,
  });

  return data;
};

export const requestGenerateCouponCode = async () => {
  const { data } = await axios.request<GenerateCouponCodeResponse>({
    url: `/api/admin/coupon/generate`,
  });

  return data;
};

export const requestCreateCoupon = async (coupon: CreateCoupon) => {
  const { data } = await axios.request<CreateCouponResponse>({
    url: `/api/admin/coupon/create`,
    method: 'POST',
    data: coupon,
  });

  return data;
};

/**
 * Creates a batch of coupons with the given parameters.
 * @param coupon CouponsBatchInputParameters
 */
export const requestCreateCouponBatch = async (
  coupon: CouponsBatchInputParameters
) => {
  const { data } = await axios.request<CreateCouponResponse>({
    url: `/api/admin/coupon/create/batch`,
    method: 'POST',
    data: coupon,
  });

  return data;
};

export const requestExportCouponList = async (params: any) => {
  return axios.request({
    url: `/api/admin/coupon/export`,
    method: 'GET',
    params,
  });
};

export const requestCouponList = async (params: any) => {
  const { data } = await axios.request<PaginatedData<Coupon>>({
    url: `/api/admin/coupon/list`,
    params,
  });

  return data;
};

export interface RequestJsonUploadParams<T> {
  datatype: CsvFileType;
  data: T;
}

export const requestJsonUpload = async <T>(
  params: RequestJsonUploadParams<T>
) => {
  /**
   * we need to make the upload using application/x-www-form-urlencoded
   * format to allow the uploading of a payload of more then
   * 100kb
   */
  const urlSearchParams = new FormData();
  const string = JSON.stringify(params.data);
  urlSearchParams.append('file', string);

  const { data } = await axios.request<JsonUploadResponse>({
    method: 'POST',
    url: `/api/admin/uploads/${params.datatype}`,
    headers: { 'content-type': 'multipart/form-data' },
    data: urlSearchParams,
  });

  return data;
};

// Courses request

export const requestAllCoursesList = async () => {
  const { data } = await axios.request<Course[]>({
    url: `/api/admin/courses/all`,
  });

  return data;
};

export const requestCoursesByUniversityGet = async (params: any) => {
  const { data } = await axios.request<Course[]>({
    method: 'GET',
    url: `/api/admin/courses`,
    params,
  });

  return data;
};

export const requestCreateCoursePost = async (course: AdminCourse) => {
  const { data } = await axios.request<Course>({
    method: 'POST',
    url: `/api/admin/courses`,
    data: course,
  });

  return data;
};

export const requestUpdateCoursePut = (
  courseId: string,
  course: AdminCourse
) => {
  return axios.request<void>({
    method: 'PUT',
    url: `/api/admin/course/${courseId}`,
    data: course,
  });
};

export const requestDeleteCourseDelete = (courseId: string) => {
  return axios.request<Course>({
    method: 'DELETE',
    url: `/api/admin/courses/${courseId}`,
  });
};

/**
 * Edit user balance.
 * Returns null if no token exists, throws error if request fails.
 * Returns response data otherwise.
 */
export const requestUserBalancePut = async (balanceData: Balance) => {
  const { data } = await axios.request<string>({
    method: 'POST',
    url: `/api/admin/ledgers`,
    data: balanceData,
  });

  return data;
};

export const requestSmsValidationCode = async (phone: string) => {
  const { data } = await axios.request<SendSmsValidationCode>({
    method: 'POST',
    url: `/api/sms/sendValidation/${phone}`,
  });

  return data;
};

export const requestRedeemsSmsValidationCode = async ({
  phone,
  code,
  allowSms,
}: {
  phone: string;
  code: string;
  allowSms: boolean;
}) => {
  const { data } = await axios.request<PhoneValidationSuccess>({
    method: 'POST',
    url: `/api/sms/validate/${phone}/${code}`,
    data: {
      allowReceiveSMS: allowSms,
    },
  });

  return data;
};

export const requestSkipPhoneNumberValidation = async () => {
  const { data } = await axios.request({
    method: 'POST',
    url: `/api/sms/skip-validation`,
  });

  return data;
};

/**
 * get all universities without paginate
 * see: conversifi-backend/app/services/admin_external/adminUniversity/handlers/UniversityByUser.js
 */
export const requestUniversityByUserGet = async (userId: string) => {
  const { data } = await axios.request<UniversityByUserGetResponse>({
    url: `/api/admin-externals/university/`,
    params: { userId },
  });

  return data;
};

/**
 * get all departments by university id
 */
export const requestDepartmentsByUniversityGet = async (
  universityId: string
) => {
  const { data } = await axios.request<DepartmentsByUniversityResponse>({
    url: `/api/admin-externals/university/departments`,
    params: { universityId },
  });

  return data;
};

/**
 * get all courses by department id
 */
export const requestCoursesByDepartmentGet = async (departmentId: string) => {
  const { data } = await axios.request<CoursesByDepartmentResponse>({
    url: `/api/admin-externals/department/courses`,
    params: { departmentId },
  });

  return data;
};

/**
 * Add university data.
 * Returns null if no token exists, throws error if request fails.
 * Returns response data otherwise.
 */
export const requestUniversityDataPost = async (
  universityData: UniversityCreate
) => {
  const { data } = await axios.request({
    method: 'POST',
    url: `/api/admin/university`,
    data: universityData,
  });

  return data;
};

/**
 * Edit university data.
 * Returns null if no token exists, throws error if request fails.
 * Returns response data otherwise.
 */
export const requestUniversityDataPut = async (universityData: University) => {
  const { data } = await axios.request<string>({
    method: 'PUT',
    url: `/api/admin/university/` + universityData._id,
    data: universityData,
  });

  return data;
};

/**
 * delete one universities
 */
export const requestUniversityDelete = async (universityId: string) => {
  const { data } = await axios.request<string>({
    method: 'DELETE',
    url: `/api/admin/university/${universityId}`,
  });

  return data;
};

/**
 * save university data for external users
 */
export const requestUniversityPost = async (
  universityData: UniversityPostRequest
) => {
  const { data } = await axios.request<string>({
    method: 'POST',
    url: '/api/admin-externals/university/update',
    params: { universityData },
  });

  return data;
};

/**
 * get faculties data for external users
 */
export const requestFacultiesDataGet = async (facultyName: string) => {
  const { data } = await axios.request<FacultiesDataResponse>({
    url: '/api/admin-externals/faculty',
    params: { facultyName },
  });

  return data;
};

/**
 * Save new sections code data
 */
export const requestSectionsCodePost = async (rowsData: RowDataRequest[]) => {
  const { data } = await axios.request<string>({
    method: 'POST',
    url: '/api/admin-externals/section-code/create',
    params: { rowsData },
  });

  return data;
};

/**
 * Get rules data
 */
export const requestRulesGet = async () => {
  const { data } = await axios.request<any>({
    method: 'GET',
    url: '/api/admin/rules',
  });

  return data.docs;
};

/**
 * Update rules data
 */
export const requestRulePut = async (rule: any) => {
  const { data } = await axios.request({
    method: 'PUT',
    url: `/api/admin/rules/${rule._id}`,
    data: rule,
  });

  return data;
};

/**
 * Verification email
 */
export const requestResetPasswordPost = (
  userId: string,
  password: string
): Promise<any> => {
  return axios.request({
    method: 'POST',
    url: '/api/admin/users/resetPassword',
    data: {
      userId,
      password,
    },
  });
};

// Promotions & Messages request

/**
 * Get a list of users based on the given filters
 * Prepared for the Promotions And Messages Admin screen
 * @param params Filters to get a list of users
 * @return Array of users
 */
export const requestPromotionsAndMessagesFilterUsersGet = (
  params: IncentivesUserFilters
): Promise<any> => {
  return axios.request({
    method: 'GET',
    url: '/api/admin/promotions-and-messages/filter-users',
    params,
  });
};

/**
 * Get the list of existent promotions. It can be
 * 'active' or 'pending' promotions
 * @param params
 * @return Array of promotions
 */
export const requestPromotionsAndMessagesPromotionsListGet = (
  params: PromotionsListRequest
): Promise<any> => {
  return axios.request<PromotionsListResponse>({
    url: '/api/admin/promotions-and-messages/promotions',
    params,
  });
};

/**
 * Remove a promotion based on its given id
 * @param promotionId
 */
export const requestPromotionsAndMessagesPromotionDelete = (
  promotionId: string
) => {
  return axios.request({
    method: 'DELETE',
    url: `/api/admin/promotions-and-messages/promotions/${promotionId}`,
  });
};

export const requestPromotionsAndMessagesConfirmPost = ({
  filters,
  promotion,
  notification,
}: any) => {
  return axios.request({
    method: 'POST',
    url: '/api/admin/promotions-and-messages',
    data: {
      filters,
      promotion,
      notification,
    },
  });
};

// Departments

export const requestUpdateDepartmentPut = (departmentId: string, data: any) => {
  return axios.request({
    method: 'PUT',
    url: `/api/admin/department/${departmentId}`,
    data,
  });
};

export const requestCreateDepartmentPost = (
  universityId: string,
  department: DepartmentCreate
) => {
  return axios.request({
    method: 'POST',
    url: `/api/admin/universities/${universityId}/departments`,
    data: department,
  });
};

export const requestDeleteDepartmentDelete = (id: string) => {
  return axios.request({
    method: 'DELETE',
    url: `/api/admin/departments/${id}`,
  });
};

// Modules

/**
 * Get the list of existent modules.
 * @param params
 * @return Array of modules
 */
export const requestModulesListGet = (params: any): Promise<any> => {
  const queryParams = new URLSearchParams(params);
  return axios.get<ModulesListResponse>('/api/modules', {
    params: queryParams,
  });
};

export const requestModulesIdentifierExistsGet = async (
  id: number
): Promise<boolean> => {
  const response = await axios.get(
    `/api/modules/checkIdentifierExists?identifier=${id}`
  );
  const { exists } = response.data;
  return exists;
};

/**
 *
 * Update a module
 * @param {string} id Module Id
 * @param {any} module Module data to do the update
 */
export const requestModulePut = (id: string, moduleData: any): Promise<any> => {
  return axios.request({
    method: 'PUT',
    url: `/api/admin/modules/${id}`,
    data: moduleData,
  });
};

/**
 *
 * Create a module
 * @param module
 * @return New module created
 */
export const requestModulePost = (module: any): Promise<any> => {
  return axios.request({
    method: 'POST',
    url: '/api/admin/modules',
    data: module,
  });
};

export const requestPendingTransactionsGet = (
  page: number,
  size: PaginatedSize
) => {
  return axios.get<PendingPaypalTransactionsResponse>(
    '/api/admin/paypal/pending-transactions',
    {
      params: { page, size },
    }
  );
};

export const requestApproveRejectTransactionGet = (
  transactionId: string,
  operation: PAYPAL_TRANSACTION_ADMIN_OPERATIONS
) => {
  return axios.get<PendingPaypalTransactionsResponse>(
    `/api/admin/paypal/pending-transactions/${transactionId}/${operation}`
  );
};

export const requestUserCallsGet = (
  userId: string,
  page: number,
  size: PaginatedSize
) => {
  return axios.get<PaginatedData<CallPopulated>>(
    `/api/admin/users/${userId}/calls`,
    {
      params: { page, size },
    }
  );
};
