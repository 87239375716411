export const range = (num: number) => [...Array(num).keys()];

export const arrPush = <T>(num: number, item: T) => range(num).map(() => item);

export const delay = (ms: number) =>
  new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const parseIntOrDefault = (value: any, def: number): number => {
  if (value === '' || value === null || value === undefined) {
    return def;
  }

  const parsed = parseInt(value, 10);
  return !isNaN(parsed) ? parsed : def;
};
