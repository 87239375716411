function remove(arr, src) {
    const injectingIndex = arr.indexOf(src);
    if (injectingIndex !== -1) {
        arr.splice(injectingIndex, 1);
    }
    else {
        console.warn('[IS6] script index not found');
    }
}
export const injectScript = (src, nameSpace) => {
    if (injectScript.isInjecting(src)) {
        console.warn(`[IS33] a script with src: "${src}" is being injecting already`);
        console.debug('script re injection prevented.');
        return Promise.resolve(nameSpace && window[nameSpace]);
    }
    if (injectScript.isInjected(src)) {
        console.warn(`[IS41]
      WARNING: a script with src: "${src}" was
      already injected in the document.
    `);
        console.debug('script re injection prevented.');
        return Promise.resolve(nameSpace && window[nameSpace]);
    }
    injectScript.injecting.push(src);
    return new Promise((resolve, reject) => {
        try {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = () => {
                console.debug('script "onload" fired');
                remove(injectScript.injecting, src);
                injectScript.injected.push(src);
                resolve(nameSpace && window[nameSpace]);
            };
            script.onerror = (err) => {
                remove(injectScript.injecting, src);
                reject(err);
            };
            script.src = src;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
        catch (err) {
            reject(err);
        }
    });
};
injectScript.injected = [];
injectScript.injecting = [];
injectScript.isInjected = (src) => {
    return injectScript.injected.includes(src);
};
injectScript.isInjecting = (src) => {
    return injectScript.injecting.includes(src);
};
injectScript.shouldInject = (src) => {
    return !injectScript.isInjected(src) && !injectScript.isInjecting(src);
};
