import { styled, TableCell } from '@mui/material';

const BodyTableCellWithLessPadding = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
}));

export default function ({ children }: { children?: React.ReactNode }) {
  return (
    <BodyTableCellWithLessPadding align="center">
      {children}
    </BodyTableCellWithLessPadding>
  );
}
