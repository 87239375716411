import axios from 'axios';
import { SectionCodeValidationData } from 'conversifi-types/src/Section';

export const sectionCodeIsValid = async (sectionCode: string) => {
  const { data } = await axios.request<SectionCodeValidationData>({
    url: `/api/sections/valid/${sectionCode}`,
  });

  return data;
};
