import React, { useCallback } from 'react';
import {
  InputStatus,
  TextInputStateProps,
} from '../../../components/InputTextState';
import { handleServerValidation } from '../../../validations/serverValidations/handleServerValidation';
import { userHandleValidationSchema } from '../../../validations/yupValidations';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import InputTextField from '../../components/InputTextField';
import debounce from 'lodash.debounce';

interface RegisterEmailProps {
  status: InputStatus;
  onStatusChange: Required<TextInputStateProps>['onStatusChange'];
}

/**
 * input of type `text` for the conversifi _@handle_ at
 * the register stage
 */
export const RegisterHandle: React.FC<RegisterEmailProps> = ({
  status,
  onStatusChange,
}) => {
  const trans = LinguiContext.useCtx().trans;

  const setHandle = useCallback(async (handle: string) => {
    try {
      userHandleValidationSchema().validateSync(handle);
      const itExist = await handleServerValidation(handle);

      onStatusChange({
        error: itExist ?? '',
        touched: true,
        value: handle,
        isValid: !itExist,
      });
    } catch (error) {
      onStatusChange({
        error: error.message,
        touched: true,
        value: handle,
        isValid: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceSetHandle = debounce((value: string) => setHandle(value), 800);

  return (
    <InputTextField
      id="registerForm.step2.handler"
      name="register-handle"
      label={trans({
        id: 'anonymous.register.inputs.create-your-screen-name',
        message: 'Create your screen name',
      })}
      fullWidth
      placeholder={trans({
        id: 'anonymous.register.inputs.create-your-screen-name',
        message: 'Create your screen name',
      })}
      required
      onChange={(e) => debounceSetHandle(e.target.value)}
      onFocus={() => onStatusChange({ ...status, touched: true })}
      onBlur={() => onStatusChange({ ...status, touched: true })}
      error={status.touched && !status.isValid}
      helperText={status.error}
    />
  );
};
