import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import cx from 'classnames';
import { es, enUS, fr } from 'date-fns/locale';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { Stack, Typography } from '@mui/material';
// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language';

import {
  BABBEL_HELP_URL,
  HELP_URL,
} from 'conversifi-shared-react/es6/constants';
import { notifyVisitorPath } from 'conversifi-shared-react/es6/util/zendesk/notifyVisitorPath';
import { UNCONFIRMED_EMAIL_EXPIRES_AFTER_SECONDS } from 'conversifi-commons/constants';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import style from './style.module.css';
import { LoginRegisterTemplate } from '../templates/login-register';
import { RegisterForm, OnSubmissionSucceededParams } from './RegisterForm';
import { useImmutableState } from '../../hooks';
import { Routes } from '../../routes';

interface State {
  submissionProps: OnSubmissionSucceededParams | null;
  userSource?: UserSource | null;
}

interface EmailSentProps {
  onTryAgain: () => void;
  email: string;
}

const defaultLang: string =
  String(detectBrowserLanguage()).substr(0, 2) || 'en';

const EmailSent: React.FC<EmailSentProps> = ({ onTryAgain, email }) => {
  let browserLocale;
  switch (defaultLang) {
    case 'es':
      browserLocale = es;
      break;
    case 'fr':
      browserLocale = fr;
      break;
    case 'en':
    default:
      browserLocale = enUS;
      break;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        <Trans
          values={{
            email,
          }}
          components={[<b />]}
          id="anonymous.register.form.we-sent-an-email-check-your-inbox"
        >
          Great! We just sent an email to <b>{email}</b>. Please check your
          inbox to verify.
        </Trans>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <Trans id="anonymous.register.form.if-you-do-not-see-it-check-spam">
          If you don't see it in your inbox, check your spam folder.
        </Trans>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <Trans
          id="anonymous.register.form.verification-email-will-expire-in"
          values={{
            time: formatDistanceStrict(
              Date.now() + UNCONFIRMED_EMAIL_EXPIRES_AFTER_SECONDS * 1000,
              Date.now(),
              { locale: browserLocale }
            ),
          }}
        >
          Verification email will expire in 1 hour
        </Trans>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <Trans
          id="anonymous.register.form.register-again-with-different-email-address"
          components={[<Link to={Routes.register.uri}></Link>]}
        >
          If it still doesn't arrive, try
          <Link to={Routes.register.uri} onClick={onTryAgain}>
            registering again
          </Link>
          with a different email address.
        </Trans>
      </Typography>
    </Stack>
  );
};
interface RegisterProps {
  userSource?: UserSource | null;
  onSubmissionSucceeded: (params: OnSubmissionSucceededParams) => void;
  onUserSourceDetected: (userSource: UserSource) => void;
}

const Register: React.FC<RegisterProps> = ({
  userSource,
  onSubmissionSucceeded,
  onUserSourceDetected,
}) => {
  return (
    <>
      <Typography variant="h6" textAlign="center">
        <Trans id="anonymous.register.form.please-register-for-an-account">
          Welcome! Please register for an account.
        </Trans>
      </Typography>
      <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
        <Trans id="anonymous.register.form.already-have-an-account">
          Already have an account?
        </Trans>
        <Link to={Routes.login.uri} style={{ marginLeft: '8px' }}>
          <Trans id="anonymous.register.form.login">Login</Trans>
        </Link>
      </Typography>
      <a
        href={userSource === 'babbel' ? BABBEL_HELP_URL : HELP_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="h6" mb={2}>
          <Trans id="anonymous.register.confirmation-code-valid-help">
            Need help?
          </Trans>
        </Typography>
      </a>
      <RegisterForm
        onSubmissionSucceeded={onSubmissionSucceeded}
        onUserSourceDetected={onUserSourceDetected}
      />
    </>
  );
};

interface Props {
  onDetectUserSource: (src: UserSource) => void;
}

export const RegisterScreen: FC<Props> = ({ onDetectUserSource }) => {
  const [state, setState] = useImmutableState<State>({
    submissionProps: null,
    userSource: null,
  });

  useEffect(() => {
    onDetectUserSource(state.userSource ?? 'conversifi');
    // eslint-disable-next-line
  }, [state.userSource]);

  const setSubmissionSucceeded = (params: OnSubmissionSucceededParams) => {
    setState({
      submissionProps: { $set: params },
    });
  };

  const onTryAgain = () => {
    setState({
      submissionProps: { $set: null },
    });
  };

  const className = cx({
    [style.childrenWider]: Boolean(state.submissionProps),
  });

  useEffect(() => {
    notifyVisitorPath('Register');
  }, []);

  // if the register submission was successful then
  // render the "email sent" screen. Otherwise render
  // the registration form.
  return (
    <LoginRegisterTemplate
      childrenExtraClassName={className}
      userSource={state.userSource}
    >
      {state.submissionProps ? (
        <EmailSent
          onTryAgain={onTryAgain}
          email={state.submissionProps.email}
        />
      ) : (
        <Register
          onSubmissionSucceeded={setSubmissionSucceeded}
          userSource={state.userSource}
          onUserSourceDetected={(userSource) => {
            setState({ userSource: { $set: userSource } });
          }}
        />
      )}
    </LoginRegisterTemplate>
  );
};
