import { createStyles, makeStyles } from '@mui/styles';
export const usePopoverDescriptionStyles = makeStyles((theme) => createStyles({
    popover: {
        pointerEvents: 'none',
    },
    childrenContainer: {
        height: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.primary.main,
        maxWidth: '250px',
    },
    title: {
        textTransform: 'uppercase',
        width: '100%',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        marginBottom: '5px',
    },
}));
