import React, { useEffect, useState, useCallback } from 'react';
import { LoginRegisterTemplate } from '../../../App.anonymous/templates/login-register';
import sdk from '../../sdk';
import { Trans } from '@lingui/react';
import { Typography, Button, Stack } from '@mui/material';

type ScreenActions = 'PAY_FEE' | 'REDEEM_COUPON' | 'SKIP';

const SubscriptionFee: React.FC = () => {
  const [action, setAction] = useState<ScreenActions>();
  const [showError, setShowError] = useState<boolean>(false);

  const onPayFee = () => {
    setAction('PAY_FEE');
  };

  const onRedeemCoupon = () => {
    setAction('REDEEM_COUPON');
  };

  const onSkip = () => {
    setAction('SKIP');
  };

  const setWizardCallback = useCallback(
    async (type: 'ACCEPTED' | 'SKIPPED') => {
      try {
        await sdk.session.skipOrAcceptSubscriptionFeeWizard(type);
      } catch (err) {
        setShowError(true);
      }
    },
    []
  );

  useEffect(() => {
    if (action) {
      let goTo: string = '';
      switch (action) {
        case 'PAY_FEE':
          setWizardCallback('ACCEPTED');
          goTo = '/transactions/payments';
          break;
        case 'REDEEM_COUPON':
          setWizardCallback('ACCEPTED');
          goTo = '/transactions/redeem-coupon';
          break;
        case 'SKIP':
          setWizardCallback('SKIPPED');
          goTo = '/search';
          break;
      }
      window.location.replace(goTo);
    }
  }, [action, setWizardCallback]);

  return (
    <LoginRegisterTemplate>
      <Typography variant="h6" gutterBottom textAlign="center">
        <Trans id="subscription-fee-wizard.welcome-to-conversifi">
          Welcome to Conversifi. Ready to pay your subscription fee?
        </Trans>
      </Typography>
      <Stack direction="column" spacing={2} alignItems="center">
        <Button
          id="subscriptionFee.paySubscriptionBtn"
          variant="contained"
          onClick={onPayFee}
          sx={{ width: 200 }}
        >
          <Trans id="subscription-fee-wizard.pay-my-subscription-fee">
            Pay my subscription fee
          </Trans>
        </Button>
        <Button
          id="subscriptionFee.paySubscriptionBtn"
          variant="contained"
          onClick={onRedeemCoupon}
          sx={{ width: 200 }}
        >
          <Trans id="subscription-fee-wizard.redeem-a-coupon">
            Redeem a coupon
          </Trans>
        </Button>
        <Typography
          onClick={onSkip}
          color="primary.light"
          textAlign="center"
          display="inline"
          variant="h6"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Trans id="subscription-fee-wizard.do-this-later">
            Do this later
          </Trans>
        </Typography>

        {showError && (
          <Typography textAlign="center" color="error" fontWeight={700}>
            <Trans id="subscription-fee-wizard.unable-to-complete-the-operation">
              Unable to complete the operation. Please try again. If the problem
              persists, try later.
            </Trans>
          </Typography>
        )}
      </Stack>
    </LoginRegisterTemplate>
  );
};

export default SubscriptionFee;
