import React from 'react';
import { AvatarState } from './types';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { BaseProvider } from 'conversifi-shared-react/es6/BaseProvider';
import { Trans } from '@lingui/react';
import { loadData } from './methods/loadData';
import { updateAvatarConfiguration } from './methods/updateAvatarConfiguration';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { AvatarConfiguration } from 'conversifi-types/src/shared-frontend-backend/Avatar';

export interface AvatarPublicAPI {
  state: AvatarConfiguration;
}

interface Props {
  linearProgressCtx: ReturnType<typeof LinearProgressContext.useCtx>;
  snackbarCtx: ReturnType<typeof SnackbarContext.useCtx>;
}

export const PublicContext = React.createContext({} as AvatarPublicAPI);

export class AvatarContext
  extends BaseProvider<Props, AvatarPublicAPI>
  implements AvatarPublicAPI
{
  readonly className = 'AvatarContext';

  protected readonly PublicContext = PublicContext;
  static useCtx = () => React.useContext(PublicContext);

  constructor(props: any) {
    super(props);
    this.bindAll();
  }

  componentDidMount() {
    this.loadData();
  }

  getInitialState(): AvatarState {
    return {
      prompt: '',
      beginnerProficiency: '',
      intermediateProficiency: '',
      advancedProficiency: '',
      maxDuration: 1,
    };
  }

  getPublicApi() {
    return {
      state: this.state,
    };
  }

  loadData = loadData;
  updateAvatarConfiguration = updateAvatarConfiguration;

  render() {
    return (
      <this.PublicContext.Provider value={this.getPublicApi()}>
        <Container maxWidth="xl">
          <Paper sx={{ maxWidth: '90vw' }}>
            <Typography
              color="primary"
              variant="h4"
              textAlign="center"
              paddingY={3}
            >
              <Trans id="admin.avatar.title">Avatar</Trans>
            </Typography>
            <Box p={3} display="flex" flexDirection="column">
              <TextField
                variant="outlined"
                label={<Trans id="admin.avatar.prompt">Initial prompt</Trans>}
                value={this.state.prompt}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  this.produce((draft) => {
                    draft.prompt = e.target.value;
                  });
                }}
              />
              <TextField
                variant="outlined"
                label={
                  <Trans id="admin.avatar.beginner-proficiency">
                    Beginner Proficiency
                  </Trans>
                }
                value={this.state.beginnerProficiency}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  this.produce((draft) => {
                    draft.beginnerProficiency = e.target.value;
                  });
                }}
              />
              <TextField
                variant="outlined"
                label={
                  <Trans id="admin.avatar.intermediate-proficiency">
                    Intermediate Proficiency
                  </Trans>
                }
                value={this.state.intermediateProficiency}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  this.produce((draft) => {
                    draft.intermediateProficiency = e.target.value;
                  });
                }}
              />
              <TextField
                variant="outlined"
                label={
                  <Trans id="admin.avatar.advanced-proficiency">
                    Advanced Proficiency
                  </Trans>
                }
                value={this.state.advancedProficiency}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  this.produce((draft) => {
                    draft.advancedProficiency = e.target.value;
                  });
                }}
              />
              <TextField
                variant="outlined"
                label={
                  <Trans id="admin.avatar.duration">
                    Max duration (in minutes)
                  </Trans>
                }
                value={this.state.maxDuration}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                type="number"
                margin="normal"
                onChange={(e) => {
                  const duration = Number(e.target.value);

                  this.produce((draft) => {
                    draft.maxDuration = duration >= 1 ? duration : 1;
                  });
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  alignSelf: 'flex-end',
                }}
                onClick={() => this.updateAvatarConfiguration(this.state)}
              >
                <Trans id="admin.avatar.save">Save prompt</Trans>
              </Button>
            </Box>
          </Paper>
        </Container>
      </this.PublicContext.Provider>
    );
  }
}

export default function () {
  return (
    <AvatarContext
      linearProgressCtx={LinearProgressContext.useCtx()}
      snackbarCtx={SnackbarContext.useCtx()}
    />
  );
}
