import * as yup from 'yup';
import {
  validateHandle,
  // @ts-ignore
} from 'conversifi-commons/helpers/validationsHelper';
import { parse } from 'date-fns';
import { I18nTrans } from 'conversifi-shared-react/es6/types/Lingui';
import { DEFAULT_DATE_FORMAT } from 'conversifi-commons/constants';

export const emailValidationSchema = yup.string().email().required();

export const userHandleValidationSchema = (i18n?: I18nTrans) => {
  return yup
    .string()
    .required()
    .min(1)
    .max(100)
    .test(
      'test-user-handle',
      i18n
        ? i18n('common.user-handle-invalid')
        : "Sorry, handles can't contain spaces, special characters or accented letters. Please simplify your handle and try again.",
      function (value) {
        // #USER_HANDLE_VALIDATOR
        return validateHandle(value);
      }
    );
};

export const sectionCodeValidationSchema = yup
  .string()
  .required()
  .min(4)
  .max(20);

export const couponLabelValidationSchema = yup
  .string()
  .required()
  .min(1)
  .max(30);

// #USER_PASSWORD_RESTRICTIONS defined previously by @Patricio.Sartore in the frontend
export const userPasswordValidationSchema = yup
  .string()
  .required()
  .min(8)
  .max(255);

export const userFirstNameValidationSchema = yup.string().required().max(255);
export const userLastNameValidationSchema = yup.string().required().max(255);

export const userValidationSchema = yup.string().email().required();

export const userBirthdateValidationSchema = (trans?: I18nTrans) => {
  return yup
    .date()
    .required()
    .transform((_value, originalValue) => {
      return parse(originalValue, DEFAULT_DATE_FORMAT, new Date());
    })
    .typeError(
      trans
        ? trans({
            id: 'commons.date.error.format',
            values: {
              format: DEFAULT_DATE_FORMAT,
            },
            message: `Error, the date format must be: ${DEFAULT_DATE_FORMAT}`,
          })
        : 'Error, the date format must be: MM/DD/YYYY'
    )
    .test(
      'test-min-date',
      trans ? trans('commons.date.error.min-date') : 'Birthdate too early.',
      function (value) {
        if (!value) return false;
        return value >= new Date(1900, 1);
      }
    )
    .test(
      'test-max-date',
      trans
        ? trans('commons.date.error.max-date')
        : "Your birthdate can't be later than today.",
      function (value) {
        if (!value) return false;
        return value < new Date();
      }
    );
};
