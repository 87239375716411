import { CallConfig } from 'conversifi-types/src/CallConfig';
import {
  InstructorModule,
  InstructorsModuleFilters,
} from 'conversifi-types/src/shared-frontend-backend/InstructorModule';
import {
  PaginatedResult,
  PaginationParams,
} from 'conversifi-types/src/shared-frontend-backend/PaginatedResult';
import axiosInstance from '../util/util.axios';
import { FacultyDisabledUser } from 'conversifi-types/src/Faculty';
import { ChatInformation } from 'conversifi-types/src/Call/Twilio';

type ModuleListParams = PaginationParams<string> & InstructorsModuleFilters;

type FilteredModulesResponse = {
  docs: InstructorModule[];
  total: number;
};

export type ModuleGrammarTags = {
  grammarTags: string;
  targetLanguage: string;
};

export class InstructorSdk {
  static async getCallMediaData(callId: string): Promise<string> {
    const { data } = await axiosInstance.get(`/calls/${callId}/media`);
    return data;
  }
  static CallConfig = {
    async getAll(): Promise<PaginatedResult<CallConfig>> {
      const { data } = await axiosInstance.get(
        '/university-admin-instructor/call-config'
      );
      return data;
    },
  };

  static Modules = {
    async getFiltered(
      params: ModuleListParams
    ): Promise<FilteredModulesResponse> {
      const { data } = await axiosInstance.get('instructor/modules', {
        params,
      });
      return data;
    },
    async getGrammarTags(): Promise<ModuleGrammarTags[]> {
      const { data } = await axiosInstance.get('modules/airtable/grammar-tags');
      return data.grammarTags;
    },
  };

  static Faculties = {
    async toggleDisableUser(disabledUser: FacultyDisabledUser): Promise<void> {
      await axiosInstance.post(`instructor/disable-user`, disabledUser);
    },
  };

  static Calls = {
    async getChatInformation(callId: string): Promise<ChatInformation> {
      const { data } = await axiosInstance.get(`/calls/${callId}/chat`);

      return data;
    },
  };
}
