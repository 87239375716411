import { TextField, styled } from '@mui/material';

const InputTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: white;
    
    &.Mui-focused {
      color: white;
    }

    &.Mui-error {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }

  & .MuiOutlinedInput-input {
    color: white;
    -webkit-text-fill-color: white;

    &::placeholder {
      color: white;
      opacity: 0.7;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0);
    }

    &::selection, &::-moz-selection {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }

    &:hover fieldset {
      border-color: white;
    }

    &.Mui-focused fieldset {
      border-color: white;
    }

    &.Mui-error fieldset {
      border-color: ${({ theme }) => theme.palette.error.main};
    }

    &.Mui-error:hover fieldset {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }

  & .MuiFormHelperText-root {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    color: white;

    &.Mui-error  {
      color: white;
      border-radius: 5px;
      padding: ${({ theme }) => theme.spacing(0.1)} ${({ theme }) => theme.spacing(1)};
      background-color: ${({ theme }) => theme.palette.error.main};
    }
  }
`;

export default InputTextField;