import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';

/**
 * Format Date as MM/DD/YY HH:mm
 * @param date Date
 * @return Formatted date
 */
export const formatDateHour = (date: any) => {
  if (date) {
    return format(parseISO(date), 'MM/dd/yy HH:mm');
  } else {
    return '';
  }
};

export const minutesElapsed = (date: string, waitingTime: number) => {
  const initDate = new Date(date);
  initDate.setSeconds(initDate.getSeconds() - waitingTime);
  const currentDate = new Date().getTime();
  const timeElapsed = Math.floor(
    (currentDate - initDate.getTime()) / (1000 * 60)
  );
  return timeElapsed < 1 ? '1<' : timeElapsed;
};

export const getNativeLanguageFlag = (countryCode: string) => {
  const code = countryCode.toLowerCase();

  if (code === 'en') return 'us';
  if (code === 'es') return 'mx';

  return code;
};
