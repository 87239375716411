import { requestUniversityDataPut } from '../requests';
import { apiErrorHandler } from '../apiErrorHandler';
import type { University } from 'conversifi-types/src/University';

// Call endpoint API for create a new promotion
export async function universityDataPut(
  universityData: University
): Promise<string> {
  try {
    const data = await requestUniversityDataPut(universityData);

    return data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
}
