import { PaginatedSize } from 'conversifi-types/src/shared-frontend-backend/PaginatedResult';
import { apiErrorHandler } from '../apiErrorHandler';
import { requestPendingTransactionsGet } from '../requests';
import { PendingPaypalTransactionsResponse } from '../ResponseSchemas';

export const getPendingTransactions = async (
  page: number,
  size: PaginatedSize
): Promise<PendingPaypalTransactionsResponse> => {
  try {
    const response = await requestPendingTransactionsGet(page, size);
    return response.data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
