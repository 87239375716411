import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import { BaseProvider } from 'conversifi-shared-react/es6/BaseProvider';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import Form from './components/form';
import ListLanguages from './components/list';
import { Languages as TLanguages } from 'conversifi-types/src/Lang';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';
import cleanLanguage from './methods/cleanLanguage';
import upsertLanguage from './methods/upsertLanguage';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import deleteLanguage from './methods/deleteLanguage';
import { useAppState } from '../../state';

interface Props {
  user: ReturnType<typeof useAppState>['user'];
  languagesCtx: ReturnType<typeof LanguagesContext.useCtx>;
  countriesCtx: ReturnType<typeof CountriesContext.useCtx>;
  snackbarCtx: ReturnType<typeof SnackbarContext.useCtx>;
  linearProgressCtx: ReturnType<typeof LinearProgressContext.useCtx>;
}

interface State {
  language: Partial<TLanguages> & { _id?: string };
}

interface Api {
  state: State;
}

export const PublicContext = React.createContext({} as Api);

export class AdminLanguagesContext extends BaseProvider<Props, Api> {
  readonly className = 'AdminLanguagesContext';

  protected readonly PublicContext = PublicContext;

  protected getInitialState(): State {
    return {
      language: {},
    };
  }

  protected getPublicApi(): Api {
    return {
      state: this.state,
    };
  }

  Form = Form;
  ListLanguages = ListLanguages;
  cleanLanguage = cleanLanguage;
  upsertLanguage = upsertLanguage;
  deleteLanguage = deleteLanguage;

  render() {
    return (
      <Container maxWidth="xl">
        <Paper>
          <Typography color="primary" variant="h4" textAlign="center" pt={2}>
            <Trans id="admin.languages.title">Languages</Trans>
          </Typography>
          <Box p={2}>
            {this.Form()}
            <Divider />
            {this.ListLanguages()}
          </Box>
        </Paper>
      </Container>
    );
  }
}

const Languages: FC = () => {
  const appState = useAppState();

  return (
    <AdminLanguagesContext
      user={appState.user}
      languagesCtx={LanguagesContext.useCtx()}
      countriesCtx={CountriesContext.useCtx()}
      snackbarCtx={SnackbarContext.useCtx()}
      linearProgressCtx={LinearProgressContext.useCtx()}
    />
  );
};
export default Languages;
