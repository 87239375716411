import React from 'react';
import { Trans } from '@lingui/react';
import cx from 'classnames';
import { Box, Typography } from '@mui/material';

import { UserSource } from 'conversifi-types/src/User/UserSource';
import classNames from './LoginRegisterTemplate.module.css';
import ConversifiLogo from '../../../assets/images/conversifi-logo.svg';
import ConnectBabbelConversifiLogo from '../../../assets/images/connect_white_babbel_conversifi.png';

const { root, container, header } = classNames;
interface Props {
  /**
   * class name to passed to the children's container
   */
  childrenExtraClassName?: string;

  /**
   * class name to be passed to the screen container
   */
  containerExtraClassName?: string;
  headerExtraClassName?: string;

  /**
   * We can receive null if we are not sure what logo show in the screen
   * for example in the registration screen.
   * If we do not receive anything (undefined) we will show Conversifi logo
   */
  userSource?: UserSource | null;
}

export const LoginRegisterTemplate: React.FC<Props> = ({
  children,
  childrenExtraClassName = '',
  containerExtraClassName = '',
  headerExtraClassName = '',
  userSource = 'conversifi',
}) => {
  /**
   * We need to (temporarily) change the Connect logo to a Conversifi logo
   * @todo: To get back to correct validation, You have to remove "false &&" on the line 48
   * @see https://speakapp.atlassian.net/browse/CONV-2953
   */
  return (
    <Box className={root}>
      <Box
        id="containerLoginRegister"
        className={cx(container, containerExtraClassName)}
      >
        {userSource && (
          <Box className={cx(header, headerExtraClassName)}>
            {false && userSource === 'babbel' ? (
              <img
                src={ConnectBabbelConversifiLogo}
                className={classNames.connectBabbelConversifiLogo}
                alt="Connect Babbel x Conversifi Logo"
              />
            ) : (
              <>
                <img
                  src={ConversifiLogo}
                  className={classNames.logo}
                  alt="conversifiLogo"
                />
                <Typography className={classNames.pCenteredFFFBig}>
                  <Trans id="anonymous.language-immersion-on-demand">
                    Language Immersion On Demand
                  </Trans>
                </Typography>
              </>
            )}
          </Box>
        )}
        <Box className={cx(classNames.children, childrenExtraClassName)}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
