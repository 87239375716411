import React from 'react';
import { Box } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import { useIsMobile } from '../../hooks/useCurrentMediaBreakpoint';
import { IsClosedApp, IsConnectedApp, IsConnectingApp } from './appView';
import { IsClosedAdmin, IsConnectedAdmin, IsConnectingAdmin, } from './adminView';
import { DISCONNECT_ERROR_REASON } from '../Sockets/constants/SocketDisconnectCodes';
const WebSocketStatus = ({ socketCtx, target }) => {
    const isMobile = useIsMobile();
    return (React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignContent: "center" },
        target === 'admin' && (React.createElement(Box, { display: "flex", flexDirection: "row" },
            !isMobile && React.createElement(DevicesIcon, { sx: { mr: 1 } }),
            socketCtx.isConnected() && React.createElement(IsConnectedAdmin, null),
            socketCtx.isConnecting() && React.createElement(IsConnectingAdmin, null),
            (socketCtx.isClosed() || socketCtx.isFailed()) && React.createElement(IsClosedAdmin, null))),
        target === 'app' && (React.createElement(Box, { display: "flex", flexDirection: "row" },
            socketCtx.isConnected() && React.createElement(IsConnectedApp, null),
            socketCtx.isConnecting() && (React.createElement(IsConnectingApp, { socketCtx: socketCtx })),
            (socketCtx.isClosed() || socketCtx.isFailed()) && (React.createElement(IsClosedApp, { displayMessage: socketCtx.state.disconnectReason !==
                    DISCONNECT_ERROR_REASON.IO_CLIENT_DISCONNECT }))))));
};
export default WebSocketStatus;
