import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { LoginRegisterTemplate } from '../templates/login-register';
import { RegisterFormStep2 } from './RegisterFormStep2';
import style from './style.module.css';
import { useImmutableState } from '../../hooks';
import { UserSdk } from '../../sdk/user.sdk';
import { UserRegisterData } from 'conversifi-types/src/shared-frontend-backend/UserRegisterData';
import { Routes } from '../../routes';
import { Trans } from '@lingui/react';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import {
  BABBEL_HELP_URL,
  HELP_URL,
} from 'conversifi-shared-react/es6/constants';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import saveLocal from 'conversifi-shared-react/es6/util/session/saveLocal';
import { useGaEvents } from 'conversifi-shared-react/es6/hooks/useGaEvents';
import { Typography } from '@mui/material';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';

const ConfirmationCodeInvalid: FC = () => {
  return (
    <Typography variant="h6" textAlign="center">
      <Trans id="anonymous.register.confirmation-code-invalid1">
        Sorry, We're having troubles trying to confirm that email. It probably
        means that the link has expired or it was already confirmed. If you
        believe that is the case, please try to
      </Trans>{' '}
      <Link id="registerScreen.step2.login" to={Routes.login.uri}>
        <Trans id="anonymous.register.confirmation-code-invalid2">login</Trans>
      </Link>{' '}
      <Trans id="anonymous.register.confirmation-code-invalid3">
        using your email, or try to
      </Trans>{' '}
      <Link id="registerScreen.step2.registerAgain" to={Routes.register.uri}>
        <Trans id="anonymous.register.confirmation-code-invalid4">
          register again
        </Trans>
      </Link>
      .
    </Typography>
  );
};

interface ConfirmationCodeValidProps {
  userSource?: UserSource | null;
}

const ConfirmationCodeValid: FC<ConfirmationCodeValidProps> = ({
  userSource,
}) => {
  return (
    <>
      <Typography variant="h6" textAlign="center">
        <Trans id="anonymous.register.confirmation-code-valid">
          Great, you're almost there! We just need a little more information to
          get you started using Conversifi.
        </Trans>
      </Typography>
      <a
        href={userSource === 'babbel' ? BABBEL_HELP_URL : HELP_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="h6" mb={2}>
          <Trans id="anonymous.register.confirmation-code-valid-help">
            Need help?
          </Trans>
        </Typography>
      </a>
    </>
  );
};

interface State {
  confirmationCode: {
    isValid: boolean;
    isValidating: boolean;
    validationFinished: boolean;
    validationResponseError: string;
    validationResponseCode: number;
  };
  register: {
    registrationOkay: boolean;
    registrationRunning: boolean;
    registrationFinished: boolean;
    registrationError: string;
  };
  registerAgain: boolean;
  userSource: UserSource;
}

interface Props {
  onDetectUserSource: (userSource: UserSource) => void;
}

interface RouterProps {
  code: string;
}

export const RegisterScreenStep2: FC<
  RouteComponentProps<RouterProps> & Props
> = (props) => {
  const onDetectUserSource = props.onDetectUserSource;
  const { code } = props.match.params;

  const { sendEvent } = useGaEvents();
  const trans = LinguiContext.useCtx().trans;
  const wipCtx = LinearProgressContext.useCtx();
  const dialogCtx = DialogContext.useCtx();

  const [state, setState] = useImmutableState<State>({
    confirmationCode: {
      isValid: false,
      isValidating: false,
      validationFinished: false,
      validationResponseError: '',
      validationResponseCode: 0,
    },

    register: {
      registrationError: '',
      registrationOkay: false,
      registrationRunning: false,
      registrationFinished: false,
    },

    registerAgain: false,
    userSource: 'conversifi',
  });

  const onValidateConfirmationCode = useCallback(async () => {
    if (
      !state.confirmationCode.isValidating &&
      !state.confirmationCode.validationFinished
    ) {
      wipCtx.showLinearProgressWithBackdrop();
      setState({
        confirmationCode: { isValidating: { $set: true } },
      });

      try {
        const result = await UserSdk.userConfirmationCodeIsValid(code);
        onDetectUserSource(result);
        setState({
          confirmationCode: {
            isValid: { $set: true },
            isValidating: { $set: false },
            validationFinished: { $set: true },
          },
          userSource: { $set: result },
        });
      } catch (err) {
        console.info(
          'Error! RegisterScreen.Step2.ConfirmationCodeInvalid',
          err
        );
        setState({
          confirmationCode: {
            isValid: { $set: false },
            isValidating: { $set: false },
            validationFinished: { $set: true },
          },
        });
      } finally {
        wipCtx.hideLinearProgressAndBackdrop();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state.confirmationCode]);

  useEffect(() => {
    onValidateConfirmationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state.confirmationCode]);

  const onLogin = async (email: string, password: string) => {
    const { token, subscriptionFeeStatus, user } = await UserSdk.userLogin(
      email,
      password
    );
    saveLocal(token, subscriptionFeeStatus);

    if (
      !Boolean(user.loggedInCheckpoint) ||
      typeof user.loggedInCheckpoint === 'undefined'
    ) {
      sendEvent('login_first_time', user.source);
    }

    window.location.reload();
  };

  const onSubmit = async (data: UserRegisterData) => {
    if (
      !state.register.registrationRunning &&
      !state.register.registrationOkay
    ) {
      data.confirmationCode = code;

      setState({
        register: {
          registrationError: { $set: '' },
          registrationRunning: { $set: true },
        },
      });

      try {
        wipCtx.showLinearProgressWithBackdrop();
        const user = await UserSdk.anonymous.userRegister(data);

        setState({
          register: {
            registrationError: { $set: '' },
            registrationRunning: { $set: false },
            registrationOkay: { $set: true },
            registrationFinished: { $set: true },
          },
        });

        if (user.source === 'babbel') {
          await onLogin(user.email, data.password);
        }

        sendEvent('click_register', user.source);
      } catch (err) {
        console.info('[RSS2:195] RegisterScreen.Step2.Register', err);
        setState({
          register: {
            registrationError: { $set: 'error trying to register' },
            registrationRunning: { $set: false },
            registrationOkay: { $set: false },
            registrationFinished: { $set: true },
          },
        });
      } finally {
        wipCtx.hideLinearProgressAndBackdrop();
      }
    }
  };

  const resetError = () => {
    setState({
      registerAgain: { $set: true },
      register: {
        registrationError: { $set: '' },
      },
    });
  };

  useEffect(() => {
    if (state.register.registrationError && !state.register.registrationOkay) {
      dialogCtx.setMessage({
        title: trans({
          id: 'anonymous.register.register-error-title',
          message: 'Registration error',
        }),
        message: trans({
          id: 'anonymous.register.register-error-description',
          message:
            "Sorry, We're having trouble trying to register you. This probably means that your registration link has expired. If you believe that is the case, please start a new registration",
        }),
        defaultAction: resetError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.register.registrationError, state.register.registrationOkay]);

  return (
    <>
      <LoginRegisterTemplate
        containerExtraClassName={
          state.confirmationCode.validationFinished ? '' : style.hideBackground
        }
        headerExtraClassName={
          !state.confirmationCode.isValid ? style.headerExtra : ''
        }
        userSource={state.userSource}
      >
        {/* Invalid confirmation code */}
        {state.confirmationCode.validationFinished &&
          !state.confirmationCode.isValid && <ConfirmationCodeInvalid />}

        {/* Confirmation code. Show register form */}
        {state.confirmationCode.isValid && (
          <>
            <ConfirmationCodeValid userSource={state.userSource} />
            <RegisterFormStep2
              userSource={state.userSource}
              onSubmit={onSubmit}
            />
          </>
        )}
        {/* Registration succeeded! */}
        {state.register.registrationFinished === true &&
          state.register.registrationOkay === true &&
          !state.register.registrationError && (
            <Redirect to={Routes.login.uri} />
          )}
        {state.registerAgain && <Redirect to={Routes.register.uri} />}
      </LoginRegisterTemplate>
    </>
  );
};
