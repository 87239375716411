import {
  createConversifiTheme,
  primaryColor,
} from 'conversifi-shared-react/es6/themes/defaultMUITheme';
import 'typeface-roboto';

export const defaultTheme = createConversifiTheme({
  MuiSnackbar: {
    styleOverrides: {
      anchorOriginTopRight: {
        top: '70px !important',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: primaryColor.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: 400,

        '&.Mui-selected': {
          '& .MuiTab-wrapper': {
            fontWeight: 800,
          },
        },
      },
      textColorPrimary: {
        color: primaryColor.main,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '5px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: primaryColor.main,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      head: {
        '& .MuiTableCell-head': {
          fontWeight: 'bold',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
});

// export const defaultMUITheme = createMUITheme({
//   ...baseThemeConfiguration,
//   components: {
//     MuiSnackbar: {
//       styleOverrides: {
//         anchorOriginTopRight: {
//           top: '70px !important',
//         },
//       },
//     },
//     MuiAppBar: {
//       styleOverrides: {
//         colorPrimary: {
//           background: `linear-gradient(to right, ${primaryColor.main}, ${secondaryColor.main})`,
//         },
//       },
//     },
//     MuiTabs: {
//       styleOverrides: {
//         indicator: {
//           backgroundColor: primaryColor.main,
//         },
//       },
//     },
//     MuiTab: {
//       styleOverrides: {
//         root: {
//           fontWeight: 400,

//           '&.Mui-selected': {
//             '& .MuiTab-wrapper': {
//               fontWeight: 800,
//             },
//           },
//         },
//         textColorPrimary: {
//           color: primaryColor.main,
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: '5px',
//         },
//       },
//     },
//     MuiIconButton: {
//       styleOverrides: {
//         root: {
//           color: primaryColor.main,
//         },
//       },
//     },
//     MuiTypography: {
//       styleOverrides: {
//         h1: {
//           color: primaryColor.main,
//         },
//         h2: {
//           color: primaryColor.main,
//         },
//         h3: {
//           color: primaryColor.main,
//         },
//       },
//     },
//     MuiTableRow: {
//       styleOverrides: {
//         head: {
//           '& .MuiTableCell-head': {
//             fontWeight: 'bold',
//           },
//         },
//       },
//     },
//     MuiLink: {
//       styleOverrides: {
//         root: {
//           '&:hover': {
//             cursor: 'pointer',
//           },
//         },
//       },
//     },
//   },
// });

// Object.assign(defaultMUITheme, {
//   overrides: {
//     MUIRichTextEditor: {
//       root: {
//         border: '1px solid gray',
//         borderRadius: '4px',
//       },
//       container: {
//         display: 'flex',
//         flexDirection: 'column',
//       },
//       toolbar: {
//         flex: 1,
//       },
//       editorContainer: {
//         padding: '16px',
//         flex: 1,
//         position: 'relative !important',
//         cursor: 'text',
//       },
//       editor: {
//         flex: 1,
//       },
//     },
//   },
// });
