import { User, Role } from 'conversifi-types/src/User';
import { Queue, QueueMatchBase } from 'conversifi-types/src/Queue';
import {
  LiveAlertData,
  LiveAlert,
  PopulatedLiveAlert,
} from 'conversifi-types/src/LiveAlert';
import { News } from 'conversifi-types/src/News';
import { Pagination } from 'conversifi-types/src/shared-frontend-backend';
import { University } from 'conversifi-types/src/University';
import {
  MultiSortArray,
  PaginatedResult,
  SimplePaginatedResult,
  PaginatedSize,
  PaginationParams,
  PaginationParamsWithFilters,
} from 'conversifi-types/src/shared-frontend-backend/PaginatedResult';
import {
  IncentivesNotification,
  IncentivesPromotion,
  IncentivesUserFilters,
  PopulatedIncentiveUser,
  UpdatePromotionData,
} from 'conversifi-types/src/shared-frontend-backend/Incentives';
import { MatchingRulesListResponse } from 'conversifi-types/src/MatchingRules/api/adminList';
import {
  MatchingRulesUpdateRequestBody,
  MatchingRulesUpdateResponse,
} from 'conversifi-types/src/MatchingRules/api/adminUpdate';
import { MatchingRules } from 'conversifi-types/src/MatchingRules';
import {
  MatchingPriorityUpdateRequestBody,
  MatchingPriorityUpdateResponse,
} from 'conversifi-types/src/MatchingPriority/api/adminUpdate';
import {
  MatchingPriorityConstantsResponse,
  MatchingPriorityListResponse,
} from 'conversifi-types/src/MatchingPriority/api/adminList';
import { LiveAlertTemplate } from 'conversifi-types/src/LiveAlertTemplate';
import { UtilsSdk } from 'conversifi-shared-react/es6/sdk/UtilsSdk';
import {
  TPaidCoachApplicationStatus,
  PaidCoachApplication,
} from 'conversifi-types/src/PaidCoachApplication';
import {
  PaidCoachApplicationData,
  PaidCoachCertifiedBeginnerInformation,
  CheckApplicationUpdateStatus,
} from 'conversifi-types/src/shared-frontend-backend/PaidCoachApplication';
import { RequestSortData } from '../api/RequestSchemas';
import { CallConfig } from 'conversifi-types/src/CallConfig';
import {
  AdminSectionFilters,
  PopulatedSection,
  SectionFormData,
} from 'conversifi-types/src/shared-frontend-backend/Sections';
import { LiveHour } from 'conversifi-types/src/LiveHour';
import {
  SocketsReportParams,
  SocketsReportResponse,
} from 'conversifi-types/src/shared-frontend-backend/SocketReport';
import {
  CoachQuizReportParams,
  CoachQuizReportResponse,
} from 'conversifi-types/src/shared-frontend-backend/CoachQuizReport';
import { ActiveSearchQueueData } from 'conversifi-types/src/Search/ActiveSearchQueueData';
import {
  ActiveCallDetails,
  ActiveCallsParams,
} from 'conversifi-types/src/Call';
import { Currency, CurrencyParams } from 'conversifi-types/src/Currency';
import { AxiosRequestConfig } from 'axios';
import { Template } from 'conversifi-types/src/Template';
import { Promotion } from 'conversifi-types/src/Promotion';
import AxiosInstance from 'conversifi-shared-react/es6/sdk/AxiosInstance';
import {
  AdminEditableFaculty,
  Faculty,
  FacultyPaginationParams,
} from 'conversifi-types/src/Faculty';
import { PriceBundlesGroup } from 'conversifi-types/src/PriceBundlesGroup';
import { UpdateCouponRequest } from 'conversifi-types/src/shared-frontend-backend/Coupon';
import { CallPopulated } from '../App.admin/Users/SearchUsers/components/CallsList';
import { LangCode, Languages } from 'conversifi-types/src/Lang';
import { Section } from 'conversifi-types/src/Section';
import { AvatarConfiguration } from 'conversifi-types/src/shared-frontend-backend/Avatar';

export class AdminSdk {
  static async toggleUserRole(userId: string, role: Role): Promise<User> {
    const { data } = await AxiosInstance.getAxiosInstance().put(
      `/admin/users/${userId}/${role}`
    );
    return data;
  }

  static LiveAlerts = {
    async getAlertsData(): Promise<LiveAlertData[]> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().get<
          LiveAlertData[]
        >('/admin/live-alerts');
        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA24]', err);
        }
        throw err;
      }
    },

    async getLiveAlertsConfig(): Promise<PopulatedLiveAlert[]> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().get<
          PopulatedLiveAlert[]
        >('/admin/live-alerts/config');
        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA36]', err);
        }
        throw err;
      }
    },

    async updateLiveAlertsConfig(
      id: string,
      data: Partial<LiveAlert>
    ): Promise<void> {
      try {
        await AxiosInstance.getAxiosInstance().put<void>(
          `/admin/live-alerts/config/${id}`,
          data
        );
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA45]', err);
        }
        throw err;
      }
    },

    async createLiveAlertsConfig(data: Partial<LiveAlert>): Promise<void> {
      try {
        await AxiosInstance.getAxiosInstance().post<void>(
          `/admin/live-alerts/config`,
          data
        );
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA45]', err);
        }
        throw err;
      }
    },

    async removeLiveAlertsConfig(id: LiveAlert['_id']): Promise<void> {
      try {
        await AxiosInstance.getAxiosInstance().delete<void>(
          `/admin/live-alerts/config/${id}`
        );
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA45]', err);
        }
        throw err;
      }
    },

    async getLiveAlertTemplates(): Promise<LiveAlertTemplate[]> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().get<
          LiveAlertTemplate[]
        >('/admin/live-alerts/templates');

        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA45]', err);
        }
        throw err;
      }
    },
  };

  static News = {
    async getNews(
      params: Pagination.PaginationParams
    ): Promise<{ docs: News[]; total: number }> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().get(
          '/admin/news',
          {
            params,
          }
        );
        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA58]', err);
        }
        throw err;
      }
    },
    async addNews(text: string): Promise<News> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().post(
          '/admin/news',
          {
            text,
          }
        );
        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA67]', err);
        }
        throw err;
      }
    },
  };

  static Incentives = {
    async getUniversityList(): Promise<PaginatedResult<University>> {
      try {
        const { data } = await AxiosInstance.getAxiosInstance().get<
          PaginatedResult<University>
        >('/admin/university/list', {
          params: {
            size: -1,
          },
        });
        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA86]', err);
        }
        throw err;
      }
    },

    async filterUsers(
      filters: IncentivesUserFilters
    ): Promise<SimplePaginatedResult<PopulatedIncentiveUser>> {
      try {
        let body: AxiosRequestConfig = {
          params: filters,
        };

        const { data } = await AxiosInstance.getAxiosInstance().get<
          SimplePaginatedResult<PopulatedIncentiveUser>
        >('/admin/incentives/filter-users', body);

        return data;
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.warn('[GA104]', err);
        }
        throw err;
      }
    },

    async getSendgridTemplates() {
      const { data } = await AxiosInstance.getAxiosInstance().get<{
        templates: Array<Template>;
      }>('/sendgrid/templates');

      return data;
    },

    async getSendgridTemplateVersion(templateId: string, versionId: string) {
      const { data } = await AxiosInstance.getAxiosInstance().get<
        Template['versions'][0]
      >(`/sendgrid/templates/${templateId}/versions/${versionId}`);

      return data;
    },

    async confirmPromotionBuild(data: {
      filters: IncentivesUserFilters;
      promotion: IncentivesPromotion;
      notification: IncentivesNotification;
    }): Promise<void> {
      return AxiosInstance.getAxiosInstance().post('/admin/incentives', data);
    },

    async getPromotions(
      filter: 'active' | 'pending'
    ): Promise<SimplePaginatedResult<Promotion>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/admin/incentives/promotions',
        {
          params: { filter },
        }
      );

      return data;
    },

    async updatePromotion(
      promotionId: string,
      updateData: UpdatePromotionData
    ): Promise<void> {
      return AxiosInstance.getAxiosInstance().put(
        `/admin/incentives/promotions/${promotionId}`,
        {
          ...updateData,
        }
      );
    },
  };

  static MAv2 = {
    async getMatchingData(): Promise<QueueMatchBase[]> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/MAv2/admin/matches'
      );
      return data;
    },

    async getMatchingSQL(): Promise<string> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/MAv2/admin/matches.sql'
      );
      return data;
    },

    // Send `size = 0` in order to get the complete list
    async getMatchingRules(
      page: number,
      size: PaginatedSize = 'TOTAL_DATA',
      sort: MultiSortArray<keyof MatchingRules> = []
    ): Promise<MatchingRulesListResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/MAv2/admin/matching-rules',
        {
          params: { page, size, sort },
        }
      );
      return data;
    },

    async updateRule(
      id: number,
      ruleData: MatchingRulesUpdateRequestBody
    ): Promise<MatchingRulesUpdateResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().patch(
        `/MAv2/admin/matching-rules/${id}`,
        ruleData
      );

      return data;
    },

    // Send `size = 0` in order to get the complete list
    async getMatchingPriorities(
      page: number,
      size: PaginatedSize = 'TOTAL_DATA'
    ): Promise<MatchingPriorityListResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/MAv2/admin/matching-priority',
        {
          params: { page, size },
        }
      );
      return data;
    },

    async updatePriority(
      id: number,
      priorityData: MatchingPriorityUpdateRequestBody
    ): Promise<MatchingPriorityUpdateResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().patch(
        `/MAv2/admin/matching-priority/${id}`,
        priorityData
      );

      return data;
    },

    async createPriority(
      priorityData: MatchingPriorityUpdateRequestBody
    ): Promise<MatchingPriorityUpdateResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        `/MAv2/admin/matching-priority`,
        priorityData
      );

      return data;
    },

    async getPrioritiesConstants(): Promise<MatchingPriorityConstantsResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/MAv2/admin/matching-priority/constants`
      );

      return data;
    },

    async updatePriorityPositions(
      positions: any
    ): Promise<MatchingPriorityListResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().patch(
        `/MAv2/admin/matching-priority/update-positions`,
        positions
      );

      return data;
    },
  };

  static Coaches = {
    async blockUserForCoaching(userId: string): Promise<void> {
      await AxiosInstance.getAxiosInstance().put(
        `/admin/coaches/${userId}/block-for-coaching`
      );
    },

    async unblockUserForCoaching(userId: string): Promise<void> {
      await AxiosInstance.getAxiosInstance().put(
        `/admin/coaches/${userId}/unblock-for-coaching`
      );
    },

    async getCoachesReport(options: {
      startDate: Date;
      endDate: Date;
      filters: {
        selectedRole: string;
        country: string;
        language: string;
      };
    }): Promise<any> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/coaches/report`,
        {
          params: {
            startDate: options.startDate.toISOString(),
            endDate: options.endDate.toISOString(),
            selectedRoles: options.filters.selectedRole,
            country: options.filters.country,
            language: options.filters.language,
          },
        }
      );

      return data;
    },

    async getCoachesAiReport(options: {
      startDate: Date;
      endDate: Date;
      filters: {
        selectedRole: string;
        country: string;
        language: string;
      };
    }): Promise<any> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/coaches/ai-report`,
        {
          params: {
            startDate: options.startDate.toISOString(),
            endDate: options.endDate.toISOString(),
            selectedRoles: options.filters.selectedRole,
            country: options.filters.country,
            language: options.filters.language,
          },
        }
      );

      return data;
    },

    async postCoachNotes(coachId: string, notes: string): Promise<void> {
      await AxiosInstance.getAxiosInstance().post(
        `/admin/coaches/notes`,
        { notes },
        {
          params: { coachId },
        }
      );
    },

    async getCoachCalls(start: Date, end: Date, coachId: string): Promise<any> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/coaches/report`,
        {
          params: {
            start: start.toISOString(),
            end: end.toISOString(),
            coachId,
          },
        }
      );

      return data;
    },
  };

  static PaidCoaches = {
    async getApplications(
      status: TPaidCoachApplicationStatus,
      page: number,
      limit: number,
      sort: RequestSortData<PaidCoachApplication>,
      filter: string,
      isCertifiedBeginnerFriendly: boolean,
      isScheduledPaidCoach: boolean,
      filterDate?: {
        from?: string;
        until?: string;
        specificDay?: string;
      },
      filterLanguage?: string[]
    ): Promise<PaginatedResult<PaidCoachApplicationData>> {
      const { data } = await AxiosInstance.getAxiosInstance().request({
        url: '/admin/paid-coach-application',
        params: {
          status,
          page,
          limit,
          sort,
          filter,
          isCertifiedBeginnerFriendly,
          isScheduledPaidCoach,
          filterDate,
          filterLanguage,
        },
      });

      return data;
    },

    async updatePaidCoachApplicationStatus(
      applicationId: string,
      status: TPaidCoachApplicationStatus
    ): Promise<void> {
      const { data } = await AxiosInstance.getAxiosInstance().request({
        method: 'PUT',
        url: `/admin/paid-coach-application/${applicationId}/${status}`,
      });

      return data;
    },

    async checkApplicationUpdateStatus(
      status: TPaidCoachApplicationStatus
    ): Promise<CheckApplicationUpdateStatus> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/paid-coach-application/check-application-update-status/${status}`
      );

      return data;
    },

    async updateApplicationData(status: any): Promise<string> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/paid-coach-application/update-paid-coach-application-data/${status}`
      );

      return data;
    },

    async updateCertifiedBeginnerPaidCoachMark(
      applicationId: string,
      status: boolean
    ): Promise<PaidCoachApplication> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        `/admin/paid-coach-application/${applicationId}/update-certified-beginner-mark`,
        { status }
      );

      return data;
    },

    async updateScheduledPaidCoachStatus(
      applicationId: string,
      status: boolean
    ): Promise<PaidCoachApplication> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        `/admin/paid-coach-application/${applicationId}/update-scheduled-coach-status`,
        { status }
      );

      return data;
    },

    async getPaidCoachBeginnerFriendlyInformation(
      applicationId: string
    ): Promise<PaidCoachCertifiedBeginnerInformation> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `/admin/paid-coach-application/${applicationId}/get-certified-beginner-information`
      );

      return data;
    },
  };

  static CallConfig = {
    async getAll(): Promise<PaginatedResult<CallConfig>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/admin/call-config'
      );

      return data;
    },

    async update(
      id: string,
      formData: Partial<CallConfig>
    ): Promise<CallConfig> {
      const { data } = await AxiosInstance.getAxiosInstance().put(
        `/admin/call-config/${id}`,
        {
          data: formData,
        }
      );

      return data;
    },

    async create(formData: Partial<CallConfig>): Promise<CallConfig> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        '/admin/call-config',
        {
          data: formData,
        }
      );

      return data;
    },

    async delete(id: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().delete(
        `/admin/call-config/${id}`
      );
    },
  };

  static Sections = {
    async updateSection(
      sectionId: string,
      section: SectionFormData
    ): Promise<any> {
      return AxiosInstance.getAxiosInstance().put(
        `/admin/sections/${sectionId}`,
        section
      );
    },

    async createSection(section: SectionFormData): Promise<any> {
      return AxiosInstance.getAxiosInstance().post('/admin/sections', section);
    },

    async deleteSection(sectionId: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().delete(
        `/admin/sections/${sectionId}`
      );
    },

    async getSections(
      params: AdminSectionFilters
    ): Promise<PaginatedResult<PopulatedSection>> {
      const { data } = await AxiosInstance.getAxiosInstance().request({
        method: 'GET',
        url: '/admin/sections',
        params,
      });

      return data;
    },
  };

  static Users = {
    async getFilteredUsers(keySearch: string): Promise<Array<User>> {
      const { data } = await AxiosInstance.getAxiosInstance().get<Array<User>>(
        '/admin/users/searchByKey',
        {
          params: {
            keySearch,
          },
        }
      );

      return data;
    },

    async deleteUser(userId: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().delete(`/admin/users/${userId}`);
    },

    async getUserCalls(
      userId: string,
      page: number,
      size: PaginatedSize
    ): Promise<SimplePaginatedResult<CallPopulated>> {
      const { data } = await AxiosInstance.getAxiosInstance().get<
        SimplePaginatedResult<CallPopulated>
      >(`/admin/users/${userId}/calls`, {
        params: { page, size },
      });

      return data;
    },

    async requestCallComposition(callId: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().get(
        `/admin/calls/${callId}/request-composition`
      );
    },

    async userBecomeCoach(userId: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().get(
        `/admin/users/${userId}/becomeCoach`
      );
    },

    async sendVerificationEmail(email: string): Promise<void> {
      return AxiosInstance.getAxiosInstance().post(
        '/auth/send-verification-email',
        { email }
      );
    },

    async manualEmailVerification(email: string): Promise<any> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        '/auth/manual-verification-email',
        { email }
      );

      return data;
    },

    async addSectionCodeToUser(
      userId: string,
      sectionCode: string
    ): Promise<Array<Section>> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        `/admin/users/${userId}/add-section`,
        { sectionCode }
      );

      return data;
    },

    async deleteSectionCodeFromUser(
      userId: string,
      sectionCode: string
    ): Promise<Array<Section>> {
      const { data } = await AxiosInstance.getAxiosInstance().delete(
        `/admin/users/${userId}/delete-section`,
        {
          params: {
            sectionCode,
          },
        }
      );

      return data;
    },

    async editSectionCodeFromUser(
      userId: string,
      oldCode: string,
      newCode: string
    ): Promise<Array<Section>> {
      const { data } = await AxiosInstance.getAxiosInstance().put(
        `/admin/users/${userId}/edit-section`,
        { oldCode, newCode }
      );

      return data;
    },
  };

  static LiveHours = {
    async getLiveHours(): Promise<LiveHour[]> {
      const { data } = await AxiosInstance.getAxiosInstance().get<LiveHour[]>(
        `/admin/live-hours`
      );
      return data;
    },
    async addLiveHour(liveHourData: Partial<LiveHour>): Promise<LiveHour[]> {
      const { data } = await AxiosInstance.getAxiosInstance().post<LiveHour[]>(
        `/admin/live-hours`,
        liveHourData
      );
      return data;
    },

    async deleteLiveHour(liveHourId: string): Promise<LiveHour[]> {
      const { data } = await AxiosInstance.getAxiosInstance().delete<
        LiveHour[]
      >(`/admin/live-hours/${liveHourId}`);
      return data;
    },
  };

  static ActiveSearches = {
    async getActiveSearches(
      params: PaginationParams
    ): Promise<SimplePaginatedResult<ActiveSearchQueueData>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        'admin/active-searches/get-active-searches',
        {
          params,
        }
      );
      return data;
    },

    async cancelActiveSearch(
      queueId: number
    ): Promise<SimplePaginatedResult<ActiveSearchQueueData>> {
      return AxiosInstance.getAxiosInstance().get(
        `admin/active-searches/cancel-active-search/${queueId}`
      );
    },

    async blockActiveSearch(queueId: number): Promise<void> {
      return AxiosInstance.getAxiosInstance().get(
        `admin/active-searches/block-active-search/${queueId}`
      );
    },

    async unblockActiveSearch(queueId: number): Promise<void> {
      return AxiosInstance.getAxiosInstance().get(
        `admin/active-searches/unblock-active-search/${queueId}`
      );
    },

    async searchPartner(value: string, omitUser?: string): Promise<Array<any>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `admin/active-searches/search-partner/${value}?omitUser=${omitUser}`
      );

      return data;
    },

    async forceMatch(
      queueId: Queue['id'],
      userId: User['_id']
    ): Promise<Array<any>> {
      const { data } = await AxiosInstance.getAxiosInstance().post(
        `admin/active-searches/force-match/${queueId}`,
        { userId }
      );

      return data;
    },

    async releaseForceMatch(queueId: Queue['id']): Promise<Array<any>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        `admin/active-searches/release-force-match/${queueId}`
      );

      return data;
    },
  };

  static ActiveCalls = {
    async getActiveCalls(
      params: ActiveCallsParams
    ): Promise<PaginatedResult<ActiveCallDetails>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        'admin/stats/get-active-calls',
        {
          params,
        }
      );
      return data;
    },
  };

  static SocketsReport = {
    async getSocketsReport(
      params: SocketsReportParams
    ): Promise<SocketsReportResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        'admin/stats/get-sockets-report',
        {
          params,
        }
      );
      return data;
    },
  };

  static CoachQuizReport = {
    async getCoachQuizReport(
      params: CoachQuizReportParams
    ): Promise<CoachQuizReportResponse> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        'admin/stats/get-coach-quiz-report',
        {
          params,
        }
      );
      return data;
    },
  };

  static Currencies = {
    async getCurrencies(params: CurrencyParams) {
      const { data } = await AxiosInstance.getAxiosInstance().get<
        PaginatedResult<Currency>
      >('admin/currencies', { params });
      return data;
    },

    async updateCurrencies(
      currencies: Currency[],
      reason: string
    ): Promise<void> {
      return AxiosInstance.getAxiosInstance().put('admin/currencies', {
        currencies,
        reason,
      });
    },

    async createCurrency(currency: Currency): Promise<void> {
      return AxiosInstance.getAxiosInstance().post(
        'admin/currencies',
        currency
      );
    },
  };

  static Faculties = {
    async getFaculties(
      params: PaginationParamsWithFilters<FacultyPaginationParams>
    ) {
      const { data } = await AxiosInstance.getAxiosInstance().get<
        PaginatedResult<Faculty>
      >('admin/faculties', {
        params,
      });
      return data;
    },

    async updateFaculty(id: string, faculty: AdminEditableFaculty) {
      return AxiosInstance.getAxiosInstance().put(`admin/faculties/${id}`, {
        data: faculty,
      });
    },

    async postFaculty(faculty: AdminEditableFaculty) {
      return AxiosInstance.getAxiosInstance().post('admin/faculties', {
        data: faculty,
      });
    },
  };

  static PriceBundle = {
    async getPriceBundle(
      params: PaginationParamsWithFilters<Partial<PriceBundlesGroup>>
    ): Promise<PaginatedResult<PriceBundlesGroup>> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        'admin/price-bundles',
        {
          params,
        }
      );

      return data;
    },
  };

  static Coupons = {
    async updateCoupon(body: UpdateCouponRequest): Promise<void> {
      await AxiosInstance.getAxiosInstance().put('admin/coupon/update', body);
    },
  };

  static Calls = {
    async changeSectionForACall(
      userId: string,
      callId: string,
      reason: string,
      newSection: string
    ): Promise<void> {
      await AxiosInstance.getAxiosInstance().put(
        `/admin/calls/${userId}/${callId}`,
        { reason, newSection }
      );
    },

    async requestZoomComposition({ callId }: { callId: string }) {
      await AxiosInstance.getAxiosInstance().get(
        `/admin/calls/${callId}/request-composition`
      );
    },
  };

  static Languages = {
    async createLanguage(data: Partial<Languages>): Promise<Languages> {
      const response = await AxiosInstance.getAxiosInstance().post(
        `/admin/languages`,
        {
          data,
        }
      );

      return response.data;
    },
    async updateLanguage(
      langCode: LangCode,
      data: Partial<Languages>
    ): Promise<Languages> {
      const response = await AxiosInstance.getAxiosInstance().put<Languages>(
        `/admin/languages/${langCode}`,
        {
          data,
        }
      );

      return response.data;
    },
    async deleteLanguage(langCode: LangCode): Promise<void> {
      await AxiosInstance.getAxiosInstance().delete<Languages>(
        `/admin/languages/${langCode}`
      );
    },
  };

  static readonly Ledgers = {
    async changeLedgerSection(
      userId: string,
      callId: string,
      newSection: string
    ): Promise<void> {
      await AxiosInstance.getAxiosInstance().put(
        `/admin/ledgers/${userId}/${callId}`,
        { newSection }
      );
    },
  };

  static readonly Avatar = {
    async getAvatarConfiguration(): Promise<AvatarConfiguration> {
      const { data } = await AxiosInstance.getAxiosInstance().get(
        '/admin/avatar/configuration'
      );

      return data;
    },

    async updateAvatarConfiguration(
      configuration: AvatarConfiguration
    ): Promise<void> {
      const { data } = await AxiosInstance.getAxiosInstance().put(
        '/admin/avatar/configuration',
        { configuration }
      );

      return data;
    },
  };
}
