import { HttpResponseError, UnexpectedHttpResponseError } from './classes';
import UnauthorizedHttpResponseError from './classes/UnauthorizedHttpResponseError';
export function responseError(arg) {
    return new HttpResponseError(arg);
}
export function unexpectedResponse(arg) {
    return new UnexpectedHttpResponseError(arg);
}
export function unauthorizedResponse(arg) {
    return new UnauthorizedHttpResponseError(arg);
}
