import React, { useEffect, useRef, useState } from 'react';
import { CONFLICT } from 'http-status-codes';
import { Trans } from '@lingui/react';
import { Link, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';

import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import { TERMS_OF_USER_URL } from 'conversifi-shared-react/es6/constants/constants';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { BABBEL_MAX_CALL_DURATION } from 'conversifi-commons/constants';
import { useGaEvents } from 'conversifi-shared-react/es6/hooks/useGaEvents';
import { RegisterSectionCode } from './inputs';
import { ReactFormEvent } from '../../util';
import { RegisterEmailSimple } from './inputs/RegisterEmailSimple';
import { useImmutableState } from '../../hooks/useImmutableState';
import { useInputTextValidation } from '../../hooks/useInputTextValidation';
import {
  emailValidationSchema,
  sectionCodeValidationSchema,
} from '../../validations/yupValidations';
import getServerValidation from '../../validations/serverValidations/sectionServerValidation';
import { UserSdk } from '../../sdk/user.sdk';
import InputSubmit from '../components/InputSubmit';

export interface OnSubmissionSucceededParams {
  email: string;
}
interface Props {
  onSubmissionSucceeded: (params: OnSubmissionSucceededParams) => void;
  onUserSourceDetected: (userSource: UserSource) => void;
}

interface ImmutableState {
  email: {
    value: string;
  };
  section: {
    value: string;
  };
  privacyPolicyAccepted: {
    value: boolean;
  };
  errorMessage: {
    header: string | JSX.Element;
    body: string | string[] | JSX.Element;
  };
  submit: {
    isSubmitting: boolean;
    submissionSucceeded: boolean;
  };
}

const getErrorMessages = (
  error: AxiosError
): { title: string; messages: string[] } | undefined => {
  const errorData = error.response?.data;

  if (!errorData) {
    return;
  }

  const hasTitle = errorData.payload && errorData.payload.title;
  const hasMessages =
    errorData.payload &&
    (errorData.payload.message || errorData.payload.messages);

  if (hasTitle && hasMessages) {
    return {
      title: errorData.payload.title,
      messages:
        (errorData.payload.message && [errorData.payload.message]) ||
        errorData.payload.messages,
    };
  }
};

const createInitialState = (): ImmutableState => {
  return {
    email: {
      value: '',
    },
    section: {
      value: '',
    },
    privacyPolicyAccepted: { value: false },
    errorMessage: {
      header: '',
      body: '',
    },
    submit: {
      isSubmitting: false,
      submissionSucceeded: false,
    },
  };
};

export const RegisterForm: React.FC<Props> = ({
  onSubmissionSucceeded,
  onUserSourceDetected,
}) => {
  const [state, setState] = useImmutableState(createInitialState());
  const { search } = useLocation();
  const trans = LinguiContext.useCtx().trans;

  const [urlSectionCode, setUrlSectionCode] = useState<string | null>(null);
  const [isBabbelUniversity, setIsBabbelUniversity] = useState<boolean | null>(
    null
  );

  const [emailValidation, resetEmailValidation] = useInputTextValidation({
    value: state.email.value,
    yupValidation: emailValidationSchema,
  });

  const [sectionValidation, resetSectionValidation] = useInputTextValidation({
    value: state.section.value,
    yupValidation: sectionCodeValidationSchema,
    serverValidation: getServerValidation(trans),
  });

  const uuid = new URLSearchParams(search).get('uuid') ?? undefined;

  const wipCtx = LinearProgressContext.useCtx();
  const dialogCtx = DialogContext.useCtx();

  const resetError = () =>
    setState({
      errorMessage: {
        header: { $set: '' },
        body: { $set: '' },
      },
    });

  useEffect(() => {
    const code = new URLSearchParams(search).get('sectionCode');

    if (code) {
      setUrlSectionCode(code);
    } else {
      setIsBabbelUniversity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (Boolean(state.errorMessage.header && state.errorMessage.body)) {
      dialogCtx.setMessage({
        title: state.errorMessage.header,
        message: Array.isArray(state.errorMessage.body)
          ? state.errorMessage.body.join(' ')
          : state.errorMessage.body,
        defaultAction: resetError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.errorMessage.header, state.errorMessage.body]);

  useEffect(() => {
    if (urlSectionCode) {
      setState({ section: { value: { $set: urlSectionCode } } });

      UserSdk.anonymous
        .getSectionByCode(urlSectionCode)
        .then((section) => {
          setIsBabbelUniversity(
            section.callConfig.maxCallDuration === BABBEL_MAX_CALL_DURATION
          );
        })
        .catch((err) => {
          setIsBabbelUniversity(false);
          console.error('Error getting section by code', err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSectionCode]);

  useEffect(() => {
    if (isBabbelUniversity !== null) {
      onUserSourceDetected(isBabbelUniversity ? 'babbel' : 'conversifi');
    }
    // eslint-disable-next-line
  }, [isBabbelUniversity]);

  const { sendEvent } = useGaEvents();
  const userEntersEmail = useRef<boolean>(false);

  const onEntersEmailForGA = () => {
    if (!userEntersEmail.current) {
      sendEvent(
        'enter_email_on_register',
        isBabbelUniversity ? 'babbel' : 'conversifi'
      );

      userEntersEmail.current = true;
    }
  };

  const onEmailChange = (value: string) => {
    onEntersEmailForGA();
    return setState({ email: { value: { $set: value } } });
  };

  const onSectionChange = (value: string) =>
    setState({ section: { value: { $set: value } } });

  const setError = (params: ImmutableState['errorMessage']) =>
    setState({
      errorMessage: {
        header: { $set: params.header },
        body: { $set: params.body },
      },
    });

  const resetAll = () => {
    resetEmailValidation();
    resetSectionValidation();
    setState({ $set: createInitialState() });
  };

  const registerDisabled =
    !emailValidation.isValid ||
    !sectionValidation.isValid ||
    !state.privacyPolicyAccepted.value ||
    state.submit.isSubmitting;

  const onSubmit = async (event: ReactFormEvent) => {
    event.preventDefault();

    sendEvent(
      'verify_email_on_register',
      isBabbelUniversity ? 'babbel' : 'conversifi'
    );

    if (!state.email.value || !state.section.value) {
      return;
    }

    wipCtx.showLinearProgressWithBackdrop();
    resetError();
    setState({ submit: { isSubmitting: { $set: true } } });

    try {
      await UserSdk.anonymous.requestUnconfirmedRegistration({
        email: state.email.value,
        sectionCode: state.section.value,
        privacyPolicyAccepted: state.privacyPolicyAccepted.value,
        uuid,
      });

      setState({
        submit: {
          isSubmitting: { $set: false },
          submissionSucceeded: { $set: true },
        },
      });
    } catch (err) {
      const { data, status } = err.response;

      if (status === CONFLICT) {
        const { meta } = data;

        if (meta?.reason) {
          // #ALREADY_REGISTERED_REASON
          let body;
          if (meta.reason === 'UNCONFIRMED_EMAIL') {
            body = (
              <Trans id="anonymous.register.form.already-registered.unconfirmed-email-body">
                This email has already started the registration process. Please
                check your email in order to continue the registration.
              </Trans>
            );
          } else {
            body = (
              <Trans
                id="anonymous.register.form.already-registered.account-already-exists-body"
                components={[<Link to={'/login'} onClick={resetError}></Link>]}
              >
                An account using this email already exists. Please
                <Link to={'/login'}>click here</Link> to log in.
              </Trans>
            );
          }
          setError({
            header:
              meta.reason === 'UNCONFIRMED_EMAIL'
                ? trans({
                    id: 'anonymous.register.form.already-registered.unconfirmed-email-header',
                    message: 'Register already started',
                  })
                : trans({
                    id: 'anonymous.register.form.already-registered.account-already-exists-header',
                    message: 'Already registered',
                  }),
            body,
          });
        } else {
          genericError();
        }
      } else {
        const errorInfo = getErrorMessages(err);

        setState({
          submit: {
            isSubmitting: { $set: false },
            submissionSucceeded: { $set: false },
          },
        });

        if (errorInfo) {
          setError({
            header: errorInfo.title,
            body: errorInfo.messages,
          });
        } else {
          genericError();
        }
      }
    } finally {
      setState({ submit: { isSubmitting: { $set: false } } });
      wipCtx.hideLinearProgressAndBackdrop();
    }
  };

  const genericError = () => {
    setError({
      header: trans({
        id: 'anonymous.register.form.unexpected-error-title',
        message: 'Unexpected Error',
      }),
      body: trans({
        id: 'anonymous.register.form.unexpected-error-message',
        message:
          "Please check your inbox. If you don't see our email, click “Chat with us” or email support@conversifi.com.",
      }),
    });
  };

  useEffect(() => {
    if (state.submit.submissionSucceeded && onSubmissionSucceeded) {
      resetAll();
      onSubmissionSucceeded({ email: state.email.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submit.submissionSucceeded]);

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          <RegisterEmailSimple
            inputId="registerForm.step1.email"
            value={state.email.value}
            onValueChange={onEmailChange}
            validationStatus={emailValidation}
          />
          <RegisterSectionCode
            inputId="registerForm.step1.email"
            value={state.section.value}
            onValueChange={onSectionChange}
            validationStatus={sectionValidation}
            disabled={Boolean(
              urlSectionCode && sectionValidation.isValid && isBabbelUniversity
            )}
          />
        </Stack>
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                id="registerForm.step1.privacyAndPolicyCheck"
                name="privacy-policy"
                onChange={(e) =>
                  setState({
                    privacyPolicyAccepted: {
                      value: { $set: e.target.checked },
                    },
                  })
                }
                checked={state.privacyPolicyAccepted.value}
                sx={{ color: 'white' }}
              />
            }
            label={
              <Trans
                id="anonymous.register.form.i-have-read-and-agree-to-the-terms-of-use"
                components={[
                  <a
                    href={TERMS_OF_USER_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of use
                  </a>,
                ]}
              >
                I have read and agree to the
                <a
                  href={TERMS_OF_USER_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of use
                </a>
              </Trans>
            }
          />
        </Box>
        <InputSubmit
          id="registerForm.step1.submit"
          disabled={registerDisabled}
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            height: (theme) => theme.spacing(6),
          }}
        >
          <Trans id="anonymous.register.form.register">Register </Trans>
        </InputSubmit>
      </form>
    </Box>
  );
};
