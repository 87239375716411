import { apiErrorHandler } from '../apiErrorHandler';
import { requestApproveRejectTransactionGet } from '../requests';
import { PAYPAL_TRANSACTION_ADMIN_OPERATIONS } from 'conversifi-types/src/PaypalTransaction';

export const approveRejectTransaction = async (
  transactionId: string,
  operation: PAYPAL_TRANSACTION_ADMIN_OPERATIONS
): Promise<void> => {
  try {
    await requestApproveRejectTransactionGet(transactionId, operation);
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
