import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import {
  CountryCode,
  parsePhoneNumberFromString,
  getCountryCallingCode,
  PhoneNumber,
} from 'libphonenumber-js/mobile';
// @ts-ignore
import 'flag-icons/css/flag-icons.min.css';
import classes from './InputMobilePhone.module.css';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import InputTextField from '../../App.anonymous/components/InputTextField';
import {
  phoneNumberValidateFlexible,
  phoneNumberValidate,
  phoneNumberCleanup,
} from 'conversifi-commons/helpers/phoneHelper';
import { Trans } from '@lingui/react';
import InpuTSelect from '../../App.anonymous/components/InputSelect';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';

interface InputMobilePhoneProps {
  defaultValue?: string;
  defaultStyle?: boolean;
  showIcon?: boolean;
  codeClassName?: string;
  phoneClassName?: string;
  selectId?: string;
  inputId?: string;
  onStatusChange?: (phone: string, callingCode?: any) => void;
  label?: string;
  registerScreenStyle?: boolean;
  onError?: (isError: boolean) => void;
  strictMode?: boolean;
}

interface CountryItem {
  label?: string;
  countryCode: CountryCode;
  phoneCode: string;
}

const registerScreenStylesPhone: SxProps = {
  '& .MuiOutlinedInput-root': {
    height: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E5E5E5',
    borderWidth: 2,
  },
  ':hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
  },
};

const defaultCountry = {
  label: 'United States of America (+1)',
  countryCode: 'US' as CountryCode,
  phoneCode: '(+1)',
};
/**
 * input of type `text` for the conversifi _@handle_ at
 * the register stage
 * Validations and code related to the phone's country were removed
 * acording to this ticket: https://speakapp.atlassian.net/browse/CONV-3797
 */
export const InputMobilePhone: React.FC<InputMobilePhoneProps> = ({
  defaultValue = '',
  defaultStyle = false,
  onStatusChange,
  phoneClassName,
  label,
  registerScreenStyle,
  strictMode = false,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<
    CountryItem | undefined
  >();
  const trans = LinguiContext.useCtx().trans;
  const countriesCtx = CountriesContext.useCtx();
  const countryOptions: CountryItem[] = countriesCtx
    .getCountries()
    .map((info) => ({
      label: `${info.name} (+${info.callingCodes.join(', ')})`,
      countryCode: info.alpha2Code as CountryCode,
      phoneCode: `(+${info.callingCodes.join(', ')})`,
    }));

  useEffect(() => {
    const parsedPhone = parsePhoneNumberFromString(defaultValue) as PhoneNumber;
    if (!parsedPhone) {
      setSelectedCountry(defaultCountry);
      setPhoneNumber(defaultValue);
    } else {
      const { country, countryCallingCode } = parsedPhone;
      setSelectedCountry({
        countryCode: (country ?? 'US') as CountryCode,
        phoneCode: `(+${countryCallingCode})`,
        label: defaultCountry.label,
      });
      setPhoneNumber(parsedPhone.nationalNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsedPhone = parsePhoneNumberFromString(
      phoneNumber as string,
      selectedCountry?.countryCode
    ) as PhoneNumber;

    if (parsedPhone) {
      const phone = phoneNumberCleanup(parsedPhone.formatInternational());
      if (onStatusChange) {
        onStatusChange(phone);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, selectedCountry]);

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let val = event.target.value;
    if (val && !phoneNumberValidateFlexible(val)) {
      return;
    }

    if (strictMode && val) {
      val = phoneNumberCleanup(val);
    }
    setPhoneNumber(val);
  };

  const handleChangeSelectedCountry = (event: SelectChangeEvent<unknown>) => {
    const countryCode = event.target.value as CountryCode;
    const phoneCode = `(+${getCountryCallingCode(countryCode)})`;
    setSelectedCountry({
      countryCode: countryCode,
      phoneCode: phoneCode,
    });
  };

  const showHelperMessage = () => {
    const noValue = !phoneNumber || phoneNumber.trim().length === 0;
    const wrongValue =
      strictMode && phoneNumber && !phoneNumberValidate(phoneNumber, false);
    return noValue || wrongValue;
  };

  const classesStyle = defaultStyle
    ? phoneClassName
    : `${classes.phoneInput} ${phoneClassName}`;

  const helpMessage = trans({
    id: 'profile.input.phone-number.placeholder',
    message: 'Enter the phone number',
  });

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FormControl required style={{ width: '150px' }}>
          <InputLabel
            sx={{ color: 'white' }}
            id="phone-verification.select-country-calling-code"
          >
            <Trans
              id="phone-verification.select-country-code"
              message="Country Code"
            />
          </InputLabel>
          <InpuTSelect
            autoFocus={false}
            id="phoneVerification.countryCode"
            labelId="phone-verification.select-country-calling-code"
            value={selectedCountry?.countryCode ?? ''}
            renderValue={(value) => (
              <CountrySelected countryCode={value as CountryCode} />
            )}
            label={
              <Trans
                id="phone-verification.select-country-calling-code"
                message="Select Country Calling Code"
              ></Trans>
            }
            onChange={handleChangeSelectedCountry}
            required
          >
            {countryOptions.map((option) => (
              <MenuItem
                key={`${option.label}-${option.countryCode}`}
                value={option.countryCode}
              >
                <span
                  className={getCountryFlagClassName(
                    option.countryCode as CountryCode
                  )}
                ></span>
                <Typography sx={{ ml: 1 }}>{option.label}</Typography>
              </MenuItem>
            ))}
          </InpuTSelect>
        </FormControl>

        {registerScreenStyle ? (
          <InputTextField
            value={phoneNumber}
            onChange={onChange}
            name="register-mobile-phone"
            label={label}
            fullWidth
            required
          />
        ) : (
          <TextField
            value={phoneNumber}
            onChange={onChange}
            className={classesStyle}
            name="register-mobile-phone"
            label={label}
            sx={
              (registerScreenStyle && {
                ...registerScreenStylesPhone,
                ml: 1,
                width: '100%',
              }) || { ml: 1, width: '100%' }
            }
            required
          />
        )}
      </Stack>
      {showHelperMessage() && (
        <Typography textAlign="left">{helpMessage}</Typography>
      )}
    </>
  );
};

const getCountryFlagClassName = (countryCode: CountryCode) => {
  return `fi fi-${countryCode.toLowerCase()}`;
};

const CountrySelected = ({ countryCode }: { countryCode: CountryCode }) => (
  <Stack direction="row" alignItems="center">
    <span className={getCountryFlagClassName(countryCode)}></span>
    <Typography sx={{ ml: 1 }}>{`(+${getCountryCallingCode(
      countryCode
    )})`}</Typography>
  </Stack>
);
