import React, { FC } from 'react';
import { ThemeProvider as Provider, CssBaseline } from '@mui/material';
import { defaultTheme } from './defaultTheme';

export const ThemeProvider: FC = ({ children }) => {
  return (
    <Provider theme={defaultTheme}>
      <CssBaseline />
      {children}
    </Provider>
  );
};
