import React from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../routes';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Trans } from '@lingui/react';

interface Params {
  history: ReturnType<typeof useHistory>;
}

export const getSidebarItems = ({ history }: Params) => {
  return [
    {
      id: 'summary',
      text: <Trans id="instructors.tabs.summary">Summary</Trans>,
      icon: SummarizeOutlinedIcon,
      onClick: () => history.push(`${Routes.instructor.uri}/summary`),
    },
    {
      id: 'university-summary',
      text: (
        <Trans id="instructors.tabs.university-report">University Report</Trans>
      ),
      icon: AssessmentOutlinedIcon,
      onClick: () =>
        history.push(`${Routes.instructor.uri}/university-summary`),
    },
    {
      id: 'history',
      text: <Trans id="instructors.tabs.history"> History</Trans>,
      icon: TimelineOutlinedIcon,
      onClick: () => history.push(`${Routes.instructor.uri}/history`),
    },
    {
      id: 'resources',
      text: (
        <Trans id="instructors.tabs.instructor-resources">
          Instructor Resources
        </Trans>
      ),
      icon: SchoolOutlinedIcon,
      onClick: () => history.push(`${Routes.instructor.uri}/resources`),
    },
    {
      id: 'module-menu',
      text: <Trans id="instructors.tabs.module-menu">Module Menu</Trans>,
      icon: MenuBookOutlinedIcon,
      onClick: () => history.push(`${Routes.instructor.uri}/module-menu`),
    },
    {
      id: 'class-setup',
      text: <Trans id="instructors.tabs.class-setup">Class Setup</Trans>,
      icon: ModeOutlinedIcon,
      onClick: () => history.push(`${Routes.instructor.uri}/class-setup`),
    },
  ];
};
