import React from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableSortLabelTypeMap,
  TextField,
} from '@mui/material';
import {
  HeadCell,
  IFilter,
} from 'conversifi-shared-react/es6/types/TableMaterialUI';
import { SORT_ORDER } from '../../api/RequestSchemas';
import debounce from 'lodash.debounce';
import { useStyles } from './styles';

const orderConfigMap: Readonly<{
  [key in string]: TableSortLabelTypeMap['props']['direction'];
}> = {
  [SORT_ORDER.ASC]: 'asc',
  [SORT_ORDER.DESC]: 'desc',
};

type TableHeaderPlusProps = {
  headCells: HeadCell[];
  order?: SORT_ORDER;
  orderBy?: string;
  firstHeadSticky?: boolean;
  onRequestSort?: (
    event: React.MouseEvent<HTMLButtonElement>,
    property: string
  ) => void;
};

export const TableHeaderPlus = (props: TableHeaderPlusProps) => {
  const { headCells, order, orderBy, firstHeadSticky, onRequestSort } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      onRequestSort?.(event, property);
    };

  const hasFilter: boolean = Boolean(
    headCells.find((headCell) => headCell.filter)
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            style={
              firstHeadSticky !== undefined && i === 0
                ? {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 5,
                  }
                : {}
            }
            sx={headCell.tableCellSx}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              {...(hasFilter && { minHeight: 60, minWidth: 60 })}
              sx={headCell.tableCellSx}
            >
              <Box sx={headCell.tableCellSx}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id && order
                      ? orderConfigMap[order]
                      : orderConfigMap[SORT_ORDER.ASC]
                  }
                  {...(Boolean(headCell.sort) && {
                    onClick: createSortHandler(headCell.id),
                  })}
                  hideSortIcon={!Boolean(headCell.sort)}
                  {...(headCell.tableSortLabelSx
                    ? { sx: headCell.tableSortLabelSx }
                    : { sx: { width: 'max-content' } })}
                >
                  {headCell.label}
                </TableSortLabel>
              </Box>
              {headCell.filter &&
                (headCell.filter.customFilter ?? (
                  <FilterByText
                    objectKey={headCell.filter.queryName ?? headCell.id}
                    callback={headCell.filter.callback}
                    debounceTime={headCell.filter.debounceTime}
                    placeholder={headCell.filter.placeholder}
                    filterInputSx={headCell.filter.filterInputSx}
                  />
                ))}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface IFilterByText extends IFilter {
  objectKey: string;
}

const FilterByText = ({
  objectKey,
  callback,
  debounceTime = 400,
  placeholder,
  filterInputSx,
}: IFilterByText) => {
  const handleDebounceFn = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (callback) callback({ [objectKey]: event.target.value });
  };
  const debounceFn = debounce(handleDebounceFn, debounceTime);
  const classes = useStyles();

  return (
    <TextField
      type="text"
      placeholder={placeholder}
      variant="standard"
      onChange={debounceFn}
      className={classes.filterByTextStyles}
      sx={filterInputSx}
    />
  );
};
