const { format } = require('date-fns');

/**
 * @type {import('./termTagHelper').getTermLongTag}
 */
const getTermLongTag = (universityName, termStartDate, termEndDate) => {
  const formatedStartDate = termStartDate
    ? format(termStartDate, 'MM/yyyy')
    : 'no-start';

  const formatedEndDate = termEndDate
    ? format(termEndDate, 'MM/yyyy')
    : 'no-end';

  if (!universityName) {
    return `- ${formatedStartDate} - ${formatedEndDate}`;
  }

  return `${universityName} ${formatedStartDate} - ${formatedEndDate}`;
};

/**
 * @type {import('./termTagHelper').SET_TERM_TYPE_SUGGESTION}
 */
const SET_TERM_TYPE_SUGGESTION = 'You should set the term type';

/**
 * @type {import('./termTagHelper').getTermShortTag}
 * # TERM_SHORT_TAG
 */
const getTermShortTag = (termType, termStartDate) => {
  if (!termType) {
    return SET_TERM_TYPE_SUGGESTION;
  }
  const startDate =
    typeof termStartDate === 'string' ? new Date(termStartDate) : termStartDate;
  const seasonCode = termType
    .slice(0, 2)
    .split('')
    .map((char, i) => {
      if (i === 0) {
        return char.toUpperCase();
      }
      return char;
    })
    .join('');
  const formatedStartDate = startDate.getFullYear().toString().slice(-2);
  return `${seasonCode}${formatedStartDate}`;
};

/**
 * @type {import('./termTagHelper').getTermMonthTag}
 * # TERM_MONTH_TAG
 */
const getTermMonthTag = (termType, termStartDate, termEndDate) => {
  if (!termType) {
    return SET_TERM_TYPE_SUGGESTION;
  }
  const startDate =
    typeof termStartDate === 'string' ? new Date(termStartDate) : termStartDate;
  const endDate =
    typeof termEndDate === 'string' ? new Date(termEndDate) : termEndDate;
  const seasonCode = termType
    .slice(0, 2)
    .split('')
    .map((char, i) => {
      if (i === 0) {
        return char.toUpperCase();
      }
      return char;
    })
    .join('');
  const startMonth = startDate.toLocaleString('en-us', {
    month: 'short',
  });
  const endMonth = endDate.toLocaleString('en-us', {
    month: 'short',
  });
  const formatedStartDate = startDate.getFullYear().toString().slice(-2);
  return `${seasonCode}${formatedStartDate} (${startMonth}-${endMonth})`;
};

/**
 * @type {import('./termTagHelper').getTermSeasonTag}
 */

const getTermSeasonTag = (termType, termStartDate) => {
  let seasson = '';

  if (!termType) {
    seasson = SET_TERM_TYPE_SUGGESTION;
  } else {
    seasson = termType[0].toUpperCase() + termType.substring(1);
  }

  const year = format(termStartDate, 'yyyy');

  return `${seasson} ${year}`;
};

exports.getTermLongTag = getTermLongTag;
exports.getTermShortTag = getTermShortTag;
exports.getTermMonthTag = getTermMonthTag;
exports.getTermSeasonTag = getTermSeasonTag;
exports.SET_TERM_TYPE_SUGGESTION = SET_TERM_TYPE_SUGGESTION;
