import React, { useCallback } from 'react';
import { ReactComponent as Logo } from '../../../assets/images/conversifi-logo.svg';
import { AppBar, Box, Container, IconButton, Toolbar } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Routes } from '../../../routes';
import { useHistory } from 'react-router-dom';
import SidebarContext from 'conversifi-shared-react/es6/components/SidebarContext';

const Navbar: React.FC<{ userName: string }> = ({ userName }) => {
  const history = useHistory();
  const sidebarCtx = SidebarContext.useCtx();

  const openSidebar = useCallback(() => {
    sidebarCtx.setOpen(true);
  }, [sidebarCtx]);

  const redirectToInsights = useCallback(() => {
    history.push(`${Routes.admin.uri}/insights`);
  }, [history]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton
            edge="start"
            onClick={openSidebar}
            sx={{ color: 'white' }}
          >
            <MenuRoundedIcon sx={{ color: 'white' }} />
          </IconButton>
          <Logo style={{ width: '12rem' }} onClick={redirectToInsights} />
          <Box></Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
