import i18nIsoLanguages from '@cospired/i18n-iso-languages';
import { DEFAULT_LANG } from 'conversifi-commons/constants';
import { getBrowserLang } from '../locale';
export function getLanguageName(langCode, toLangCode = DEFAULT_LANG.langCode) {
    if (langCode === 'asl') {
        return 'ASL';
    }
    if (langCode === 'XX') {
        return 'Other';
    }
    return i18nIsoLanguages.getName(langCode, toLangCode === 'asl' ? 'en' : toLangCode);
}
export function getLanguageNameBrowserLang(langCode) {
    return getLanguageName(langCode, getBrowserLang());
}
