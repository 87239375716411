import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { UniversitiesSdk } from '../../sdk/UniversitiesSdk';
export const PublicContext = React.createContext({});
export class UniversitiesContext extends BaseProvider {
    className = 'UniversitiesContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    getInitialState() {
        return {
            universities: [],
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            reloadData: this.reloadData,
        };
    }
    componentDidMount() {
        this.getUniversitiesData();
    }
    getUniversitiesData() {
        UniversitiesSdk.getAll().then(({ data }) => {
            this.produce((draft) => {
                draft.universities = data.sort((a, b) => a.name.localeCompare(b.name));
            });
        });
    }
    async reloadData() {
        this.getUniversitiesData();
    }
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() }, this.props.children));
    }
}
