import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { injectScript } from '../util/dom/injectScript';
import { useLocation } from 'react-router-dom';
const { REACT_APP_ENV, REACT_APP_GANALYTICS_MEASUREMENT_ID } = process.env;
export const useGaTracker = () => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();
    const envEnable = REACT_APP_ENV === 'uat' || REACT_APP_ENV === 'prod';
    const startTracker = async () => {
        if (REACT_APP_GANALYTICS_MEASUREMENT_ID !== '' && envEnable) {
            await injectScript(`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GANALYTICS_MEASUREMENT_ID}`);
            ReactGA.initialize(REACT_APP_GANALYTICS_MEASUREMENT_ID);
            setInitialized(true);
        }
    };
    useEffect(() => {
        startTracker();
    }, []);
    useEffect(() => {
        if (initialized) {
            ReactGA.event('page_view', {
                page_location: location.pathname,
            });
        }
    }, [initialized, location.pathname]);
};
