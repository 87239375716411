import io from 'socket.io-client';
import { getJwt } from '../../../../util';
import 'browser-report';
const { REACT_APP_BASE_URL, REACT_APP_BASE_WS_PATH, REACT_APP_WEB_SOCKET_MAX_RECONNECTION_ATTEMPTS, REACT_APP_WEB_SOCKET_MAX_RECONNECTION_DELAY_MILLISECONDS, } = process.env;
function socketCreate({ uri = REACT_APP_BASE_URL, path = REACT_APP_BASE_WS_PATH, clientUuid, }) {
    console.info('Connecting socket to base uri:', uri, 'base path:', path);
    const browserReport = window.browserReportSync();
    const query = {
        auth_token: getJwt(),
        platform: browserReport.os.name,
        browser: browserReport.browser.name,
        version: browserReport.browser.version,
        clientUuid,
    };
    const reconnectionDelay = Number(REACT_APP_WEB_SOCKET_MAX_RECONNECTION_DELAY_MILLISECONDS);
    return io(uri, {
        secure: true,
        path,
        transports: ['websocket', 'polling'],
        query,
        timeout: 5000,
        reconnection: true,
        randomizationFactor: 0,
        reconnectionDelay,
        reconnectionDelayMax: reconnectionDelay,
        reconnectionAttempts: Number(REACT_APP_WEB_SOCKET_MAX_RECONNECTION_ATTEMPTS) || 10,
    });
}
export default socketCreate;
