import { addTags, removeTags } from './tags';
export function addKeyValueTagFactory(options) {
    return async (value) => {
        const { key, tagsToRemove, defaultValue, separator = '_', parser = (v) => v, } = options;
        const tagToAdd = parser(value ?? defaultValue);
        if (tagsToRemove?.length) {
            await removeTags(...tagsToRemove.filter((l) => l !== key).map((l) => `${key}_${l}`));
        }
        if (tagToAdd) {
            await addTags(`${key}${separator}${tagToAdd}`);
        }
    };
}
