export default class BaseError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this);
        }
    }
    get json() {
        return this.toJSON();
    }
    toJSON() {
        const err = {};
        if (this.name) {
            err.name = this.name;
        }
        if (this.message) {
            err.message = this.message;
        }
        if (this.stack) {
            err.stack = this.stack;
        }
        return err;
    }
}
