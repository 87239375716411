import { ROLE_ADMIN, ROLE_CUSTOMER_SERVICE, } from 'conversifi-commons/constants';
export default function () {
    const { user, items } = this.props;
    const isCustomerServiceRole = user?.roles?.includes(ROLE_CUSTOMER_SERVICE) &&
        !user?.roles?.includes(ROLE_ADMIN) &&
        this.props.app === 'admin';
    if (!isCustomerServiceRole)
        return;
    this.produce((draft) => {
        draft.items = items.filter((item) => {
            const canAddItem = this.customerServicePermissions.includes(item.id);
            if (canAddItem) {
                item.items = item.items?.filter((subItem) => this.customerServicePermissions.includes(subItem.id));
            }
            return canAddItem;
        });
    });
}
