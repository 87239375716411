import { requestCreateCoupon, requestCreateCouponBatch } from '../requests';
import { apiErrorHandler } from '../apiErrorHandler';
import { CreateCoupon } from '../RequestSchemas';
import { CouponsBatchInputParameters } from 'conversifi-types/src/CouponsBatchConfig';

export const createCoupon = async (coupon: CreateCoupon) => {
  try {
    return await requestCreateCoupon(coupon);
  } catch (err) {
    throw apiErrorHandler(err);
  }
};

export const createCouponsBatch = async (
  coupon: CouponsBatchInputParameters
) => {
  try {
    return await requestCreateCouponBatch(coupon);
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
