/**
 * attempt to get user data. if it is logged it
 * returns {ok: true, result: userData}, otherwise see
 * more details on the definition for AssertIsUserResponse
 * in `src/App.bootstrap/AppBootstrap.tsx`
 */

import { Authorized } from './userGet';
import { ErrorInfo, isErrorUnauthorized } from '../../util';
import * as api from '../../api';
import { UserUniversity } from 'conversifi-shared-react';

export interface LoginStatus {
  status: 'logged' | 'notLogged' | 'error' | 'checking' | 'idle';
  ok: boolean;
  result: null | Authorized | ErrorInfo;
  userUniversity?: UserUniversity | null;
}

export interface IsLogged extends LoginStatus {
  status: 'logged';
  ok: true;
  result: Authorized;
  userUniversity: UserUniversity | null;
}

export interface IsNotLogged extends LoginStatus {
  status: 'notLogged';
  ok: false;
  result: null;
}

interface ResultUnexpected extends LoginStatus {
  status: 'error';
  ok: false;
  result: ErrorInfo;
}

/**
 * AppBootstrap expect function prop `assertIsUserAsync` to return
 * this type.
 */
export type IsUser = IsLogged | IsNotLogged | ResultUnexpected;

export async function isUser(): Promise<IsUser> {
  try {
    const data = await api.userGet();

    if (!data) {
      return { ok: false, result: null, status: 'notLogged' };
    }

    if (api.userGet.responseIsAuthorized(data)) {
      const userUniversity = await api.getUserUniversity();
      return {
        ok: true,
        result: data,
        status: 'logged',
        userUniversity,
      } as IsLogged;
    }

    return { ok: false, result: null, status: 'notLogged' };
  } catch (error) {
    if (isErrorUnauthorized(error) && error.extra && error.extra.responseData) {
      return { ok: false, result: null, status: 'notLogged' };
    }

    return { ok: false, result: error, status: 'error' };
  }
}
