import { AvatarContext } from '..';
import { AdminSdk } from '../../../../sdk/admin.sdk';
import { AvatarConfiguration } from 'conversifi-types/src/shared-frontend-backend/Avatar';

export async function updateAvatarConfiguration(
  this: AvatarContext,
  configuration: AvatarConfiguration
) {
  this.props.linearProgressCtx.showLinearProgressWithBackdrop();
  try {
    await AdminSdk.Avatar.updateAvatarConfiguration(configuration);

    this.props.snackbarCtx.setMessage({
      message: 'Prompt updated successfully',
      severity: 'success',
    });
  } catch (err) {
    this.logger.error('Error while updating prompt', err);
    this.props.snackbarCtx.setMessage({
      message: 'Error while updating prompt',
      severity: 'error',
    });
  } finally {
    this.props.linearProgressCtx.hideLinearProgressAndBackdrop();
  }
}
