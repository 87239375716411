import ActiveSearchesContext from '../index';
import { ILiveAlertMetaData } from 'conversifi-types/src/LiveAlert';

export default async function (this: ActiveSearchesContext) {
  if (this.state.alerts.length) {
    for (const alertContent of this.state.alerts) {
      const { interpretationKey, data, metaData } = alertContent;
      switch (interpretationKey) {
        case 'EN_USERS_WAITING_FOR_CALL':
          if (data > 0) {
            const message = `There are ${data} users looking for a partner`;

            this.props.liveAlertSnackbar.queueMessage({
              message,
              key: `EN_USERS_WAITING_FOR_CALL`,
              title: 'EN users waiting!',
              severity: 'info',
            });
          } else {
            this.props.liveAlertSnackbar.removeMessageByKey(
              `EN_USERS_WAITING_FOR_CALL`
            );
          }
          break;
        case 'ROLE_IS_WAITING_TOO_MUCH_TIME':
          const {
            lang,
            targetLanguage,
            role,
            waitingTimeInMin,
            amountUsersWaiting,
          } = metaData as ILiveAlertMetaData['ROLE_IS_WAITING_TOO_MUCH_TIME'];

          if (data >= amountUsersWaiting) {
            let title,
              message: string = '';

            if (role === 'coach') {
              title = `${lang} coaches waiting!`;
              message = `There are ${data} coaches looking for a student waiting for more than ${waitingTimeInMin} minutes`;
            } else {
              title = `${lang} learners waiting!`;
              message = `There are ${data} ${lang} students looking for a ${targetLanguage} coach waiting for more than ${waitingTimeInMin} minutes`;
            }

            this.props.liveAlertSnackbar.queueMessage({
              title,
              message,
              key: lang + role + targetLanguage + waitingTimeInMin.toString(),
              severity: 'info',
            });
          } else {
            this.props.liveAlertSnackbar.removeMessageByKey(
              lang + role + targetLanguage + waitingTimeInMin.toString()
            );
          }
          break;
        default:
          this.logger.warn('[LAC153] Unexpected interpretation key!', {
            interpretationKey,
            data,
          });
      }
    }
  }
}
