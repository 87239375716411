import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Trans } from '@lingui/react';
export const CustomTooltip = ({ titleKey, titleDefault, icon, open, onClick, }) => (React.createElement(Tooltip, { title: React.createElement(Box, { component: "div", onClick: onClick },
        React.createElement(Typography, { fontSize: 20 },
            React.createElement(Trans, { id: titleKey, message: titleDefault }))), open: open, enterTouchDelay: 0, PopperProps: {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    }, onOpen: onClick, onClose: onClick },
    React.createElement(IconButton, null, icon)));
