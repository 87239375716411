export default async function (attempts) {
    this.logger.info(`Socket reconnected after ${attempts} attempts`);
    this.produce((draft) => {
        draft.status = 'CONNECTED';
    });
    Promise.all(this._reconnectListeners.map(async (handler) => await handler.apply(this, [undefined])))
        .then(() => {
        this.logger.info('Reconnect listeners executed');
    })
        .catch((err) => this.logger.error('Reconnect listeners failed', err));
}
