/* eslint-disable @typescript-eslint/no-unused-vars */
import './assets/css/index.ts';
import React, { useEffect, useState } from 'react';
import {
  REACT_APP_INSTRUCTORS_LANDING_URL,
  NODE_ENV,
} from './util/util.parsedEnvs';
import { LoginStatus, isUser, IsUser, userGet, Authorized } from './api';
import { useImmutableState, UpdateWithPrevType } from './hooks';
import { UserLoginResponse } from './api/ResponseSchemas';
import { AppContexts } from './AppContexts';
import { Routes, RoutesMatches } from './routes';
import AdminApp from './App.admin/AdminApp';
import InstructorsPortal from './App.instructors';
import AppLoading from './App.loading';
import AnonymousScreen from './App.anonymous/AnonymousScreen';
import PhoneVerification from './App.user/app/phone-verification/PhoneVerification';
import { getUriPart } from './util/util.url';
import LogoutComponent from './common-components/LogoutComponent';
import SubscriptionFee from './App.user/app/subscription-fee-wizard/SubscriptionFee';
import { useGaTracker, zendesk } from 'conversifi-shared-react';
import { ForgotPassword } from './App.anonymous/ForgotPassword';
import { ThemeProvider } from './themes/ThemeProvider';
import { isErrorInfo } from './util';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import { LtiScreen } from './App.Lti/LtiScreen';
import { LtiLogoutScreen } from './App.Lti/LtiLogoutScreen';
import useHotjar from 'conversifi-shared-react/es6/util/hotjar/useHotjar.js';

const DEFAULT_ROUTE_VALUE = '/search';

interface State {
  loginStatus: LoginStatus;
}

const initialLoginStatus: LoginStatus = {
  status: 'idle',
  ok: false,
  result: null,
};

function render(
  state: State,
  setState: UpdateWithPrevType<State>,
  redirectTo?: string
): JSX.Element {
  // The user is logged in now, so it doesn't
  // make sense to deal with a url like /login or /register.
  // This condition happens right after the user logs in
  // using the login form.

  if (redirectTo) {
    window.location.href = redirectTo;
    return <AppLoading />;
  }

  if (
    Routes.login.match.test(window.location.pathname) ||
    Routes.register.match.test(window.location.pathname)
  ) {
    window.location.href = Routes.root.uri;
    return <AppLoading />;
  }

  if (
    !state.loginStatus.result ||
    isErrorInfo(state.loginStatus.result) ||
    window.location.pathname === '/logout'
  ) {
    return <LogoutComponent />;
  }

  const { user, userApi } = state.loginStatus.result;

  const isRootPath = Routes.root.match.test(window.location.pathname);
  const univ = state.loginStatus.userUniversity;

  // Init Zendesk
  zendesk.init(userApi, univ);

  /**
   * The user either verified his phone or
   * skipped the whole process.
   */
  function onPhoneVerificationEnded(reason: 'VERIFIED' | 'NOT_VERIFY') {
    if (userGet.mustPaySubscriptionFee(user)) {
      setState({
        loginStatus: {
          result: {
            user: {
              SMSVerified: { $set: reason },
            },
          },
        },
      });
    } else {
      window.location.replace(DEFAULT_ROUTE_VALUE);
    }
  }

  /**
   * User has a pending phone verification
   */
  if (userGet.mustVerifyPhone(user)) {
    return (
      <AppContexts user={user}>
        <PhoneVerification
          onEnded={onPhoneVerificationEnded}
          authorizedLoginResponse={state.loginStatus.result as Authorized}
          user={user}
        />
      </AppContexts>
    );
  }

  if (userGet.mustPaySubscriptionFee(user)) {
    return (
      <AppContexts user={user}>
        <SubscriptionFee />
      </AppContexts>
    );
  }

  /**
   * Role: Admin
   * Redirect ROOT to Search
   */
  if (isRootPath && userGet.isAdmin(user) && NODE_ENV === 'production') {
    // CONV-1147
    window.location.href = DEFAULT_ROUTE_VALUE;
    return <AppLoading />;
  }

  /**
   * Role: Admin
   * Redirect other paths to Admin screen
   */
  if (
    ((userGet.isAdmin(user) || userGet.isCustomerService(user)) &&
      Routes.admin.match.test(window.location.pathname)) ||
    (NODE_ENV === 'development' && isRootPath)
  ) {
    return (
      <AppContexts user={user}>
        <AdminApp user={user} />
      </AppContexts>
    );
  }

  /**
   * Role: Instructor
   * user is an instructor and he/she is trying to access
   * the instructor's portal.
   */
  if (
    userGet.isInstructor(user) &&
    (isRootPath || Routes.instructor.match.test(window.location.pathname))
  ) {
    if (
      !window.location.pathname.startsWith(REACT_APP_INSTRUCTORS_LANDING_URL)
    ) {
      window.location.pathname = `${REACT_APP_INSTRUCTORS_LANDING_URL}/summary`;
      return <AppLoading />;
    } else {
      zendesk.notifyVisitorPath('Instructor');
      return (
        <AppContexts user={user}>
          <InstructorsPortal instructor={user} />
        </AppContexts>
      );
    }
  }

  /**
   * Role: Regular user
   */
  if (
    Routes.root.match.test(window.location.pathname) &&
    NODE_ENV === 'production'
  ) {
    window.location.href = DEFAULT_ROUTE_VALUE;
    return <AppLoading />;
  } else if (!RoutesMatches.some((r) => r.test(window.location.pathname))) {
    window.location.href = getUriPart(window.location);
    return <AppLoading />;
  }

  return <p>Not found</p>;
}

/**
 * The user reach this app when he entered (or was
 * redirected to) a url that start with:
 *   - /
 *   - /admin
 *   - /login
 *   - /register
 *   - /instructor
 *   - /university-admin
 *   - /forgot-password
 */
const AppSelector: React.FC = () => {
  const [state, setState] = useImmutableState<State>({
    loginStatus: initialLoginStatus,
  });
  const [detectedUserSource, setDetecteUserSource] =
    useState<UserSource>('conversifi');

  useHotjar({
    user: {
      _id: (state.loginStatus.result as Authorized)?.user._id,
      email: (state.loginStatus.result as Authorized)?.user.email,
    },
  });

  /**
   * check if the user is logged in or not
   */
  useEffect(() => {
    if (state.loginStatus.status === 'idle') {
      setState({ loginStatus: { status: { $set: 'checking' } } });
      isUser().then((loginStatus: IsUser) => {
        setState({ loginStatus: { $set: loginStatus } });
      });
    }
  });

  /**
   * called after a successful login via form submission.
   */
  const onLoggedIn = (userLoggedInResponse: UserLoginResponse) => {
    zendesk.nativeLang(userLoggedInResponse.user.language.code);

    setState({
      loginStatus: {
        status: { $set: 'logged' },
        ok: { $set: true },
        result: { $set: userLoggedInResponse },
      },
    });
  };

  const isLtiLogout = Routes.ltiLogout.match.test(window.location.pathname);
  if (isLtiLogout) {
    return <LtiLogoutScreen />;
  }

  const isLti = Routes.lti.match.test(window.location.pathname);
  if (isLti) {
    return <LtiScreen onLoggedIn={onLoggedIn} />;
  }

  /**
   * if the user is not logged, render the app
   * for anonymous users. This app only contains
   * the screens to login and register.
   */
  if (state.loginStatus.status === 'notLogged') {
    const isForgotPassword = Routes.forgotPassword.match.test(
      window.location.pathname
    );

    let user;

    if (state.loginStatus.result) {
      user = (state.loginStatus.result as Authorized).user;
    }

    return (
      <AppContexts user={user} detectedUserSource={detectedUserSource}>
        {isForgotPassword ? (
          <ForgotPassword />
        ) : (
          <AnonymousScreen
            onLoggedIn={onLoggedIn}
            onDetectUserSource={setDetecteUserSource}
          />
        )}
      </AppContexts>
    );
  }

  if (
    state.loginStatus.status === 'logged' &&
    state.loginStatus.result &&
    userGet.responseIsAuthorized(state.loginStatus.result)
  ) {
    const redirectTo = localStorage.getItem('redirectAfterLogin') ?? undefined;
    localStorage.removeItem('redirectAfterLogin');
    return render(state, setState, redirectTo);
  }

  /**
   * show a loading screen while checking
   */
  return <AppLoading />;
};

const Routing: React.FC = () => {
  useGaTracker();

  return (
    <ThemeProvider>
      <AppSelector />
    </ThemeProvider>
  );
};
export default Routing;
