import { colors, createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
export const primaryColor = {
    main: '#59A9C0',
    light: '#7ABACD',
    dark: '#47879A',
    contrastText: '#fff',
};
export const secondaryColor = {
    main: '#224A72',
    light: '#4E6E8E',
    dark: '#1B3B5B',
    contrastText: '#fff',
};
export const THEME_ERROR_COLORS = {
    light: red[300],
    main: '#F5724E',
    dark: red[700],
    contrastText: '#fff',
};
export const baseThemeConfiguration = {
    palette: {
        primary: primaryColor,
        secondary: secondaryColor,
        error: THEME_ERROR_COLORS,
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: ['"Helvetica Neue"', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    },
};
export function createConversifiTheme(components) {
    return createTheme({
        ...baseThemeConfiguration,
        components: {
            MuiSnackbar: {
                styleOverrides: {
                    anchorOriginTopRight: {
                        top: '70px !important',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: primaryColor.main,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontWeight: 400,
                        '&.Mui-selected': {
                            '& .MuiTab-wrapper': {
                                fontWeight: 800,
                            },
                        },
                    },
                    textColorPrimary: {
                        color: primaryColor.main,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '5px',
                    },
                    containedPrimary: {
                        color: 'white',
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        fontSize: 32,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: primaryColor.main,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    h1: {
                        color: primaryColor.main,
                    },
                    h2: {
                        color: primaryColor.main,
                    },
                    h3: {
                        color: primaryColor.main,
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        '& .MuiTableCell-head': {
                            fontWeight: 'bold',
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    message: {
                        color: 'white',
                    },
                    standard: {
                        backgroundColor: primaryColor.main,
                    },
                    standardError: {
                        backgroundColor: THEME_ERROR_COLORS.main,
                    },
                    filledError: {
                        backgroundColor: THEME_ERROR_COLORS.main,
                    },
                    outlinedError: {
                        backgroundColor: 'rgba(245, 114, 78, 0.8)',
                        borderColor: THEME_ERROR_COLORS.dark,
                    },
                    standardInfo: {
                        backgroundColor: secondaryColor.main,
                    },
                    filledInfo: {
                        backgroundColor: secondaryColor.main,
                    },
                    outlinedInfo: {
                        backgroundColor: secondaryColor.light,
                        borderColor: secondaryColor.dark,
                    },
                    icon: {
                        color: 'white !important',
                        '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                        },
                    },
                    root: {
                        '& .MuiAlert-action .MuiButtonBase-root': {
                            color: `white !important`,
                            '& .MuiSvgIcon-root': {
                                fontSize: '22px !important',
                            },
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    '.MuiPaper-root.MuiAlert-root': {
                        backgroundColor: primaryColor.main,
                        '&.MuiAlert-standardError': {
                            backgroundColor: THEME_ERROR_COLORS.main,
                        },
                        '&.MuiAlert-standardWarning': {
                            backgroundColor: `${colors.amber['500']} !important`,
                        },
                    },
                    '& .MuiAlert-icon, .MuiAlert-message': {
                        color: 'white !important',
                    },
                    '& .MuiAlert-action .MuiButtonBase-root': {
                        color: 'white !important',
                    },
                },
            },
            ...components,
        },
    });
}
export const defaultMUITheme = createConversifiTheme();
