import { useEffect } from 'react';
import { injectScript } from '../util/dom';
import { useAsyncController } from './useAsyncController';
const CLOUDINARY_WIDGET_SOURCE = 'https://widget.cloudinary.com/v2.0/global/all.js';
const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_UPLOAD_PRESET, REACT_APP_APP_ENV, } = process.env;
export const useCloudinaryWidget = (props) => {
    const { userId, language = 'EN', context, onUploadSuccess, onUploadError, } = props;
    const ctrl = useAsyncController();
    useEffect(() => {
        if (!window.cloudinary && ctrl.isIdle) {
            ctrl.setPending();
            injectScript(CLOUDINARY_WIDGET_SOURCE)
                .then(() => {
                const tags = [
                    'profilePicture',
                    `env:${REACT_APP_APP_ENV}`,
                    `context:${context}`,
                ];
                if (userId) {
                    tags.push(`userId:${userId}:${REACT_APP_APP_ENV}`);
                }
                ctrl.setResolved(window.cloudinary.createUploadWidget({
                    cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
                    uploadPreset: REACT_APP_CLOUDINARY_UPLOAD_PRESET,
                    folder: REACT_APP_APP_ENV,
                    sources: ['local', 'camera', 'url', 'facebook', 'instagram'],
                    cropping: true,
                    croppingAspectRatio: 1,
                    croppingShowDimensions: true,
                    croppingCoordinatesMode: 'custom',
                    multiple: false,
                    context: 'photo=',
                    maxImageWidth: 300,
                    maxImageHeight: 300,
                    tags,
                    resourceType: 'image',
                    clientAllowedFormats: ['png', 'gif', 'jpeg'],
                    language,
                    text: {},
                }, async (error, result) => {
                    if (error && onUploadError) {
                        onUploadError(error);
                    }
                    if (!error && result && result.event === 'success') {
                        const { public_id } = result.info;
                        onUploadSuccess(public_id);
                    }
                }));
            })
                .catch(ctrl.setRejected);
        }
    }, [ctrl]);
    return ctrl;
};
