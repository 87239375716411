import { requestUserHandleExists } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'
import { ServerErrorInfo } from '../../util/util.axios'

export const userHandleExists = async (handle: string): Promise<boolean> => {
  try {
    const response = await requestUserHandleExists(handle)
    if (response.status === 204) {
      return true
    } else {
      return false
    }
  } catch (err) {
    const error = apiErrorHandler(err)

    const serverError = error as ServerErrorInfo

    if (serverError.responseStatus === 404) {
      return false
    }

    console.error('[UHE22] userHandleExists error', error)

    return false
  }
}
