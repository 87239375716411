import { OTHER_LANG, SUPPORTED_LANGUAGE_CODES, } from 'conversifi-commons/constants';
import { addKeyValueTagFactory } from './addKeyValueTagFactory';
const NO_LANG = OTHER_LANG.langCode;
export const nativeLang = addKeyValueTagFactory({
    key: 'NL',
    tagsToRemove: [
        ...SUPPORTED_LANGUAGE_CODES,
        NO_LANG,
        'X',
    ],
    defaultValue: NO_LANG,
});
