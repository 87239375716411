import React, { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LSProps {
  /**
   * CSSProperties top
   * @default undefined
   */
  top?: CSSProperties['top'];
  /**
   * A style object that will be applied to the primereact
   * ProgressSpinner container
   */
  style?: CSSProperties;
}

/**
 * AbsoluteSpinner is basically a wrapper around the primereact
 * ProgressSpinner component with css `position: absolute`.
 * By default it will render horizontally centered in relation
 * to the width of his parent component **if the parent component
 * have his position css property set to relative**.
 */
export const AbsoluteSpinner: React.FC<LSProps> = ({ style = {}, top }) => {
  const css: CSSProperties = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    ...style,
  };

  if (top) {
    css.top = top;
  }

  return (
    <div style={css}>
      <CircularProgress />
    </div>
  );
};
