import { requestSkipPhoneNumberValidation } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'

/**
 * skip phone validation
 */
export async function userSkipPhoneValidation(): Promise<any> {
  try {
    const data = await requestSkipPhoneNumberValidation()
    return data
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
