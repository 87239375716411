import BaseProvider from '../../BaseProvider';
import socketCreate from './partials/connect/socketCreate';
import { SocketSdk } from '../../sdk';
import { SocketError } from './types/SocketError';
import ioOnReconnect from './partials/io/onReconnect';
import ioOnReconnectAttempt from './partials/io/onReconnectAttempt';
import ioOnReconnectError from './partials/io/onReconnectError';
import ioOnReconnectFailed from './partials/io/onReconnectFailed';
import refreshSessionExpirationDate from '../../util/session/refreshSessionExpirationDate';
import { DISCONNECT_ERROR_REASON } from './constants/SocketDisconnectCodes';
export class SocketContext extends BaseProvider {
    _socketInstance = null;
    _errorListeners = [];
    _connectListeners = [];
    _reconnectListeners = [];
    _disconnectListeners = [];
    get socket() {
        return this._socketInstance;
    }
    get id() {
        return this._socketInstance?.id;
    }
    getInitialState() {
        return {
            status: 'NOT_CREATED',
            reconnectionsAttempts: 0,
            reconnectionFailed: false,
            hasConnectedOnce: false,
        };
    }
    getPublicApi() {
        return this;
    }
    constructor(props) {
        super(props);
        this.bindAll();
        if (props.onConnect) {
            this.onStateEvent('connect', props.onConnect);
        }
        if (props.onDisconnect) {
            this.onStateEvent('disconnect', props.onDisconnect);
        }
        if (props.onError) {
            this.onStateEvent('connect_error', props.onError);
        }
    }
    componentDidUpdate(_prevProps, prevState) {
        if (this.props.onStatusUpdated && prevState.status !== this.state.status) {
            this.props.onStatusUpdated(this.state.status);
        }
        if (this.state.status === 'FAILED') {
            this.close();
        }
    }
    onComponentWillUnmount() {
        this.close();
    }
    ioOnReconnect = ioOnReconnect;
    ioOnReconnectAttempt = ioOnReconnectAttempt;
    ioOnReconnectError = ioOnReconnectError;
    ioOnReconnectFailed = ioOnReconnectFailed;
    connect() {
        const errorHandler = (eventName) => (error) => {
            if (SocketSdk.isExpectedDisconnectReasons(error.code)) {
                this.logger.debug(`Error ${eventName} with expected disconnection reason:`, error);
            }
            else {
                this.logger.error(`[SC107] Error ${eventName} in WS:`, error);
            }
            const err = new SocketError(error);
            Promise.all(this._errorListeners.map(async (handler) => await handler.apply(this, [err])));
        };
        return new Promise((resolve, reject) => {
            if (this._socketInstance && this.isConnected()) {
                throw new Error('[SC77] SocketInstance already defined!');
            }
            this.produce((draft) => {
                draft.status = 'CONNECTING';
            });
            this._socketInstance = socketCreate({
                clientUuid: this.props.clientUuid,
                uri: this.props.uri,
                path: this.props.path,
            });
            this._socketInstance.io.on('reconnect', this.ioOnReconnect);
            this._socketInstance.io.on('reconnect_attempt', this.ioOnReconnectAttempt);
            this._socketInstance.io.on('reconnect_error', this.ioOnReconnectError);
            this._socketInstance.io.on('reconnect_failed', this.ioOnReconnectFailed);
            this._socketInstance.on('connect_error', errorHandler('connect_error'));
            this._socketInstance.on('error', errorHandler('error'));
            this._socketInstance.on('disconnect', (reason) => {
                this.logger.info('[SCP195] Disconnected:', reason);
                const status = Object.values(DISCONNECT_ERROR_REASON)
                    .filter((v) => v !== DISCONNECT_ERROR_REASON.IO_CLIENT_DISCONNECT)
                    .includes(reason)
                    ? 'CONNECTING'
                    : 'CLOSED';
                this.logger.info('status:', status);
                this.produce((draft) => {
                    draft.status = status;
                    draft.disconnectReason = reason;
                });
                if (!SocketSdk.isExpectedDisconnectReasons(reason)) {
                    this.logger.warn('[SC199] unknown socket disconnection reason:', reason);
                }
                Promise.all(this._disconnectListeners.map(async (handler) => await handler.apply(this, [undefined])))
                    .then(() => resolve())
                    .catch((err) => reject(err));
            });
            this._socketInstance.on('connect', () => {
                this.logger.info('on Connect');
                const socket = this._socketInstance;
                if (socket) {
                    socket.on('SESSION_EXTENDED', () => {
                        refreshSessionExpirationDate();
                    });
                    this.logger.debug('WS connected. Socket.id:', socket.id);
                    if (this.state.status !== 'CONNECTED') {
                        this.produce((draft) => {
                            if (!this.state.hasConnectedOnce)
                                this.setHasConnectedOnce();
                            draft.status = 'CONNECTED';
                            draft.disconnectReason = undefined;
                        });
                    }
                    Promise.all(this._connectListeners.map(async (handler) => await handler.apply(this, [undefined])))
                        .then(() => resolve())
                        .catch((err) => reject(err));
                }
                else {
                    this.logger.warn('[CS151] WS connection lost');
                    const error = new Error('socket-connect-lost');
                    this.produce((draft) => {
                        draft.status = 'FAILED';
                    });
                    reject(error);
                }
            });
        });
    }
    isConnected() {
        return this.state.status === 'CONNECTED';
    }
    isConnecting() {
        return this.state.status === 'CONNECTING';
    }
    isClosed() {
        return this.state.status === 'CLOSED';
    }
    isFailed() {
        return this.state.status === 'FAILED';
    }
    close() {
        return new Promise((resolve) => {
            this.clearTimeoutReferences();
            this._reconnectListeners = [];
            this._connectListeners = [];
            this._disconnectListeners = [];
            this._errorListeners = [];
            this.logger.debug('Closing socket');
            if (this._socketInstance) {
                try {
                    this._socketInstance.disconnect();
                }
                catch (err) {
                    this.logger.info('[SCP253] Error disconnecting socket', { err });
                }
            }
            else {
                this.logger.debug('Already closed');
                resolve();
            }
        });
    }
    emit(event, params) {
        this._logEventSent(event, params);
        this._ensureSocketReady();
        this._socketInstance?.emit(event, params);
    }
    on(event, listener) {
        this.logger.debug('subscribing to event:', event);
        this._ensureSocketReady();
        this._socketInstance?.on(event, (...data) => {
            this._logEventReceived(event, data);
            listener(Array.isArray(data) && data.length > 1 ? data : data[0]);
        });
    }
    off(event, listener) {
        if (listener) {
            this.logger.debug(`unsubscribing from event:`, {
                event,
                eventHandler: listener,
            });
            this._socketInstance?.off(event, listener);
        }
        else {
            this.logger.debug('unsubscribing all from event:', event);
            this._socketInstance?.off(event);
        }
    }
    once(event, listener) {
        this.logger.debug('subscribing once to event:', event);
        this._ensureSocketReady();
        this._socketInstance?.on(event, (...data) => {
            this._logEventReceived(event, data);
            listener(Array.isArray(data) && data.length > 1 ? data : data[0]);
        });
    }
    onStateEvent(event, listener) {
        const addListener = (src) => {
            if (src.includes(listener)) {
                const index = src.indexOf(listener);
                this.logger.debug({ listener });
                this.logger.error(`[SE22] Warning! Try to register event[${event}] listener again (#${index})`, new Error().stack);
            }
            else {
                src.push(listener);
            }
        };
        switch (event) {
            case 'connect':
                addListener(this._connectListeners);
                break;
            case 'disconnect':
                addListener(this._disconnectListeners);
                break;
            case 'connect_error':
                addListener(this._errorListeners);
                break;
            case 'error':
                addListener(this._errorListeners);
                break;
            default:
                throw new SocketError(`[CE27] Invalid event "${event}"`);
        }
    }
    onceStateEvent(event, listener) {
        const onceListener = () => {
            this.offStateEvent(onceListener, event);
            this.offStateEvent(listener, event);
        };
        this.onStateEvent(event, listener);
        this.onStateEvent(event, onceListener);
    }
    offStateEvent(listener, event) {
        const removeListener = (src) => {
            const index = src.indexOf(listener);
            if (index > -1) {
                src.splice(index, 1);
            }
        };
        if (event) {
            switch (event) {
                case 'connect':
                    removeListener(this._connectListeners);
                    break;
                case 'disconnect':
                    removeListener(this._disconnectListeners);
                    break;
                case 'error':
                    removeListener(this._errorListeners);
                    break;
                default:
                    throw new Error(`[CE60] Invalid event "${event}"`);
            }
        }
        else {
            removeListener(this._connectListeners);
            removeListener(this._disconnectListeners);
            removeListener(this._errorListeners);
        }
    }
    _logEventSent(event, data) {
        this.logger.debug('==>', event, data);
    }
    _logEventReceived(event, data, ackFn) {
        this.logger.debug('<==', event, data, ackFn ? 'with ackFn' : 'without ackFn');
    }
    _logEventResponse(event, data) {
        this._logEventReceived(`[response:${event}]`, data);
    }
    _ensureSocketReady() {
        if (!this.isConnected()) {
            throw new SocketError('[SC176] Socket not connected!');
        }
    }
    setHasConnectedOnce() {
        this.produce((draft) => {
            draft.hasConnectedOnce = true;
        });
    }
    attachListenerOnConnect(listener) {
        this.logger.info('Attaching onConnect listener');
        if (this._connectListeners.includes(listener)) {
            const index = this._connectListeners.indexOf(listener);
            this.logger.debug({ listener });
            this.logger.error(`[SE22] Warning! Try to register event['onConnect'] listener again (#${index})`, new Error().stack);
        }
        else {
            this._connectListeners.push(listener);
            if (this.state.status === 'CONNECTED')
                listener();
        }
    }
    detachListenerOnConnect(listener) {
        this.logger.info('Detaching onConnect listener');
        if (this._connectListeners.includes(listener)) {
            const index = this._connectListeners.indexOf(listener);
            if (index > -1) {
                this._connectListeners.splice(index, 1);
            }
        }
        else {
            this.logger.error('Impossible to detach onConnect listener, not found');
        }
    }
    attachListenerOnDisconnect(listener) {
        this.logger.info('Attaching onDisconnect listener');
        if (this._disconnectListeners.includes(listener)) {
            const index = this._disconnectListeners.indexOf(listener);
            this.logger.debug({ listener });
            this.logger.error(`[SE22] Warning! Try to register event['onDisconnect'] listener again (#${index})`, new Error().stack);
        }
        else {
            this._disconnectListeners.push(listener);
        }
    }
    detachListenerOnDisconnect(listener) {
        this.logger.info('Detaching onDisconnect listener');
        if (this._disconnectListeners.includes(listener)) {
            const index = this._disconnectListeners.indexOf(listener);
            if (index > -1) {
                this._disconnectListeners.splice(index, 1);
            }
        }
        else {
            this.logger.error('Impossible to detach onDisconnect listener, not found');
        }
    }
    attachListenerOnReconnect(listener) {
        this.logger.info('Attaching onReconnect listener');
        if (this._reconnectListeners.includes(listener)) {
            const index = this._reconnectListeners.indexOf(listener);
            this.logger.debug({ listener });
            this.logger.error(`[SE22] Warning! Try to register event['onReconnect'] listener again (#${index})`, new Error().stack);
        }
        else {
            this._reconnectListeners.push(listener);
        }
    }
    detachListenerOnReconnect(listener) {
        this.logger.info('Detaching onReconnect listener');
        if (this._reconnectListeners.includes(listener)) {
            const index = this._reconnectListeners.indexOf(listener);
            if (index > -1) {
                this._reconnectListeners.splice(index, 1);
            }
        }
        else {
            this.logger.error('Impossible to detach onReconnect listener, not found');
        }
    }
}
