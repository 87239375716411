import { JWT_TOKEN_NAME, SESSION_KEY_IN_CALL, SESSION_KEY_SUBSCRIPTION_FEE_STATUS, SESSION_EXPIRATION_DATE, LAST_CHECKED_CALL_ID, LAST_LEDGER_DATA, LAST_PAYMENT_TIMESTAMP, LAST_SEARCH_CONFIGURATION, } from '../../constants';
export const SessionKeys = [
    JWT_TOKEN_NAME,
    SESSION_KEY_SUBSCRIPTION_FEE_STATUS,
    LAST_SEARCH_CONFIGURATION,
    SESSION_KEY_IN_CALL,
    LAST_LEDGER_DATA,
    LAST_PAYMENT_TIMESTAMP,
    LAST_CHECKED_CALL_ID,
    SESSION_EXPIRATION_DATE,
];
