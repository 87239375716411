import { ConversifiSocketBackendEventsData } from 'conversifi-types/src/Socket/types';
import ActiveSearchesContext from '../index';

export default function (
  this: ActiveSearchesContext,
  data: ConversifiSocketBackendEventsData<'LIVE_ALERTS_UPDATED'>
) {
  if (data.length) {
    this.produce((draft) => {
      draft.alerts = data;
    });
    this.interpretateAlerts();
  }
}
