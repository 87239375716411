import React from 'react';
import { Trans } from '@lingui/react';
import { BABBEL_HELP_URL, HELP_URL, PRIVACY_POLICY_URL, TERMS_OF_USER_URL, } from '../../../constants';
const createInitialState = (props) => {
    return {
        enabled: true,
        open: false,
        side: props.side ?? 'left',
        items: props.items,
        user: props.user,
        secondaryItems: [
            {
                id: 'terms-of-use',
                text: React.createElement(Trans, { id: "sidebar.menu-item.terms-of-use" }, "Terms of use"),
                onClick: () => {
                    window.open(TERMS_OF_USER_URL, '_blank');
                },
            },
            {
                id: 'privacity-policy',
                text: (React.createElement(Trans, { id: "sidebar.menu-item.privacy-policy" }, "Privacy Policy")),
                onClick: () => {
                    window.open(PRIVACY_POLICY_URL, '_blank');
                },
            },
            {
                id: 'help',
                text: React.createElement(Trans, { id: "sidebar.menu-item.help" }, "Help"),
                onClick: () => {
                    window.open(props.user?.source === 'babbel' ? BABBEL_HELP_URL : HELP_URL, '_blank');
                },
            },
        ],
    };
};
export default createInitialState;
