import { addKeyValueTagFactory } from './addKeyValueTagFactory';
import { getTermShortTag } from 'conversifi-commons/helpers/termTagHelper';
export const setTerm = addKeyValueTagFactory({
    key: 'Term',
    separator: ': ',
    parser(section) {
        if (Array.isArray(section)) {
            section = section[0];
        }
        if (section?.term?.type && section.term.start) {
            return getTermShortTag(section.term.type, section.term.start);
        }
        else if (section?.termId) {
            return section.termId;
        }
        return '?';
    },
});
