export const getCurrentDomain = (attachPath = '') => {
    return `${window.location.protocol}//${window.location.host}${attachPath}`;
};
export function redirectToProdInCDNHost() {
    const cdnURLs = [
        'cdn-qa.conversifi.com',
        'cdn-uat.conversifi.com',
        'cdn.conversifi.com',
    ];
    if (cdnURLs.includes(window.location.host)) {
        window.location.replace('https://app.conversifi.com');
    }
}
