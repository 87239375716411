import React, { FC, useState, useEffect } from 'react';
import { CsvParserContext, useCsvParser } from './hooks/useCsvParser';
import { GooglePlacesContext } from './contexts';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { useGoogle } from './util';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as locales from 'date-fns/locale';
import { SnackbarLingui } from './components/SnackbarLingui/index';
import { Dialog } from './components/Dialog';
// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language';
import { User } from 'conversifi-types/src/User/User';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import { DEFAULT_LANG } from 'conversifi-commons/constants';
import LinguiContext from './contexts/LinguiContext';
import { LangCode } from 'conversifi-types/src/Lang';

const browserLang: LangCode =
  (String(detectBrowserLanguage()).substr(0, 2) as LangCode) ||
  DEFAULT_LANG.langCode;

interface Props {
  user?: User;
  detectedUserSource?: UserSource;
}

export const AppContexts: FC<Props> = ({
  user,
  detectedUserSource = 'conversifi',
  children,
}) => {
  const [language, setLanguage] = useState<LangCode>(browserLang);

  useEffect(() => {
    if (user) {
      setLanguage(user.language?.code ?? browserLang);
    }
  }, [user]);

  // configure google api library to initialize the
  // provider of our react context `GooglePlacesContext`.
  // The google library will not be loaded until some
  // component call the `ensureGoogleLibrary()` function.
  // the `ensureGoogleLibrary` can be called multiple times
  // but it will load the google library just once
  const [isLoading, google, ensureGoogleLibrary] = useGoogle();
  const googleContextValue = {
    isLoading,
    google,
    ensureGoogleLibrary,
  };

  return (
    <LanguagesContext>
      <LinguiContext userLang={language} userSource={detectedUserSource}>
        <CsvParserContext.Provider value={useCsvParser()}>
          <CountriesContext>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locales[language as keyof typeof locales]}
            >
              <GooglePlacesContext.Provider value={googleContextValue}>
                <LinearProgressContext>
                  <SnackbarLingui>
                    <Dialog>{children}</Dialog>
                  </SnackbarLingui>
                </LinearProgressContext>
              </GooglePlacesContext.Provider>
            </LocalizationProvider>
          </CountriesContext>
        </CsvParserContext.Provider>
      </LinguiContext>
    </LanguagesContext>
  );
};
