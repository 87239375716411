import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { readError } from '../../util';
export const PublicContext = React.createContext({});
export class DialogContext extends BaseProvider {
    className = 'MaterialDialogContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    getInitialState() {
        return {
            data: undefined,
            opened: false,
            displayDialog: false,
            closeOnBackdrop: true,
            disableEscapeKeyDown: false,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            setMessage: this.setMessage,
            closeDialog: this.closeDialog,
            showDialogTranslatedErrorMessage: this.showDialogTranslatedErrorMessage,
            renderFC: this.renderFC,
        };
    }
    setConfig(draft, config) {
        [
            'disableEscapeKeyDown',
            'closeOnBackdrop',
            'onClickAnywhere',
            'maxWidth',
            'onCloseDialog',
            'dialogProps',
        ].forEach((key) => {
            if (typeof config[key] !== 'undefined') {
                draft[key] = config[key];
            }
        });
    }
    setMessage(data, config) {
        this.produce((draft) => {
            draft.data = data;
            draft.opened = true;
            draft.fcData = undefined;
            draft.displayDialog = true;
            if (config) {
                this.setConfig(draft, config);
            }
        });
        if (data.withSound && this.props.ringer) {
            this.props.ringer.setSound(data.withSound);
            this.props.ringer.play();
        }
    }
    renderFC(fc, fcProps, config) {
        this.produce((draft) => {
            draft.fcData = {
                fc,
                fcProps,
            };
            draft.data = undefined;
            draft.opened = true;
            draft.displayDialog = true;
            if (config) {
                this.setConfig(draft, config);
            }
        });
    }
    showDialogTranslatedErrorMessage(dialogErrorMessage) {
        const { err, defaultTranslationKey, actions, messageStyle, defaultAction } = dialogErrorMessage;
        const { data } = readError(err);
        let translationKey = (data?.translationKey || data?.meta?.translationKey) ??
            defaultTranslationKey;
        const detailsTranslationKey = data?.detailsTranslationKey || data?.meta?.detailsTranslationKey;
        let transInterpolationValue = data?.transInterpolationValue || data?.meta?.transInterpolationValue;
        if (transInterpolationValue) {
            transInterpolationValue = this.props.trans(transInterpolationValue);
        }
        const title = detailsTranslationKey
            ? this.props.trans({ id: translationKey })
            : 'Error';
        const message = detailsTranslationKey ? (React.createElement(Box, { sx: messageStyle ?? { mx: 3 } }, this.props.trans({
            id: detailsTranslationKey,
            values: { transInterpolationValue },
        }))) : (React.createElement(Box, { my: 3 }, this.props.trans({
            id: translationKey,
            values: { transInterpolationValue },
        })));
        const dialogData = {
            title,
            message,
            actions,
            defaultAction,
        };
        this.produce((draft) => {
            draft.data = dialogData;
            draft.opened = true;
        });
    }
    closeDialog = () => {
        if (this.props.ringer?.isRinging) {
            this.props.ringer?.stop();
        }
        this.state.onCloseDialog?.();
        this.produce((draft) => {
            draft.data = undefined;
            draft.opened = false;
            draft.fcData = undefined;
            draft.displayDialog = false;
        });
    };
    onCloseDialog(reason) {
        if (reason === 'backdropClick' && this.state.closeOnBackdrop) {
            this.closeDialog();
        }
        if (reason === 'escapeKeyDown' && !this.state.disableEscapeKeyDown) {
            this.closeDialog();
        }
    }
    DefaultButtonAction() {
        return (React.createElement(Box, { textAlign: "right", flex: 1 },
            React.createElement(Button, { onClick: () => {
                    this.state.data?.defaultAction?.();
                    this.closeDialog();
                }, color: "primary" }, this.props.trans({
                id: 'commons.dialog.ok',
                message: 'Ok',
            }))));
    }
    render() {
        const dialogProps = {
            ...this.state.dialogProps,
        };
        let backdropProps = {
            sx: { zIndex: 0 },
        };
        if (this.state.dialogProps?.BackdropProps) {
            backdropProps = {
                ...this.state.dialogProps.BackdropProps,
                sx: { zIndex: 0 },
            };
        }
        if (this.state.maxWidth) {
            if (this.state.maxWidth === 'fullWidth') {
                dialogProps.fullWidth = true;
            }
            else {
                dialogProps.maxWidth = this.state.maxWidth;
            }
        }
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() },
            this.state.opened && (this.state.data || this.state.fcData) && (React.createElement(Dialog, { ...dialogProps, BackdropProps: backdropProps, onClose: (_, reason) => this.onCloseDialog(reason), open: Boolean(this.state.displayDialog), disableEscapeKeyDown: this.state.disableEscapeKeyDown ?? false, onClick: () => this.state.onClickAnywhere?.() },
                this.state.fcData && (React.createElement(this.state.fcData.fc, { ...this.state.fcData?.fcProps })),
                this.state.data && (React.createElement(React.Fragment, null,
                    this.state.data.title && (React.createElement(DialogTitle, null, this.state.data.title)),
                    React.createElement(DialogContent, { style: { position: 'relative' } },
                        this.state.data.message,
                        this.state.data.errorCode && (React.createElement("small", null, this.state.data.errorCode))),
                    !Boolean(this.state.data.noActions) && (React.createElement(DialogActions, null, this.state.data.actions || this.DefaultButtonAction())))))),
            this.props.children));
    }
}
