import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { Typography, Alert, AlertTitle, } from '@mui/material';
import { readError } from '../../util';
import { SnackbarProvider, useSnackbar } from 'notistack';
export const PublicContext = React.createContext({});
export class SnackbarContext extends BaseProvider {
    className = 'SnackbarContext';
    PublicContext = PublicContext;
    MAX_SNACK_MESSAGES = 4;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    getInitialState() {
        return {
            data: undefined,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            setMessage: this.setMessage,
            showSnackbarTranslatedErrorMessage: this.showSnackbarTranslatedErrorMessage,
            closeSnackbar: this.closeSnackbar,
        };
    }
    setMessage(data) {
        this.produce((draft) => {
            draft.data = data;
        });
        if (data.withSound && this.props.ringer) {
            this.props.ringer.setSound(data.withSound);
            this.props.ringer.play();
        }
    }
    showSnackbarTranslatedErrorMessage(err, defaultTranslationKey) {
        const { data } = readError(err);
        let translationKey = (data?.translationKey || data?.meta?.translationKey) ??
            defaultTranslationKey;
        const detailsTranslationKey = data?.detailsTranslationKey || data?.meta?.detailsTranslationKey;
        let transInterpolationValue = data?.transInterpolationValue || data?.meta?.transInterpolationValue;
        if (transInterpolationValue) {
            transInterpolationValue = this.props.trans(transInterpolationValue);
        }
        const title = detailsTranslationKey
            ? this.props.trans({ id: translationKey })
            : undefined;
        const message = detailsTranslationKey
            ? this.props.trans({
                id: detailsTranslationKey,
                values: { transInterpolationValue },
            })
            : this.props.trans({
                id: translationKey,
                values: { transInterpolationValue },
            });
        const snackbarData = {
            severity: 'error',
            title,
            message,
        };
        this.produce((draft) => {
            draft.data = snackbarData;
        });
    }
    closeSnackbar() {
        this.state.data?.onClose?.();
        this.produce((draft) => {
            draft.data = undefined;
        });
    }
    SnackbarComponent = ({ data }) => {
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        React.useEffect(() => {
            if (data) {
                enqueueSnackbar(data.message, {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: data.autoHideDuration ?? 8000,
                    preventDuplicate: true,
                    content: (key) => {
                        return (React.createElement(Alert, { severity: data.severity, style: { maxWidth: '400px' }, onClose: () => closeSnackbar(key), action: data.noAction ? React.createElement(React.Fragment, null) : data.action },
                            data.title && React.createElement(AlertTitle, null, data.title),
                            React.createElement(Typography, null, data.message)));
                    },
                });
            }
            else {
                closeSnackbar();
            }
        }, [data]);
        return React.createElement(React.Fragment, null);
    };
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() },
            React.createElement(SnackbarProvider, { maxSnack: this.MAX_SNACK_MESSAGES, classes: { anchorOriginTopCenter: '70px !important' } },
                React.createElement(this.SnackbarComponent, { data: this.state.data }),
                this.props.children)));
    }
}
