import { Select, styled } from '@mui/material';

const InpuTSelect = styled(Select)`
  &.MuiInputLabel-root {
    color: white;
  }

  &.MuiInputBase-root {
    max-height: 56px;
    & fieldset {
      border-color: white;
    }

    &:hover fieldset {
      border-color: white;
    }

    &.Mui-focused fieldset {
      border-color: white;
    }

    &.MuiOutlinedInput-root {
      color: white;

      & svg {
        color: white;
      }
    }
  }
`;

export default InpuTSelect;
