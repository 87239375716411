import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import countryData from 'conversifi-commons/data/countryInfo.json';
import { getCountriesInfo } from 'conversifi-commons/helpers/parseCountryInfo';
import { SUPPORTED_LANGUAGE_CODES } from 'conversifi-commons/constants';
import 'flag-icons/css/flag-icons.min.css';
import { MenuItem, TextField, ListSubheader, } from '@mui/material';
import { Trans } from '@lingui/react';
import isoCountries from 'i18n-iso-countries';
export const PublicContext = React.createContext({});
function sortAlphabetically(a, b) {
    return a.name.localeCompare(b.name);
}
const TOP_HIT_COUNTRIES = ['US', 'MX', 'CA', 'ES', 'AR', 'CO'];
export class CountriesContext extends BaseProvider {
    className = 'CountriesContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
        SUPPORTED_LANGUAGE_CODES.forEach((code) => {
            const langCode = code === 'asl' ? 'en' : code.toLowerCase();
            isoCountries.registerLocale(require(`i18n-iso-countries/langs/${langCode}.json`));
        });
    }
    getInitialState() {
        const countries = getCountriesInfo(countryData, SUPPORTED_LANGUAGE_CODES);
        const topHitCountries = countries.filter((country) => TOP_HIT_COUNTRIES.includes(country.alpha2Code));
        const countriesWithoutTopHit = countries
            .filter((country) => !TOP_HIT_COUNTRIES.includes(country.alpha2Code))
            .sort(sortAlphabetically);
        return {
            countries: countries.sort(sortAlphabetically),
            topHitCountries,
            countriesWithoutTopHit,
            isoCountries,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            getCountries: this.getCountries,
            createFlagSpanElement: this.createFlagSpanElement,
        };
    }
    getCountries(topHitFirst = true) {
        return topHitFirst
            ? [
                ...this.state.topHitCountries,
                ...this.state.countriesWithoutTopHit.sort(sortAlphabetically),
            ]
            : this.state.countries.sort(sortAlphabetically);
    }
    createFlagSpanElement(countryCode) {
        if (countryCode === 'asl') {
            return (React.createElement("img", { src: `${process.env.PUBLIC_URL}/assets/deaf-flag.jpeg`, alt: "deaf-flag-community", style: {
                    position: 'relative',
                    display: 'inline-block',
                    width: '1.333333em',
                    lineHeight: '1em',
                } }));
        }
        else {
            const flagClassname = `fi fi-${countryCode.toLowerCase()}`;
            return React.createElement("span", { className: flagClassname });
        }
    }
    static CountriesSelector = ({ showSubheader = true, userLang, onChange, ...rest }) => {
        const ctx = CountriesContext.useCtx();
        const memo = React.useMemo(() => {
            const lang = userLang === 'asl' ? 'en' : userLang;
            return (React.createElement(TextField, { select: true, onChange: (e) => onChange?.(e.target.value), ...rest },
                showSubheader && (React.createElement(ListSubheader, null,
                    React.createElement(Trans, { id: "countries.most-frequent.title" }, "Most frequent countries"))),
                ctx.state.topHitCountries.map(({ alpha2Code }, index) => {
                    return (React.createElement(MenuItem, { key: `${alpha2Code}-${index}`, value: alpha2Code }, isoCountries.getName(alpha2Code.toLowerCase(), lang)));
                }),
                showSubheader && (React.createElement(ListSubheader, null,
                    React.createElement(Trans, { id: "countries.all-countries.title" }, "All countries"))),
                ctx.state.countriesWithoutTopHit.map(({ alpha2Code }, index) => (React.createElement(MenuItem, { key: `${alpha2Code} ${index}`, value: alpha2Code }, isoCountries.getName(alpha2Code, lang.toLocaleLowerCase()))))));
        }, [userLang, rest['value']]);
        return memo;
    };
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() }, this.props.children));
    }
}
