import React from 'react';
import { AdminLanguagesContext } from '../index';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { SUPPORTED_LANGUAGE_CODES } from 'conversifi-commons/constants';
import { getLanguageName } from 'conversifi-shared-react/es6/util/miscellaneous/languages';
import { LangCode } from 'conversifi-types/src/Lang';
import { Trans } from '@lingui/react';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

export default function (this: AdminLanguagesContext) {
  const updating = Boolean(this.state.language._id);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="space-between"
      mb={2}
    >
      <FormControl
        variant="outlined"
        sx={{ minWidth: 150, flex: 1, mx: '10px' }}
      >
        <InputLabel id="language-selector-label">
          <Trans id="admin.languages.language">Language</Trans>
        </InputLabel>
        <Select
          labelId="language-selector-label"
          id="language-selector"
          value={this.state.language.langCode || ''}
          onChange={(e) => {
            this.produce((draft) => {
              draft.language.langCode = e.target.value as LangCode;
            });
          }}
          label="Language"
          variant="outlined"
          disabled={updating}
        >
          {SUPPORTED_LANGUAGE_CODES.filter(
            (langCode) =>
              updating ||
              !this.props.languagesCtx
                .getEnabledLanguages()
                .map((lang) => lang.langCode)
                .includes(langCode)
          ).map((langCode) => {
            return (
              <MenuItem value={langCode}>
                ({langCode}) -{' '}
                {getLanguageName(langCode, this.props.user.language.code)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        sx={{ minWidth: 150, flex: 1.5, mx: '10px' }}
      >
        <InputLabel id="flag-icon-label">
          <Trans id="admin.languages.flag-icon">Flag icon</Trans>
        </InputLabel>
        <Select
          labelId="flag-icon-label"
          id="flag-icon-selector"
          value={this.state.language.flagIconCountryCode || ''}
          onChange={(e) => {
            if (e.target.value) {
              this.produce((draft) => {
                draft.language.flagIconCountryCode = e.target.value;
              });
            }
          }}
          label="Language"
          variant="outlined"
        >
          <MenuItem value="asl">
            {this.props.countriesCtx.createFlagSpanElement('asl')}
            {' - '}
            American Sign Language
          </MenuItem>
          {this.props.countriesCtx.getCountries().map((country) => {
            return (
              <MenuItem value={country.alpha2Code}>
                {this.props.countriesCtx.createFlagSpanElement(
                  country.alpha2Code
                )}
                - {country.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        label={
          <Trans id="admin.languages.youtube-coach-video-id">
            Youtube Coach Video ID
          </Trans>
        }
        sx={{ flex: 1.5, mx: '10px' }}
        variant="outlined"
        placeholder="For example: -5yZln43aoi"
        value={this.state.language.youtubeLink || ''}
        onChange={(e) => {
          this.produce((draft) => {
            draft.language.youtubeLink = e.target.value;
          });
        }}
      />
      <Box m="auto">
        <Button
          variant="contained"
          size="large"
          onClick={() => this.upsertLanguage()}
        >
          {updating ? (
            <Trans id="admin.languages.update">Update</Trans>
          ) : (
            <Trans id="admin.languages.save">Save</Trans>
          )}
        </Button>
        {updating && (
          <IconButton
            onClick={() => this.cleanLanguage()}
            sx={{
              ml: 1,
            }}
          >
            <CleaningServicesIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
