import React, { PureComponent } from 'react';
import { produce } from 'immer';
import { createLogger } from '../util';
class BaseProvider extends PureComponent {
    produce;
    _logger = null;
    get logger() {
        if (!this._logger) {
            this._logger = createLogger(this.className);
        }
        return this._logger;
    }
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.produce = (mutateFn) => {
            this.setState((prev) => produce(prev, (draft) => mutateFn(draft)));
        };
    }
    bindAll() {
        const methods = Object.getOwnPropertyNames(this);
        methods.push(...Object.getOwnPropertyNames(this.__proto__));
        methods
            .filter((item, pos) => methods.indexOf(item) === pos)
            .filter((p) => typeof this[p] === 'function')
            .forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });
    }
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() }, this.props.children));
    }
    componentWillUnmount() {
        this.clearTimeoutReferences();
        this.onComponentWillUnmount();
    }
    onComponentWillUnmount() {
    }
    _timeoutRef = [];
    clearTimeoutReferences() {
        if (this._timeoutRef.length) {
            this.stopTimeout(...this._timeoutRef);
        }
    }
    stopTimeout(...timeoutIds) {
        const refs = timeoutIds.filter((t) => t);
        for (const timeoutId of refs) {
            if (Array.isArray(timeoutId)) {
                if (timeoutId.length) {
                    this.stopTimeout(...timeoutId);
                }
            }
            else {
                clearTimeout(timeoutId);
                const index = this._timeoutRef.findIndex((timeout) => timeout === timeoutId);
                if (index !== -1) {
                    this._timeoutRef.splice(index);
                }
            }
        }
    }
    addTimeout(callback, time) {
        const timeoutRef = setTimeout(() => {
            this.stopTimeout(timeoutRef);
            callback();
        }, time);
        this._timeoutRef.push(timeoutRef);
        return timeoutRef;
    }
    addInterval(callback, time) {
        const intervalRev = setInterval(callback, time);
        this._timeoutRef.push(intervalRev);
        return intervalRev;
    }
}
export default BaseProvider;
