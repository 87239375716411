import { JWT_TOKEN_NAME, SESSION_KEY_SUBSCRIPTION_FEE_STATUS, } from '../../constants';
import saveLocalItem from './saveLocalItem';
export default (token, subscriptionFeeStatus) => {
    if (!token) {
        console.warn("[SL16] No session token provided. Can't save session.");
    }
    else {
        saveLocalItem(JWT_TOKEN_NAME, token);
    }
    if (subscriptionFeeStatus !== undefined) {
        saveLocalItem(SESSION_KEY_SUBSCRIPTION_FEE_STATUS, subscriptionFeeStatus);
    }
};
