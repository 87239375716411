import { apiErrorHandler } from '../apiErrorHandler';
import axios from 'axios';
import type { ResponseInsights } from 'conversifi-types/src/InsightsDashboard';

export const searchInsights = async (lastId?: string) => {
  try {
    const params = lastId ? { lastId } : {};
    const { data } = await axios.get<ResponseInsights>(`/api/admin/insights`, {
      params,
      timeout: 30 * 1000,
    });
    return data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
