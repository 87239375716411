import { LangCode } from 'conversifi-types';
import { AdminSdk } from '../../../../sdk/admin.sdk';
import { AdminLanguagesContext } from '../index';

export default async function (
  this: AdminLanguagesContext,
  langCode: LangCode
): Promise<void> {
  this.props.linearProgressCtx.showLinearProgressWithBackdrop();

  try {
    await AdminSdk.Languages.deleteLanguage(langCode);

    await this.props.languagesCtx.reloadLanguages();
  } catch (err) {
  } finally {
    this.props.linearProgressCtx.hideLinearProgressAndBackdrop();
  }
}
