import { userHandleExists } from '../../api/user/userHandleExists'

export const handleServerValidation = async (handle: string) => {
  console.debug('handle is valid. validate on the server...')
  try {
    const handleExists = await userHandleExists(handle)
    if (handleExists) {
      return 'This handle already exists. Please, try another one.'
    }
  } catch (err) {
    const msg = 'Error happend while try to register a new handle'
    console.error('[HSV12]', msg, err)
    return msg
  }
}
