import React from 'react';
import BaseProvider from '../../BaseProvider';
import { Drawer } from '@mui/material';
import Sidebar from './components/Sidebar';
import PublicContext from './types/PublicContext';
import setOpen from './methods/setOpen';
import setEnable from './methods/setEnable';
import toggleOpenSidebar from './methods/toggleOpenSidebar';
import toggleDrawer from './methods/toggleDrawer';
import setUser from './methods/setUser';
import setItems from './methods/setItems';
import createInitialState from './methods/createInitialState';
import setItemSelected from './methods/setItemSelected';
import filterSidebarItems from './methods/filterSidebarItems';
export default class SidebarContext extends BaseProvider {
    className = 'SidebarContext';
    PublicContext = PublicContext;
    customerServicePermissions = [
        'home',
        'insights',
        'users',
        'edit-users',
        'email-verification',
        'equilibrium',
        'pc-dashboard',
        'admin',
        'coupons',
        'stats',
        'reports',
        'active-calls',
        'active-searches',
        'schedule',
        'settings',
        'sockets',
        'coach-quizes',
        'banner',
    ];
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items ||
            prevProps.user !== this.props.user) {
            const { user } = this.props;
            if (user) {
                this.setUser(user);
            }
            this.filterSidebarItems();
        }
    }
    getInitialState() {
        return createInitialState(this.props);
    }
    getPublicApi() {
        return {
            state: this.state,
            setOpen: this.setOpen,
            setEnabled: this.setEnabled,
            toggleOpenSidebar: this.toggleOpenSidebar,
            toggleDrawer: this.toggleDrawer,
            setItems: this.setItems,
            setItemSelected: this.setItemSelected,
        };
    }
    setOpen = setOpen;
    setEnabled = setEnable;
    toggleOpenSidebar = toggleOpenSidebar;
    toggleDrawer = toggleDrawer;
    setUser = setUser;
    setItems = setItems;
    setItemSelected = setItemSelected;
    filterSidebarItems = filterSidebarItems;
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() },
            React.createElement(Drawer, { open: this.state.enabled && this.state.open, onClose: (e) => this.toggleDrawer(e, false), BackdropProps: { style: { backgroundColor: 'transparent' } }, anchor: this.state.side },
                React.createElement(Sidebar, null)),
            this.props.children));
    }
}
