import axios, { AxiosError } from 'axios';
import { UserUniversity } from 'conversifi-shared-react';
import { apiErrorHandler } from '../apiErrorHandler';

export async function getUserUniversity(): Promise<UserUniversity | null> {
  try {
    const { data } = await axios.get<UserUniversity>(`/api/user/university`);
    return data || null;
  } catch (err) {
    const axiosError = err as AxiosError;

    if (axiosError.response) {
      if (axiosError.response.status < 500) {
        console.info(
          '[GUU13]',
          axiosError.response.status,
          axiosError.response.statusText,
          axiosError.response.data
        );
      } else {
        console.warn(
          '[GUU20]',
          axiosError.response.status,
          axiosError.response.statusText,
          axiosError.response.data
        );
      }

      // @see https://speakapp.atlassian.net/browse/CONV-1845
      // cannot get university from users with no sections
      if (axiosError.response.status === 404) {
        return null;
      }
    }

    throw apiErrorHandler(err);
  }
}
