import { requestJsonUpload, RequestJsonUploadParams } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'
import { CsvFileType } from '../../util'

const validFileTypes: CsvFileType[] = [
  'universities',
  'departments',
  'faculties',
  'modules',
  'courses',
  'sections',
  'priceBundles'
]

export const uploadJson = async <T>(params: RequestJsonUploadParams<T>) => {
  if (!params.datatype || !validFileTypes.includes(params.datatype)) {
    throw apiErrorHandler(new Error('invalid file type'))
  }

  try {
    return await requestJsonUpload(params)
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
