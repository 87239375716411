import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Navbar.module.scss';
import { ReactComponent as Logo } from '../../assets/images/conversifi-logo.svg';
import { Routes } from '../../routes';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import WebSocketStatus from 'conversifi-shared-react/es6/components/WebSocketStatus';
import { useIsMobile } from 'conversifi-shared-react/es6/hooks/useCurrentMediaBreakpoint';
import { SocketContext } from '../context/SocketContext';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SidebarContext from 'conversifi-shared-react/es6/components/SidebarContext';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';

export const Navbar: FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const socketCtx = SocketContext.useCtx();
  const sidebarCtx = SidebarContext.useCtx();
  const trans = LinguiContext.useCtx().trans;

  const openSidebar = useCallback(() => {
    sidebarCtx.setOpen(true);
  }, [sidebarCtx]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton
            edge="start"
            onClick={openSidebar}
            sx={{ color: 'white' }}
          >
            <MenuRoundedIcon sx={{ color: 'white' }} />
          </IconButton>
          <Logo
            className={styles.logo}
            onClick={() => history.push(`${Routes.admin.uri}/insights`)}
          />
          {isMobile && <WebSocketStatus socketCtx={socketCtx} target="admin" />}
          {!isMobile && (
            <WebSocketStatus socketCtx={socketCtx} target="admin" />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
