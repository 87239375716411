import { useRef, useState, MutableRefObject } from 'react'
import update, { Spec } from 'immutability-helper'

type SetStateType<T> = (value: React.SetStateAction<T>) => void
export type UpdateWithPrevType<T> = (updates: Spec<T, never>) => T | null

function buildImmutableUpdateWithPrev<T>(
  setState: SetStateType<T>,
  prevRef: MutableRefObject<T | null>
) {
  return function updateWithPrev(updates: Spec<T>) {
    setState(prev => {
      // @ts-ignore
      const updated = update(prev, updates)
      prevRef.current = prev
      return updated
    })

    return prevRef.current
  }
}

function initImmutableState<T>(state: T) {
  return update<T>(state, { $set: state })
}

export function useImmutableState<T>(
  initState: T
): [T, UpdateWithPrevType<T>, T] {
  /**
   * ref to hold the previous state. The first time
   * we initialize it with the initial state.
   */
  const prevStateRef = useRef(initState)

  /**
   * ref to hold the immutable update function
   */
  const updateRef = useRef<UpdateWithPrevType<T> | null>(null)
  const [state, setState] = useState(initImmutableState(initState))

  /**
   * init the prevState ref. At first render will be the same state as the next
   */
  if (prevStateRef.current === null) {
    prevStateRef.current = state
  }

  if (!updateRef.current) {
    updateRef.current = buildImmutableUpdateWithPrev<T>(setState, prevStateRef)
  }

  return [state, updateRef.current, prevStateRef.current]
}
