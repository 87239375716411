import Session from './Session'

export class SDK {
  public session: Session
  constructor() {
    this.session = new Session()
  }
}

export default new SDK()
