import {
  RequestUnconfirmedRegistrationData,
  UserRegisterData,
} from 'conversifi-types/src/shared-frontend-backend/UserRegisterData';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import { User } from 'conversifi-types/src/User/User';
import axiosInstance from '../util/util.axios';
import { UserLoginResponse } from '../api/ResponseSchemas';
import { Section } from 'conversifi-types/src/Section';
import { CallConfig } from 'conversifi-types/src/CallConfig';

type SectionWithCallConfig = Section & {
  callConfig: CallConfig;
};

export class UserSdk {
  static async sendTemporaryPassword(email: string): Promise<any> {
    return axiosInstance.post('/auth/forgot-password', {
      email,
    });
  }

  static async userConfirmationCodeIsValid(code: string): Promise<UserSource> {
    const { data } = await axiosInstance.get<UserSource>(
      `/auth/confirmcode/${code}`
    );
    return data;
  }

  static async userLogin(
    email: string,
    password: string
  ): Promise<UserLoginResponse> {
    const { data } = await axiosInstance.post('/auth/login', {
      email,
      password,
    });
    return data;
  }

  static anonymous = {
    async userRegister(userData: UserRegisterData): Promise<User> {
      const { data } = await axiosInstance.post('/auth/register', userData);
      return data;
    },

    async getSectionByCode(code: string): Promise<SectionWithCallConfig> {
      const { data } = await axiosInstance.get(`/sections/code/${code}`);
      return data;
    },

    async requestUnconfirmedRegistration(
      unconfirmedRegistration: RequestUnconfirmedRegistrationData
    ): Promise<string> {
      const { data } = await axiosInstance.post<string>(
        '/register',
        unconfirmedRegistration
      );

      return data;
    },
  };
}
