import axios from 'axios';
import {
  ReportStatus,
  Summary,
} from 'conversifi-types/src/shared-frontend-backend/InstructorReport';
import { Awaited } from 'conversifi-types/src/Util';
import {
  InstructorReadTermsResponse,
  SectionsByTermResponse,
  StudentDetails,
} from './responses';
import { CallDetail } from 'conversifi-types/src/shared-frontend-backend/InstructorReport';
import { StudentSummary } from 'conversifi-types/src/shared-frontend-backend/StudentSummary';
import { ReadUniversityForInstructor } from 'conversifi-types/src/University';
import { ChatInformation } from 'conversifi-types/src/Call/Twilio';
import { ActiveUniversity } from 'conversifi-types/src/shared-frontend-backend/ActiveUniversity';
import AxiosInstance from 'conversifi-shared-react/es6/sdk/AxiosInstance';
import { StudentWithCalls } from 'conversifi-types/src/shared-frontend-backend/StudentsWithCalls';

export type GetSectionsStudents = Awaited<
  typeof Session.prototype.getSectionsStudents
>;

export type GetStudentCallDetails = Awaited<
  typeof Session.prototype.getStudentCallDetails
>;

export type GetTermsParams = {
  /**
   * include only active terms?
   */
  activeOnly?: boolean;
};

export default class Session {
  /**
   * @deprecated Not used as it was replaced by the callendar
   */
  async getSummary(session: string): Promise<Summary> {
    const { data } = await axios.get<any>(`/api/instructor/summary/${session}`);
    return data;
  }

  async getActiveTermStudents() {
    const { data } = await axios.get<ActiveUniversity>(
      `/api/instructor/students`
    );
    return data;
  }

  async getStudentDetails({
    termId,
    sectionId,
    studentId,
  }: {
    termId: string;
    sectionId: string;
    studentId: string;
  }) {
    const { data } = await axios.get<StudentDetails>(
      `/api/instructor/university/term/${termId}/section/${sectionId}/student/${studentId}/details`
    );
    return data;
  }

  async getStudentCallsBySection(
    sectionId: string
  ): Promise<StudentWithCalls[]> {
    const { data } = await AxiosInstance.getAxiosInstance().get<
      StudentWithCalls[]
    >('instructor/students/calls/section/' + sectionId);

    return data;
  }

  async getStudentsDetailsBySection({
    termId,
    sectionId,
  }: {
    termId: string;
    sectionId: string;
  }) {
    const { data } = await axios.get<StudentDetails[]>(
      `/api/instructor/students/term/${termId}/section/${sectionId}/details`
    );
    return data;
  }

  async enqueueSummaryDetailReport({
    termId,
    sectionId,
  }: {
    termId: string;
    sectionId: string;
  }) {
    const { data } = await axios.get<ReportStatus>(
      `/api/instructor/students/term/${termId}/section/${sectionId}/details`
    );
    return data;
  }

  async getStudentCallDetails({
    termId,
    sectionId,
    studentId,
    callIds,
  }: {
    termId: string;
    sectionId: string;
    studentId: string;
    callIds: string[];
  }) {
    const { data } = await axios.get<CallDetail[]>(
      `/api/instructor/university/term/${termId}/section/${sectionId}/student/${studentId}`,
      { params: { callIds } }
    );

    return data;
  }

  async getCallChat(callId: string) {
    const { data } = await axios.get<ChatInformation>(
      `/api/calls/${callId}/chat`
    );

    return data;
  }

  /**
   * Get instructor university data
   */
  async getUniversity() {
    const { data } = await axios.get<ReadUniversityForInstructor>(
      `/api/instructor/university`
    );
    return data;
  }

  /**
   * Get instructor university terms data
   */
  async getTerms(params: GetTermsParams = { activeOnly: false }) {
    const { data } = await axios.get<InstructorReadTermsResponse>(
      `/api/instructor/university/terms`,
      { params }
    );
    return data;
  }

  async getTermsVideos() {
    const { data } = await axios.get<StudentSummary[]>(
      `/api/instructor/university/terms/videos`
    );

    return data;
  }

  async getSectionsByTerm(termId: string) {
    const { data } = await axios.get<SectionsByTermResponse>(
      `/api/instructor/university/term/${termId}/sections`
    );
    return data;
  }

  async getSectionsStudents(termId: string, sectionId: string) {
    type UserData = {
      _id: string;
      firstName: string;
      lastName: string;
      createdAt: string;
      loggedInCheckpoint: string;
      callStats: {
        learnerCalls: number;
        coachCalls: number;
        learnerCallIds: string[];
        coachCallIds: string[];
      };
    };

    type ResponseData = {
      termId: string;
      sectionId: string;
      students: UserData[];
    };

    const { data } = await axios.get<ResponseData>(
      `/api/instructor/university/term/${termId}/section/${sectionId}`
    );

    return data;
  }

  async togglePoorQuality(
    callId: string,
    description?: string,
    urgent = false
  ) {
    const { data } = await axios.put(
      `/api/instructor/call/${callId}/togglePoorQuality`,
      {
        description,
        urgent,
      }
    );

    return data;
  }
}
