import { getZopim } from './zopim';
import { setPageTitlePrefix } from './pageTitlePrefix';
import { addTags, removeTags } from './tags';
import { nativeLang } from './nativeLang';
import { regexObjectId } from 'conversifi-commons/helpers/validationsHelper';
import { setTerm } from './setTerm';
const { REACT_APP_ENV } = process.env;
const UNKNOWN_UNIVERSITY_TAG = 'UNKNOWN';
const NO_SECTION_UNIVERSITY_TAG = 'NO-SECTION';
const universityTag = (university) => `University: ${university}`;
const getUserSection = (user) => user?.sections?.[0];
const getUniversity = (user, userUniversity) => {
    let university = null;
    const section = getUserSection(user);
    if (userUniversity?.name) {
        university = userUniversity.name;
    }
    else if (section) {
        university = section.universityId || UNKNOWN_UNIVERSITY_TAG;
    }
    else {
        university = NO_SECTION_UNIVERSITY_TAG;
    }
    return university || UNKNOWN_UNIVERSITY_TAG;
};
const getUniversityRefs = (user, userUniversity) => {
    const refs = [UNKNOWN_UNIVERSITY_TAG, NO_SECTION_UNIVERSITY_TAG];
    const section = getUserSection(user);
    if (userUniversity?._id) {
        refs.push(universityTag(userUniversity._id));
    }
    else if (section) {
        refs.push(universityTag(section.universityId));
    }
    return refs;
};
const setUniversityTag = async (user, userUniversity) => {
    const university = getUniversity(user, userUniversity);
    if (!regexObjectId.test(university)) {
        await removeTags(...getUniversityRefs(user, userUniversity));
        await addTags(universityTag(university));
    }
};
export const init = (user, userUniversity) => new Promise(async (resolve) => {
    console.debug('Zendesk init');
    const zopim = await getZopim();
    setPageTitlePrefix(REACT_APP_ENV);
    zopim(async function () {
        console.debug('Zendesk setup');
        const tagsToAdd = [`APP_ENV:${REACT_APP_ENV}`];
        const tagsToRemove = [];
        if (user) {
            await initUser(user, zopim, tagsToAdd);
        }
        await Promise.all([
            addTags(...tagsToAdd),
            setUniversityTag(user, userUniversity),
            removeTags(tagsToRemove),
            removeTags('In Call'),
        ]);
        resolve();
    });
});
async function initUser(user, zopim, tagsToAdd) {
    await nativeLang(user.language.code);
    const section = getUserSection(user);
    zopim.livechat.setName(`${user.firstName} ${user.lastName}`);
    zopim.livechat.setEmail(user.email);
    if (user.paidCoachApplication &&
        user.paidCoachApplication.applicationStatus === 'APPROVED') {
        tagsToAdd.push('PAID-COACH');
    }
    if (user.paidCoachApplication &&
        user.paidCoachApplication.isScheduledPaidCoach === true) {
        tagsToAdd.push('Sched');
    }
    if (user.paidCoachApplication &&
        user.paidCoachApplication.certifiedBeginner) {
        tagsToAdd.push('Beg Coach');
    }
    if (user.roles && user.roles.length) {
        if (user.roles.includes('EXTERNAL_INSTRUCTOR_ADMIN')) {
            tagsToAdd.push('INSTRUCTOR-ADMIN');
        }
    }
    if (user.faculty) {
        tagsToAdd.push(`Faculty: ${user.faculty.name ? user.faculty.name : user.faculty}`);
    }
    await setTerm(section);
}
