import { JWT_TOKEN_NAME, SESSION_KEY_SUBSCRIPTION_FEE_STATUS, } from '../../constants';
import saveSessionItem from './saveSessionItem';
export default (token, subscriptionFeeStatus) => {
    if (!token) {
        console.warn("[SS16] No session token provided. Can't save session.");
    }
    else {
        saveSessionItem(JWT_TOKEN_NAME, token);
    }
    if (subscriptionFeeStatus !== undefined) {
        saveSessionItem(SESSION_KEY_SUBSCRIPTION_FEE_STATUS, subscriptionFeeStatus);
    }
};
