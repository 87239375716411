import { requestGenerateCouponCode } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'

export const generateCouponCode = async () => {
  try {
    return await requestGenerateCouponCode()
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
