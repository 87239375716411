import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { Box, LinearProgress, Typography } from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';
import { CustomTooltip } from '../components/CustomTooltip';
import { useIsMobile } from '../../../hooks/useCurrentMediaBreakpoint';
export const IsConnectedAdmin = () => {
    const [open, setOpen] = useState(false);
    const isMobile = useIsMobile();
    return (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
            React.createElement(Typography, { variant: "body1", fontSize: 10 },
                React.createElement(Trans, { id: "websocket.connected" }, "WebSocket connected")))),
        React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
            React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                        boxShadow: '2px 3px 28px 0px rgba(0,255,39,0.75)',
                        width: 15,
                        height: 15,
                        borderRadius: '50%',
                        backgroundColor: 'greenyellow',
                    } }), titleKey: "websocket.connected", titleDefault: "WebSocket connected", open: open, onClick: () => setOpen((prev) => !prev) }))));
};
export const IsConnectingAdmin = () => {
    const [open, setOpen] = useState(false);
    const isMobile = useIsMobile();
    return (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement(React.Fragment, null,
            React.createElement(Box, { display: "block" },
                React.createElement(Typography, { variant: "body1", fontSize: 8, marginBottom: "4px" },
                    React.createElement(Trans, { id: "websocket.connecting-websocket" }, "Connecting websocket")),
                React.createElement(LinearProgress, { color: "primary", sx: {
                        flex: 1,
                        margin: 'auto 5px',
                    } })),
            React.createElement(DnsIcon, null))),
        React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                    boxShadow: '2px 3px 28px 0px rgb(255,107,0)',
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: 'orange',
                } }), titleKey: "websocket.connecting-websocket", titleDefault: "Connecting websocket", open: open, onClick: () => setOpen((prev) => !prev) })));
};
export const IsClosedAdmin = () => {
    const [open, setOpen] = useState(false);
    const isMobile = useIsMobile();
    return (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
            React.createElement(Typography, { variant: "body1", fontSize: 10, color: "error", textAlign: "center" },
                React.createElement(Trans, { id: "websocket.socket-broken" }, "WebSocket broken")))),
        React.createElement(CustomTooltip, { icon: React.createElement("span", { style: {
                    boxShadow: '2px 3px 28px 0px rgb(235,0,0)',
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                } }), titleKey: "websocket.socket-broken", titleDefault: "WebSocket broken", open: open, onClick: () => setOpen((prev) => !prev) })));
};
