import axios from 'axios';
export const getAxiosErrorStatus = (err) => {
    if (!axios.isAxiosError(err) || !err.response) {
        return null;
    }
    let status = 'internal';
    switch (err.response.status) {
        case 404:
            status = 'notFound';
            break;
        case 403:
            status = 'forbidden';
            break;
    }
    return status;
};
