import axios from 'axios';
export class UtilsSdk {
    static sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }
    static isAsync(fn) {
        return (fn &&
            (fn[Symbol.toStringTag] === 'AsyncFunction' ||
                typeof fn.then === 'function'));
    }
    static isNetworkError(err) {
        if (err instanceof Error) {
            const msg = err.message.toLowerCase();
            return (msg.includes('network error') ||
                msg.includes('timeout') ||
                msg.includes('bad gateway'));
        }
        return false;
    }
    static async requestLong(requestConfig, retryInterval = 1000) {
        while (true) {
            try {
                return await axios.request(requestConfig);
            }
            catch (err) {
                if (!axios.isAxiosError(err) ||
                    (err.message !== 'Network Error' &&
                        err.response &&
                        err.response.data &&
                        err.response.data.message !== 'CacheTimeout')) {
                    throw err;
                }
                await UtilsSdk.sleep(retryInterval);
            }
        }
    }
    static resolveValue(value) {
        return new Promise((resolve, reject) => {
            if (value) {
                const xValue = value;
                if (typeof xValue.then === 'function') {
                    xValue.then(resolve).catch(reject);
                }
                else if (this.isAsync(xValue)) {
                    xValue().then(resolve).catch(reject);
                }
                else if (typeof xValue === 'function') {
                    try {
                        const v = xValue();
                        resolve(v);
                    }
                    catch (err) {
                        reject(err);
                    }
                }
                else {
                    resolve(xValue);
                }
            }
            else {
                resolve(value);
            }
        });
    }
}
