import resolveObjectPath from 'conversifi-commons/utils/resolveObjectPath';
import { SORT_ORDER } from 'conversifi-shared-react/es6/types/TableMaterialUI';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  const x = resolveObjectPath(b, orderBy as string, '');
  const y = resolveObjectPath(a, orderBy as string, '');
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

export const getComparator = <T>(
  order: SORT_ORDER,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === SORT_ORDER.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const paginateRows = <T>(
  rows: T[],
  page: number,
  rowSize: number,
  order?: SORT_ORDER,
  orderBy?: keyof T
): T[] => {
  const arr = Array.from(rows);

  if (order && orderBy) {
    arr.sort(getComparator<T>(order, orderBy));
  }

  return arr.slice(page * rowSize, page * rowSize + rowSize);
};
