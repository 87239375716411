import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { LinearProgress, Backdrop, CircularProgress } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
export const PublicContext = React.createContext({});
export class LinearProgressContext extends BaseProvider {
    className = 'LinearProgressContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    getInitialState() {
        return {
            showLinearProgress: false,
            showBackdrop: false,
        };
    }
    getPublicApi() {
        return {
            state: this.state,
            showLinearProgress: this.showLinearProgress,
            hideLinearProgress: this.hideLinearProgress,
            showLinearProgressWithBackdrop: this.showLinearProgressWithBackdrop,
            hideLinearProgressAndBackdrop: this.hideLinearProgressAndBackdrop,
        };
    }
    showLinearProgress = () => {
        this.produce((draft) => {
            draft.showLinearProgress = true;
        });
    };
    hideLinearProgress = () => {
        this.produce((draft) => {
            draft.showLinearProgress = false;
        });
    };
    showLinearProgressWithBackdrop = () => {
        this.produce((draft) => {
            draft.showBackdrop = true;
            draft.showLinearProgress = true;
        });
    };
    hideLinearProgressAndBackdrop = () => {
        this.produce((draft) => {
            draft.showBackdrop = false;
            draft.showLinearProgress = false;
        });
    };
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() },
            this.state.showLinearProgress && (React.createElement(LinearProgress, { id: "linear-progress", style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: zIndex.drawer + 1,
                } })),
            React.createElement(Backdrop, { style: {
                    zIndex: zIndex.drawer + zIndex.snackbar + 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    height: '100vh',
                }, open: this.state.showBackdrop },
                React.createElement(CircularProgress, { color: "primary" })),
            this.props.children));
    }
}
