import { useRef, useEffect } from 'react';
import { StringSchema } from 'yup';
import { useImmutableState } from './useImmutableState';
import { REACT_APP_INPUT_VALIDATION_TIMEOUT } from '../util/util.parsedEnvs';
import { Spec } from 'immutability-helper';

export const createValidationStatus = (
  isValid: boolean = true
): InputValidationStatus => {
  return {
    touched: false,
    errorMessage: '',
    isValid,
  };
};

export interface ExtraMessageContent {
  extraMessage: string;
}

export interface InputValidationStatus {
  /**
   * wether the input was already "touched" by the user
   */
  touched: boolean;

  /**
   * an error message if the input value entered by the
   * user is not valid. This is populated only after
   * the validation timeout occurs and/or after
   * the server validation occurs.
   */
  errorMessage: string;

  /**
   * if the input value is valid or not
   */
  isValid: boolean;

  /**
   * If the server validation has an extra message this field is assigned
   */
  extraMessage?: string;
}

export interface InputTextValidationProps {
  /**
   * the input value
   */
  value: string;

  /**
   * a callback function to be called right after
   * the validation timeout is run.
   */
  afterValidationTimeout?: (status: InputValidationStatus) => void;

  /**
   * Server validation is a callback function that will be called
   * after the local (yup) validation occurs but only that validation
   * passes okay.
   * The callback must return a promise that resolves with an error
   * message if the validation failed.
   */
  serverValidation?: (
    validValue: string
  ) => Promise<string | undefined | ExtraMessageContent>;

  /**
   * yup schema upon which the input value will be validated on, as the user types
   */
  yupValidation?: StringSchema;
}

type ResetStatus = () => void;

export const useInputTextValidation = (
  props: InputTextValidationProps
): [InputValidationStatus, ResetStatus] => {
  /**
   * holds the input validation timeout generated by `window.setTimeout`
   */
  const validationTimeoutRef = useRef<null | number>(null);

  /**
   * if the user provide a `serverValidation` callback
   * we will store the input value here at the moment
   * of starting the request to the server. If when the response
   * comeback the current input value in the state is not the
   * same as this value, then we know that the user must have
   * touched the input in the mean time making the server
   * validation no relevant anymore as it was made against an old
   * version of the input value.
   */
  const valueWhenServerValidationStarted = useRef<string>('');

  const prevValue = useRef<string>(props.value);

  /**
   * the component immutable state
   */
  const [state, setState] = useImmutableState<InputValidationStatus>({
    touched: false,
    errorMessage: '',
    /**
     * if no validation schema is provided, then this
     * input is always valid
     */
    isValid: props.yupValidation || props.serverValidation ? false : true,
  });

  /**
   * is the input validation timeout running?
   */
  const validationTimeoutIsRunning = () =>
    typeof validationTimeoutRef.current === 'number';

  /**
   * clear the validation timeout
   */
  function clearValidationTimeout() {
    window.clearTimeout(validationTimeoutRef.current as number);
    validationTimeoutRef.current = null;
  }

  /**
   * start the validation timeout. When the timeout is completed
   * it will fire the input validation
   */
  function startValidationTimeout() {
    validationTimeoutRef.current = window.setTimeout(() => {
      setState({ extraMessage: { $set: '' } });

      clearValidationTimeout();

      let localValidationFailed: boolean = false;

      if (props.yupValidation) {
        try {
          props.yupValidation.validateSync(props.value);
          setState({ errorMessage: { $set: '' }, isValid: { $set: true } });
        } catch (yupValidationError) {
          console.debug(yupValidationError.message);
          localValidationFailed = true;
          setState({
            errorMessage: { $set: yupValidationError.message },
            isValid: { $set: false },
          });
        }
      }

      if (props.afterValidationTimeout) {
        props.afterValidationTimeout(state);
      }

      if (props.serverValidation && !localValidationFailed) {
        /**
         * store the value to be validated in a ref object
         */
        valueWhenServerValidationStarted.current = props.value;
        props
          .serverValidation(props.value)
          .then((errorMsg) => {
            // if the user doesn't changed the input value since the
            // server validation started, then show an error feedback.
            if (valueWhenServerValidationStarted.current === props.value) {
              if (errorMsg && typeof errorMsg === 'string') {
                setState({
                  isValid: { $set: false },
                  errorMessage: { $set: errorMsg },
                  extraMessage: { $set: '' },
                });
              } else {
                const message =
                  (errorMsg as ExtraMessageContent).extraMessage ?? '';
                setState({
                  isValid: { $set: true },
                  errorMessage: { $set: '' },
                  extraMessage: { $set: message },
                });
              }
            }
          })
          .catch((err) => {
            /**
             * if the serverValidation callback is well implemented
             * this should not happen..
             */
            console.error('[UITV173]', err);
          });
      }
    }, REACT_APP_INPUT_VALIDATION_TIMEOUT);
  }

  useEffect(() => {
    if (props.value !== prevValue.current) {
      // reset any running validation time out
      if (validationTimeoutIsRunning()) {
        clearValidationTimeout();
      }
      startValidationTimeout();

      // update the previous value ref
      prevValue.current = props.value;

      const updates: Spec<InputValidationStatus> = {
        touched: { $set: true },

        /**
         * dismiss any error feedback while user is typing
         */
        errorMessage: { $set: '' },
      };

      /**
       * keep track of the current valid/invalid
       * status of the value. In case of validation
       * fails, we set the `isValid` state to `true`
       * to prevent the submission of invalid values.
       * However, wec keep the `errorMessage` empty
       * cause we don't want to show any error message
       * yet. It will be shown after some delay if the
       * value is still invalid
       */
      if (props.yupValidation) {
        try {
          props.yupValidation.validateSync(props.value);
          updates.isValid = { $set: true };
        } catch (yupValidationError) {
          updates.isValid = { $set: false };
        }
      }

      /** commit the updates */
      setState(updates);
    }
  });

  const resetStatus = () => {
    clearValidationTimeout();
    prevValue.current = '';
    valueWhenServerValidationStarted.current = '';
    setState({
      isValid: { $set: true },
      errorMessage: { $set: '' },
    });
  };

  return [state, resetStatus];
};
