import React, { useContext, useRef, useEffect, useState } from 'react';
import { GooglePlacesContext } from '../../../contexts';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import InputTextField from '../../components/InputTextField';
import { SxProps, Theme, useTheme } from '@mui/material';

const getDefaultStyles = (theme: Theme): SxProps => ({
  color: 'primary.main',

  '& .MuiInputLabel-root': {
    color: 'primary.main',

    '&.Mui-focused': {
      color: 'primary.main',
    },

    '&.Mui-error': {
      color: 'error.main',
    },
  },

  '& .MuiOutlinedInput-input': {
    color: 'primary.main',
    '-webkit-text-fill-color': theme.palette.text.primary,

    '&::placeholder': {
      color: 'primary.main',
      opacity: 0.7,
    },

    '&::selection, &::-moz-selection': {
      backgroundColor: 'primary.main',
      color: 'primary.main',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'primary.main',
    },

    '&:hover fieldset': {
      borderColor: 'primary.main',
    },

    '&.Mui-focused fieldset': {
      borderColor: 'primary.main',
    },

    ' &.Mui-error fieldset': {
      borderColor: 'error.main',
    },

    '&.Mui-error:hover fieldset': {
      borderColor: 'error.main',
    },
  },

  '& .MuiFormHelperText-root': {
    color: 'primary.main',
  },
});

interface RegisterLocationProps {
  value: string;
  label?: string;
  placeholder?: string;
  className?: string;
  defaultStyle?: boolean;
  transparent?: boolean;
  showIcon?: boolean;
  inputId?: string;
  required?: boolean;
  onPlaceChange: (location: any) => void;
  onError?: (error: boolean) => void;
}

const onBlurInLocationInput = (element: HTMLInputElement | null) => {
  if (element) {
    element.blur();
  }
};

export const RegisterLocation: React.FC<RegisterLocationProps> = ({
  value,
  label,
  placeholder,
  onPlaceChange,
  inputId,
  onError,
  defaultStyle,
  required,
}) => {
  const GoogleApisContext = useContext(GooglePlacesContext);
  const loginRegister = document.getElementById('containerLoginRegister');
  const [touched, setTouched] = useState<boolean>(false);
  const theme = useTheme();
  const styles = defaultStyle ? getDefaultStyles(theme) : {};

  useEffect(() => {
    GoogleApisContext.ensureGoogleLibrary();
  }, [GoogleApisContext]);

  const inputEl = useRef(null);

  const [inputValue, setInputValue] = useState<string>(value);
  const [gaReady, setGaReady] = useState(false);
  const [isInputError, setIsInputError] = useState(value === '');
  const trans = LinguiContext.useCtx().trans;

  useEffect(() => {
    if (loginRegister) {
      loginRegister.addEventListener('scroll', () =>
        onBlurInLocationInput(inputEl.current)
      );

      return () => {
        loginRegister.removeEventListener('scroll', () =>
          onBlurInLocationInput(inputEl.current)
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!inputEl.current) {
      // @ts-ignore
      inputEl.current = document.getElementById(inputId as string);
    }

    if (inputEl.current && GoogleApisContext.google && !gaReady) {
      // this is set to true only once, it prevents the
      // google autocomplete service to register multiple
      // listeners
      setGaReady(true);
    }
  });

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete;
    let listener: google.maps.MapsEventListener;
    if (inputEl.current && GoogleApisContext.google) {
      autocomplete = new GoogleApisContext.google.maps.places.Autocomplete(
        // @ts-ignore
        inputEl.current,
        { types: ['(cities)'] }
      );

      listener = autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let country: string | null = null;

        if (place.address_components) {
          place.address_components.forEach((component) => {
            const types = component.types;
            if (types && types.includes('country')) {
              country = component.short_name;
            }
          });

          const currentLocation = {
            address: place.formatted_address,
            currentCountry: country,
          };

          onPlaceChange(currentLocation);
          setIsInputError(false);
          onError?.(false);
          if (currentLocation.address) {
            setInputValue(currentLocation.address);
          }
        }
      });
    }

    return function cleanup() {
      if (autocomplete && listener) {
        google.maps.event.removeListener(listener);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaReady]); // => DO NOT REMOVE [gaReady]

  return (
    <InputTextField
      value={inputValue}
      disabled={!GoogleApisContext.google}
      name="register-location"
      placeholder={placeholder}
      label={label}
      onChange={(event) => {
        setInputValue(event.currentTarget.value);
        setIsInputError(true);
        onError?.(true);
        setTouched(true);
      }}
      required={required}
      fullWidth
      sx={styles}
      id={inputId}
      error={touched && isInputError}
      onFocus={() => setTouched(true)}
      onBlur={() => setTouched(true)}
      helperText={
        isInputError &&
        touched &&
        trans({
          id: 'USERS.EDITPROFILE.USERFORM.CURRENT_LOCATION_ERROR',
          message: 'This is a required field',
        })
      }
    />
  );
};
