import { requestSmsValidationCode } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'
import { SendSmsValidationCode } from '../ResponseSchemas'

// Call endpoint API for create a new promotion
export async function userValidatePhone(
  phone: string
): Promise<SendSmsValidationCode> {
  try {
    const data = await requestSmsValidationCode(phone)

    return data
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
