import React, { FC } from 'react';
import { LinguiContext as LinguiCtx } from 'conversifi-shared-react/es6/components/LinguiContext';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import { LangCode } from 'conversifi-types/src/Lang';
import { UserSource } from 'conversifi-types/src/User/UserSource';

const LinguiContext: FC<{ userLang: LangCode; userSource: UserSource }> = (
  props
) => {
  return (
    <LinguiCtx
      userLang={props.userLang}
      languagesCtx={LanguagesContext.useCtx()}
      userSource={props.userSource}
    >
      {props.children}
    </LinguiCtx>
  );
};

export default LinguiContext;
