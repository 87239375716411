import React, { useCallback } from 'react';
import { Box, Divider, List, ListItemText, Stack, Typography, } from '@mui/material';
import SidebarContext from '../..';
import { UserPicture } from '../../../UserPicture';
import { ROLE_ADMIN, ROLE_CUSTOMER_SERVICE, ROLE_INSTRUCTOR, } from 'conversifi-commons/constants';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { ADMIN_URL, INSTRUCTOR_URL, LOGOUT_URL, USER_VIEW_URL, } from '../../../../constants';
import { REACT_APP_APP_VERSION, REACT_APP_DEPLOYMENT_ID, } from '../../../../util/miscellaneous/util.parsedEnvs';
import Item from '../Item/Item';
import SecondaryItem from '../SecondaryItem';
import { Trans } from '@lingui/react';
const CustomDivider = React.memo(() => (React.createElement(Divider, { sx: {
        height: (theme) => theme.spacing(0.1),
        backgroundColor: (theme) => theme.palette.primary.main,
        marginY: 1,
    } })));
const Sidebar = () => {
    const sidebarCtx = SidebarContext.useCtx();
    const { user, items, secondaryItems, itemSelectedId } = sidebarCtx.state;
    const isInstructor = user?.roles?.includes(ROLE_INSTRUCTOR);
    const isInstructorPath = window.location.pathname.includes(INSTRUCTOR_URL);
    const isAdmin = user?.roles?.includes(ROLE_ADMIN);
    const isAdminPath = window.location.pathname.includes(ADMIN_URL);
    const isCustomerService = user?.roles?.includes(ROLE_CUSTOMER_SERVICE) && !isAdmin;
    const isUserView = !isInstructorPath && !isAdminPath;
    const userName = `${user?.firstName} ${user?.lastName}`;
    const userHandle = `${user?.handle}`;
    const toggleDrawer = useCallback((e) => {
        sidebarCtx.toggleDrawer(e, false);
    }, []);
    const setItemSelected = useCallback((itemId) => {
        sidebarCtx.setItemSelected(itemId);
    }, []);
    const closeSidebar = useCallback(() => {
        sidebarCtx.setOpen(false);
    }, []);
    return (React.createElement(Box, { sx: { width: 300, p: 3 }, onKeyDown: toggleDrawer },
        React.createElement(Stack, { direction: "column", justifyContent: "center", alignItems: "center", spacing: 0.5 },
            React.createElement(UserPicture, { photoId: user?.photoId, width: 150, height: 150 }),
            React.createElement(Typography, { variant: "h6", color: "primary" }, userName),
            React.createElement(Typography, { variant: "subtitle2", color: "primary" },
                "@",
                userHandle)),
        React.createElement(CustomDivider, null),
        React.createElement(List, null,
            items.map((item) => (React.createElement(Item, { key: item.id, item: item, setItemSelected: setItemSelected, itemSelected: itemSelectedId, closeSidebar: closeSidebar }))),
            React.createElement(Item, { item: {
                    id: 'logout',
                    text: React.createElement(Trans, { id: "sidebar.menu-item.logout" }, "Logout"),
                    icon: PowerSettingsNewOutlinedIcon,
                    onClick: () => {
                        window.location.href = LOGOUT_URL;
                    },
                } })),
        React.createElement(List, null,
            (isInstructor || isCustomerService || isAdmin) && React.createElement(CustomDivider, null),
            !isUserView && (React.createElement(Item, { item: {
                    id: 'search',
                    text: React.createElement(Trans, { id: "sidebar.menu-item.user-view" }, "User view"),
                    icon: PermIdentityOutlinedIcon,
                    onClick: () => {
                        window.location.replace(USER_VIEW_URL);
                    },
                } })),
            isInstructor && !isInstructorPath && (React.createElement(Item, { item: {
                    id: 'instructors',
                    text: (React.createElement(Trans, { id: "sidebar.menu-item.instructors" }, "Instructors")),
                    icon: PermIdentityOutlinedIcon,
                    onClick: () => {
                        window.location.replace(INSTRUCTOR_URL);
                    },
                } })),
            (isCustomerService || isAdmin) && !isAdminPath && (React.createElement(Item, { item: {
                    id: 'admin',
                    text: isAdmin ? (React.createElement(Trans, { id: "sidebar.menu-item.admin" }, "Admin")) : (React.createElement(Trans, { id: "sidebar.menu-item.customer-service" }, "Customer service")),
                    icon: SupervisorAccountOutlinedIcon,
                    onClick: () => {
                        window.location.href = ADMIN_URL;
                    },
                } }))),
        React.createElement(List, null,
            secondaryItems &&
                secondaryItems.map((secondaryItem) => (React.createElement(SecondaryItem, { key: secondaryItem.id, text: secondaryItem.text, onClick: secondaryItem.onClick }))),
            React.createElement(ListItemText, { primaryTypographyProps: {
                    color: (theme) => theme.palette.grey[500],
                    variant: 'caption',
                }, primary: React.createElement(Trans, { id: "sidebar.label.see", values: {
                        version: `${REACT_APP_APP_VERSION} | ${REACT_APP_DEPLOYMENT_ID}`,
                    } },
                    "See: ",
                    REACT_APP_APP_VERSION,
                    " | $",
                    REACT_APP_DEPLOYMENT_ID) }))));
};
export default Sidebar;
