import { AvatarContext } from '..';
import { AdminSdk } from '../../../../sdk/admin.sdk';

export async function loadData(this: AvatarContext) {
  const data = await AdminSdk.Avatar.getAvatarConfiguration();

  this.produce((draft) => {
    draft.prompt = data.prompt;
    draft.beginnerProficiency = data.beginnerProficiency;
    draft.intermediateProficiency = data.intermediateProficiency;
    draft.advancedProficiency = data.advancedProficiency;
    draft.maxDuration = data.maxDuration;
  });
}
