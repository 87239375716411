import Hotjar from '@hotjar/browser';
import { useEffect } from 'react';
export default function ({ user }) {
    const { REACT_APP_HOTJAR_SITE_ID, REACT_APP_HOTJAR_VERSION, REACT_APP_ENV } = process.env;
    const isProd = REACT_APP_ENV === 'prod';
    const startHotjar = (verifiedUser) => {
        Hotjar.init(REACT_APP_HOTJAR_SITE_ID, REACT_APP_HOTJAR_VERSION);
        Hotjar.identify(verifiedUser._id, {
            email: verifiedUser.email,
        });
    };
    useEffect(() => {
        if (REACT_APP_HOTJAR_SITE_ID && REACT_APP_HOTJAR_VERSION) {
            if (isProd && user?._id && user?.email) {
                startHotjar(user);
            }
        }
    }, [REACT_APP_HOTJAR_SITE_ID, REACT_APP_HOTJAR_VERSION, isProd, user]);
}
