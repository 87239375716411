import { ApiUserReadableData } from 'conversifi-types/src/User/User';
import { RecordId } from 'conversifi-types/src/commons';
import { Lti } from 'conversifi-types/src/Lti';
import { University } from 'conversifi-types/src/University';
import axiosInstance from '../util/util.axios';

export class LtiSdk {
  static async requestUser(token?: string): Promise<ApiUserReadableData> {
    if (token) {
      axiosInstance.defaults.headers['Authorization'] = token;
    }

    const { data } = await axiosInstance.get<ApiUserReadableData>('/user');
    return data;
  }

  static async updateUniversityLTIConfig(
    universityId: RecordId,
    lti: Lti
  ): Promise<University> {
    const { data } = await axiosInstance.put<University>(
      `/lti/university/${universityId}`,
      lti
    );

    return data;
  }
}
