import { requestUserGet } from '../requests';
import type { ApiUserReadableData, User } from 'conversifi-types/src/User/User';
import { ErrorInfo } from '../../util/util.axios';
import { apiErrorHandler } from '../apiErrorHandler';
import { getJwt } from 'conversifi-shared-react';
import axios from 'axios';
import { USER_ROLES_EXCLUDED_FROM_INTERMEDIATE_SCREENS } from '../../constants/roles';

type TUserRoles = Pick<User, 'roles' | 'subscriptionFeeDialog'>;

// Add a request interceptor that logs
// all the while we're in development mode.
axios.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV === 'development') {
    let msg = `==> ${config.method?.toUpperCase()} ${config.url}`;
    if (config.params) {
      msg = `${msg} & ${JSON.stringify(config.params)}`;
    }

    if (config.data) {
      msg = `${msg} \n ${JSON.stringify(config.data, null, 2)}`;
    }

    console.debug(msg);
  }

  return config;
});

export interface Authorized
  extends Pick<ApiUserReadableData, 'APP_VERSION' | 'subscriptionFeeStatus'> {
  /**
   * @deprecated The real result type is {@link userApi}
   */
  user: User;
  userApi: ApiUserReadableData;
}

/**
 * null = no token on axios headers & no token in localStorage
 */
type GetUserResponse = null | Authorized | ErrorInfo;

/**
 * Check if the user is an admin, university admin or instructor by his roles
 * @param {User} user
 * @return {boolean} Represent if the user is excluded of intermediate screens
 * like subscription fee and verification phone
 */
const userExcludedFromIntermediateScreens = (user: TUserRoles): boolean => {
  return USER_ROLES_EXCLUDED_FROM_INTERMEDIATE_SCREENS.some((role) =>
    user.roles.includes(role)
  );
};

/**
 * Try to get the user data using the existing token.
 */
export async function userGet(): Promise<GetUserResponse> {
  if (!axios.defaults.headers.common.Authorization) {
    const token = getJwt();

    if (token) {
      axios.defaults.headers.common.Authorization = token;
    } else {
      console.debug('no token in localStorage');
      // if there is no auth token, we directly block this request
      return null;
    }
  }

  try {
    const data = await requestUserGet();
    const { APP_VERSION, subscriptionFeeStatus } = data;

    return {
      APP_VERSION,
      subscriptionFeeStatus,
      user: data,
      // Fix data type
      userApi: (data as unknown) as ApiUserReadableData,
    };
  } catch (err) {
    throw apiErrorHandler(err);
  }
}

/**
 * check whether the given object contains user information
 */
userGet.responseIsAuthorized = (param: {
  [key: string]: any;
}): param is Authorized => {
  const isAuthorized = Boolean(
    param &&
      param.APP_VERSION &&
      param.APP_VERSION.length &&
      param.user &&
      param.user.email
  );
  return isAuthorized;
};

userGet.mustVerifyPhone = (user: User) => {
  return (
    user.SMSVerified &&
    user.SMSVerified === 'VERIFY' &&
    !userExcludedFromIntermediateScreens(user)
  );
};

userGet.isInstructor = (user: TUserRoles) => {
  return user.roles.includes('EXTERNAL_INSTRUCTOR_ADMIN');
};

userGet.isCustomerService = (user: TUserRoles) => {
  return user.roles.includes('CUSTOMER_SERVICE') && !userGet.isAdmin(user);
};

userGet.isAdmin = (user: TUserRoles) => {
  return user.roles.includes('ADMIN');
};

userGet.mustPaySubscriptionFee = (user: TUserRoles): boolean => {
  return (
    (!user.subscriptionFeeDialog || user.subscriptionFeeDialog === 'PENDING') &&
    !userExcludedFromIntermediateScreens(user)
  );
};
