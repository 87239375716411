/**
 * Admin APP Routes.
 *
 * @type {import('./routes').AdminAppRoutes}
 * @ref #REACT-ROUTING-BACKEND
 */
const Routes = {
  root: {
    match: /^\/$/,
    uri: '/',
  },

  admin: {
    match: /^\/admin/,
    uri: '/admin',
  },

  universityAdmin: {
    match: /^\/university-admin/,
    uri: '/university-admin',
  },

  instructor: {
    match: /^\/instructor/,
    uri: '/instructor',
  },

  login: {
    match: /^\/login/,
    uri: '/login',
  },

  logout: {
    match: /^\/logout/,
    uri: '/logout',
  },

  register: {
    match: /^\/register/,
    uri: '/register',
  },

  forgotPassword: {
    match: /^\/forgotPassword/,
    uri: '/forgotPassword',
  },

  lti: {
    match: /^\/lti/,
    uri: '/lti',
  },

  ltiLogout: {
    match: /^\/lti-logout/,
    uri: '/lti-logout',
  },
};

/**
 * All routes mappings.
 *
 * @ref #GET-ROUTES-MATCHES
 */
const RoutesMatches = Object.values(Routes).map((v) => v.match);

module.exports = { RoutesMatches, Routes };
