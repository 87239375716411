import { createContext, useState, useCallback, useRef } from 'react'

type CsvParserModule = typeof import('csv-parse')
type Load = () => Promise<CsvParserModule>
type LoadFake = () => void
export type ParserInstance = ReturnType<CsvParserModule>

export interface CsvParserContextType {
  parser: CsvParserModule | null
  isLoading: boolean
  loadingError: string | null
  load: Load | LoadFake
}

export const CsvParserContext = createContext<CsvParserContextType>({
  parser: null,
  isLoading: false,
  loadingError: '',
  load: () => {},
})

export const useCsvParser = () => {
  const parserRef = useRef<CsvParserModule | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<string | null>(null)

  const load = useCallback(() => {
    if (!parserRef.current && !loadingError && !isLoading) {
      setIsLoading(true)
      import('csv-parse')
        .then(module => {
          parserRef.current = module.default
          setIsLoading(false)
          if (loadingError) {
            setLoadingError(null)
          }
        })
        .catch(err => {
          setIsLoading(false)
          setLoadingError(err.message)
        })
    }
  }, [isLoading, loadingError])

  return { parser: parserRef.current, isLoading, loadingError, load }
}
