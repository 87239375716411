import React, { FC, useState, useEffect } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { capitalizeFirstLetter } from 'conversifi-shared-react/es6/util/miscellaneous/capitalizeFirstLetter';
import { PasswordConstraint } from '../LoginForm';
import InputTextField from '../../components/InputTextField';

interface Props {
  onUpdate: (pass: string) => void;
}

export const Password: FC<Props> = ({ onUpdate }) => {
  const trans = LinguiContext.useCtx().trans;
  const [error, setError] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [pass, setPass] = useState<string>('');
  const [seePass, setSeePass] = useState<boolean>(false);

  useEffect(() => {
    onUpdate(pass);

    PasswordConstraint.validate(pass)
      .then(() => {
        setError('');
      })
      .catch((err) => {
        setError(capitalizeFirstLetter(err.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pass]);

  return (
    <InputTextField
      value={pass}
      onChange={(e) => {
        setChanged(true);
        setPass(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" color="white">
            <HttpsOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => setSeePass((prevState) => !prevState)}
              edge="end"
            >
              {seePass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      id="loginForm.password"
      aria-describedby="loginForm.password-help"
      autoComplete="conv-login-password"
      name="conv-login-password"
      label={trans('common.password')}
      placeholder={trans('common.password')}
      fullWidth
      required
      type={seePass ? 'text' : 'password'}
      error={changed && !focused && Boolean(error)}
      helperText={changed && !focused && Boolean(error) && error}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};
