exports.getCountriesInfo = (countries, supportedLanguagesCodes) => {
  const infos = countries.map((info) => {
    const result = {
      name: info.name,
      translatedNames: {},
      alpha2Code: info.alpha2Code,
      callingCodes: info.callingCodes,
    };

    supportedLanguagesCodes.forEach((langKey) => {
      const k = langKey.toLowerCase();
      // Default translation: English
      result.translatedNames[k] = info.translations[k] || info.name;
    });

    return result;
  });

  // Sorts by calling code, but keeps US code first
  infos.sort((a, b) => {
    const aCallingCode = a.callingCodes[0];
    const aName = a.name;
    const bCallingCode = b.callingCodes[0];
    if (aName === 'United States of America') return -1;
    return aCallingCode.localeCompare(bCallingCode);
  });

  // Removes info without calling code
  return infos.filter(({ callingCodes }) => callingCodes[0] !== '');
};
