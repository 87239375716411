import React, { FC, useRef, useEffect } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Grow,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Trans } from '@lingui/react';

export type Actions = 'TRY_AGAIN' | 'CANCEL' | 'OK' | 'CLOSE';

type AllActions = ['TRY_AGAIN', 'CANCEL', 'OK', 'CLOSE'];

interface LoginErrorOverlayProps {
  visible: boolean;
  title: string;
  message: string;
  showButtons: Actions[];
  onHide: (withAction?: Actions) => void;
}

export const getAllActions = (): AllActions => [
  'TRY_AGAIN',
  'CANCEL',
  'OK',
  'CLOSE',
];

export const LoginErrorOverlay: FC<LoginErrorOverlayProps> = ({
  title,
  message,
  visible,
  showButtons,
  onHide,
}) => {
  const ref = useRef<Node | null>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      onHide('CLOSE');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const isActionEnabled = (action: Actions) => showButtons.includes(action);

  return (
    <Backdrop
      sx={{
        height: '100vh',
        color: '#fff',
      }}
      open={visible}
    >
      <Grow in={visible} mountOnEnter unmountOnExit>
        <Box width="100%" position="absolute" top={0} left={0} ref={ref}>
          <Paper sx={{ height: 200, p: 2, boxSizing: 'border-box' }}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => onHide('CLOSE')}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
              <Typography gutterBottom>{message}</Typography>
            </Box>
            <Stack spacing={1} direction="row" justifyContent="flex-start">
              {isActionEnabled('TRY_AGAIN') && (
                <Button
                  id="loginForm.tryAgainBtn"
                  variant="contained"
                  onClick={() => onHide('TRY_AGAIN')}
                  color="success"
                >
                  <Trans id="anonymous.login-error.try-again">Try again</Trans>
                </Button>
              )}
              {isActionEnabled('CANCEL') && (
                <Button
                  id="loginForm.cancelBtn"
                  variant="contained"
                  onClick={() => onHide('CANCEL')}
                  color="secondary"
                >
                  <Trans id="anonymous.login-error.cancel">Cancel</Trans>
                </Button>
              )}
              {isActionEnabled('OK') && (
                <Button
                  id="loginForm.okBtn"
                  variant="contained"
                  onClick={() => onHide('OK')}
                  color="success"
                >
                  <Trans id="anonymous.login-error.ok">OK</Trans>
                </Button>
              )}
            </Stack>
          </Paper>
        </Box>
      </Grow>
    </Backdrop>
  );
};
