import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material';
import React, { useCallback } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const Item = ({ item, itemSelected, setItemSelected, closeSidebar, }) => {
    const { id, text, icon: Icon, onClick } = item;
    const isItemSelected = itemSelected === id;
    const itemHasSubItems = Boolean(item.items);
    const onHandleClick = useCallback(() => {
        if (itemHasSubItems) {
            setItemSelected?.(id);
        }
        else {
            closeSidebar?.();
            onClick?.();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { onClick: onHandleClick, sx: { p: 0, m: 0 } },
            React.createElement(ListItemButton, { sx: { p: 0, m: 0 } },
                React.createElement(ListItemIcon, null,
                    Icon && React.createElement(Icon, { color: "primary", fontSize: "small" }),
                    !Icon && (React.createElement(ArrowRightIcon, { sx: { color: 'white' }, fontSize: "small" }))),
                React.createElement(ListItemText, { primaryTypographyProps: {
                        color: 'primary',
                        fontSize: (theme) => theme.spacing(2.4),
                    }, primary: text, color: "primary" }),
                itemHasSubItems &&
                    (isItemSelected ? (React.createElement(ExpandLessIcon, { color: "primary" })) : (React.createElement(ExpandMoreIcon, { color: "primary" }))))),
        itemHasSubItems && (React.createElement(Collapse, { in: isItemSelected, timeout: "auto", unmountOnExit: true },
            React.createElement(List, { component: "div", disablePadding: true }, item.items &&
                item.items.map((subItem) => (React.createElement(Item, { key: subItem.id, item: subItem, setItemSelected: setItemSelected, itemSelected: itemSelected, closeSidebar: closeSidebar }))))))));
};
export default Item;
