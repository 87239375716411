import { createAxiosInstance } from './createAxiosInstance';
export default class AxiosInstance {
    static onSessionExpired = () => { };
    static instance = AxiosInstance.createInstance();
    static createInstance() {
        const newInstance = createAxiosInstance({
            onSessionExpired: AxiosInstance.onSessionExpired,
        });
        AxiosInstance.instance = newInstance;
        return newInstance;
    }
    static setOnSessionExpired(onSessionExpired) {
        AxiosInstance.onSessionExpired = onSessionExpired;
        AxiosInstance.createInstance();
    }
    static refreshAxiosInstance() {
        AxiosInstance.createInstance();
    }
    static getAxiosInstance() {
        return AxiosInstance.instance;
    }
}
