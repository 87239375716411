export default (key, defaultValue) => {
    let value = localStorage.getItem(key);
    if (!value) {
        value = sessionStorage.getItem(key);
    }
    if (value) {
        try {
            return JSON.parse(value);
        }
        catch (err) {
            console.warn(`[GLSI16] can't parse [${key}]`, value);
        }
    }
    return defaultValue;
};
