import axios from 'axios';
import type { University } from 'conversifi-types/src/University';
import { apiErrorHandler } from '../apiErrorHandler';

/**
 * Get university by id
 * @param universityId university id
 */
export const getUniversity = async (universityId: string) => {
  try {
    const { data } = await axios.get<University>(
      `/api/admin/university/${universityId}`
    );

    return data;
  } catch (err) {
    throw apiErrorHandler(err);
  }
};
