import { requestRedeemsSmsValidationCode } from '../requests'
import { apiErrorHandler } from '../apiErrorHandler'
import { SendSmsValidationCode } from '../ResponseSchemas'

// Call endpoint API for create a new promotion
export async function userRedeemSmsCode(
  phone: string,
  code: string,
  allowSms: boolean = false
): Promise<SendSmsValidationCode> {
  try {
    let numberToSend = ''
    const rawPhone = phone
    const firstChar = rawPhone[0]
    const sanitized = rawPhone.replace(/[^0-9]/gi, '')
    if (firstChar === '+') {
      numberToSend = numberToSend.concat(firstChar)
    }

    numberToSend = encodeURIComponent(numberToSend.concat(sanitized))

    const data = await requestRedeemsSmsValidationCode({
      phone: numberToSend,
      code,
      allowSms,
    })

    return data
  } catch (err) {
    throw apiErrorHandler(err)
  }
}
