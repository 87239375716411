import { addTags, removeTags } from './tags';
const STORAGE_NAME = 'ZD_MODE';
export async function setMode(mode) {
    const currentMode = localStorage.getItem(STORAGE_NAME);
    if (currentMode) {
        console.debug(`Zendesk Mode clean:`, currentMode);
        await removeTags(currentMode);
    }
    if (mode) {
        const newMode = `MODE_${mode
            .trim()
            .toUpperCase()
            .replace(/[^\d\w\-_:;#]+/g, '_')}`;
        console.debug(`Zendesk Mode update:`, { mode, newMode });
        localStorage.setItem(STORAGE_NAME, newMode);
        await addTags(newMode);
    }
    else {
        console.debug(`Zendesk Mode reset`);
        localStorage.removeItem(STORAGE_NAME);
    }
}
