import React, { useEffect, ReactEventHandler, useCallback } from 'react';
import { Authorized, userValidatePhone } from '../../../api';
import { RouteComponentProps } from 'react-router-dom';
import { LoginRegisterTemplate } from '../../../App.anonymous/templates/login-register';
import {
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js/mobile';
import style from './style.module.css';
import {
  useImmutableState,
  InputValidationStatus,
  createValidationStatus,
} from '../../../hooks';
import { SmsSent } from './phone-validation-screen/SmsSent';
import { getResponseDataFromError } from '../../../util';
import { REACT_APP_TWILIO_CODE_INVALID_PHONE } from '../../../util/util.parsedEnvs';
import { userSkipPhoneValidation } from '../../../api/user/userSkipPhoneValidation';
import { Trans } from '@lingui/react';
import { UtilsSdk } from 'conversifi-shared-react/es6/sdk/UtilsSdk';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { User } from 'conversifi-types/src/User/User';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { Stack, Typography, Button, Box } from '@mui/material';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { InputMobilePhone } from '../../../components/InputMobilePhone/InputMobilePhone';
import { formatPhoneNumber } from 'conversifi-commons/helpers/phoneHelper';

interface Props {
  authorizedLoginResponse: Authorized;
  /**
   * The user either verified his phone or
   * skipped the whole process
   */
  onEnded: (reason: 'VERIFIED' | 'NOT_VERIFY') => void;
  user: User;
  routerProps?: RouteComponentProps;
}

interface State {
  phoneInputValue: string;
  phoneNumberValidationStatus: InputValidationStatus;
  isPossible: boolean;
  isValid: boolean;
  type: string | undefined;
  smsCodeRequest: {
    isRunning: boolean;
    succeeded: null | boolean;
    error: string | JSX.Element;
    errorType?: 'INVALID_PHONE_NUMBER' | 'UNEXPECTED_ERROR';
  };

  firstRender: boolean;
}

const PhoneVerification: React.FC<Props> = ({
  authorizedLoginResponse,
  onEnded,
  user,
}) => {
  const trans = LinguiContext.useCtx().trans;

  const [state, setState] = useImmutableState<State>({
    phoneInputValue: '',
    phoneNumberValidationStatus: createValidationStatus(),
    isPossible: false,
    isValid: false,
    type: undefined,
    smsCodeRequest: {
      isRunning: false,
      succeeded: null,
      error: '',
      errorType: undefined,
    },
    firstRender: true,
  });

  const dialogCtx = DialogContext.useCtx();
  const wipCtx = LinearProgressContext.useCtx();

  useEffect(() => {
    if (state.smsCodeRequest.error) {
      dialogCtx.setMessage({
        title: (
          <Typography>
            {state.smsCodeRequest.errorType === 'INVALID_PHONE_NUMBER' ? (
              <Trans id="phone-verification.invalid-phone-number">
                Invalid Phone Number
              </Trans>
            ) : (
              <Trans id="phone-verification.unexpected-error">
                Unexpected error
              </Trans>
            )}
          </Typography>
        ),
        message: <Typography>{state.smsCodeRequest.error}</Typography>,
        actions: (
          <Button
            id="phoneVerification.errorDialog"
            onClick={onErrorDialogClose}
            variant="contained"
          >
            {state.smsCodeRequest.errorType === 'INVALID_PHONE_NUMBER' ? (
              <Trans id="phone-verification.correct-phone-number">
                Correct phone number
              </Trans>
            ) : (
              <Trans id="phone-verification.ok">Ok</Trans>
            )}
          </Button>
        ),
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.smsCodeRequest.error, state.smsCodeRequest.errorType]);

  /**
   * set the initial state based on the component's props
   */
  useEffect(() => {
    if (authorizedLoginResponse.userApi.phone) {
      setState({
        phoneInputValue: { $set: authorizedLoginResponse.userApi.phone },
        firstRender: { $set: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedLoginResponse.userApi.phone, setState]);

  useEffect(() => {
    const parsedPhone = parsePhoneNumberFromString(
      state.phoneInputValue as string
    ) as PhoneNumber;

    if (parsedPhone) {
      setState({
        isPossible: { $set: parsedPhone.isPossible() },
        isValid: { $set: parsedPhone.isValid() },
        type: { $set: parsedPhone.getType() },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phoneInputValue]);

  const onSendCode = async () => {
    let numberToSend = '';
    const rawPhone = state.phoneInputValue;
    const firstChar = rawPhone[0];
    const sanitized = rawPhone.replace(/[^0-9]/gi, '');
    if (firstChar === '+') {
      numberToSend = numberToSend.concat(firstChar);
    }

    numberToSend = encodeURIComponent(numberToSend.concat(sanitized));

    if (numberToSend) {
      setState({
        smsCodeRequest: {
          isRunning: { $set: true },
        },
      });

      try {
        wipCtx.showLinearProgressWithBackdrop();
        await userValidatePhone(numberToSend);
        setState({
          smsCodeRequest: {
            isRunning: { $set: false },
            succeeded: { $set: true },
          },
        });
      } catch (err) {
        if (!UtilsSdk.isNetworkError(err)) {
          console.error('[PV216]', err);
        }

        const response = getResponseDataFromError<{
          success: boolean;
          message: string;
          errorCode: number;
        }>(err);

        if (
          response &&
          response.errorCode === REACT_APP_TWILIO_CODE_INVALID_PHONE
        ) {
          setState({
            smsCodeRequest: {
              isRunning: { $set: false },
              succeeded: { $set: false },
              errorType: { $set: 'INVALID_PHONE_NUMBER' },
              error: {
                $set: (
                  <Trans id="phone-verification.invalid-phone-number-message">
                    The given phone number doesn't seem to be valid. Please
                    check it. HINT: The number after \"Intl.:\" should turn blue
                    or green.
                  </Trans>
                ),
              },
            },
          });
        } else {
          setState({
            smsCodeRequest: {
              isRunning: { $set: false },
              succeeded: { $set: false },
              errorType: { $set: 'UNEXPECTED_ERROR' },
              error: {
                $set: (
                  <Trans id="phone-verification.unexpected-error-message">
                    We're having trouble submitting your phone number. Please
                    try again. If the problem persists, try later.
                  </Trans>
                ),
              },
            },
          });
        }
      } finally {
        wipCtx.hideLinearProgressAndBackdrop();
      }
    }
  };

  const updatePhoneInputValue = (value: string) => {
    setState({
      phoneInputValue: { $set: value },
    });
  };

  /**
   * if at the end, the code that the user received via SMS
   * is invalid or expired, he can request another SMS
   */
  const onTryAgain = () => {
    setState({
      smsCodeRequest: {
        isRunning: { $set: false },
        succeeded: { $set: false },
      },
    });
  };

  const onErrorDialogClose = useCallback(() => {
    dialogCtx.closeDialog();
    setState({
      smsCodeRequest: {
        isRunning: { $set: false },
        succeeded: { $set: false },
        error: { $set: '' },
        errorType: { $set: undefined },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSkip: ReactEventHandler = async () => {
    try {
      await userSkipPhoneValidation();
      onEnded('NOT_VERIFY');
    } catch (err) {
      console.error('[PV297]', err);
    } finally {
      window.location.href = '/';
    }
  };

  const phoneRating = state.isValid
    ? 'valid'
    : state.isPossible
    ? 'possible'
    : undefined;

  useEffect(() => {
    if (
      user.source === 'babbel' &&
      user.phone &&
      !state.smsCodeRequest.isRunning &&
      !state.firstRender
    )
      onSendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.firstRender]);

  const getIntlPhoneColor = () => {
    switch (phoneRating) {
      case 'valid':
        return '#3bf03b';
      case 'possible':
        return 'primary.light';
      default:
        return 'error';
    }
  };

  return (
    <>
      <LoginRegisterTemplate
        containerExtraClassName={style.templateContainer}
        headerExtraClassName={style.header}
        childrenExtraClassName={style.content}
        userSource={user.source}
      >
        {!state.smsCodeRequest.succeeded && (
          <>
            <Typography variant="h6" textAlign="center">
              <Trans
                id="phone-verification.welcome-to-conversifi"
                values={{
                  source: user.source === 'babbel' ? 'Connect' : 'Conversifi',
                  handle: authorizedLoginResponse.userApi.handle,
                }}
                components={[<strong />]}
              >
                Welcome to Conversifi @
                <strong>@{authorizedLoginResponse.userApi.handle}!</strong>
              </Trans>
            </Typography>
            <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
              <Trans id="phone-verification.please-validate-your-phone-number">
                Please validate your phone number!
              </Trans>
            </Typography>
            <Stack direction="column" spacing={2}>
              <InputMobilePhone
                defaultValue={authorizedLoginResponse.userApi.phone}
                onStatusChange={updatePhoneInputValue}
                label={trans('phone-verification.your-mobile-phone-number')}
                registerScreenStyle={true}
                strictMode
              />
              <Typography variant="h6">
                Intl.:{' '}
                <Typography
                  color={getIntlPhoneColor()}
                  component="span"
                  variant="h6"
                  fontWeight={600}
                >
                  {formatPhoneNumber(state.phoneInputValue)}
                </Typography>
              </Typography>
              <Button
                variant="contained"
                disabled={
                  !state.phoneInputValue ||
                  state.smsCodeRequest.isRunning ||
                  Boolean(state.smsCodeRequest.succeeded) ||
                  !phoneRating
                }
                onClick={onSendCode}
                id="phoneVerification.submit"
                sx={{
                  height: '50px',
                }}
              >
                <Trans id="phone-verification.send-a-code-to-my-phone">
                  Send a code to my phone
                </Trans>
              </Button>
            </Stack>
          </>
        )}
        {state.smsCodeRequest.succeeded && (
          <SmsSent
            rating={phoneRating}
            phone={state.phoneInputValue}
            onTryAgain={onTryAgain}
            onFinish={() => onEnded('VERIFIED')}
            userSource={user.source}
          />
        )}
        <Box my={2} textAlign="center">
          <Typography
            onClick={onSkip}
            color="primary.light"
            textAlign="center"
            display="inline"
            variant="h6"
            sx={{
              cursor: 'pointer',
            }}
          >
            <Trans id="phone-verification.skip">Skip</Trans>
          </Typography>
        </Box>
      </LoginRegisterTemplate>
    </>
  );
};

export default PhoneVerification;
