import axios from 'axios';
import { getAxiosErrorStatus } from '.';
export function readError(err) {
    let message;
    let data = null;
    let axiosErrorStatus = null;
    if (err === null) {
        throw new Error(`[RE27] Error cannot be null`);
    }
    if (axios.isAxiosError(err)) {
        message = err.message;
        data = err.response?.data;
        axiosErrorStatus = getAxiosErrorStatus(err);
    }
    else if (err instanceof Error) {
        message = err.message;
    }
    else {
        message = `${err}`;
    }
    return { message, data, axiosErrorStatus };
}
