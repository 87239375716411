import { AdminSdk } from '../../../../sdk/admin.sdk';
import { CoachesBehaviorsContext } from '../index';

export default async function fetchCoachesReport(
  this: CoachesBehaviorsContext,
  coachId: string,
  notes: string
) {
  try {
    await AdminSdk.Coaches.postCoachNotes(coachId, notes);

    this.props.snackbarCtx.setMessage({
      message: 'Note saved',
      severity: 'success',
    });

    this.fetchCoachesAiReport();
  } catch (err) {
    console.error(err);

    this.props.snackbarCtx.setMessage({
      message: 'Error saving note',
      severity: 'error',
    });
  }
}
