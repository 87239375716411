import React, { useEffect, useRef, useState } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Avatar, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
const { REACT_APP_CLOUDINARY_CLOUD_NAME } = process.env;
export const UserPicture = ({ photoId, width = 200, height = 200, rounded = true, className, styles, alt = '', containerSx, }) => {
    const profilePictureRef = useRef();
    const [currentPhotoId, setCurrentPhotoId] = useState();
    useEffect(() => {
        if (currentPhotoId && profilePictureRef.current) {
            const img = profilePictureRef.current.element;
            if (Reflect.isExtensible(img)) {
                img.onerror = () => setCurrentPhotoId('');
            }
        }
    }, [currentPhotoId, profilePictureRef]);
    useEffect(() => {
        setCurrentPhotoId(photoId || '');
    }, [photoId]);
    let style;
    if (currentPhotoId) {
        style = Object.assign({
            objectFit: 'fit',
            borderRadius: rounded ? '50%' : 'initial',
            overflow: 'hidden',
        }, styles);
    }
    else {
        style = Object.assign({
            objectFit: 'cover',
            borderRadius: rounded ? '50%' : 'initial',
            overflow: 'hidden',
            height,
            width,
            margin: '0 auto',
        }, styles);
    }
    const containerStyle = Object.assign({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }, containerSx || {});
    return (React.createElement(Box, { sx: containerStyle }, currentPhotoId ? (React.createElement(Image, { ref: profilePictureRef, cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME, publicId: currentPhotoId, alt: alt, className: className, style: style },
        React.createElement(Transformation, { width: width, height: height, gravity: "faces", crop: "fill" }))) : (React.createElement(Avatar, { alt: "NoProfilePicture", className: className || undefined, style: style },
        React.createElement(PersonIcon, { style: {
                height,
                width: width * 0.8,
            } })))));
};
