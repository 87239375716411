import React from 'react';
import Routing from './Routing';
import { BrowserRouter } from 'react-router-dom';
import { REACT_APP_BASE_PATH } from './util/util.parsedEnvs';
import { Routes, RoutesMatches } from './routes';
import AppLoading from './App.loading';
import { MUIThemeProvider } from 'conversifi-shared-react/es6/themes';
import { redirectToProdInCDNHost } from 'conversifi-shared-react/es6/util/miscellaneous';

/**
 * The user reach this app when he entered (or was
 * redirected to) a url that start with:
 *   - /
 *   - /admin
 *   - /login
 *   - /register
 *   - /instructor
 *   - /university-admin
 */
export const App: React.FC = () => {
  redirectToProdInCDNHost();
  if (!RoutesMatches.some((url) => url.test(window.location.pathname))) {
    window.location.href = Routes.root.uri;
    return <AppLoading />;
  }

  return (
    <MUIThemeProvider>
      <BrowserRouter basename={REACT_APP_BASE_PATH}>
        <Routing />
      </BrowserRouter>
    </MUIThemeProvider>
  );
};
