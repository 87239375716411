import React from 'react';
import BaseProvider from '../../BaseProvider';
import { silenceRing } from './assets';
import { PublicContext } from './partials/PublicContext';
export class Ringer extends BaseProvider {
    className = 'Ringer';
    static useCtx = () => React.useContext(PublicContext);
    _audioRing;
    _audioRingPromise;
    _previousSrc;
    PublicContext = PublicContext;
    constructor(props) {
        super(props);
        this.bindAll();
        this._previousSrc = '';
        try {
            this._audioRing = new Audio(silenceRing);
            this._audioRing.volume = 1;
            this._audioRing.autoplay = false;
            this._audioRing.loop = false;
            this.logger.debug('audio init');
            this._audioRing.addEventListener('ended', () => {
                try {
                    if (this._audioRing?.loop) {
                        this._audioRingPromise = this._audioRing
                            ?.play()
                            .catch((err) => this.logger.info('WARN: [RP44]', err));
                        this.logger.debug('audio.restart');
                    }
                }
                catch (err) {
                    this.logger.info('WARN: audio.ended > Play ring err', err);
                }
            }, false);
        }
        catch (err) {
            this.logger.info('WARN: new Audio > init err', err);
        }
    }
    getInitialState() {
        return {
            isRinging: false,
            soundData: {
                src: new Audio(silenceRing),
                autoplay: false,
                loop: false,
                volume: 1,
            },
            isSoundAllowed: false,
        };
    }
    get isRinging() {
        return this.state.isRinging;
    }
    getPublicApi() {
        return {
            state: this.state,
            isRinging: this.isRinging,
            play: this.play,
            stop: this.stop,
            setSound: this.setSound,
            allowInitialSound: this.allowInitialSound,
        };
    }
    async play() {
        if (this._audioRing) {
            try {
                await this.stop();
                this.logger.debug('audio.play');
                this._audioRing.src = this.state.soundData.src;
                this._audioRing.loop = this.state.soundData.loop || false;
                this._audioRing.autoplay = this.state.soundData.autoplay || false;
                this._audioRing.volume = this.state.soundData.volume || 1;
                this._audioRingPromise = this._audioRing
                    .play()
                    .catch((err) => this.logger.info('WARN: [RP85]', err));
            }
            catch (err) {
                this.logger.info('WARN: audio.play', err);
            }
        }
        else {
            this.logger.debug('audio.play already working');
        }
        this.produce((draft) => {
            draft.isRinging = true;
        });
    }
    setSound(sound) {
        this.produce((draft) => {
            draft.soundData = sound;
        });
    }
    async stop() {
        try {
            if (this._audioRingPromise) {
                await this._audioRingPromise;
                delete this._audioRingPromise;
            }
            this._stop();
        }
        catch (err) {
            this.logger.info('WARN: audio.stop', err);
        }
        finally {
            this.produce((draft) => {
                draft.isRinging = false;
            });
        }
    }
    onComponentWillUnmount() {
        this._stop();
    }
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.soundData.src !== prevState.soundData.src) {
            this._previousSrc = prevState.soundData.src;
        }
    }
    _stop() {
        if (this._audioRing) {
            try {
                delete this._audioRingPromise;
                this._audioRing.pause();
                this.logger.debug('audio.pause');
            }
            catch (err) {
                this.logger.info('WARN: audio.pause', err);
            }
        }
    }
    async allowInitialSound() {
        try {
            this.setSound({
                src: `${process.env.PUBLIC_URL}/assets/silenceRing.mp3`,
            });
            await this.play();
            await this.stop();
            this.setSound({
                src: this._previousSrc,
            });
            this.produce((draft) => {
                draft.isSoundAllowed = true;
            });
        }
        catch (err) {
            this.logger.info('WARN: allowInitialSound', err);
        }
    }
}
