import { UserLoginResponse } from '../ResponseSchemas';
import { apiErrorHandler } from '../apiErrorHandler';
import { requestUserLogin } from '../requests';
import type { User } from 'conversifi-types/src/User/User';
import { UserLoginData } from 'conversifi-types/src/shared-frontend-backend/UserLoginData';

export async function userLogin(
  userLoginData: UserLoginData
): Promise<UserLoginResponse> {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return await requestUserLogin({ ...userLoginData, timeZone });
  } catch (err) {
    throw apiErrorHandler(err);
  }
}

userLogin.isAdmin = (user: User) => user.roles.includes('ADMIN');
userLogin.isInstructor = (user: User) =>
  user.roles.includes('EXTERNAL_INSTRUCTOR_ADMIN');
