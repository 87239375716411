import React from 'react';
import { BaseProvider } from '../../BaseProvider';
import { TRIGGER_LOGOUT_AFTER_INACTIVITY_MS } from 'conversifi-commons/constants';
import getSessionExpirationDate from '../../util/session/getSessionExpirationDate';
import { clearLocalSession } from '../../util';
import { DialogContext } from '../DialogContext';
import { Trans } from '@lingui/react';
const PublicContext = React.createContext({});
class UserSessionContext extends BaseProvider {
    className = 'UserSessionContext';
    PublicContext = PublicContext;
    static useCtx = () => React.useContext(PublicContext);
    constructor(props) {
        super(props);
        this.bindAll();
    }
    getInitialState() {
        return {};
    }
    getPublicApi() {
        return {
            state: this.state,
        };
    }
    componentDidMount() {
        if (this.sessionIsExpired()) {
            this.onSessionExpires();
        }
        else {
            this.addInterval(() => {
                if (this.sessionIsExpired()) {
                    this.onSessionExpires();
                }
            }, 10000);
        }
    }
    onComponentWillUnmount() {
        this.clearTimeoutReferences();
    }
    sessionIsExpired() {
        const date = getSessionExpirationDate();
        return !date || date.getTime() < Date.now();
    }
    goToLogout() {
        window.location.href = '/logout';
    }
    onSessionExpires() {
        clearLocalSession();
        this.props.dialogCtx.setMessage({
            title: React.createElement(Trans, { id: "session.session-expired" }, "Session expired"),
            message: (React.createElement(Trans, { id: "session.please-login-again" }, "Your session has expired. Please log in again.")),
            defaultAction: this.goToLogout,
        });
        this.addTimeout(this.goToLogout, TRIGGER_LOGOUT_AFTER_INACTIVITY_MS);
    }
    render() {
        return (React.createElement(this.PublicContext.Provider, { value: this.getPublicApi() }, this.props.children));
    }
}
const UserSession = (props) => {
    return (React.createElement(UserSessionContext, { dialogCtx: DialogContext.useCtx() }, props.children));
};
export default UserSession;
