import { SUPPORTED_LANGUAGE_CODES } from 'conversifi-commons/constants';
const SUPPORTED_LANGS = SUPPORTED_LANGUAGE_CODES.map((lang) => {
    return lang.toLowerCase();
});
export function getBrowserLang() {
    const browserLang = window.navigator &&
        window.navigator.language.slice(0, 2).toLocaleLowerCase();
    if (browserLang && SUPPORTED_LANGS.includes(browserLang)) {
        return browserLang;
    }
    return 'en';
}
export const computeLang = (user) => {
    if (user &&
        user.language &&
        user.language.code &&
        SUPPORTED_LANGS.includes(user.language.code.toLocaleLowerCase())) {
        return user.language.code.toLocaleLowerCase();
    }
    return getBrowserLang();
};
