import ActiveSearchesContext from '../index';

export default function (this: ActiveSearchesContext) {
  if (this.props.socketCtx.isConnected()) {
    this.props.socketCtx.emit('LEAVE_ROOM', {
      room: 'LIVE_ALERTS',
    });
  }

  this.removeAllLiveAlertListener();
}
