import React, { FC, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';

import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { InputTextProps } from '../../../components/InputText';
import { InputValidationStatus } from '../../../hooks/useInputTextValidation';
import InputTextField from '../../components/InputTextField';

interface Props extends InputTextProps {
  value: string;
  validationStatus: InputValidationStatus;
  onValueChange: (value: string) => void;
  inputId?: string;
  disabled?: boolean;
}

export const RegisterSectionCode: FC<Props> = (props) => {
  const trans = LinguiContext.useCtx().trans;
  const [helperText, setHelperText] = useState<string>('');

  useEffect(() => {
    setHelperText('');

    if (
      !Boolean(props.validationStatus.errorMessage) &&
      props.validationStatus.extraMessage
    ) {
      setHelperText(props.validationStatus.extraMessage);
    }

    if (Boolean(props.validationStatus.errorMessage)) {
      setHelperText(props.validationStatus.errorMessage);
    }
  }, [props.validationStatus]);

  return (
    <InputTextField
      id={props.inputId ?? 'register-section'}
      value={props.value}
      onChange={(event) => props.onValueChange(event.currentTarget.value)}
      label={trans({
        id: 'anonymous.register.inputs.section-code',
        message: 'Section code',
      })}
      placeholder={trans({
        id: 'anonymous.register.inputs.section-code',
        message: 'Section code',
      })}
      error={Boolean(props.validationStatus.errorMessage)}
      helperText={<Trans id={helperText}>{helperText}</Trans>}
    />
  );
};
