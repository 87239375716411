export const DISCONNECT_REASON = {
    SERVER_DISCONNECTION: 1000,
    SOCKET_NORMAL_HUNG_UP: 1001,
    SOCKET_HUNG_UP: 1006,
    SERVER_PING_TIMEOUT: 4000,
    CLIENT_PONG_TIMEOUT: 4001,
    CLIENT_CONNECTION_TIMEOUT: 4007,
    SERVER_HANDSHAKE_REJECT: 4008,
    IO_SERVER_DISCONNECTED: 'io server disconnect',
    IO_DISCONNECTED: 'io client disconnect',
};
export const DISCONNECT_ERROR_REASON = {
    IO_PING_TIMEOUT: 'ping timeout',
    IO_TRANSPORT_CLOSE: 'transport close',
    IO_TRANSPORT_ERROR: 'transport error',
    IO_SERVER_DISCONNECT: 'io server disconnect',
    IO_CLIENT_DISCONNECT: 'io client disconnect',
};
