/**
 * @ref #APP-LANGUAGES
 * @typedef {import('../conversifi-types').LangCode} LangCode
 * @typedef {import('../conversifi-types').SearchMode} SearchMode
 */

exports.API_BASE_PATH = '/api';

/**
 * Twilio Endpoint.
 *
 * @ref #TWILIO-RECORDING
 */
exports.RECORDINGS_API_CALLBACK_URL = '/calls/recordings';

/**
 * Twilio recording storage path.
 *
 * @ref #TWILIO-RECORDING-DELETE
 */
exports.RECORDINGS_STORAGE_PATH = 'recordings';

/**
 * Social Feed
 *
 * @ref #SOCIAL-FEED
 * https://speakapp.atlassian.net/browse/CONV-2260
 */
exports.SOCIAL_FEED_MIN_USER_INPUT = 5;
exports.SOCIAL_FEED_MAX_USER_INPUT = 255;

// Bad Calls
exports.MINUTES_CHECK_PARTICIPANTS = 0.5;
exports.MINUTES_START_CHECK = 2;
exports.CANT_CHECK_PARTICIPANTS = 3;
exports.CANT_CHECK_PER_ITERATION = 3;

// Bad Calls Scripts
exports.MINUTES_CALL_TOLERANCE = 3;
exports.TOLERANCE_FACTOR = 0.25;

/**
 * minimum amount of successful calls that a coach must have
 * to be able to apply to be a paid coach.
 */
exports.PAID_COACH_APPLICATION_MINIMUM_SUCCESS_CALLS = 1;
exports.PAID_COACH_APPLICATION_MINIMUM_SESSIONS_COACHED_TO_SET_STATUS_NEW = 6;
exports.PAID_COACH_APPLICATION_MINIMUM_RATING_AVG = 4.5;
exports.PAID_COACH_APPLICATION_MINIMUM_PERCENT_CONNECTION = 50; // 50%

// Notify Filter
exports.USERS_FLOODERS_MODE = {
  REGULAR_USER: 'Regular User',
  PAID_COACH: 'Paid Coach',
};

exports.LIFE_TIME_WAIT_FILTER = ['CLTW', 'LLTW'];
exports.NOTIFY_BY_TYPE_FILTER = ['SMS', 'EMAIL'];

// Redis Keys
exports.REDIS_KEY_SESSION = 'sess:';
exports.REDIS_KEY_CACHE = 'cache:';
exports.REDIS_KEY_MONGO_CACHE = `${exports.REDIS_KEY_CACHE}mongo:`;
exports.REDIS_KEY_MAv2 = 'MAv2:';
exports.REDIS_KEY_CURRENT_MATCHES = 'MAv2-current-matches';

// Google Analytics
exports.GA_TYPE = {
  FLOODERS: 'Flooders',
  NO_FLOODERS: 'No Flooders',
};
exports.GA_ROLE = { COACH: 'Coach', LEARNER: 'Learner' };
exports.GA_ACTION = {
  FIND: 'Find',
  CANCEL: 'Cancel',
  CALL: 'Call',
  STARTED: 'Started',
};
exports.GA_CATEGORY = { SEARCH: 'Search', CALL: 'Call' };

// Google Credentials
exports.GOOGLE_CREDENTIALS_TYPE = 'service_account';

// Paypal
// https://www.paypal.com/us/webapps/mpp/merchant-fees#paypal-payouts
// Payouts fee is $0.25 (PAYPAL_FEE_FIXED) for the US (PAYPAL_FEE_COUNTRY),
// 2% (PAYPAL_FEE_PERCENT) for the rest of the world.
exports.PAYPAL_FEE_FIXED = 0.25;
exports.PAYPAL_FEE_PERCENT = 0.02;
exports.PAYPAL_FEE_COUNTRY = 'US';

/** @type {import('./constants').PASSWORD_VERSION_2_SHA_256} */
exports.PASSWORD_VERSION_2_SHA_256 = 2;

/** @type {import('./constants').UNCONFIRMED_EMAIL_EXPIRES_AFTER_SECONDS} */
exports.UNCONFIRMED_EMAIL_EXPIRES_AFTER_SECONDS = 60 * 60;

/** @type {import('./constants').UNCONFIRMED_SMS_EXPIRES_AFTER_SECONDS} */
exports.UNCONFIRMED_SMS_EXPIRES_AFTER_SECONDS = 60 * 15;

/** @type {import('./constants').UNCONFIRMED_SMS_CODE_LEN} */
exports.UNCONFIRMED_SMS_CODE_LEN = 5;

/**
 *  @type {import('./constants').PAID_COACH_APPLICATIONS_REDIS_STATUS}
 *
 * #PAID_COACH_APPLICATIONS_REDIS_STATUS
 */
exports.PAID_COACH_APPLICATIONS_REDIS_STATUS = {
  NOT_STARTED: 'NOT STARTED',
  STARTED: 'STARTED',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

/** @type {import('./constants').PAID_COACH_APPLICATIONS_REDIS_TYPE} */
exports.PAID_COACH_APPLICATIONS_REDIS_TYPE = {
  STATUS: '-Status',
  PERCENT: '-Percent',
};

/** @type {import('./constants').PROFICIENCY_VALUES} */
exports.PROFICIENCY_VALUES = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED'];

/** @type {import('./constants').SEARCH_MODES} */
exports.SEARCH_MODES = ['coach', 'learner'];

/** @type {import('./constants').SECTIONS_EXPIRES_DATE} */
exports.SECTIONS_EXPIRES_DATE = new Date('2020/05/01');

const DEFAULT_FLOODER_SECTION_CODE = 'FD1000';

/** @type {import('./constants').DEFAULT_FLOODER_SECTION_CODE} */
exports.DEFAULT_FLOODER_SECTION_CODE = DEFAULT_FLOODER_SECTION_CODE;

/** @type {import('./constants').USER_PASSWORD_MIN_LENGTH} */
exports.USER_PASSWORD_MIN_LENGTH = 8;

/** @type {import('./constants').USER_PASSWORD_MAX_LENGTH} */
exports.USER_PASSWORD_MAX_LENGTH = 256;

/** @type {import('./constants').ROLE_LEARNER} */
exports.ROLE_LEARNER = 'STUDENT';

/** @type {import('./constants').ROLE_COACH} */
exports.ROLE_COACH = 'COACH';

/** @type {import('./constants').ROLE_ADMIN} */
exports.ROLE_ADMIN = 'ADMIN';

/** @type {import('./constants').ROLE_CUSTOMER_SERVICE} */
exports.ROLE_CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';

/** @type {import('./constants').ROLE_INSTRUCTOR} */
exports.ROLE_INSTRUCTOR = 'EXTERNAL_INSTRUCTOR_ADMIN';

/** @type {import('./constants').ROLE_DEFAULTS} */
exports.ROLE_DEFAULTS = [exports.ROLE_LEARNER];

/**
 * @type {import('conversifi-types/src/Lang').Languages}
 */
exports.DEFAULT_LANG = {
  langCode: 'en',
  enabled: true,
};

/**
 * @type {import('conversifi-types/src/Lang').LangCode[]}
 */
exports.SUPPORTED_LANGUAGE_CODES = [
  'ar',
  'asl',
  'bg',
  'br',
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fi',
  'fr',
  'he',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'ms',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sv',
  'th',
  'uk',
  'vi',
  'zh',
];

/**
 * @type {import('conversifi-types/src/Lang').Languages}
 */
exports.OTHER_LANG = {
  langCode: 'xx',
  enabled: true,
};

/** @type {import('./constants').PACKAGE_CONVERSIFI_BACKEND} */
exports.PACKAGE_CONVERSIFI_BACKEND = 'conversifi-backend';

/** @type {import('./constants').COACH_ONLY_SPOKE_NATIVE_LANG_RELEASE_DATE} */
exports.COACH_ONLY_SPOKE_NATIVE_LANG_RELEASE_DATE = new Date('2021/08/01');

/** @type {import('./constants').TERM_TYPE_VALUES} */
exports.TERM_TYPE_VALUES = ['fall', 'winter', 'spring', 'summer', 'other'];

/** @type {import('./constants').TWILIO_URL_REGEX} */
exports.TWILIO_URL_REGEX = /^https?:\/\/\w+\.twilio\.com\/v\d+\/.+(\?.*)?$/;

/** @type {import('./constants').S3_URL_REGEX} */
// @ref #S3_URL_REGEX
exports.S3_URL_REGEX = /\bs3\.amazonaws\.com\b/;

exports.TWILIO_STATUS_CALLBACK_EVENTS = [
  'room-created',
  'room-ended',
  'participant-connected',
  'participant-disconnected',
  'track-added',
  'track-removed',
  'track-enabled',
  'track-disabled',
  'recording-started',
  'recording-completed',
  'recording-failed',
];

/**
 * @type {import('./constants').TWILIO_MAX_COMPOSITION_ATTEMPTS_BEFORE_DECLARE_UNPROCESSABLE}
 *
 * @see https://speakapp.atlassian.net/browse/CONV-2801
 */
exports.TWILIO_MAX_COMPOSITION_ATTEMPTS_BEFORE_DECLARE_UNPROCESSABLE = 6;

/** @type {import('./constants').BALANCE_CHANGE_REASONS} */
exports.BALANCE_CHANGE_REASONS = [
  'CORRECT_PAYPAL_ERROR',
  'CORRECT_CONVERSIFI_ERROR',
  'GOODWILL',
  'OPTED_OUT',
  'TOO_MANY_COACHES_WAITING',
  'COACH_WAITED_LEARNER',
  'PAID_LEARNER',
  'SCHEDULED_SHIFT',
  'BONUS_OR_INCENTIVE',
  'BABBEL',
  'REFUND',
  'OTHER',
];

/** @type {import('./constants').USER_BLOCK_REASONS} */
exports.USER_BLOCK_REASONS = [
  'UNFRIENDLY_OR_UNKIND',
  'HARD_TO_UNDERSTAND',
  'INAPPROPRIATE_OR_OFFENSIVE',
  'BORING_OR_NOT_MUCH_IN_COMMON',
  'DID_NOT_STAY_IN_MODULE_TOPIC',
  'DID_NOT_STAY_IN_TARGET_LANGUAGE',
];

/** @type {import('./constants').NEEDS_TRAINING_REASONS} */
exports.NEEDS_TRAINING_REASONS = [
  'STRAYED_FROM_TARGET_LANG',
  'WAS_DISTRACTED',
  'WAS_DISRESPECTFUL',
  'MADE_ME_FEEL_UNCOMFORTABLE',
  'DID_NOT_FOLLOW_MODULE_TOPIC',
  'TROUBLE_KEEPING_CONVERSATION_GOING',
  'SPOKE_TOO_FAST',
];

const BADGES = {
  PATIENT_WITH_BEGINNERS: 'Patient with beginners',
  VERY_FRIENDLY: 'Very friendly',
  GREAT_CONVERSATIONALIST: 'Great conversationalist',
  SPEAKS_CLEARLY: 'Speaks clearly',
};

exports.BADGES = Object.keys(BADGES);

/** @type {import('./constants').BADGES_VALUES} */
exports.BADGES_MAP = BADGES;

/** @type {import('./constants').BASE_WS_PATH} */
exports.BASE_WS_PATH = '/ws';

/** @type {import('./constants').AVATAR_WS_PATH} */
exports.AVATAR_WS_PATH = '/avatar';

exports.CONVERSIFI_START_DATE = new Date('2017-01-01');

exports.CONVERSIFI_TIME_ZONE = 'America/New_York';

// #region #BULLMQ_QUEUE_NAMES
exports.BULL_REPETITIVE_QUEUE_NAME = 'BULL_REPETITIVE_QUEUE';
exports.BULL_SIMPLE_EXECUTION_QUEUE_NAME = 'BULL_SIMPLE_EXECUTION_QUEUE';
exports.BULL_CALL_PROCESSOR_QUEUE_NAME = 'BULL_CALL_PROCESSOR_QUEUE_NAME';
// #endregion

// #region #CALLS_BULL_MQ_TASKS_KEYS
/**
 * BULL_USER_IS_AFK is used when the user is notified about a match via websocket.
 */
exports.BULL_USER_IS_AFK = 'BULL_USER_IS_AFK';

/**
 * BULL_SEND_DEFERRED_EMAIL is used when we need to send an email with a specific delay time.
 */
exports.BULL_SEND_DEFERRED_EMAIL = 'BULL_SEND_DEFERRED_EMAIL';

/**
 * BULL_SEND_DEFERRED_SMS is used when we need to send a SMS with a specific delay time.
 */
exports.BULL_SEND_DEFERRED_SMS = 'BULL_SEND_DEFERRED_SMS';

/**
 * BULL_VIDEO_CALL_TIMEOUT is used for close the twilio room after X time in case both participants
 * are disconnected without a correct flow in the call screen.
 */
exports.BULL_VIDEO_CALL_TIMEOUT = 'BULL_VIDEO_CALL_TIMEOUT';

/**
 * BULL_CHECK_USER_IS_STILL_AFK is used to terminate a user's search if the user
 * is still afk after a timeout.
 */
exports.BULL_CHECK_USER_IS_STILL_AFK = 'BULL_CHECK_USER_IS_STILL_AFK';

/**
 * AFK_CHECK_WAIT_TIME is used to add a delay to bull job BULL_CHECK_USER_IS_STILL_AFK
 */
exports.AFK_CHECK_WAIT_TIME = 5;

/**
 * BULL_FINISH_CALL is used to finish the call and exchange tokens
 */
exports.BULL_FINISH_CALL = 'BULL_FINISH_CALL';

/**
 * BULL_CANCEL_QUEUE_IF_USER_IS_DISCONNECTED is used to check if the user have an active socket after a disconnection
 */
exports.BULL_CANCEL_QUEUE_IF_USER_IS_DISCONNECTED =
  'BULL_CANCEL_QUEUE_IF_USER_IS_DISCONNECTED';

/**
 * BULL_INSTRUCTOR_REPORT_SUMMARY_DETAIL is used to enqueue the instructor petition about the summary detail report.
 */
exports.BULL_INSTRUCTOR_REPORT_SUMMARY_DETAIL =
  'BULL_INSTRUCTOR_REPORT_SUMMARY_DETAIL';

/**
 * BULL_CHECK_CALL_COMPOSITION_STATUS is used to check the status of the composition and request the composition again if * it is not ready.
 */
exports.BULL_CHECK_CALL_COMPOSITION_STATUS =
  'BULL_CHECK_CALL_COMPOSITION_STATUS';

/**
 * BULL_CHECK_CALL_COMPOSITION_STATUS is used to check the status of the composition and request the composition again if * it is not ready.
 */
exports.BULL_EXPIRE_SCHEDULED_SESSION = 'BULL_EXPIRE_SCHEDULED_SESSION';

/**
 * BULL_REMOVE_FORCED_MATCH is used to remove a forced match from a schedule session after the waiting
 * time is over.
 */
exports.BULL_REMOVE_FORCED_MATCH = 'BULL_REMOVE_FORCED_MATCH';

/**
 * CHECK_IF_USER_IS_IN_QUEUE_AFTER_TIMEOUT is used to validate whether a user is in the queue after SEARCH_MAX_TIMEOUT_IN_MINUTES.
 * @ref SEARCH_MAX_TIMEOUT_IN_MINUTES
 */
exports.BULL_CHECK_IF_USER_IS_IN_QUEUE_AFTER_TIMEOUT =
  'BULL_CHECK_IF_USER_IS_IN_QUEUE_AFTER_TIMEOUT';

/**
 * BULL_USER_IS_ALONE_IN_ZOOM_MEETING is used to close the zoom meeting if the user is alone.
 */
exports.BULL_USER_IS_ALONE_IN_ZOOM_MEETING =
  'BULL_USER_IS_ALONE_IN_ZOOM_MEETING';

/**
 * BULL_START_MEETING_RECORDING is used to start the recording in a zoom meeting.
 */
exports.BULL_START_MEETING_RECORDING = 'BULL_START_MEETING_RECORDING';

/**
 * BULL_CLOSE_MEETING_IF_NO_PARTICIPANTS is used to close a call if no participants are connected.
 */
exports.BULL_CLOSE_MEETING_IF_NO_PARTICIPANTS =
  'BULL_CLOSE_MEETING_IF_NO_PARTICIPANTS';

/**
 * BULL_MIGRATE_ZOOM_COMPOSITION_TO_S3 is used to migrate compositions from zoom to s3
 */
exports.BULL_MIGRATE_ZOOM_COMPOSITION_TO_S3 =
  'BULL_MIGRATE_ZOOM_COMPOSITION_TO_S3';
exports.BULL_ADD_TWILIO_PARTICIPANT_END_REASON =
  'BULL_ADD_TWILIO_PARTICIPANT_END_REASON';

// #endregion

// #region #UNIVERSITIES_IDS
exports.UNIVERSITY_ID_UAZ = '5d84e9c50ac269004160381c';
exports.UNIVERSITY_ID_BABBEL = '630cc68cb5fbc0693cca32dd';
exports.UNIVERSITY_ID_KATE_U_CT = '5e30aa2d912f84004e40b6cb';
// #endregion

exports.SCHEDULED_PC_MATCH_WITH_SCHEDULED_LEARNERS =
  'Match Scheduled Paid Coaches with Scheduled Learners in off hours';
// #endregion

/** @type {import('./constants').USER_SOURCE} */
exports.USER_SOURCE = ['conversifi', 'babbel'];

/**@type {import('./constants').LIVE_HOUR_WEEK_DAYS} */
exports.LIVE_HOUR_WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6];
/**@type {import('./constants').LIVE_HOUR_WEEK_DAYS_LABELS} */
exports.LIVE_HOUR_WEEK_DAYS_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

/**@type {import('./constants').DEFAULT_TOKENS_FOR_BABBEL_USERS} */
exports.DEFAULT_TOKENS_FOR_BABBEL_USERS = 5;

/**@type {import('./constants').DEFAULT_CONVERSIFI_ADMIN_EMAIL} */
exports.DEFAULT_CONVERSIFI_ADMIN_EMAIL = 'admin@conversifi.com';

/**
 * Defines the minimum number of coaches needed per country
 * to be selected in a search.
 * @see https://speakapp.atlassian.net/browse/CONV-2919
 * @type {import('./constants').MIN_NUMBER_COACHES_AVAILABLE_PER_COUNTRY}
 */
exports.MIN_NUMBER_COACHES_AVAILABLE_PER_COUNTRY = 1;

/**
 * Admin Portal Socket event names
 */
exports.ADMIN_SOCKET = {
  ROOMS: {
    ACTIVE_CALLS: 'ACTIVE_CALLS',
    ACTIVE_SEARCHES: 'ACTIVE_SEARCHES',
    INSIGHT_DASHBOARD: 'INSIGHT_DASHBOARD',
    LIVE_ALERTS: 'LIVE_ALERTS',
    COACH_QUIZES: 'COACH_QUIZES',
    SCHEDULED_SESSION: 'SCHEDULED_SESSION',
    USER_SEARCHES: 'USER_SEARCHES',
  },
  EVENTS: {
    NEW_SEARCH: 'NEW_SEARCH',
    SEARCH_UPDATED: 'SEARCH_UPDATED',
    NEW_CALL: 'NEW_CALL',
    CALL_FINISHED: 'CALL_FINISHED',
    JOIN_ROOM: 'JOIN_ROOM',
    JOINED_ROOM: 'JOINED_ROOM',
    LIVE_ALERTS_UPDATED: 'LIVE_ALERTS_UPDATED',
    COACH_QUIZES_NEW_QUIZ: 'COACH_QUIZES_NEW_QUIZ',
    SCHEDULED_SESSION_NEW: 'SCHEDULED_SESSION_NEW',
    SCHEDULED_SESSION_UPDATED: 'SCHEDULED_SESSION_UPDATED',
    SCHEDULED_SESSION_CANCELED: 'SCHEDULED_SESSION_CANCELED',
    COACHES_QUEUE_UPDATE: 'COACHES_QUEUE_UPDATE',
  },
};

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2965
 * @type {import('./constants').SCHEDULE_SESION_EXPORT_FORMAT_DATE}
 */
exports.SCHEDULE_SESION_EXPORT_FORMAT_DATE = 'yyyy-MM-dd';

exports.DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2965
 * @type {import('./constants').SCHEDULE_SESION_DEFAULT_EXPORT_FORMAT_DATE}
 */
exports.SCHEDULE_SESION_DEFAULT_EXPORT_FORMAT_DATE = 'N/A';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2965
 * @type {import('./constants').SCHEDULE_SESION_FORMAT_DATE}
 */
exports.SCHEDULE_SESION_FORMAT_DATE = 'yyyy-MM-dd';
exports.SCHEDULE_SESION_FORMAT_DATE_TIME = 'yyyy-MM-dd HH:mm';
exports.SCHEDULE_SESION_DEFAULT_FORMAT_DATE = 'N/A';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3000
 * @type {import('./constants').MAX_TIME_IN_MILISECONDS_TO_EXECUTE_RULES}
 */
exports.MAX_TIME_IN_MILISECONDS_TO_EXECUTE_RULES = 5000;

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3000
 * @type {import('./constants').MAX_TIME_IN_MILISECONDS_TO_EXECUTE_RULE}
 */
exports.MAX_TIME_IN_MILISECONDS_TO_EXECUTE_RULE = 1000;

/**
 * User is considered 'idle' after 2 minutes without interact with the screen
 *
 * @see https://speakapp.atlassian.net/browse/CONV-3009
 * @type {import('./constants').USER_APP_IDLE_TIMER_TIMEOUT}
 */
exports.USER_APP_IDLE_TIMER_TIMEOUT = 1000 * 60 * 5;

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3009
 * @type {import('./constants').SORT_ORDER}
 */
exports.SORT_ORDER = {
  ASC: 1,
  DESC: -1,
};

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2948
 * @type {import('./constants').ENABLE_MONTHS_UNTIL}
 */
exports.ENABLE_MONTHS_UNTIL = 6;

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2948
 * @type {import('./constants').SHOW_HOURS_EVERY}
 */
exports.SHOW_HOURS_EVERY = 20;

exports.WEB_SOCKET_MAX_RECONNECTION_ATTEMPTS = 10;
exports.WEB_SOCKET_MAX_RECONNECTION_DELAY_MILLISECONDS = 3000;

/**
 * @type {import('./constants').MONETARY_NUMBER}
 */
exports.MONETARY_NUMBER = /^([0-9]{1,2}(\.[0-9]{1,2})?)$/;

/**
 * @type {import('./constants').WHOLE_NUMBER}
 */
exports.WHOLE_NUMBER = /^([0-9]{1,2})$/;

/**
 * @see https://speakapp.atlassian.net/browse/CONV-2959
 * @type {import('./constants').COACH_QUIZ_QUESTIONS}
 */
exports.COACH_QUIZ_QUESTIONS = [
  {
    questionId: 'profile.coach.quiz-modal.appropriate-places-title',
    question: 'Which is an appropriate place to take a Conversifi call?',
    type: 'single',
    answers: [
      {
        answerId: 'profile.coach.quiz-modal.outdoors',
        answer: 'Walking around, either indoors or outdoors',
        letter: 'A',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.at-a-desk',
        answer: 'Sitting at a desk or table in an uncluttered space',
        letter: 'B',
        isCorrect: true,
      },
      {
        answerId: 'profile.coach.quiz-modal.lying-in-bed',
        answer: 'Lying in bed, on the couch or floor',
        letter: 'C',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.in-a-public-place',
        answer: 'Sitting in a room with other people around',
        letter: 'D',
        isCorrect: false,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.when-working-with-beginner-title',
    question:
      "When you're working with a beginner, it's important to... Choose all that apply",
    type: 'multiple',
    answers: [
      {
        answerId:
          'profile.coach.quiz-modal.speak-very-slowly-and-clearly-title',
        answer: 'Speak very slowly and clearly.',
        letter: 'A',
        isCorrect: true,
      },
      {
        answerId: 'profile.coach.quiz-modal.use-simple-language-title',
        answer: 'Use simple language and avoid slang.',
        letter: 'B',
        isCorrect: true,
      },
      {
        answerId: 'profile.coach.quiz-modal.read-the-questions-exactly-title',
        answer: 'Go off topic and use complicated phrasing',
        letter: 'C',
        isCorrect: false,
      },
    ],
  },
  {
    questionId:
      'profile.coach.quiz-modal.when-you-are-talking-with-an-intermediate-title',
    question: "When you're talking with an intermediate or advanced partner...",
    type: 'single',
    answers: [
      {
        answerId:
          'profile.coach.quiz-modal.it-is-okay-to-skip-some-questions-title',
        answer:
          "It's okay to skip some questions or add your own, as long as you stay on the assigned topic.",
        letter: 'A',
        isCorrect: true,
      },
      {
        answerId:
          'profile.coach.quiz-modal.it-is-okay-to-skip-the-module-topic-title',
        answer:
          "It's okay to skip the module topic completely and talk about whatever you want.",
        letter: 'B',
        isCorrect: false,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.what-to-do-when-you-start-call-title',
    question:
      'What should you do when you start a call? Choose all that apply.',
    type: 'multiple',
    answers: [
      {
        answerId: 'profile.coach.quiz-modal.introduce-yourself-title',
        answer: 'Introduce yourself',
        letter: 'A',
        isCorrect: true,
      },
      {
        answerId: 'profile.coach.quiz-modal.smile-title',
        answer: 'Smile!',
        letter: 'B',
        isCorrect: true,
      },
      {
        answerId:
          'profile.coach.quiz-modal.ask-learner-where-they-are-from-title',
        answer:
          'Take a minute to ask questions about where the learner is from',
        letter: 'C',
        isCorrect: true,
      },
      {
        answerId: 'profile.coach.quiz-modal.start-reading-the-questions-title',
        answer: 'Start reading the questions immediately',
        letter: 'D',
        isCorrect: false,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.a-good-coach-should-title',
    question: 'A good coach should...',
    type: 'single',
    answers: [
      {
        answerId: 'profile.coach.quiz-modal.talk-more-than-learner-title',
        answer:
          'Talk more than the learner so your partner can practice listening.',
        letter: 'A',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.let-the-learner-do-talking-title',
        answer:
          'Let the learner do all the talking so they can practice speaking.',
        letter: 'B',
        isCorrect: false,
      },
      {
        answerId:
          'profile.coach.quiz-modal.find-balance-between-listening-title',
        answer: 'Find a good balance between listening and speaking.',
        letter: 'C',
        isCorrect: true,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.check-all-that-are-true-title',
    question: 'Check all that are true',
    type: 'multiple',
    answers: [
      {
        answerId:
          'profile.coach.quiz-modal.if-your-partner-does-not-understand-title',
        answer:
          "If your partner doesn't understand, you should try to explain another way instead of translating the word.",
        letter: 'A',
        isCorrect: true,
      },
      {
        answerId:
          'profile.coach.quiz-modal.it-is-important-to-correct-every-mistake-title',
        answer: "It's important to correct every mistake your partner makes.",
        letter: 'B',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.to-avoid-breaking-the-flow-title',
        answer:
          'To keep the flow of conversation, just repeat a mistaken phrase back correctly.',
        letter: 'C',
        isCorrect: true,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.partner-does-not-understand-you',
    question: "If your partner doesn't understand you...",
    type: 'single',
    answers: [
      {
        answerId: 'profile.coach.quiz-modal.speak-in-their-native-language',
        answer: "It's helpful to speak in their native language.",
        letter: 'A',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.keep-speaking-in-target-language',
        answer:
          'You should stay in the target language, even for beginners. Just be patient and encouraging!',
        letter: 'B',
        isCorrect: true,
      },
    ],
  },
  {
    questionId:
      'profile.coach.quiz-modal.which-of-the-following-actions-is-allowed-title',
    question: 'Which one of the following actions is allowed?',
    type: 'single',
    answers: [
      {
        answerId:
          'profile.coach.quiz-modal.telling-your-partner-they-are-pretty-title',
        answer:
          "Telling your partner they're pretty/handsome during your call.",
        letter: 'A',
        isCorrect: false,
      },
      {
        answerId:
          'profile.coach.quiz-modal.talking-about-your-partners-appearance-title',
        answer: "Talking about your partner's appearance and dating status.",
        letter: 'B',
        isCorrect: false,
      },
      {
        answerId:
          'profile.coach.quiz-modal.asking-your-partner-if-they-have-title',
        answer:
          'Asking your partner if they have a boyfriend/girlfriend during the call.',
        letter: 'C',
        isCorrect: false,
      },
      {
        answerId:
          'profile.coach.quiz-modal.including-your-contact-information-title',
        answer:
          "Including your contact information in the comments AFTER the call if you'd like to stay in touch, so your partner doesn't feel pressure during the call.",
        letter: 'D',
        isCorrect: true,
      },
    ],
  },
  {
    questionId: 'profile.coach.quiz-modal.when-can-you-end-your-call-title',
    question: 'When can you end your call?',
    type: 'single',
    answers: [
      {
        answerId: 'profile.coach.quiz-modal.two-minutes-before',
        answer: '2 minutes before the timer runs out',
        letter: 'A',
        isCorrect: false,
      },
      {
        answerId: 'profile.coach.quiz-modal.last-minute',
        answer: 'During the last minute on the timer',
        letter: 'B',
        isCorrect: true,
      },
    ],
  },
];

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3037
 * @type
 */
exports.AIRTABLE_ENDPOINT_URL = 'https://api.airtable.com';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3095
 */
exports.TRIGGER_LOGOUT_AFTER_INACTIVITY_MS = 10 * 60 * 1000;

exports.WEBSOCKET_PING_INTERVAL_MS = 10000;
exports.WEBSOCKET_PING_TIMEOUT_MS = 5000;

exports.PC_CALENDAR_TIME_FORMAT = 'hh:mm a';
exports.PC_CALENDAR_DAY_PICKER_FORMAT = 'eeee, MMMM dd';
exports.PC_CALENDAR_WEEK_DAY_FORMAT = 'eeee';
exports.SCHEDULE_RULE_TYPES = ['not-repeat', 'repeat', 'delete', 'override'];
exports.SCHEDULE_RULE_WEEK_DAYS = [1, 2, 3, 4, 5, 6, 0];
exports.REPEAT_RULE_DEFAULT_INTERVAL = 1;

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3306
 * @type {import('./constants').REMINDER_IN_24_HOURS}
 */
exports.REMINDER_IN_24_HOURS = 'REMINDER_IN_24_HOURS';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3306
 * @type {import('./constants').REMINDER_IN_15_MINUTES}
 */
exports.REMINDER_IN_15_MINUTES = 'REMINDER_IN_15_MINUTES';

exports.REMINDER_IN_15_MINUTES = 'REMINDER_IN_15_MINUTES';

/**
 * @see https://speakapp.atlassian.net/browse/CONV-3357
 * @type {import('./constants').MAX_SECTION_CODES}
 */
exports.MAX_SECTION_CODES = 4;

/**
 * Limit of elements in a list of twilio recordings
 * @see https://speakapp.atlassian.net/browse/CONV-3358
 *  * @type {import('./constants').LIMIT_OF_TWILIO_RECORDINGS}
 */
exports.LIMIT_OF_TWILIO_RECORDINGS = 4;

/**
 * Limit of elements in a list of twilio audio recordings
 * @see https://speakapp.atlassian.net/browse/CONV-3358
 *  @type {import('./constants').LIMIT_OF_TWILIO_AUDIO_RECORDINGS}
 */
exports.LIMIT_OF_TWILIO_AUDIO_RECORDINGS = 2;

/**
 * Limit of elements in a list of twilio video recordings
 * @see https://speakapp.atlassian.net/browse/CONV-3358
 *  @type {import('./constants').LIMIT_OF_TWILIO_VIDEO_RECORDINGS}
 */
exports.LIMIT_OF_TWILIO_VIDEO_RECORDINGS = 2;

/**
 * Conversifi issuer
 */
exports.CONVERSIFI_ISSUER = 'https://app.conversifi.com';

/**
 * Grace period in minutes to expire a scheduled session
 * @see https://speakapp.atlassian.net/browse/CONV-3361
 */
exports.SCHEDULED_SESSION_EXPIRE_GRACE_PERIOD_IN_MINUTES = 3;

exports.ZOOM_ISV_DOMAIN = 'ISV.conversifi.com';

/**
 * One minute.
 *
 * @see
 */
exports.MAX_TIME_PARTICIPANT_ALONE_IN_MEETING = 90000;
/**
 * Babbel max call duration in minutes
 */
exports.BABBEL_MAX_CALL_DURATION = 5.5;

/**
 * Begginer max call duration in minutes
 */
exports.BEGINNER_MAX_CALL_DURATION = 10;

/**
 * This determines the default tokenRate for new call configs
 */
exports.DEFAULT_CALL_CONFIG_TOKEN_RATE = 1;

exports.ZOOM_WEBHOOK_CUSTOM_HEADER_NAME = 'x-conversifi-zoom-webhook-header';

/**
 * By default, the idle timeout of an ALB is 60 seconds, which is fairly reasonable for most applications.
 */
exports.SERVER_KEEP_ALIVE_TIMEOUT = 65000;
