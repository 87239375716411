import React from 'react';
import { Trans } from '@lingui/react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CallDetail,
  StudentCallDetailRowData,
} from 'conversifi-types/src/shared-frontend-backend/InstructorReport';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { S3_URL_REGEX } from 'conversifi-shared-react/src/constants/constants';
import { InstructorSdk } from '../../../sdk/instructor.sdk';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { createChatTable } from 'conversifi-shared-react/es6/util/call/createChatTable';
import ensureDate from 'conversifi-commons/utils/ensureDate';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { BadgeWithChip } from 'conversifi-shared-react/es6/components/BadgeWithChip';
import { sdk } from '../../sdk';
import { INSTRUCTOR_REPORT_BAD_QUALITY_VIDEO_FORM_MAX_LENGTH } from '../../constants';
import { REACT_APP_CONVERSIFI_SUPPORT_EMAIL } from '../../../util/util.parsedEnvs';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';

/**
 * Loading indicator
 */
export const LoadingSpinner: React.FC = ({ children }) => {
  return (
    <Box textAlign="center">
      <CircularProgress />
      <Typography>{children}</Typography>
    </Box>
  );
};

/**
 * Error Message
 */
export const SummaryError: React.FC<{ isNotFound?: boolean }> = ({
  children,
  isNotFound,
}) => {
  return (
    <Box textAlign="center">
      <Typography>
        {isNotFound ? (
          <Trans id="instructors.report-not-found-title">No Activity</Trans>
        ) : (
          <Trans id="instructors.unexpected-error">Unexpected Error</Trans>
        )}
      </Typography>
      <Typography>{children}</Typography>
    </Box>
  );
};

const ReportVideoCall: React.FC<{ call: CallDetail }> = ({ call }) => {
  const [description, setDescription] = React.useState('');
  const [urgent, setUrgent] = React.useState(false);
  const [blocked, setBlocked] = React.useState(false);
  const trans = LinguiContext.useCtx().trans;
  const dialogCtx = DialogContext.useCtx();
  const snackbarCtx = SnackbarContext.useCtx();

  const reportProblem = async () => {
    setBlocked(true);
    await sdk.session.togglePoorQuality(call.call, description, urgent);
    snackbarCtx.setMessage({
      severity: 'success',
      message: (
        <Trans id="instructors.share-video-access.feedback.thanks-for-your-feedback">
          Thank you for your feedback. We will review the video and get back to
          you shortly.
        </Trans>
      ),
    });
    dialogCtx.closeDialog();
  };

  return (
    <Box mx={4} my={2}>
      <Typography variant="h6">
        <Trans id="instructors.share-video-access.feedback.title">
          What would you like us to check in this call?
        </Trans>
      </Typography>
      <Box sx={{ flex: 1 }}>
        <TextField
          multiline
          rows={6}
          placeholder={trans(
            'instructors.share-video-access.feedback.max-characters',
            {
              chars: INSTRUCTOR_REPORT_BAD_QUALITY_VIDEO_FORM_MAX_LENGTH,
            }
          )}
          sx={{ marginTop: '1rem', minHeight: '150px' }}
          inputProps={{
            maxLength: INSTRUCTOR_REPORT_BAD_QUALITY_VIDEO_FORM_MAX_LENGTH,
          }}
          fullWidth
          required
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={urgent} />}
          label={
            <Trans id="instructors.share-video-access.feedback.urgent">
              Urgent
            </Trans>
          }
          onChange={(_event, checked) => {
            setUrgent(checked);
          }}
        />
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button
          type="button"
          variant="outlined"
          color="error"
          onClick={dialogCtx.closeDialog}
        >
          <Trans id="common.cancel">Cancel</Trans>
        </Button>
        <Button
          onClick={reportProblem}
          disabled={blocked}
          variant="contained"
          color="primary"
        >
          <Trans id="instructors.share-video-access.feedback.send">Send</Trans>
        </Button>
      </Stack>
    </Box>
  );
};

export const VideoAccessColumn: React.FC<{
  call: CallDetail;
  onDownload: (url: string) => void;
  onPlay: (url: string) => void;
}> = ({ call, onDownload, onPlay }) => {
  const dialogCtx = DialogContext.useCtx();
  const snackbarCtx = SnackbarContext.useCtx();

  const onReport = () => {
    dialogCtx.renderFC(ReportVideoCall, { call });
  };

  const supportEmail = REACT_APP_CONVERSIFI_SUPPORT_EMAIL;

  return (
    <Box style={{ display: 'flex' }}>
      <Tooltip
        title={
          call.videoSharedWithFaculty ? (
            <Trans id="instructors.university-summary.watch-video">
              Watch video
            </Trans>
          ) : (
            <Trans id="instructors.university-summary.student-not-shared-video">
              The student has not shared the video yet
            </Trans>
          )
        }
      >
        <span>
          <IconButton
            sx={{
              color: call.videoSharedWithFaculty ? 'primary.main' : '',
            }}
            disabled={!call.videoSharedWithFaculty}
            onClick={async () => {
              if (call.videoSharedWithFaculty) {
                let mediaUrl = '';

                if (call.platformSource === 'zoom') {
                  mediaUrl = call.zoom.composition?.media ?? '';
                } else {
                  mediaUrl = call.twilio?.composition?.media;
                }

                try {
                  let url;

                  url = S3_URL_REGEX.test(mediaUrl)
                    ? await InstructorSdk.getCallMediaData(call.call)
                    : `/api/calls/${call.call}/media`;

                  console.info('onPlay from Components', url);
                  onPlay(url);
                } catch (err) {}
              }
            }}
          >
            <PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={
          call.videoSharedWithFaculty ? (
            <Trans id="instructors.university-summary.download-video">
              Download video
            </Trans>
          ) : (
            <Trans id="instructors.university-summary.student-not-shared-video">
              The student has not shared the video yet
            </Trans>
          )
        }
      >
        <span>
          <IconButton
            sx={{
              color: call.videoSharedWithFaculty ? 'primary.main' : '',
            }}
            disabled={!call.videoSharedWithFaculty}
            onClick={async () => {
              if (call.videoSharedWithFaculty) {
                onDownload(`/api/calls/${call.call}/media/download`);
              }
            }}
          >
            <CloudDownloadOutlinedIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip
        title={
          call.instructorReportsPoorQuality ? (
            <Trans
              id="instructors.university-summary.message-sent"
              values={{ supportEmail }}
            >
              Message sent: contact us at {supportEmail} with any additional
              information
            </Trans>
          ) : (
            <Trans id="instructors.university-summary.send-a-message-about-this-call">
              Send us a message about this call
            </Trans>
          )
        }
      >
        <span>
          <IconButton
            sx={{
              color: call.videoSharedWithFaculty ? 'primary.main' : '',
            }}
            disabled={
              !call.videoSharedWithFaculty || call.instructorReportsPoorQuality
            }
            onClick={onReport}
          >
            <FlagCircleOutlinedIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip
        title={
          <Trans id="instructors.university-summary.download-chat">
            Download chat from call
          </Trans>
        }
      >
        <IconButton
          sx={{
            color: call.videoSharedWithFaculty ? 'primary.main' : '',
          }}
          onClick={async () => {
            const { learner, coach, chat, date } =
              await InstructorSdk.Calls.getChatInformation(call.call);

            if (chat.length) {
              createChatTable(
                call.platformSource,
                learner,
                coach,
                chat,
                ensureDate(date)
              );
            } else {
              snackbarCtx.setMessage({
                severity: 'error',
                message: (
                  <Trans id="instructors.university-summary.no-chat-for-call">
                    There isn't chat for this call
                  </Trans>
                ),
              });
            }
          }}
        >
          <ChatOutlinedIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const CoachBadges: React.FC<{
  call: StudentCallDetailRowData | CallDetail;
}> = ({ call }): JSX.Element | null => {
  const badges = call?.feedbackCoach?.badges ?? [];

  return badges.length ? (
    <Box textAlign="center">
      {badges.map((badge) => (
        <BadgeWithChip
          key={`${call.call}-${badge}`}
          sx={{ mt: 1, mb: 1 }}
          content={
            <Typography
              component="span"
              sx={{
                display: 'inline-flex',
                minWidth: '110px',
                justifyContent: 'center',
              }}
            >
              <Trans id={`badges.${badge}`}></Trans>
            </Typography>
          }
        />
      ))}
    </Box>
  ) : null;
};
