import { parseIntOrDefault } from './util.js';
import { Routes } from '../routes';

/**
 * normalize and cast all the environment variables
 */

type T_NODE_ENV = Readonly<typeof process.env.NODE_ENV>;
type T_PUBLIC_URL = Readonly<typeof process.env.PUBLIC_URL>;
type T_LOGIN_ENABLE_DEV_CREDS = Readonly<boolean>;
type T_LOGIN_DEV_CREDS_EMAIL = Readonly<
  typeof process.env.LOGIN_DEV_CREDS_EMAIL
>;
type T_LOGIN_DEV_CREDS_PASSWORD = Readonly<
  typeof process.env.LOGIN_DEV_CREDS_PASSWORD
>;

export const NODE_ENV: T_NODE_ENV = process.env.NODE_ENV || 'development';
export const PUBLIC_URL: T_PUBLIC_URL = process.env.PUBLIC_URL;
export const LOGIN_ENABLE_DEV_CREDS: T_LOGIN_ENABLE_DEV_CREDS =
  process.env.LOGIN_ENABLE_DEV_CREDS === 'yes';
export const LOGIN_DEV_CREDS_EMAIL: T_LOGIN_DEV_CREDS_EMAIL =
  process.env.LOGIN_DEV_CREDS_EMAIL;
export const LOGIN_DEV_CREDS_PASSWORD: T_LOGIN_DEV_CREDS_PASSWORD =
  process.env.LOGIN_DEV_CREDS_PASSWORD;

/**
 * delay after user stop typing to trigger input validation
 */
export const REACT_APP_INPUT_VALIDATION_TIMEOUT = parseIntOrDefault(
  process.env.REACT_APP_INPUT_VALIDATION_TIMEOUT,
  700
);

export const REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH || '/';

export const REACT_APP_GOOGLE_API_KEY = process.env
  .REACT_APP_GOOGLE_API_KEY as string;

if (!REACT_APP_GOOGLE_API_KEY) {
  console.warn(
    `
    WARNING: no google api key was provided.
    Please, create a var in your .env file called
    "REACT_APP_GOOGLE_API_KEY" with your google
    api key. See .env.example
    `
  );
}

export const REACT_APP_CHAR_DELIMIT = process.env.REACT_APP_CHAR_DELIMIT || '|';

export const REACT_APP_UNCONFIRMED_SMS_CODE_LEN = parseInt(
  (process.env.REACT_APP_UNCONFIRMED_SMS_CODE_LEN as string) || '10',
  10
);

if (
  !REACT_APP_UNCONFIRMED_SMS_CODE_LEN ||
  isNaN(REACT_APP_UNCONFIRMED_SMS_CODE_LEN)
) {
  console.warn(
    `
    WARNING: REACT_APP_UNCONFIRMED_SMS_CODE_LEN.
    is not defined or is invalid
    `
  );
}

export const REACT_APP_TWILIO_CODE_INVALID_PHONE = parseInt(
  process.env.REACT_APP_TWILIO_CODE_INVALID_PHONE || '21211',
  10
);

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

if (!REACT_APP_ENV) {
  console.warn('REACT_APP_ENV was not set.');
}

export const REACT_APP_SEARCH_INSIGHTS_REQUEST_INTERVAL = parseIntOrDefault(
  process.env.REACT_APP_SEARCH_INSIGHTS_REQUEST_INTERVAL,
  20
);

if (!REACT_APP_SEARCH_INSIGHTS_REQUEST_INTERVAL) {
  console.warn(`
  REACT_APP_SEARCH_INSIGHTS_REQUEST_INTERVAL was not set.
  A default value of 20 seconds will be used.`);
}

if (!process.env.REACT_APP_UNIVERSITY_ADMIN_LANDING_URL) {
  console.warn(`
  REACT_APP_UNIVERSITY_ADMIN_LANDING_URL was not set.
  default to "${Routes.universityAdmin.uri}".
  `);
}

export const REACT_APP_UNIVERSITY_ADMIN_LANDING_URL =
  process.env.REACT_APP_UNIVERSITY_ADMIN_LANDING_URL ||
  `${Routes.universityAdmin.uri}`;

export const REACT_APP_PORT = process.env.REACT_APP_PORT || '3000';

export const REACT_APP_INSTRUCTORS_LANDING_URL =
  process.env.REACT_APP_INSTRUCTORS_LANDING_URL || Routes.instructor.uri;

export const REACT_APP_APP_VERSION = process.env.REACT_APP_APP_VERSION;
export const REACT_APP_DEPLOYMENT_ID =
  process.env.REACT_APP_DEPLOYMENT_ID || '%';

// If `true` and `NODE_ENV === development` disable
// the google api for location in the register form (frontend)
// and in the register endpoint (backend) as the google location api doesn't
// work anymore in localhost. In this case a default (fake)
// location will be used.
// THIS ENV IS SET AT: conversifi-commons/config.env
export const REACT_APP_DISABLE_GOOGLE_LOCATION_API_IN_REGISTER =
  process.env.REACT_APP_DISABLE_GOOGLE_LOCATION_API_IN_REGISTER === 'true' &&
  NODE_ENV === 'development';

export const REACT_APP_CONVERSIFI_DEFAULT_TITLE =
  process.env.REACT_APP_CONVERSIFI_DEFAULT_TITLE || 'Conversifi';

export const REACT_APP_CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

export const REACT_APP_CONVERSIFI_API_PATH =
  process.env.REACT_APP_CONVERSIFI_API_PATH;

export const REACT_APP_CONVERSIFI_NOREPLY_EMAIL =
  process.env.REACT_APP_CONVERSIFI_NOREPLY_EMAIL;

/**
 * Balance change reasons
 */
export const REACT_APP_BALANCE_CHANGE_REASONS = JSON.parse(
  process.env.REACT_APP_BALANCE_CHANGE_REASONS ?? '[]'
);

export const REACT_APP_SUCCESSFUL_CALL_THRESHOLD_IN_MIN =
  Number(process.env.REACT_APP_SUCCESSFUL_CALL_THRESHOLD_IN_MIN) || 10;

export const REACT_APP_CONVERSIFI_SUPPORT_EMAIL =
  Number(process.env.REACT_APP_CONVERSIFI_SUPPORT_EMAIL) ||
  'support@conversifi.com';

export const REACT_APP_WEB_SOCKET_MAX_RECONNECTION_ATTEMPTS =
  Number(process.env.REACT_APP_WEB_SOCKET_MAX_RECONNECTION_ATTEMPTS) || 10;

export const REACT_APP_WEB_SOCKET_MAX_RECONNECTION_DELAY_MILLISECONDS =
  Number(
    process.env.REACT_APP_WEB_SOCKET_MAX_RECONNECTION_DELAY_MILLISECONDS
  ) || 3000;
