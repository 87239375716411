import React from 'react';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { Ringer } from 'conversifi-shared-react/es6/components/Ringer';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';

export const Dialog: React.FC = (props) => {
  const trans = LinguiContext.useCtx().trans;
  const ringerCtx = Ringer.useCtx();

  return (
    <DialogContext trans={trans} ringer={ringerCtx}>
      {props.children}
    </DialogContext>
  );
};
