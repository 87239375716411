import React, { FC } from 'react';
import style from './style.module.css';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
  inputId: string;
  label: string;
  helper?: string;
}

export const RegisterOptInMessages: FC<Props> = (props) => {
  return (
    <Box className={style.container}>
      <FormControlLabel
        control={
          <Checkbox
            className={style.optInCheckbox}
            checked={props.value}
            onChange={(e) => props.onValueChange(e.target.checked)}
          />
        }
        label={props.label}
      />
      {props.helper && (
        <FormHelperText className={style.optInCheckbox}>
          {props.helper}
        </FormHelperText>
      )}
    </Box>
  );
};
