import React from 'react';
import { userLogout } from '../api';
import AppLoading from '../App.loading';
import { Routes } from '../routes';

const LogoutComponent: React.FC = () => {
  userLogout()
    .catch((err) => {
      const statusCode = err.data?.statusCode ?? 500;

      if (statusCode < 500) {
        console.info(`[LC12] Error Code ${statusCode}:`, err);
      } else {
        console.error('[LC14]', err);
      }
    })
    .finally(() => {
      window.location.href = Routes.login.uri;
    });
  return <AppLoading />;
};

export default LogoutComponent;
