import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { injectScript } from '../util/dom/injectScript';
import { useLocation } from 'react-router-dom';
const { REACT_APP_ENV, REACT_APP_GANALYTICS_MEASUREMENT_ID } = process.env;
export const useGaEvents = () => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();
    const [pageLocation, setPageLocation] = useState('');
    useEffect(() => setPageLocation(location.pathname), [location.pathname]);
    const envEnable = REACT_APP_ENV === 'uat' || REACT_APP_ENV === 'prod';
    useEffect(() => {
        startTracker();
    }, []);
    const startTracker = async () => {
        if (REACT_APP_GANALYTICS_MEASUREMENT_ID !== '' && envEnable) {
            await injectScript(`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GANALYTICS_MEASUREMENT_ID}`);
            ReactGA.initialize(REACT_APP_GANALYTICS_MEASUREMENT_ID);
            setInitialized(true);
        }
    };
    const sendEvent = (description, userSource) => {
        if (initialized) {
            ReactGA.event('conversifi_event', {
                description,
                user_source: userSource ?? '',
                page_location: pageLocation,
            });
        }
    };
    return {
        sendEvent,
    };
};
