import React, { useState, useContext, useEffect, useRef } from 'react';
import { GooglePlacesContext } from '../../contexts';
import { RegisterHandle, RegisterLocation, RegisterPicture } from './inputs';
import { InputStatus } from '../../components/InputTextState';
import type { SMSVerified, User } from 'conversifi-types/src/User/User';
import type { LangCode, AllLangCode } from 'conversifi-types/src/Lang';
import { ReactFormEvent } from '../../util';
import { InputMobilePhone } from '../../components/InputMobilePhone';
import { CountryCode } from 'libphonenumber-js/min';
import {
  userPasswordValidationSchema,
  userFirstNameValidationSchema,
  userLastNameValidationSchema,
} from '../../validations/yupValidations';
import { REACT_APP_DISABLE_GOOGLE_LOCATION_API_IN_REGISTER } from '../../util/util.parsedEnvs';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import { UserSource } from 'conversifi-types/src/User/UserSource';
import { UserRegisterData } from 'conversifi-types/src/shared-frontend-backend/UserRegisterData';
import { RegisterOptInMessages } from './inputs/RegisterOptInMessages';
import { RegisterBirthday } from './inputs/RegisterBirthday';
import { getDateInUsDateFormat } from '../../util/util.date';
import { useGaEvents } from 'conversifi-shared-react/es6/hooks/useGaEvents';
import { SORT_ORDER } from 'conversifi-commons/constants';
import { getComparator } from '../../util/util.sortTables';
import {
  Box,
  Collapse,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';
import { CountryInfo } from 'conversifi-types/src/CountryInfo';
import InputTextField from '../components/InputTextField';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import InpuTSelect from '../components/InputSelect';
import { Trans } from '@lingui/react';
import { PROFICIENCY_VALUES } from '../../constants/proficiencyValues';
import InputSubmit from '../components/InputSubmit';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import { getLanguageNameBrowserLang } from 'conversifi-shared-react/es6/util/miscellaneous/languages';
import { phoneNumberValidateFlexible } from 'conversifi-commons/helpers/phoneHelper';

interface Props {
  userSource: UserSource;
  onSubmit: (data: UserRegisterData) => void;
}

export const RegisterFormStep2: React.FC<Props> = ({
  userSource,
  onSubmit,
}) => {
  const GoogleApisContext = useContext(GooglePlacesContext);
  const languagesCtx = LanguagesContext.useCtx();
  const trans = LinguiContext.useCtx().trans;
  const countriesCtx = CountriesContext.useCtx();

  GoogleApisContext.ensureGoogleLibrary();

  const [passwordStatus, setPasswordStatus] = useState<InputStatus>({
    error: '',
    isValid: false,
    touched: false,
    value: '',
  });
  const [firstNameStatus, setFirstNameStatus] = useState<InputStatus>({
    error: '',
    isValid: false,
    touched: false,
    value: '',
  });
  const [lastNameStatus, setLastNameStatus] = useState<InputStatus>({
    error: '',
    isValid: false,
    touched: false,
    value: '',
  });
  const [handleStatus, setHandleStatus] = useState<InputStatus>({
    error: '',
    isValid: false,
    touched: false,
    value: '',
  });
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [callingCode, setCallingCode] = useState<CountryCode>('US');
  const [birthdateStatus, setBirthdateStatus] = useState<InputStatus>({
    error: '',
    isValid: false,
    touched: false,
    value: '',
  });
  const [nativeLang, setNativeLang] = useState<LangCode>('en');
  const [nativeLangMain, setNativeLangMain] = useState<AllLangCode>('en');
  const [currentLocation, setCurrentLocation] =
    useState<User['currentLocation']>();
  const [country, setCountry] = useState<CountryInfo>();
  const [targetLang, setTargetLang] = useState<LangCode>();
  const [proficiency, setProficiency] = useState<string>('');
  const [photoId, setPhotoId] = useState<string>('');
  const [checkOptInMessages, setCheckOptInMessages] = useState<boolean>(false);
  const [smsVerify, setSMSVerify] = useState<SMSVerified>();
  const [isInputLocationValid, setIsInputLocationValid] =
    useState<boolean>(false);

  // We have a edge case with the "Other Language" option. In order to allow the user to select it, we need to add it to the list of languages. We will ignore the linter error for this line because the langCode 'XX' is not a valid language code.
  // @ts-ignore
  const LANGUAGES_WITH_OTHER = languagesCtx.getEnabledLanguages().concat({
    enabled: true,
    langCode: 'XX',
  });

  useEffect(() => {
    setNativeLang(nativeLangMain === 'XX' ? 'en' : nativeLangMain);
  }, [nativeLangMain]);

  useEffect(() => {
    if (userSource === 'babbel') {
      setNativeLang('en');
      setCountry(
        countriesCtx
          .getCountries()
          .find((country) => country.alpha2Code === 'US')
      );
      setCurrentLocation({
        address: 'New York, NY',
        currentCountry: 'US',
      });
      setTargetLang('es');
      setProficiency('BEGINNER');
      setNativeLangMain('en');
    }
    // eslint-disable-next-line
  }, [userSource]);

  useEffect(() => {
    if (!checkOptInMessages) {
      setPhoneNumber('');
      setSMSVerify('NOT_VERIFY');
    } else {
      setSMSVerify('VERIFY');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOptInMessages]);

  const mainCountries = ['MX', 'US'];
  const firstCountries = countriesCtx
    .getCountries()
    .filter((country) => mainCountries.includes(country.alpha2Code));
  const restCountries = countriesCtx
    .getCountries()
    .filter((country) => !mainCountries.includes(country.alpha2Code));
  const orderedCountries = [
    ...firstCountries.sort(getComparator(SORT_ORDER.DESC, 'name')),
    ...restCountries.sort(getComparator(SORT_ORDER.ASC, 'name')),
  ];

  const passIsValid = passwordStatus && passwordStatus.isValid;
  const firstNameIsValid = firstNameStatus && firstNameStatus.isValid;
  const lastNameIsValid = lastNameStatus && lastNameStatus.isValid;
  const handleIsValid = handleStatus && handleStatus.isValid;
  const phoneNumIsValid = phoneNumber
    ? phoneNumberValidateFlexible(phoneNumber)
    : false;
  const nativeLangIsValid =
    nativeLang &&
    languagesCtx
      .getEnabledLanguages()
      .some((language) => language.langCode === nativeLang);

  const currentLocationIsValid =
    REACT_APP_DISABLE_GOOGLE_LOCATION_API_IN_REGISTER
      ? true
      : Boolean(
          currentLocation &&
            currentLocation.address &&
            currentLocation.currentCountry
        );
  const babbelDateOfBirthIsValid =
    userSource !== 'babbel' ? true : birthdateStatus?.isValid;

  const translationBySource =
    userSource === 'conversifi' ? 'Conversifi' : 'Connect';

  const loginIsDisabled =
    !passIsValid ||
    !firstNameIsValid ||
    !lastNameIsValid ||
    !handleIsValid ||
    !currentLocationIsValid ||
    !isInputLocationValid ||
    !phoneNumIsValid ||
    !nativeLangIsValid ||
    // Country where you learned it
    !country ||
    !targetLang ||
    !proficiency ||
    !babbelDateOfBirthIsValid;

  function _onSubmit(event: ReactFormEvent) {
    event.preventDefault();
    if (!loginIsDisabled) {
      onSubmit({
        // @ts-ignore (we already validated the values)
        password: passwordStatus.value,
        // @ts-ignore (we already validated the values)
        firstName: firstNameStatus.value,
        // @ts-ignore (we already validated the values)
        lastName: lastNameStatus.value,
        // @ts-ignore (we already validated the values)
        handle: handleStatus.value,
        // @ts-ignore (we already validated the values)
        phone: phoneNumber,
        ...(phoneNumber &&
          userSource === 'babbel' && { allowReceiveSms: true }),
        language: {
          code: nativeLang,
          country: country.alpha2Code,
          isNative: nativeLangMain !== 'XX',
        },
        currentLocation: {
          ...(currentLocation as User['currentLocation']),
          callingCode,
        },
        targetLanguages: [
          {
            language: {
              code: targetLang as LangCode,
            },
            proficiency: {
              level: proficiency,
            },
          },
        ],
        photoId,
        confirmationCode: '',
        dob: birthdateStatus?.value
          ? getDateInUsDateFormat(birthdateStatus?.value)
          : birthdateStatus?.value,
        SMSVerified: smsVerify,
      });
    }
  }

  const displayPhoneField =
    userSource === 'conversifi' ||
    (userSource === 'babbel' && checkOptInMessages);

  const emailVerifiedFirstTime = useRef<boolean>(false);
  const { sendEvent } = useGaEvents();

  useEffect(() => {
    if (!emailVerifiedFirstTime.current) {
      sendEvent('email_verified_on_register', userSource);
      emailVerifiedFirstTime.current = true;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewPhone = (phone?: any, callingCode?: any) => {
    if (phone) setPhoneNumber(phone);

    if (callingCode) setCallingCode(callingCode);
  };

  const setPassword = (password: string) => {
    try {
      userPasswordValidationSchema.validateSync(password);
      setPasswordStatus({
        value: password,
        isValid: true,
        touched: true,
        error: '',
      });
    } catch (error) {
      setPasswordStatus({
        value: password,
        isValid: false,
        touched: true,
        error: error.message,
      });
    }
  };

  const setFirstName = (firstName: string) => {
    try {
      userFirstNameValidationSchema.validateSync(firstName);
      setFirstNameStatus({
        value: firstName,
        isValid: true,
        touched: true,
        error: '',
      });
    } catch (error) {
      setFirstNameStatus({
        value: firstName,
        isValid: false,
        touched: true,
        error: error.message,
      });
    }
  };

  const setLastname = (lastName: string) => {
    try {
      userLastNameValidationSchema.validateSync(lastName);
      setLastNameStatus({
        value: lastName,
        isValid: true,
        touched: true,
        error: '',
      });
    } catch (error) {
      setLastNameStatus({
        value: lastName,
        isValid: false,
        touched: true,
        error: error.message,
      });
    }
  };

  const onSetCountry = (countryName: string) => {
    const countries = countriesCtx.getCountries();

    const countryFound = countries.find(
      (countryItem: CountryInfo) => countryItem.name === countryName
    );

    setCountry(countryFound);
  };

  return (
    <form onSubmit={_onSubmit}>
      <Stack direction="column" spacing={2} my={3}>
        <InputTextField
          type="password"
          id="registerForm.step2.password"
          name="register-password"
          required
          value={passwordStatus.value}
          onChange={(e) => setPassword(e.target.value)}
          label={trans({
            id: 'anonymous.register.inputs.password',
            message: 'Password',
          })}
          placeholder={trans({
            id: 'anonymous.register.inputs.password',
            message: 'Password',
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" color="white">
                <HttpsOutlinedIcon sx={{ color: 'white' }} fontSize="large" />
              </InputAdornment>
            ),
          }}
          onFocus={() => setPassword(passwordStatus.value ?? '')}
          onBlur={() => setPassword(passwordStatus.value ?? '')}
          error={passwordStatus.touched && !passwordStatus.isValid}
          helperText={passwordStatus.error}
        />
        <InputTextField
          type="text"
          id="registerForm.step2.firstName"
          name="registerForm.step2.firstName"
          required
          value={firstNameStatus.value}
          onChange={(e) => setFirstName(e.target.value)}
          label={trans({
            id: 'anonymous.register.inputs.first-name',
            message: 'First name',
          })}
          placeholder={trans({
            id: 'anonymous.register.inputs.first-name',
            message: 'First name',
          })}
          onFocus={() => setFirstName(firstNameStatus.value ?? '')}
          onBlur={() => setFirstName(firstNameStatus.value ?? '')}
          error={firstNameStatus.touched && !firstNameStatus.isValid}
          helperText={firstNameStatus.error}
        />
        <InputTextField
          type="text"
          id="registerForm.step2.lastName"
          name="registerForm.step2.lastName"
          required
          value={lastNameStatus.value}
          onChange={(e) => setLastname(e.target.value)}
          label={trans({
            id: 'anonymous.register.inputs.last-name',
            message: 'Last name',
          })}
          placeholder={trans({
            id: 'anonymous.register.inputs.last-name',
            message: 'Last name',
          })}
          onFocus={() => setLastname(lastNameStatus.value ?? '')}
          onBlur={() => setLastname(lastNameStatus.value ?? '')}
          error={lastNameStatus.touched && !lastNameStatus.isValid}
          helperText={lastNameStatus.error}
        />
        <RegisterHandle
          status={handleStatus}
          onStatusChange={setHandleStatus}
        />
        {userSource === 'babbel' && (
          <RegisterBirthday
            onStatusChange={setBirthdateStatus}
            birthDate={birthdateStatus}
          />
        )}
        <RegisterPicture photoId={photoId} onUploadSuccess={setPhotoId} />
        {userSource === 'conversifi' && (
          <RegisterLocation
            required={true}
            value={currentLocation?.address ?? ''}
            onPlaceChange={setCurrentLocation}
            inputId="registerForm.step2.location"
            label={trans('anonymous.register.inputs.location')}
            transparent
            onError={(isError) => setIsInputLocationValid(!isError)}
          />
        )}
        {userSource === 'babbel' && (
          <RegisterOptInMessages
            inputId="registerForm.step2.optInMessages"
            value={checkOptInMessages}
            onValueChange={setCheckOptInMessages}
            label={trans('anonymous.register.inputs.optInMessages')}
            helper={trans({
              id: 'sms-sent.send-me-periodic-text-messages',
              values: {
                source: translationBySource,
              },
              message: `Send me periodic text messages to maximize the ${translationBySource} experience (regular rates apply).`,
            })}
          />
        )}
        {displayPhoneField && (
          <Box sx={{ my: 4 }}>
            <InputMobilePhone
              onStatusChange={setNewPhone}
              label={trans('anonymous.register.inputs.phone')}
              registerScreenStyle={true}
            />
          </Box>
        )}
        {userSource === 'conversifi' && (
          <>
            <FormControl fullWidth required>
              <InputLabel sx={{ color: 'white' }}>
                <Trans id="anonymous.register.inputs.coaching-language-main">
                  Native language
                </Trans>
              </InputLabel>
              <InpuTSelect
                id="registerForm.step2.coachingLanguageMain"
                value={nativeLangMain}
                fullWidth
                label={
                  <Trans id="anonymous.register.inputs.coaching-language-main">
                    Native language
                  </Trans>
                }
                onChange={(e) =>
                  setNativeLangMain(e.target.value as AllLangCode)
                }
              >
                {LANGUAGES_WITH_OTHER.map(({ langCode }) => (
                  <MenuItem key={`NL-${langCode}`} value={langCode}>
                    {getLanguageNameBrowserLang(langCode)}
                  </MenuItem>
                ))}
              </InpuTSelect>
            </FormControl>
            <Collapse in={nativeLangMain === 'XX'}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'white' }}>
                  <Trans id="anonymous.register.inputs.coaching-language">
                    Screen language
                  </Trans>
                </InputLabel>
                <InpuTSelect
                  id="registerForm.step2.coachingLanguage"
                  value={nativeLang}
                  fullWidth
                  label={
                    <Trans id="anonymous.register.inputs.coaching-language">
                      Screen language
                    </Trans>
                  }
                  onChange={(e) => setNativeLang(e.target.value as LangCode)}
                >
                  {languagesCtx.getEnabledLanguages().map((lang) => (
                    <MenuItem key={`SL-${lang.langCode}`} value={lang.langCode}>
                      {getLanguageNameBrowserLang(lang.langCode)}
                    </MenuItem>
                  ))}
                </InpuTSelect>
              </FormControl>
            </Collapse>
            <FormControl fullWidth required>
              <InputLabel sx={{ color: 'white' }}>
                <Trans id="anonymous.register.inputs.country-where-you-learned-it">
                  Country where you learned it
                </Trans>
              </InputLabel>
              <InpuTSelect
                id="registerForm.step2.country"
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                value={country}
                fullWidth
                label={
                  <Trans id="anonymous.register.inputs.country-where-you-learned-it">
                    Country where you learned it
                  </Trans>
                }
                onChange={(e) => onSetCountry(String(e.target.value))}
              >
                {orderedCountries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </InpuTSelect>
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel sx={{ color: 'white' }}>
                <Trans id="anonymous.register.inputs.language-you-are-studying">
                  Language you’re studying
                </Trans>
              </InputLabel>
              <InpuTSelect
                id="registerForm.step2.targetLanguage"
                value={targetLang}
                fullWidth
                label={
                  <Trans id="anonymous.register.inputs.language-you-are-studying">
                    Language you’re studying
                  </Trans>
                }
                onChange={(e) => setTargetLang(e.target.value as LangCode)}
              >
                {languagesCtx.getEnabledLanguages().map((lang) => (
                  <MenuItem key={`LL-${lang.langCode}`} value={lang.langCode}>
                    {getLanguageNameBrowserLang(lang.langCode)}
                  </MenuItem>
                ))}
              </InpuTSelect>
            </FormControl>
            <Collapse in={Boolean(targetLang)}>
              <FormControl fullWidth required>
                <InputLabel sx={{ color: 'white' }}>
                  <Trans id="anonymous.register.inputs.proficiency">
                    Proficiency
                  </Trans>
                </InputLabel>
                <InpuTSelect
                  id="registerForm.step2.proficiency"
                  value={proficiency}
                  fullWidth
                  label={
                    <Trans id="anonymous.register.inputs.proficiency">
                      Proficiency
                    </Trans>
                  }
                  onChange={(e) => setProficiency(e.target.value as string)}
                >
                  {PROFICIENCY_VALUES.map((proficiencyItem) => (
                    <MenuItem key={proficiencyItem} value={proficiencyItem}>
                      <Trans id={`proficiency.${proficiencyItem}`}>
                        {proficiencyItem}
                      </Trans>
                    </MenuItem>
                  ))}
                </InpuTSelect>
              </FormControl>
            </Collapse>
          </>
        )}
      </Stack>
      <InputSubmit
        id="registerForm.step2.submit"
        fullWidth
        disabled={loginIsDisabled}
        type="submit"
        variant="contained"
      >
        <Trans id="anonymous.register.inputs.register">Register</Trans>
      </InputSubmit>
    </form>
  );
};
